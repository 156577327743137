<template>
    <b-container>
        <b-row>
            <b-col cols="12" sm="12" md="12" lg="12">
                <h2 class="font-size-vw txt_primary text-uppercase pl-3">Hoja de valoración pre-anestésica</h2>
            </b-col>
        </b-row>

        <b-container>
            <b-row class="margen">
                <b-col class="p-0" cols="12" sm="12" md="12" lg="3">
                    <div class="container">
                        <section class="step-indicator">
                            <div class="step step1">
                                <button id="step1" @click="formNotaPreA('NotaPreAnestesicaUno')" class="step-icon" />
                            </div>
                            <div class="indicator-line"></div>
                            <div class="step step2 active">
                                <!-- <button id="step2" @click="formNotaPreA('NotaPreAnestesicaDos')" class="step-icon" /> -->
                                <button id="step2" class="step-icon" />
                            </div>
                        </section>
                    </div>
                </b-col>
            </b-row>

            <div class="sticky-top pt-3" v-if="(isEgresado && !loading)">
                <b-alert show dismissible variant="warning">
                    El paciente ha egresado desea ver la <b style="padding-top: 4px;">&rArr;</b> <strong
                        class="alert-nota-egreso" @click="verNotaDeEgreso()">nota de egreso</strong>
                </b-alert>
            </div>

            <div v-else-if="(dtaExist && !loading)" class="sticky-top mt-3">
                <b-alert show dismissible variant="info">
                    <div class="d-flex" v-if="(datos.firma.is_firmado == 1)">
                        <i class="fa fa-floppy-o fa-lg my-auto pr-2" />
                        Nota firmada y guardada correctamente
                    </div>
                    <div class="d-flex" v-if="(datos.firma.is_firmado == 0)">
                        <i class="fa fa-floppy-o fa-lg my-auto pr-2" />
                        Guardado correctamente, se requiere firmar la nota
                    </div>
                </b-alert>
            </div>

            <b-row class="mt-3">
                <b-col cols="12" sm="12" md="5" lg="5">
                    <b-table-simple class="table-sm">
                        <b-tbody>
                            <b-tr>
                                <b-th class="th_style size_th">Peso</b-th>
                                <b-td class="border_table">
                                    <div class="d-flex">
                                        <b-form-input :disabled="(disableInput || state.oldExp || isEgresado)" type="text"
                                            name="peso" autocomplete="off" v-on:keypress="soloNumeros($event)"
                                            @change="calcularMasa(datos.peso)" maxlength="6" :state="peso"
                                            v-model="datos.peso" class="border-0" size="sm" />
                                        <span class="float-right px-1 txt_gray">
                                            <span class="txt_gray size-span">kg</span>
                                        </span>
                                    </div>
                                </b-td>
                            </b-tr>
                            <b-tr>
                                <b-th class="th_style">Talla</b-th>
                                <b-td class="border_table">
                                    <div class="d-flex">
                                        <b-form-input :disabled="(disableInput || state.oldExp || isEgresado)" type="text"
                                            name="talla" autocomplete="off" v-on:keypress="soloNumeros($event)"
                                            maxlength="6" @change="calcularMasa(datos.peso)" :state="talla"
                                            v-model="datos.talla" class="border-0" size="sm" />
                                        <span class="float-right px-1 txt_gray">
                                            <span class="txt_gray size-span">cm</span>
                                        </span>
                                    </div>
                                </b-td>
                            </b-tr>
                            <b-tr>
                                <b-th class="th_style">Temperatura</b-th>
                                <b-td class="border_table">
                                    <div class="d-flex">
                                        <b-form-input :disabled="(disableInput || state.oldExp || isEgresado)" type="text"
                                            name="temperatura" autocomplete="off" v-on:keypress="soloNumeros($event)"
                                            maxlength="6" :state="temperatura" v-model="datos.temperatura" class="border-0"
                                            size="sm" />
                                        <span class="float-right px-1 txt_gray">
                                            <span class="txt_gray size-span">°C</span>
                                        </span>
                                    </div>
                                </b-td>
                            </b-tr>
                            <!-- <b-tr>
                                    <b-th class="th_style">SPO2</b-th>
                                    <b-td class="border_table">
                                        <div class="d-flex">
                                            <b-form-input :disabled="(disableInput || state.oldExp || isEgresado)" type="text" name="spo2" autocomplete="off" v-on:keypress="soloNumeros($event)" maxlength="6" :state="saturacion_oxigeno" v-model="datos.saturacion_oxigeno" class="border-0" size="sm" />
                                            <span class="float-right px-1 txt_gray">
                                                <span class="txt_gray size-span">%</span>
                                            </span>
                                        </div>
                                    </b-td>
                                </b-tr> -->
                        </b-tbody>
                    </b-table-simple>
                </b-col>
                <b-col cols="12" sm="12" md="7" lg="7">
                    <b-table-simple class="table-sm">
                        <b-tbody>
                            <b-tr>
                                <b-th class="th_style size_th_table_frec">Superficie corporal</b-th>
                                <b-td class="border_table">
                                    <b-form-input disabled type="text" name="superficieCorporal" autocomplete="off"
                                        v-on:keypress="soloNumeros($event)" :state="superficie_corporal"
                                        v-model="datos.superficie_corporal" class="border-0" size="sm" />
                                </b-td>
                            </b-tr>
                            <b-tr>
                                <b-th class="th_style">Frecuencia cardiaca</b-th>
                                <b-td class="border_table">
                                    <div class="d-flex">
                                        <b-form-input :disabled="(disableInput || state.oldExp || isEgresado)" type="text"
                                            name="fc" autocomplete="off" v-on:keypress="soloNumeros($event)" maxlength="5"
                                            :state="frecuencia_cardiaca" v-model="datos.frecuencia_cardiaca"
                                            class="border-0" size="sm" />
                                        <span class="float-right px-1 txt_gray">
                                            <span class="txt_gray size-span">/min</span>
                                        </span>
                                    </div>
                                </b-td>
                            </b-tr>
                            <!-- <b-tr>
                                <b-th class="th_style">Frecuencia respiratoria</b-th>
                                <b-td class="border_table">
                                    <div class="d-flex">
                                        <b-form-input :disabled="(disableInput || state.oldExp || isEgresado)" type="text"
                                            name="fr" autocomplete="off" v-on:keypress="soloNumeros($event)" maxlength="5"
                                            :state="frecuencia_respiratoria" v-model="datos.frecuencia_respiratoria"
                                            class="border-0" size="sm" />
                                        <span class="float-right px-1 txt_gray">
                                            <span class="txt_gray size-span">/min</span>
                                        </span>
                                    </div>
                                </b-td>
                            </b-tr>
                            <b-tr>
                                <b-th class="th_style">Presión arterial</b-th>
                                <b-td class="border_table">
                                    <div class="d-flex">
                                        <div>
                                            <b-form-input :disabled="(disableInput || state.oldExp || isEgresado)"
                                                type="text" name="pa1" autocomplete="off"
                                                v-on:keypress="soloNumeros($event)" maxlength="5" v-model="presion1"
                                                class="text-center border-0" size="sm" placeholder="#" />
                                        </div>
                                        <div class="txt_gray px-1">/</div>
                                        <div>
                                            <b-form-input :disabled="(disableInput || state.oldExp || isEgresado)"
                                                type="text" name="pa2" autocomplete="off"
                                                v-on:keypress="soloNumeros($event)" maxlength="5" :state="presion_arterial"
                                                v-model="presion2" @keyup="tensionArterial()" class="text-center border-0"
                                                size="sm" placeholder="#" />
                                        </div>
                                    </div>
                                </b-td>
                            </b-tr> -->
                        </b-tbody>
                    </b-table-simple>
                </b-col>
            </b-row>
            <b-row class="mt-3">
                <b-col cols="12" sm="12" md="5" lg="5">
                    <b-table-simple class="table-sm">
                        <b-tbody>
                            <b-tr>
                                <b-th class="th_style">Horas de ayuno</b-th>
                                <b-td class="border_table">
                                    <div class="d-flex">
                                        <b-form-input :disabled="(disableInput || state.oldExp || isEgresado)" type="text"
                                            name="horaAyuno" autocomplete="off" v-on:keypress="soloNumeros($event)"
                                            maxlength="2" :state="horas_ayuno" v-model="datos.horas_ayuno" class="border-0"
                                            size="sm" />
                                        <span class="float-right px-1 txt_gray">
                                            <span class="txt_gray size-span">horas</span>
                                        </span>
                                    </div>
                                </b-td>
                            </b-tr>
                            <b-tr>
                                <b-th class="th_style">Estado de hidratación</b-th>
                                <b-td class="border_table">
                                    <b-form-select :disabled="(disableInput || state.oldExp || isEgresado)"
                                        :options="pronostico" name="edoHidratacion" autocomplete="off"
                                        :state="estado_hidratacion" v-model="datos.estado_hidratacion" class="border-0"
                                        size="sm" />
                                </b-td>
                            </b-tr>
                        </b-tbody>
                    </b-table-simple>
                </b-col>
                <b-col cols="12" sm="12" md="7" lg="7">
                    <b-table-simple class="table-sm">
                        <b-tbody>
                            <b-tr>
                                <b-th class="th_style">Escala de Glasgow</b-th>
                                <b-td class="border_table">
                                    <b-form-input :disabled="(disableInput || state.oldExp || isEgresado)" type="text" name="glasgow" autocomplete="off"
                                        v-on:keypress="soloNumeros($event)" v-model="datos.escala_glasgow" class="border-0"
                                        size="sm" />
                                </b-td>
                            </b-tr>
                            <b-tr>
                                <b-th class="th_style">Estado psiquiátrico</b-th>
                                <b-td class="border_table">
                                    <b-form-input :disabled="(disableInput || state.oldExp || isEgresado)" type="text"
                                        name="edoPsiquiatrico" autocomplete="off" :state="estado_psiquiatrico"
                                        v-model="datos.estado_psiquiatrico" class="border-0" size="sm" />
                                </b-td>
                            </b-tr>
                            <b-tr>
                                <b-th class="th_style">Color de egumentos</b-th>
                                <b-td class="border_table">
                                    <b-form-input :disabled="(disableInput || state.oldExp || isEgresado)" type="text"
                                        name="colorEgumentos" autocomplete="off" :state="color_egumentos"
                                        v-model="datos.color_egumentos" class="border-0" size="sm" />
                                </b-td>
                            </b-tr>
                        </b-tbody>
                    </b-table-simple>
                </b-col>
            </b-row>

            <b-table-simple class="table-sm mt-3">
                <b-tbody>
                    <b-tr>
                        <b-th class="th_style">Cabeza</b-th>
                        <b-td class="border_table">
                            <b-form-input :disabled="(disableInput || state.oldExp || isEgresado)" type="text" name="cabeza"
                                autocomplete="off" :state="cabeza" v-model="datos.cabeza" class="border-0" size="sm" />
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style">Cuello</b-th>
                        <b-td class="border_table">
                            <b-form-input :disabled="(disableInput || state.oldExp || isEgresado)" type="text" name="cuello"
                                autocomplete="off" :state="cuello" v-model="datos.cuello" class="border-0" size="sm" />
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style">Cavidad oral</b-th>
                        <b-td class="border_table">
                            <b-form-input :disabled="(disableInput || state.oldExp || isEgresado)" type="text"
                                name="cavidadOral" autocomplete="off" :state="cavidad_oral" v-model="datos.cavidad_oral"
                                class="border-0" size="sm" />
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style">Tórax</b-th>
                        <b-td class="border_table">
                            <b-form-input :disabled="(disableInput || state.oldExp || isEgresado)" type="text" name="torax"
                                autocomplete="off" :state="torax" v-model="datos.torax" class="border-0" size="sm" />
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style">Área cardiaca</b-th>
                        <b-td class="border_table">
                            <b-form-input :disabled="(disableInput || state.oldExp || isEgresado)" type="text"
                                name="areaCardiaca" autocomplete="off" :state="area_cardiaca" v-model="datos.area_cardiaca"
                                class="border-0" size="sm" />
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style">Abdomen</b-th>
                        <b-td class="border_table">
                            <b-form-input :disabled="(disableInput || state.oldExp || isEgresado)" type="text"
                                name="abdomen" autocomplete="off" :state="abdomen" v-model="datos.abdomen" class="border-0"
                                size="sm" />
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style">Otros hallazgos</b-th>
                        <b-td class="border_table">
                            <b-form-input :disabled="(disableInput || state.oldExp || isEgresado)" type="text"
                                name="otrosHallazgos" autocomplete="off" :state="otros_hayazgos"
                                v-model="datos.otros_hallazgos" class="border-0" size="sm" />
                        </b-td>
                    </b-tr>
                </b-tbody>
            </b-table-simple>

            <b-row>
                <b-col cols="12" sm="12" md="6" lg="6">
                    <b-table-simple class="table-sm mt-3">
                        <b-tbody>
                            <b-tr>
                                <b-th class="th_style">V.C.S.</b-th>
                                <b-td class="border_table">
                                    <b-form-input :disabled="(disableInput || state.oldExp || isEgresado)" type="text"
                                        name="vcs" autocomplete="off" v-on:keypress="soloNumeros($event)" :state="vcs"
                                        v-model="datos.vcs" class="border-0" size="sm" />
                                </b-td>
                            </b-tr>
                            <b-tr>
                                <b-th class="th_style">Hb</b-th>
                                <b-td class="border_table">
                                    <b-form-input :disabled="(disableInput || state.oldExp || isEgresado)" type="text"
                                        name="hb" autocomplete="off" v-on:keypress="soloNumeros($event)" :state="hb"
                                        v-model="datos.hb" class="border-0" size="sm" />
                                </b-td>
                            </b-tr>
                            <b-tr>
                                <b-th class="th_style">Hto</b-th>
                                <b-td class="border_table">
                                    <b-form-input :disabled="(disableInput || state.oldExp || isEgresado)" type="text"
                                        name="hto" autocomplete="off" v-on:keypress="soloNumeros($event)" :state="hto"
                                        v-model="datos.hto" class="border-0" size="sm" />
                                </b-td>
                            </b-tr>
                            <b-tr>
                                <b-th class="th_style">TT</b-th>
                                <b-td class="border_table">
                                    <b-form-input :disabled="(disableInput || state.oldExp || isEgresado)" type="text"
                                        name="tt" autocomplete="off" v-on:keypress="soloNumeros($event)" :state="tt"
                                        v-model="datos.tt" class="border-0" size="sm" />
                                </b-td>
                            </b-tr>
                            <b-tr>
                                <b-th class="th_style">TP</b-th>
                                <b-td class="border_table">
                                    <b-form-input :disabled="(disableInput || state.oldExp || isEgresado)" type="text"
                                        name="tp" autocomplete="off" v-on:keypress="soloNumeros($event)" :state="tp"
                                        v-model="datos.tp" class="border-0" size="sm" />
                                </b-td>
                            </b-tr>
                            <b-tr>
                                <b-th class="th_style">TPT</b-th>
                                <b-td class="border_table">
                                    <b-form-input :disabled="(disableInput || state.oldExp || isEgresado)" type="text"
                                        name="tpt" autocomplete="off" v-on:keypress="soloNumeros($event)" :state="tpt"
                                        v-model="datos.tpt" class="border-0" size="sm" />
                                </b-td>
                            </b-tr>
                            <b-tr>
                                <b-th class="th_style">Na</b-th>
                                <b-td class="border_table">
                                    <b-form-input :disabled="(disableInput || state.oldExp || isEgresado)" type="text"
                                        name="na" autocomplete="off" v-on:keypress="soloNumeros($event)" :state="na"
                                        v-model="datos.na" class="border-0" size="sm" />
                                </b-td>
                            </b-tr>
                            <b-tr>
                                <b-th class="th_style">K</b-th>
                                <b-td class="border_table">
                                    <b-form-input :disabled="(disableInput || state.oldExp || isEgresado)" type="text"
                                        name="k" autocomplete="off" v-on:keypress="soloNumeros($event)" :state="k"
                                        v-model="datos.k" class="border-0" size="sm" />
                                </b-td>
                            </b-tr>
                            <b-tr>
                                <b-th class="th_style">Cl</b-th>
                                <b-td class="border_table">
                                    <b-form-input :disabled="(disableInput || state.oldExp || isEgresado)" type="text"
                                        name="cl" autocomplete="off" v-on:keypress="soloNumeros($event)" :state="ci"
                                        v-model="datos.ci" class="border-0" size="sm" />
                                </b-td>
                            </b-tr>
                            <b-tr>
                                <b-th class="th_style">Mg</b-th>
                                <b-td class="border_table">
                                    <b-form-input :disabled="(disableInput || state.oldExp || isEgresado)" type="text"
                                        name="mg" autocomplete="off" v-on:keypress="soloNumeros($event)" :state="mg"
                                        v-model="datos.mg" class="border-0" size="sm" />
                                </b-td>
                            </b-tr>
                        </b-tbody>
                    </b-table-simple>
                </b-col>
                <b-col cols="12" sm="12" md="6" lg="6">
                    <b-table-simple class="table-sm mt-3">
                        <b-tbody>
                            <b-tr>
                                <b-th class="th_style">Grupo Rh</b-th>
                                <b-td class="border_table">
                                    <b-form-select :disabled="(disableInput || state.oldExp || isEgresado)"
                                        v-model="datos.grupo_rh" :options="grupo_sanguineo" :state="grupo_rh"
                                        class="border-0" size="sm" placeholder="seleccionar" />
                                </b-td>
                            </b-tr>
                            <b-tr>
                                <b-th class="th_style">Tele de tórax</b-th>
                                <b-td class="border_table">
                                    <b-form-select :disabled="(disableInput || state.oldExp || isEgresado)"
                                        v-on:keypress="soloNumeros($event)" :state="tele_torax_id"
                                        v-model="datos.tele_torax_id" :options="normalidad" class="border-0" size="sm"
                                        placeholder="seleccionar" />
                                </b-td>
                            </b-tr>
                            <b-tr>
                                <b-th class="th_style">E.G.O.</b-th>
                                <b-td class="border_table">
                                    <b-form-select :disabled="(disableInput || state.oldExp || isEgresado)"
                                        v-on:keypress="soloNumeros($event)" :state="ego_id" v-model="datos.ego_id"
                                        :options="normalidad" class="border-0" size="sm" placeholder="seleccionar" />
                                </b-td>
                            </b-tr>
                            <b-tr>
                                <b-th class="th_style">E.C.G.</b-th>
                                <b-td class="border_table">
                                    <b-form-select :disabled="(disableInput || state.oldExp || isEgresado)"
                                        v-on:keypress="soloNumeros($event)" :state="ecg_id" v-model="datos.ecg_id"
                                        :options="normalidad" class="border-0" size="sm" placeholder="seleccionar" />
                                </b-td>
                            </b-tr>
                            <b-tr>
                                <b-th class="th_style">VIH</b-th>
                                <b-td class="border_table">
                                    <label class="switch">
                                        <input :disabled="(disableInput || state.oldExp || isEgresado)" v-model="datos.vih"
                                            type="checkbox">
                                        <span class="slider round"></span>
                                    </label>
                                </b-td>
                            </b-tr>
                            <b-tr>
                                <b-th class="th_style">ASA</b-th>
                                <b-td class="border_table">
                                    <b-form-select :disabled="(disableInput || state.oldExp || isEgresado)"
                                        v-on:keypress="soloNumeros($event)" :state="asa_id" v-model="datos.asa_id"
                                        :options="numeros_romanos" class="border-0" size="sm" placeholder="seleccionar" />
                                </b-td>
                            </b-tr>
                            <b-tr>
                                <b-th class="th_style">NYHA</b-th>
                                <b-td class="border_table">
                                    <b-form-select :disabled="(disableInput || state.oldExp || isEgresado)"
                                        v-on:keypress="soloNumeros($event)" :state="nyha_id" v-model="datos.nyha_id"
                                        :options="numeros_romanos" class="border-0" size="sm" placeholder="seleccionar" />
                                </b-td>
                            </b-tr>
                            <b-tr>
                                <b-th class="th_style">GOLMAN</b-th>
                                <b-td class="border_table">
                                    <b-form-select :disabled="(disableInput || state.oldExp || isEgresado)"
                                        v-on:keypress="soloNumeros($event)" :state="golman_id" v-model="datos.golman_id"
                                        :options="numeros_romanos" class="border-0" size="sm" placeholder="seleccionar" />
                                </b-td>
                            </b-tr>
                            <b-tr>
                                <b-th class="th_style">Gasometría</b-th>
                                <b-td class="border_table">
                                    <b-form-select :disabled="(disableInput || state.oldExp || isEgresado)"
                                        v-on:keypress="soloNumeros($event)" :state="gasometria_id"
                                        v-model="datos.gasometria_id" :options="normalidad" class="border-0" size="sm"
                                        placeholder="seleccionar" />
                                </b-td>
                            </b-tr>
                            <b-tr>
                                <b-th class="th_style">Génito urinario</b-th>
                                <b-td class="border_table">
                                    <b-form-select :disabled="(disableInput || state.oldExp || isEgresado)"
                                        v-on:keypress="soloNumeros($event)" :state="genito_urinario_id"
                                        v-model="datos.genito_urinario_id" :options="normalidad" class="border-0" size="sm"
                                        placeholder="seleccionar" />
                                </b-td>
                            </b-tr>
                        </b-tbody>
                    </b-table-simple>
                </b-col>
            </b-row>

            <b-row class="mt-3">
                <b-col cols="12" sm="12" md="5" lg="5">
                    <b-table-simple class="table-sm">
                        <b-tbody>
                            <b-tr>
                                <b-th class="th_style">Plaquetas</b-th>
                                <b-td class="border_table">
                                    <b-form-input :disabled="(disableInput || state.oldExp || isEgresado)" type="text"
                                        name="plaquetas" autocomplete="off" v-on:keypress="soloNumeros($event)"
                                        :state="planquetas" v-model="datos.planquetas" class="border-0" size="sm" />
                                </b-td>
                            </b-tr>
                            <b-tr>
                                <b-th class="th_style">Glucosa</b-th>
                                <b-td class="border_table">
                                    <b-form-input :disabled="(disableInput || state.oldExp || isEgresado)" type="text"
                                        name="glucosa" autocomplete="off" v-on:keypress="soloNumeros($event)"
                                        :state="glucosa" v-model="datos.glucosa" class="border-0" size="sm" />
                                </b-td>
                            </b-tr>
                            <b-tr>
                                <b-th class="th_style">Urea</b-th>
                                <b-td class="border_table">
                                    <b-form-input :disabled="(disableInput || state.oldExp || isEgresado)" type="text"
                                        name="urea" autocomplete="off" v-on:keypress="soloNumeros($event)" :state="urea"
                                        v-model="datos.urea" class="border-0" size="sm" />
                                </b-td>
                            </b-tr>
                            <b-tr>
                                <b-th class="th_style">Creat</b-th>
                                <b-td class="border_table">
                                    <b-form-input :disabled="(disableInput || state.oldExp || isEgresado)" type="text"
                                        name="creat" autocomplete="off" v-on:keypress="soloNumeros($event)" :state="creat"
                                        v-model="datos.creat" class="border-0" size="sm" />
                                </b-td>
                            </b-tr>
                        </b-tbody>
                    </b-table-simple>
                </b-col>
                <b-col cols="12" sm="12" md="7" lg="7">
                    <b-table-simple class="table-sm">
                        <b-tbody>
                            <b-tr>
                                <b-th class="th_style">Sangrado permisible</b-th>
                                <b-td class="border_table">
                                    <div class="d-flex">
                                        <b-form-input :disabled="(disableInput || state.oldExp || isEgresado)" type="text"
                                            name="sangradoPermisible" autocomplete="off" v-on:keypress="soloNumeros($event)"
                                            :state="sangrado_permisible" v-model="datos.sangrado_permisible"
                                            class="border-0" size="sm" />
                                        <span class="float-right px-1 txt_gray">
                                            <span class="txt_gray size-span">cm³</span>
                                        </span>
                                    </div>
                                </b-td>
                            </b-tr>
                            <b-tr>
                                <b-th class="th_style">Sangre de reserva</b-th>
                                <b-td class="border_table">
                                    <div class="d-flex">
                                        <b-form-input :disabled="(disableInput || state.oldExp || isEgresado)" type="text"
                                            name="sangreReserva" autocomplete="off" v-on:keypress="soloNumeros($event)"
                                            :state="sangre_reserva" v-model="datos.sangre_reserva" class="border-0"
                                            size="sm" />
                                        <span class="float-right px-1 txt_gray">
                                            <span class="txt_gray size-span">Unidades</span>
                                        </span>
                                    </div>
                                </b-td>
                            </b-tr>
                            <b-tr>
                                <b-th class="th_style">Hemodilución</b-th>
                                <b-td class="border_table">
                                    <b-row>
                                        <b-col cols="8">
                                            <b-row class="pl-2">
                                                <b-col cols="12" sm="12" md="6" lg="6">
                                                    <div class="d-flex pl-4">
                                                        <span class="px-2 txt_gray rbtn_text">
                                                            Sí
                                                        </span>
                                                        <b-form-radio
                                                            :disabled="(disableInput || state.oldExp || isEgresado)"
                                                            v-model="datos.hemodilucion" v-bind:value='true'
                                                            class="rbtn_color" size="md" />
                                                    </div>
                                                </b-col>
                                                <b-col cols="12" sm="12" md="6" lg="6">
                                                    <div class="d-flex pl-2">
                                                        <span class="px-2 txt_gray rbtn_text">
                                                            No
                                                        </span>
                                                        <b-form-radio
                                                            :disabled="(disableInput || state.oldExp || isEgresado)"
                                                            v-model="datos.hemodilucion" v-bind:value="false"
                                                            class="rbtn_color" size="md" />
                                                    </div>
                                                </b-col>
                                            </b-row>
                                        </b-col>
                                    </b-row>
                                </b-td>
                            </b-tr>
                        </b-tbody>
                    </b-table-simple>
                </b-col>
            </b-row>

            <b-table-simple class="table-sm mt-3">
                <b-tbody>
                    <b-tr>
                        <b-th class="th_style">Otros exámenes</b-th>
                        <b-td class="border_table">
                            <b-form-input :disabled="(disableInput || state.oldExp || isEgresado)" name="otrosExamenes"
                                autocomplete="off" :state="otros_examenes" v-model="datos.otros_examenes" class="border-0"
                                size="sm" />
                        </b-td>
                    </b-tr>
                </b-tbody>
            </b-table-simple>

            <b-row>
                <b-col class="mt-3" cols="12">
                    <p class="txt_secondary">Nota preanestésica, vigilancia y riesgo anestésico</p>
                    <b-form-textarea :disabled="(disableInput || state.oldExp || isEgresado)" name="ntaPreanestesica"
                        autocomplete="off" :state="nota_preanestesica" v-model="datos.nota_preanestesica"
                        class="txtArea_border" size="sm" no-resize rows="3" max-rows="3" />
                </b-col>
                <b-col cols="12">
                    <p class="txt_secondary mt-3">Medicación preanestésica</p>
                    <b-form-textarea :disabled="(disableInput || state.oldExp || isEgresado)" name="medicacion"
                        autocomplete="off" :state="medicacion_preanestésica" v-model="datos.medicacion_preanestésica"
                        class="txtArea_border" size="sm" no-resize rows="3" max-rows="3" />
                </b-col>
                <b-col cols="12">
                    <p class="txt_secondary mt-3">Plan anestésico</p>
                    <b-form-textarea :disabled="(disableInput || state.oldExp || isEgresado)" name="planAnestesico"
                        autocomplete="off" :state="plan_anestesico" v-model="datos.plan_anestesico" class="txtArea_border"
                        size="sm" no-resize rows="3" max-rows="3" />
                </b-col>
                <b-col cols="12">
                    <p class="txt_secondary mt-3">Observaciones</p>
                    <b-form-textarea :disabled="(disableInput || state.oldExp || isEgresado)" name="Observaciones"
                        autocomplete="off" :state="observaciones" v-model="datos.observaciones" class="txtArea_border mb-3"
                        size="sm" no-resize rows="3" max-rows="3" />
                </b-col>
            </b-row>

            <div v-if="(datos.firma.is_firmado == 1)" class="mb-4">
                <span class="txt_secondary">Nota firmada</span><br>
                <span class="txt_secondary size-span"><i class="fa fa-user mr-1"></i>Médico:
                    {{ datos.firma.firmado_por.nombre }}</span> <br>
                <span class="txt_secondary size-span"><i class="fa fa-calendar mr-1"></i>Fecha:
                    {{ datos.firma.fecha_firmado }}</span>
            </div>

            <b-row v-if="error && !state.oldExp" class="my-3">
                <b-col cols="12">
                    <div class="contenedor-de-errores pt-3 pb-1">
                        <ul class="lista-errores px-2">
                            <li v-for="(value, key) in errores" :key="key.value"><i class="fa fa-warning px-1" />|
                                {{ value[0] }} <br></li>
                        </ul>
                    </div>
                </b-col>
            </b-row>
            <div v-if="((!dtaExist && !state.oldExp && !isEgresado) || update && !isEgresado)"
                class="d-flex justify-content-end my-4">
                <b-col cols="12" sm="12" md="6" lg="6">
                    <button @click="formNotaPreA('NotaPreAnestesicaUno')"
                        class="btn_formluario border-0 btn-block py-2 txt_white">ANTERIOR</button>
                </b-col>
                <b-col cols="12" sm="12" md="6" lg="6">
                    <b-overlay :show="busy" rounded opacity="0.6" spinner-small spinner-variant="primary">
                        <button class="btn_formluario border-0 btn-block py-2 txt_white" :disabled="busy"
                            @click="guardar()">GUARDAR</button>
                    </b-overlay>
                </b-col>
            </div>
            <div v-if="(btnGuardar && update && !state.oldExp && !isEgresado)" class="d-flex justify-content-end my-4">
                <b-col cols="12" sm="12" md="6" lg="6">
                    <button @click="formNotaPreA('NotaPreAnestesicaUno')"
                        class="btn_formluario border-0 btn-block py-2 txt_white">ANTERIOR</button>
                </b-col>
                <b-col cols="12" sm="12" md="6" lg="6">
                    <b-overlay :show="busy" rounded opacity="0.6" spinner-small spinner-variant="primary">
                        <button class="btn_formluario border-0 btn-block py-2 txt_white" :disabled="busy"
                            @click="guardar()">ACTUALIZAR</button>
                    </b-overlay>
                </b-col>
            </div>

            <div v-else-if="(datos.firma.is_firmado == 0 && !update && !state.oldExp && idResponsable === usuario.id && !isEgresado)"
                class="d-flex justify-content-end mb-4 mt-4">
                <b-col v-if="dtaExist" cols="12" sm="12" md="6" lg="6">
                    <button @click="formNotaPreA('NotaPreAnestesicaUno')"
                        class="btn_formluario border-0 btn-block py-2 txt_white">ANTERIOR</button>
                </b-col>
                <b-col cols="12" sm="12" md="6" lg="6">
                    <b-overlay :show="busy" rounded opacity="0.6" spinner-small spinner-variant="primary">
                        <button @click="firmar()" class="btn_formluario border-0 btn-block py-2 txt_white">FIRMAR Y
                            GUARDAR</button>
                    </b-overlay>
                </b-col>
            </div>
            <div v-else-if="(update && !state.oldExp && !isEgresado)" class="d-flex justify-content-end mb-4">
                <b-col cols="12" sm="12" md="6" lg="6">
                    <button @click="cancelEdit()" class="btn_formluario border-0 btn-block py-2 txt_white">CANCELAR</button>
                </b-col>
            </div>
            <!-- <div v-if="update && dtaExist && !state.oldExp" class="d-flex justify-content-start mb-4 mt-4">
                <b-col cols="12" sm="12" md="6" lg="6">
                    <button @click="formNotaPreA('NotaPreAnestesicaUno')" class="btn_formluario border-0 btn-block py-2 txt_white">ANTERIOR</button>
                </b-col>
            </div> -->
            <div v-else-if="(state.oldExp || datos.firma.is_firmado == 1 || isEgresado||(dtaExist&&!update))"
                class="d-flex justify-content-start mb-4 mt-4">
                <b-col cols="12" sm="12" md="6" lg="6">
                    <button @click="formNotaPreA('NotaPreAnestesicaUno')"
                        class="btn_formluario border-0 btn-block py-2 txt_white">ANTERIOR</button>
                </b-col>
            </div>
            <FirmaPassword :abrir="openFirmaPsw" @firma_psw="firma_psw($event)" @cerrarModalFirmarPswd="cerrarModalFirmarPswd()"/>
            <Success :abrir="openSuccess" @cerrarModal="cerrarModal()" />
            <Error :abrir="openError" @cerrarModalError="cerrarModalError()" />
            <AltertPresionArterial :abrir="openAlertPresion"
                @cerrarModalAlertPresionArterial="cerrarModalAlertPresionArterial()" />
        </b-container>

        <div class="sticky-container"
            v-if="(datos.expediente_id != '' && !loading && !openFirmaPsw && !openSuccess && !openError && !openAlertPresion && !openPreSave)">
            <ul class="sticky">
                <li v-if="!dtaExist && !state.oldExp" @click="preGuardar()">
                    <img :src="require('../../../assets/icons/guardar.png')" width="50" height="32">
                    <p>
                        <a>Preguardar</a>
                    </p>
                </li>
                <li v-if="dtaExist && updating && !state.oldExp && datos.firma.is_firmado == 0" @click="editar()">
                    <img :src="require('../../../assets/icons/editar.png')" width="57" height="32">
                    <p>
                        <a>Editar</a>
                    </p>
                </li>
                <li v-if="(dtaExist && updating)" @click="imprimir()">
                    <img :src="require('../../../assets/icons/imprimir.png')" width="50" height="32">
                    <p>
                        <a>Imprimir</a>
                    </p>
                </li>

            </ul>
        </div>
        <Loading :show="loading" />
    </b-container>
</template>

<script>
import Vue from 'vue'
import PDFMerger from 'pdf-merger-js';
import router from '../../../router';
import FirmaPassword from "@/components/Modals/FirmaPassword.vue"
import { url } from "../../../../settings";
import Success from "@/components/Modals/Success.vue";
import Error from "@/components/Modals/Error.vue";
import CryptoJS from 'crypto-js'
import AltertPresionArterial from '@/components/Modals/AltertPresionArterial.vue';
import Loading from '@/components/Loader/Loading.vue'

export default {
    name: "FormDos",
    components: { Success, Error, FirmaPassword, AltertPresionArterial, Loading },

    computed: {
        hour() {
            return this.datos.hora.length != 0
        },

        frecuencia_respiratoria() {
            return this.datos.frecuencia_respiratoria != ""
        },

        presion_arterial() {
            return this.presion1 != "" && this.presion2 != ""
        },

        peso() {

            return this.datos.peso != ""
        },

        talla() {
            return this.datos.talla != ""
        },

        superficie_corporal() {
            return this.datos.superficie_corporal != ""
        },

        frecuencia_cardiaca() {
            return this.datos.frecuencia_cardiaca != ""
        },

        temperatura() {
            return this.datos.temperatura != ""
        },

        estado_psiquiatrico() {
            return this.datos.estado_psiquiatrico != ""
        },

        color_egumentos() {
            return this.datos.color_egumentos != ""
        },

        horas_ayuno() {
            return this.datos.horas_ayuno != ""
        },

        estado_hidratacion() {
            return this.datos.estado_hidratacion != null
        },

        cabeza() {
            return this.datos.cabeza != ""
        },

        cuello() {
            return this.datos.cuello != ""
        },

        cavidad_oral() {
            return this.datos.cavidad_oral != ""
        },

        torax() {
            return this.datos.torax != ""
        },

        area_cardiaca() {
            return this.datos.area_cardiaca != ""
        },

        abdomen() {
            return this.datos.abdomen != ""
        },

        otros_hayazgos() {
            return this.datos.otros_hallazgos != ""
        },

        vcs() {
            return this.datos.vcs != ""
        },

        hb() {
            return this.datos.hb != ""
        },

        hto() {
            return this.datos.hto != ""
        },

        grupo_rh() {
            return this.datos.grupo_rh != ""
        },

        tt() {
            return this.datos.tt != ""
        },

        tp() {
            return this.datos.tp != ""
        },

        tpt() {
            return this.datos.tpt != ""
        },

        tele_torax_id() {
            return this.datos.tele_torax_id != ""
        },

        na() {
            return this.datos.na != ""
        },

        k() {
            return this.datos.k != ""
        },

        ci() {
            return this.datos.ci != ""
        },

        mg() {
            return this.datos.mg != ""
        },

        ego_id() {
            return this.datos.ego_id != ""
        },

        ecg_id() {
            return this.datos.ecg_id != ""
        },

        asa_id() {
            return this.datos.asa_id != ""
        },

        nyha_id() {
            return this.datos.nyha_id != ""
        },

        golman_id() {
            return this.datos.golman_id != ""
        },

        gasometria_id() {
            return this.datos.gasometria_id != ""
        },

        genito_urinario_id() {
            return this.datos.genito_urinario_id != ""
        },

        sangrado_permisible() {
            return this.datos.sangrado_permisible != ""
        },

        sangre_reserva() {
            return this.datos.sangre_reserva != ""
        },

        planquetas() {
            return this.datos.planquetas != ""
        },

        glucosa() {
            return this.datos.glucosa != ""
        },

        urea() {
            return this.datos.urea != ""
        },

        creat() {
            return this.datos.creat != ""
        },

        otros_examenes() {
            return this.datos.otros_examenes != ""
        },

        nota_preanestesica() {
            return this.datos.nota_preanestesica != ""
        },

        medicacion_preanestésica() {
            return this.datos.medicacion_preanestésica != ""
        },

        plan_anestesico() {
            return this.datos.plan_anestesico != ""
        },

        observaciones() {
            return this.datos.observaciones != ""
        },

        escala_glasgow() {
            return this.datos.escala_glasgow != ""
        },

        saturacion_oxigeno() {
            return this.datos.saturacion_oxigeno != ""
        },
    },

    data: () => ({
        noExpediente: "",
        isEgresado: false,
        usuario: {
            id: 0,
            nombre: "",
            email: "",
            celular: "",
            perfil: {
                id: 1,
                nombre: ""
            },
            cedula: "",
            especialidad: {
                especialidad: {
                    id: null,
                    nombre: ""
                },
                cedula: ""
            }
        },
        idResponsable: null,

        state: {
            idExpediente: "",
            idPaciente: "",
            oldExp: false
        },

        btnFirmaUpdate: false,
        updating: true,
        disableInput: false,
        actualizar: false,
        cancelar: false,
        busyUpdate: false,
        dtaExist: false,
        pswUpdate: false,

        done: false,


        loading: false,

        cargado: false,
        openPreSave: false,
        datosPreGuardados: [],
        showDta: false,

        openAlertPresion: false,
        openFirmaPsw: false,
        btnGuardar: false,
        btnFirmar: true,
        error: false,
        errores: "",
        presion1: "",
        presion2: "",
        busy: false,
        openSuccess: false,
        openError: false,
        datosFormUno: {},

        allDatos: {
            openAlertPresion: false,
            no_habitacion: null,
            expediente_id: null,
            password: '',
            fecha: "",
            hora: "",
            genero_id: null,
            fuma: false,
            cigarros: null,
            bebe: false,
            bebidas_alcoholicas: null,
            emplea_drogas: false,
            drogas: 0,
            actividad_fisica_id: null,
            complicaciones_anestesias_previas: false,
            cuales_complicaciones_anestesias: null,
            complicaciones_anestesias_previas_familia: false,
            cuales_complicaciones_anestesias_familia: null,
            escala_glasgow: "",
            resfriado: false,
            fiebre: false,
            garganta_inflamada: false,
            protesis_dentales: false,
            bronquitis: false,
            dientes_flojos: false,
            laringuitis: false,
            dientes_rotos: false,
            paralisis: false,
            nausea_vomito: false,
            presion_alta: false,
            vision_borrosa_perdida: false,
            artritis: false,
            problemas_rinion_vejiga: false,
            diabetes: false,
            sangrado_excesivo: false,
            asma_enfisema_neumonia: false,
            sangre_orina_excremento: false,
            mareos_movimiento: false,
            problemas_higado: false,
            dolor_espalda_cuello: false,
            ataques_espilepsia: false,
            dolor_pecho_ejercicio: false,
            ataque_falla_cardiaca: false,
            dolor_piernas_ejercicio: false,
            cancer: false,
            hinchazon_piernas_tobillos: false,
            medicamentos_alternativos: false,
            tos_espectoracion_cronica: false,
            padecimientos_observaciones: "",

            //form 2
            peso: "",
            talla: "",
            superficie_corporal: "",
            frecuencia_cardiaca: "",
            temperatura: "",
            estado_psiquiatrico: "",
            color_egumentos: "",
            horas_ayuno: "",
            estado_hidratacion: null,
            cabeza: "",
            cuello: "",
            cavidad_oral: "",
            torax: "",
            area_cardiaca: "",
            abdomen: "",
            otros_hallazgos: '',
            vcs: "",
            hb: "",
            hto: "",
            grupo_rh: "",
            tt: "",
            tp: "",
            tpt: "",
            tele_torax_id: "",
            na: "",
            k: "",
            ci: "",
            mg: "",
            ego_id: "",
            ecg_id: "",
            vih: false,
            asa_id: "",
            nyha_id: "",
            golman_id: "",
            gasometria_id: "",
            genito_urinario_id: "",
            sangrado_permisible: "",
            sangre_reserva: "",
            planquetas: "",
            glucosa: "",
            urea: "",
            creat: "",
            hemodilucion: false,
            otros_examenes: '',
            nota_preanestesica: "",
            medicacion_preanestésica: "",
            plan_anestesico: "",
            observaciones: ""
        },

        datos: {
            expediente_id: "",
            password: '',
            saturacion_oxigeno: "",
            peso: "",
            talla: "",
            superficie_corporal: "",
            frecuencia_cardiaca: "",
            temperatura: "",
            frecuencia_respiratoria: "",
            estado_psiquiatrico: "",
            color_egumentos: "",
            horas_ayuno: "",
            estado_hidratacion: null,
            cabeza: "",
            cuello: "",
            cavidad_oral: "",
            torax: "",
            area_cardiaca: "",
            abdomen: "",
            otros_hallazgos: '',
            vcs: "",
            hb: "",
            hto: "",
            grupo_rh: "",
            tt: "",
            tp: "",
            tpt: "",
            tele_torax_id: "",
            na: "",
            k: "",
            ci: "",
            mg: "",
            ego_id: "",
            ecg_id: "",
            vih: false,
            asa_id: "",
            nyha_id: "",
            golman_id: "",
            gasometria_id: "",
            genito_urinario_id: "",
            sangrado_permisible: "",
            sangre_reserva: "",
            planquetas: "",
            glucosa: "",
            urea: "",
            creat: "",
            hemodilucion: false,
            otros_examenes: '',
            nota_preanestesica: "",
            medicacion_preanestésica: "",
            plan_anestesico: "",
            observaciones: '',
            escala_glasgow: "",
            presion_arterial: "",
            firma: {
                fecha_firmado: null,
                firmado_por: {
                    id: "",
                    nombre: ""
                },
                is_firmado: 0
            },
        },

        normalidad: [
            {
                value: "",
                text: "seleccionar"
            },
            {
                value: 1,
                text: "Normal"
            },
            {
                value: 2,
                text: "Anormal"
            }
        ],

        grupo_sanguineo: [
            {
                value: "",
                text: "seleccionar"
            },
            {
                value: "A+",
                text: "A+"
            },
            {
                value: "A-",
                text: "A-"
            },
            {
                value: "B+",
                text: "B+"
            },
            {
                value: "B-",
                text: "B-"
            },
            {
                value: "AB+",
                text: "AB+"
            },
            {
                value: "AB-",
                text: "AB-"
            },
            {
                value: "O+",
                text: "O+"
            },
            {
                value: "O-",
                text: "O-"
            }
        ],

        numeros_romanos: [
            {
                value: "",
                text: "seleccionar"
            },
            {
                value: 1,
                text: "I"
            },
            {
                value: 2,
                text: "II"
            },
            {
                value: 3,
                text: "III"
            },
            {
                value: 4,
                text: "IV"
            }
        ],

        pronostico: [
            {
                value: null,
                text: "Seleccionar"
            },
            {
                value: "bueno",
                text: "Bueno"
            },
            {
                value: "Malo",
                text: "Malo"
            },
            {
                value: "Regular",
                text: "Regular"
            }
        ],
        expediente_id: null,
        update: false

    }),
    methods: {
        async imprimir() {
            // var can = false;
            var este = this;
            this.loading = true
            let urlim = `${url}/api/notas/pre-anestesica/descargar/${this.datos.expediente_id}`;
            return fetch(urlim, {
                method: 'get',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Requested-With': 'XMLHttpRequest',
                    'Authorization': "Bearer " + localStorage.getItem("token"),
                }
            }).then(function (resp) {
                // can = resp.ok
                console.log(resp);
                return resp.blob("paciente.pdf")
            }).then(function (blob) {
                este.downloadPDF(blob)

            })
        },
        async downloadPDF(blob) {
            try {
                var merger = new PDFMerger()
                await merger.add(URL.createObjectURL(blob))
                await merger.save(this.noExpediente + " Hoja de valoración pre-anestésica");
                this.loading = false
            } catch (error) {
                console.log(error);
                this.loading = false
            }
        },

        verNotaDeEgreso() {
            this.$emit('toEgreso')
        },

        editar() {
            this.update = true
            this.disableInput = false
            this.updating = false
            this.$emit("updatePA")
            this.formNotaPreA('NotaPreAnestesicaUno')
        },

        cancelEdit() {
            this.disableInput = true
            this.updating = true
            this.update = false
            this.btnGuardar = false
            this.btnFirmar = true
            this.error = false
            this.errores = []
            this.$emit("cancelarUpdatePA")
            this.setData()
        },

        preGuardar() {
            console.log("preguardado");
            this.$emit("preGuardarNPreA");
        },

        verificarPreGuardado() {
            console.log("verifico");
            try {
                if (localStorage.preGuardadoNPreA2) {
                    console.log("si hay datos");
                    this.datosPreGuardados = JSON.parse(localStorage.preGuardadoNPreA2);
                    console.log(this.datosPreGuardados);
                }
            } catch (error) {
                console.log(error);
            }
            for (let val of this.datosPreGuardados) {
                if (this.datos.expediente_id === val.datosF2.expediente_id) {
                    console.log(val);
                    if (this.showDta === true) {
                        this.datos = val.datosF2
                    } else {
                        this.openPreSave = true;
                    }
                }
            }
        },

        tensionArterial() {
            if (this.presion2.length >= 2 && this.presion1.length >= 2) {
                setTimeout(() => {
                    if (this.presion1 < 100 || this.presion1 > 140 || this.presion2 < 60 || this.presion2 > 90) {
                        this.openAlertPresion = true
                    }
                }, 500);
            }
        },
        firmar() {
            this.openFirmaPsw = true;
        },

        calcularMasa() {
            this.datos.superficie_corporal = Math.pow(((this.datos.peso * this.datos.talla) / 3600), 0.5).toFixed(3)
        },
        soloNumeros(evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault();
            } else {
                return true;
            }
        },

        soloLetras(evt) {
            let char = String.fromCharCode(evt.keyCode);
            if (/^[A-Za-z-zA-ZÀ-ÿ,\u00f1\u00d1 ]+$/.test(char)) {
                return true;
            } else {
                evt.preventDefault();
            }
        },

        formNotaPreA(value) {
            this.$emit('changeStepperPreAnestesica', value);
        },

        guardar(to) {
            this.busy = true
            try {
                this.allDatos.presion_arterial = this.presion1 + "/" + this.presion2
            } catch (error) {
                console.log("");
            }
            this.allDatos.no_habitacion = this.datosFormUno.no_habitacion
            this.allDatos.expediente_id = this.datosFormUno.expediente_id
            this.allDatos.fecha = this.datosFormUno.fecha
            this.allDatos.hora = this.datosFormUno.hora
            this.allDatos.genero_id = this.datosFormUno.genero_id
            this.allDatos.fuma = this.datosFormUno.fuma
            this.allDatos.cigarros = this.datosFormUno.cigarros
            this.allDatos.bebe = this.datosFormUno.bebe
            this.allDatos.bebidas_alcoholicas = this.datosFormUno.bebidas_alcoholicas
            this.allDatos.emplea_drogas = this.datosFormUno.emplea_drogas
            this.allDatos.drogas = this.datosFormUno.drogas
            this.allDatos.actividad_fisica_id = this.datosFormUno.actividad_fisica_id
            this.allDatos.complicaciones_anestesias_previas = this.datosFormUno.complicaciones_anestesias_previas
            this.allDatos.cuales_complicaciones_anestesias = this.datosFormUno.cuales_complicaciones_anestesias
            this.allDatos.complicaciones_anestesias_previas_familia = this.datosFormUno.complicaciones_anestesias_previas_familia
            this.allDatos.cuales_complicaciones_anestesias_familia = this.datosFormUno.cuales_complicaciones_anestesias_familia
            this.allDatos.resfriado = this.datosFormUno.resfriado
            this.allDatos.fiebre = this.datosFormUno.fiebre
            this.allDatos.garganta_inflamada = this.datosFormUno.garganta_inflamada
            this.allDatos.protesis_dentales = this.datosFormUno.protesis_dentales
            this.allDatos.bronquitis = this.datosFormUno.bronquitis
            this.allDatos.dientes_flojos = this.datosFormUno.dientes_flojos
            this.allDatos.laringuitis = this.datosFormUno.laringuitis
            this.allDatos.dientes_rotos = this.datosFormUno.dientes_rotos
            this.allDatos.paralisis = this.datosFormUno.paralisis
            this.allDatos.nausea_vomito = this.datosFormUno.nausea_vomito
            this.allDatos.presion_alta = this.datosFormUno.presion_alta
            this.allDatos.vision_borrosa_perdida = this.datosFormUno.vision_borrosa_perdida
            this.allDatos.artritis = this.datosFormUno.artritis
            this.allDatos.problemas_rinion_vejiga = this.datosFormUno.problemas_rinion_vejiga
            this.allDatos.diabetes = this.datosFormUno.diabetes
            this.allDatos.sangrado_excesivo = this.datosFormUno.sangrado_excesivo
            this.allDatos.asma_enfisema_neumonia = this.datosFormUno.asma_enfisema_neumonia
            this.allDatos.sangre_orina_excremento = this.datosFormUno.sangre_orina_excremento
            this.allDatos.mareos_movimiento = this.datosFormUno.mareos_movimiento
            this.allDatos.problemas_higado = this.datosFormUno.problemas_higado
            this.allDatos.dolor_espalda_cuello = this.datosFormUno.dolor_espalda_cuello
            this.allDatos.ataques_espilepsia = this.datosFormUno.ataques_espilepsia
            this.allDatos.dolor_pecho_ejercicio = this.datosFormUno.dolor_pecho_ejercicio
            this.allDatos.ataque_falla_cardiaca = this.datosFormUno.ataque_falla_cardiaca
            this.allDatos.dolor_piernas_ejercicio = this.datosFormUno.dolor_piernas_ejercicio
            this.allDatos.cancer = this.datosFormUno.cancer
            this.allDatos.hinchazon_piernas_tobillos = this.datosFormUno.hinchazon_piernas_tobillos
            this.allDatos.medicamentos_alternativos = this.datosFormUno.medicamentos_alternativos
            this.allDatos.tos_espectoracion_cronica = this.datosFormUno.tos_espectoracion_cronica
            this.allDatos.padecimientos_observaciones = this.datosFormUno.padecimientos_observaciones

            //datos form 2

            this.allDatos.escala_glasgow = this.datos.escala_glasgow
            this.allDatos.peso = this.datos.peso;
            this.allDatos.talla = this.datos.talla;
            this.allDatos.superficie_corporal = this.datos.superficie_corporal;
            this.allDatos.frecuencia_cardiaca = this.datos.frecuencia_cardiaca;
            this.allDatos.temperatura = this.datos.temperatura;
            this.allDatos.estado_psiquiatrico = this.datos.estado_psiquiatrico;
            this.allDatos.color_egumentos = this.datos.color_egumentos;
            this.allDatos.horas_ayuno = this.datos.horas_ayuno;
            this.allDatos.estado_hidratacion = this.datos.estado_hidratacion;
            this.allDatos.cabeza = this.datos.cabeza;
            this.allDatos.cuello = this.datos.cuello;
            this.allDatos.cavidad_oral = this.datos.cavidad_oral;
            this.allDatos.torax = this.datos.torax;
            this.allDatos.area_cardiaca = this.datos.area_cardiaca;
            this.allDatos.abdomen = this.datos.abdomen;
            this.allDatos.otros_hallazgos = this.datos.otros_hallazgos;
            this.allDatos.vcs = this.datos.vcs;
            this.allDatos.hb = this.datos.hb;
            this.allDatos.hto = this.datos.hto;
            this.allDatos.grupo_rh = this.datos.grupo_rh;
            this.allDatos.tt = this.datos.tt;
            this.allDatos.tp = this.datos.tp;
            this.allDatos.tpt = this.datos.tpt;
            this.allDatos.tele_torax_id = this.datos.tele_torax_id;
            this.allDatos.na = this.datos.na;
            this.allDatos.k = this.datos.k;
            this.allDatos.ci = this.datos.ci;
            this.allDatos.mg = this.datos.mg;
            this.allDatos.ego_id = this.datos.ego_id;
            this.allDatos.ecg_id = this.datos.ecg_id;
            this.allDatos.vih = this.datos.vih;
            this.allDatos.asa_id = this.datos.asa_id;
            this.allDatos.nyha_id = this.datos.nyha_id;
            this.allDatos.golman_id = this.datos.golman_id;
            this.allDatos.gasometria_id = this.datos.gasometria_id;
            this.allDatos.genito_urinario_id = this.datos.genito_urinario_id;
            this.allDatos.sangrado_permisible = this.datos.sangrado_permisible;
            this.allDatos.sangre_reserva = this.datos.sangre_reserva;
            this.allDatos.planquetas = this.datos.planquetas;
            this.allDatos.glucosa = this.datos.glucosa;
            this.allDatos.urea = this.datos.urea;
            this.allDatos.creat = this.datos.creat;
            this.allDatos.hemodilucion = this.datos.hemodilucion;
            this.allDatos.otros_examenes = this.datos.otros_examenes;
            this.allDatos.nota_preanestesica = this.datos.nota_preanestesica;
            this.allDatos.medicacion_preanestésica = this.datos.medicacion_preanestésica;
            this.allDatos.plan_anestesico = this.datos.plan_anestesico;
            this.allDatos.observaciones = this.datos.observaciones;

            if (to == "firmar") {
                this.updatePreAnestesica()
            } else if (this.update) {
                console.log("update");
                this.updatePreAnestesica()
            } else {
                this.preAnestesica();
            }

        },

        async updatePreAnestesica() {
            this.error = false
            this.errores = []
            this.allDatos.expediente_id = this.expediente_id
            try {
                const res = await fetch(`${url}/api/notas/pre-anestesica/update/` + this.datos.id, {
                    method: 'put',
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        "Content-Type": "application/json",
                        "X-Requested-With": "XMLHttpRequest",
                        'Authorization': "Bearer " + localStorage.getItem("token"),
                    },
                    body: JSON.stringify(this.allDatos),
                });
                const data = res.json();
                Vue.prototype.$error500 = res.status === 500
                var warn = false;
                var err = []
                var promise = Promise.resolve(data);
                await promise.then(function (val) {
                    if (val.class == "success") {
                        warn = true;
                    } else {
                        err = val.errors
                    }
                });
                if (warn) {
                    this.$emit("borrarDatosPreAnestesica")
                    this.formNotaPreA('NotaPreAnestesicaUno')
                    this.openSuccess = true;
                    this.busy = false
                    this.cancelEdit()
                } else {
                    console.log('errores');
                    this.error = true
                    this.errores = err
                    this.$emit("errores", {bol:true, errs:this.errores})
                    this.formNotaPreA('NotaPreAnestesicaUno')
                    this.openError = true;
                    this.busy = false
                }
                if (this.errores.password) {
                    this.btnGuardar = false;
                    this.btnFirmar = true;
                }
            } catch (error) {
                console.log(error);
            }
        },

        async preAnestesica() {
            this.error = false
            this.errores = []
            this.allDatos.expediente_id = this.expediente_id
            try {
                const res = await fetch(`${url}/api/notas/pre-anestesica/store`, {
                    method: 'post',
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        "Content-Type": "application/json",
                        "X-Requested-With": "XMLHttpRequest",
                        'Authorization': "Bearer " + localStorage.getItem("token"),
                    },
                    body: JSON.stringify(this.allDatos),
                });
                const data = res.json();
                Vue.prototype.$error500 = res.status === 500
                var warn = false;
                var err = []
                var promise = Promise.resolve(data);
                await promise.then(function (val) {
                    if (val.class == "success") {
                        warn = true;
                    } else {
                        err = val.errors
                    }
                });
                if (warn) {
                    this.$emit("borrarDatosPreAnestesica")
                    this.formNotaPreA('NotaPreAnestesicaUno')
                    this.openSuccess = true;
                    this.busy = false
                } else {
                    this.error = true
                    this.errores = err
                    this.$emit("errores", {bol:true, errs:this.errores})
                    this.formNotaPreA('NotaPreAnestesicaUno')
                    this.openError = true;
                    this.busy = false
                }
                if (this.errores.password) {
                    this.btnGuardar = false;
                    this.btnFirmar = true;
                }
            } catch (error) {
                console.log(error);
            }
        },

        clearData() {
            this.showDta = false
            this.datos.password = ''
            this.datos.saturacion_oxigeno = ""
            this.datos.peso = ""
            this.datos.talla = ""
            this.datos.superficie_corporal = ""
            this.datos.frecuencia_cardiaca = ""
            this.datos.temperatura = ""
            this.datos.frecuencia_respiratoria = ""
            this.datos.estado_psiquiatrico = ""
            this.datos.color_egumentos = ""
            this.datos.horas_ayuno = ""
            this.datos.estado_hidratacion = ""
            this.datos.cabeza = ""
            this.datos.cuello = ""
            this.datos.cavidad_oral = ""
            this.datos.torax = ""
            this.datos.area_cardiaca = ""
            this.datos.abdomen = ""
            this.datos.otros_hallazgos = ''
            this.datos.vcs = ""
            this.datos.hb = ""
            this.datos.hto = ""
            this.datos.grupo_rh = ""
            this.datos.tt = ""
            this.datos.tp = ""
            this.datos.tpt = ""
            this.datos.tele_torax_id = ""
            this.datos.na = ""
            this.datos.k = ""
            this.datos.ci = ""
            this.datos.mg = ""
            this.datos.ego_id = ""
            this.datos.ecg_id = ""
            this.datos.vih = false
            this.datos.asa_id = ""
            this.datos.nyha_id = ""
            this.datos.golman_id = ""
            this.datos.gasometria_id = ""
            this.datos.genito_urinario_id = ""
            this.datos.sangrado_permisible = ""
            this.datos.sangre_reserva = ""
            this.datos.planquetas = ""
            this.datos.glucosa = ""
            this.datos.urea = ""
            this.datos.creat = ""
            this.datos.hemodilucion = false
            this.datos.otros_examenes = ''
            this.datos.nota_preanestesica = ""
            this.datos.medicacion_preanestésica = ""
            this.datos.plan_anestesico = ""
            this.datos.observaciones = ''
            this.datos.escala_glasgow = ""
            this.datos.presion_arterial = ""
            this.setData()
        },

        /* Obtener datos Form dos */
        async obetenerDatosFormDos(value) {
            try {
                const res = await fetch(`${url}/api/notas/pre-anestesica/${value}`, {
                    method: 'get',
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        'Content-Type': 'application/json',
                        'X-Requested-With': 'XMLHttpRequest',
                        'Authorization': "Bearer " + localStorage.getItem("token"),
                    }
                });
                if (!res.ok) {
                    const message = `An error has occured: ${res.status} - ${res.statusText}`;
                    throw new Error(message);
                }
                const data = await res.json();
                console.log(data);
                if (data.preanestesica) {
                    this.dtaExist = true
                    this.disableInput = true
                    this.datos = data.preanestesica
                    if (data.preanestesica.hemodilucion == 1) {
                        this.datos.hemodilucion = true;
                    } else {
                        this.datos.hemodilucion = false;
                    }
                    if (data.preanestesica.vih == 1) {
                        this.datos.vih = true;
                    } else {
                        this.datos.vih = false
                    }
                    try {
                        var ta = this.datos.presion_arterial
                        var ta1 = ta.indexOf("/")
                        this.presion1 = ta.substring(0, ta1)
                        this.presion2 = ta.substring(ta1 + 1, ta.length)
                    } catch (error) {
                        console.log("");
                    }
                    this.datos.tele_torax_id = data.preanestesica.tele_torax.id
                    this.datos.ego_id = data.preanestesica.ego.id
                    this.datos.ecg_id = data.preanestesica.ecg.id
                    this.datos.asa_id = data.preanestesica.asa.id
                    this.datos.nyha_id = data.preanestesica.nyha.id
                    this.datos.golman_id = data.preanestesica.golman.id
                    this.datos.gasometria_id = data.preanestesica.gasometria.id
                    this.datos.genito_urinario_id = data.preanestesica.genito_urinario.id
                    this.done = true
                    this.loading = false
                    window.scrollTo({ top: 0, behavior: 'smooth' })
                } else {
                    this.done = true
                    this.loading = false
                    window.scrollTo({ top: 0, behavior: 'smooth' })
                    this.disableInput = false
                    this.obtenerExploracionFisica(value)
                    // this.verificarPreGuardado()
                }

            } catch (error) {
                this.loading = false
                window.scrollTo({ top: 0, behavior: 'smooth' })
                console.log(error);
            }
        },

        async obtenerExploracionFisica(value) {
            try {
                const res = await fetch(`${url}/api/historia_clinica/exploracion_fisica/${value}`, {
                    method: 'get',
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        'Content-Type': 'application/json',
                        'X-Requested-With': 'XMLHttpRequest',
                        'Authorization': "Bearer " + localStorage.getItem("token"),
                    }
                });

                const data = await res.json();
                if (data.nota_exploracion_fisica) {
                    this.datos.peso = data.nota_exploracion_fisica.peso ? data.nota_exploracion_fisica.peso : ""
                    this.datos.talla = data.nota_exploracion_fisica.talla ? data.nota_exploracion_fisica.talla : ""
                    this.datos.cabeza = data.nota_exploracion_fisica.cabeza ? data.nota_exploracion_fisica.cabeza : ""
                    this.datos.cuello = data.nota_exploracion_fisica.cuello ? data.nota_exploracion_fisica.cuello : ""
                    this.datos.torax = data.nota_exploracion_fisica.torax ? data.nota_exploracion_fisica.torax : ""
                    this.datos.abdomen = data.nota_exploracion_fisica.abdomen ? data.nota_exploracion_fisica.abdomen : ""
                    data.nota_exploracion_fisica.peso && data.nota_exploracion_fisica.talla ? this.calcularMasa() : ""
                }

            } catch (error) {
                window.scrollTo({ top: 0, behavior: 'smooth' })
                this.loading = false
                console.log(error);
            }
        },

        async getNotaDeIngreso() {
            this.dtaExist = false
            try {
                const res = await fetch(`${url}/api/nota_ingreso/` + this.allDatos.expediente_id, {
                    method: 'get',
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        'Content-Type': 'application/json',
                        'X-Requested-With': 'XMLHttpRequest',
                        'Authorization': "Bearer " + localStorage.getItem("token"),
                    }
                });
                if (!res.ok) {
                    const message = `An error has occured: ${res.status} - ${res.statusText}`;
                    throw new Error(message);
                }
                const data = await res.json();
                console.log("datos del get" + this.allDatos.expediente_id);
                console.log(data);
                this.datos.escala_glasgow = data.nota_ingreso.motriz_id + data.nota_ingreso.ocular_id + data.nota_ingreso.verbal_id
            } catch (error) {
                console.log("");
            }
        },

        async getUserData() {
            this.dtaExist = false
            try {
                const res = await fetch(`${url}/api/usuarios/logged`, {
                    method: 'get',
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        'Content-Type': 'application/json',
                        'X-Requested-With': 'XMLHttpRequest',
                        'Authorization': "Bearer " + localStorage.getItem("token"),
                    }
                });
                const data = await res.json();
                try {
                    this.usuario = data.usuario
                } catch (error) {
                    console.log(error);
                }
            } catch (error) {
                console.log("");
            }
        },

        setData() {
            this.getUserData()
            const pacienteEncrypt = localStorage.expedienteEncrypt
            const expediente = CryptoJS.AES.decrypt(pacienteEncrypt, 'secret key heal4701tech').toString(CryptoJS.enc.Utf8);
            const paciente = JSON.parse(expediente)

            this.idResponsable = paciente.medico.id
            console.log(paciente);
            this.noExpediente = paciente.no_expediente

            this.state.idExpediente = paciente.id
            this.state.idPaciente = this.idPacienteSeleccionado

            if (paciente.paciente.lastExpediente.expediente_id != this.state.idExpediente) {
                this.state.oldExp = true
            } else {
                console.log("entrr en el else");
                this.state.oldExp = false
            }

            this.allDatos.expediente_id = paciente.id
            this.datos.expediente_id = paciente.id
            this.obetenerDatosFormDos(this.allDatos.expediente_id)
            this.getNotaDeIngreso()
            this.expediente_id = paciente.id
        },
        cerrarModal() {
            this.openSuccess = false
        },
        cerrarModalError() {
            this.openError = false
        },
        cerrarModalAlertPresionArterial() {
            this.openAlertPresion = false
        },
        firma_psw(psw) {
            this.allDatos.password = psw
            if (!this.dtaExist) {
                this.guardar()
            } else {
                this.guardar('firmar');
            }
            this.openFirmaPsw = false
        },
        showDtaPreSave(){
            this.showDta = true
            this.verificarPreGuardado()
            this.openPreSave = false
        },
        cerrarModalPreSave(){
            this.openPreSave = false
        },
        cerrarModalFirmarPswd(){
            this.openFirmaPsw = false
        },
        datosPaTwo(value, update){
            console.log(value);
            this.datosFormUno = value
            this.update = update
        },
        preGuardarNPreA(){
            this.datos.password = ""
            var existe = false;
            try {
                if (localStorage.preGuardadoNPreA2) {
                    this.datosPreGuardados = JSON.parse(localStorage.preGuardadoNPreA2)
                }
            } catch (error) {
                console.log(error);
            }
            var i = 0;
            for (let val of this.datosPreGuardados) {
                if (this.datos.expediente_id === val.datosF2.expediente_id) {
                    this.datosPreGuardados[i] = { "datosF2": this.datos }
                    existe = true
                }
                i++
            }
            if (!existe) {
                this.datosPreGuardados.push({ "datosF2": this.datos });
            }

            const dta = JSON.stringify(this.datosPreGuardados)
            localStorage.setItem('preGuardadoNPreA2', dta);
            console.log('preguardar');
        },
        expChangedPA(){
            this.clearData()
            this.showDta = false
            this.done = false
        }
    },
    mounted() {

        if (!this.done) {
            Object.assign(this.$data, this.$options.data());
            const egreso = localStorage.egreso
            if (egreso) {
                const egresoDE = JSON.parse(CryptoJS.AES.decrypt(egreso, 'secret key heal4701tech').toString(CryptoJS.enc.Utf8));
                console.log(egresoDE);
                this.isEgresado = egresoDE.egreso
            } else {
                router.push("/main")
            }

            this.setData()
            this.loading = true
        }
    },
    deactivated() {
        console.log(this.done);
    }
}
</script>

<style scoped>@media only screen and (min-width: 1200px) {
    .margen {
        margin-top: 33px;
    }
}

@media only screen and (max-width: 1200px) {
    .margen {
        margin-top: 40px;
    }
}

/* Tabla de frecuencia */
@media only screen and (min-width: 1200px) {
    .size_th_table_frec {
        width: 12vw;
    }
}

@media only screen and (max-width: 992px) {
    .size_th_table_frec {
        width: 25vw;
    }
}

@media only screen and (max-width: 768px) {
    .size_th_table_frec {
        width: 30vw;
    }
}

@media only screen and (max-width: 576px) {
    .size_th_table_frec {
        width: auto;
    }
}

/* End */

@media only screen and (min-width: 1100px) {
    .font-size-vw {
        font-size: 1.7vw
    }
}

@media only screen and (max-width: 1100px) {
    .font-size-vw {
        font-size: 2vw
    }
}

@media only screen and (max-width: 910px) {
    .font-size-vw {
        font-size: 2.2vw
    }
}

@media only screen and (max-width: 810px) {
    .font-size-vw {
        font-size: 2.7vw
    }
}

@media only screen and (max-width: 710px) {
    .font-size-vw {
        font-size: 3vw
    }
}

@media only screen and (max-width: 576px) {
    .font-size-vw {
        font-size: 3.8vw
    }
}

/* Tables */
@media only screen and (min-width: 960px) {
    .size_th {
        width: 8vw;
    }
}

@media only screen and (max-width: 960px) {
    .size_th {
        width: 13vw;
    }
}

@media only screen and (max-width: 768px) {
    .size_th {
        width: 25vw;
    }
}

@media only screen and (max-width: 576px) {
    .size_th {
        width: auto;
    }
}</style>