<template>
    <b-container>
        <b-row>
            <HCFormUno v-if="current==='HCFormUno'" @changeHC="changeHC($event)" @toEgreso="$emit('toEgreso')" @nextHCTwo="nextHCTwo($event)"/>
            <HCFormDos v-if="current==='HCFormDos'" @changeHC="changeHC($event)" @toEgreso="$emit('toEgreso')"/>
            <HCFormTres v-if="current==='HCFormTres'" @changeHC="changeHC($event)" @toEgreso="$emit('toEgreso')"/>
        </b-row>
    </b-container>
</template>

<script>
import { HCFormUno, HCFormDos, HCFormTres } from '@/components/Paciente/Historia_clinica'
export default {
    name:'hcmain',
    components: { 
    HCFormUno,
    HCFormDos,
    HCFormTres
    },
    data:() => ({
        current:'HCFormUno'
    }),
    methods:{
        changeHC(value){
            this.current = value;
        },
         nextHCTwo(value){
            this.current = value;
        },
    },
    activated(){
        this.current='HCFormUno'
    },
    deactivated(){
        this.current=''
    }

}
</script>

<style></style>