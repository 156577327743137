<template>
    <div>
        <Navbar/>
        <b-container class="mt-5">
            <b-row>
                <b-col cols="12" sm="12" md="12" lg="3">
                    <button id="atras" @click="regresar()" class="text-center border_primary py-2 mb-4 text-left btn_radius btn-block">
                        <i class="fa fa-arrow-left fa-lg px-1" />
                        Regresar
                    </button>
                    <b-overlay
                    :show="busy"
                    rounded
                    opacity="0.6"
                    spinner-small
                    spinner-variant="primary"
                    >
                        <button id="atras" @click="Imprimir()" class="text-center border_secoundary py-2 mb-4 text-left btn_radius btn-block">
                            <i class="fa fa-download fa-lg px-1" />
                            Imprimir todas las notas
                        </button>
                    </b-overlay>
                    <b-overlay
                    rounded
                    opacity="0.6"
                    spinner-small
                    spinner-variant="primary"
                    >
                        <button id="atras" @click="ImprimirAviso()" class="text-center border_secoundary py-2 mb-4 text-left btn_radius btn-block">
                            <i class="fa fa-download fa-lg px-1" />
                            Imprimir aviso de privacidad
                        </button>
                    </b-overlay>
                    <hr>
                    <button id="infoPaciente" @click="switchComponent('Informacion')" class="btn_menu_pressed border_primary py-2 mb-4 text-left btn_radius btn-block">
                        <i class="fa fa-user fa-lg px-2" />
                        Información del paciente
                    </button>
                    <button v-show="showView.show.frontal_expediente && (clinica==='cqc' || clinica==='sandbox')" id="frontalExpediente" @click="switchComponent('FrontalDeExpediente')" class="border_primary py-2 mb-4 text-left btn_radius btn-block">
                        <i class="fa fa-edit fa-lg px-2" />
                        Frontal de expediente
                    </button>
                    <button v-show="showView.show.hoja_admision && (clinica==='cqc' || clinica==='sandbox')" id="admision" @click="switchComponent('VerHojaDeAdmision')" class="border_primary py-2 mb-4 text-left btn_radius btn-block">
                        <i class="fa fa-edit fa-lg px-2" />
                        Hoja de admisión
                    </button>
                    <button v-show="showView.show.nota_consulta" id="notaConsulta" @click="switchComponent('NotaDeConsulta')" class="border_primary py-2 mb-4 text-left btn_radius btn-block">
                        <i class="fa fa-file fa-lg px-2" />
                        Nota de consulta
                    </button>
                    <button v-show="showView.show.historia_clinica && (idPerfil==1 || idPerfil==3)" id="histClinica" @click="switchComponent('HCMain')" class="border_primary py-2 mb-4 text-left btn_radius btn-block">
                        <i class="fa fa-file fa-lg px-2" />
                        Historia clínica
                    </button>

                    <button id="consentimiento" @click="switchComponent('consentim')" class="border_primary py-2 mb-4 text-left btn_radius btn-block">
                        <i class="fa fa-envelope fa-lg px-2" />
                        Consentimientos
                    </button>
                    <b-row  v-if="!showConsentim" >
                        <b-col cols="12">
                            <div v-show="showView.show.consentimientos.carta">
                                <div class="d-flex mb-4">
                                    <i class="fa fa-file fa-lg my-auto px-3 icon_color_main" />
                                    <button id="carta" @click="switchComponent('Carta')" class="border_secoundary py-2 text-left btn_radius btn-block">
                                        Carta consentimiento
                                        <span class="float-right px-2">
                                            <i class="fa fa-edit fa-lg" />
                                        </span>
                                    </button>
                                </div>
                            </div>

                            <div v-show="showView.show.consentimientos.anestesiologia">
                                <div class="d-flex mb-4">
                                    <i class="fa fa-file fa-lg my-auto px-3 icon_color_main" />
                                    <button id="consentimAnest" @click="switchComponent('Anestesiologia')" class="border_secoundary py-2 text-left btn_radius btn-block">
                                        Anestesiología
                                        <span class="float-right px-2">
                                            <i class="fa fa-edit fa-lg" />
                                        </span>
                                    </button>
                                </div>
                            </div>

                            <div v-show="showView.show.consentimientos.hemodinamia">
                                <div class="d-flex mb-4">
                                    <i class="fa fa-file fa-lg my-auto px-3 icon_color_main" />
                                    <button id="hemodinamia" @click="switchComponent('Hemodinamia')" class="border_secoundary py-2 text-left btn_radius btn-block">
                                        Hemodinámica
                                        <span class="float-right px-2">
                                            <i class="fa fa-edit fa-lg" />
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </b-col>
                    </b-row>
                    <button v-show="Object.values(showView.show.notas).some(v=>v)" id="notasMedicas" @click="switchComponent('notas')" class="border_primary py-2 mb-4 text-left btn_radius btn-block">
                        <!-- (idPerfil==1 || idPerfil==3) -->
                        <i class="fa fa-clipboard fa-lg px-2" />
                        Notas médicas
                    </button>
                    <b-row v-if="!show">
                        <b-col cols="12">
                            <div v-show="showView.show.notas.nota_ingreso">
                                <div class="d-flex mb-4">
                                    <i class="fa fa-file fa-lg my-auto px-3 icon_color_main" />
                                    <button id="notaIngreso" @click="switchComponent('NotaDeIngreso')" class="border_secoundary py-2 text-left btn_radius btn-block">
                                        Nota de ingreso
                                        <span class="float-right px-2">
                                            <i class="fa fa-edit fa-lg" />
                                        </span>
                                    </button>
                                </div>
                            </div>
                            <div v-show="showView.show.notas.hoja_ingreso">
                                <div class="d-flex mb-4">
                                    <i class="fa fa-file fa-lg my-auto px-3 icon_color_main" />
                                    <button id="hojaIngreso" @click="switchComponent('HojaDeIngreso')" class="border_secoundary py-2 text-left btn_radius btn-block">
                                        Hoja de ingreso
                                        <span class="float-right px-2">
                                            <i class="fa fa-edit fa-lg" />
                                        </span>
                                    </button>
                                </div>
                            </div>
                            <div v-show="showView.show.notas.nota_evolucion">
                                <div class="d-flex mb-4">
                                    <i class="fa fa-file fa-lg my-auto px-3 icon_color_main" />
                                    <button id="notaEvolucion" @click="switchComponent('NotaEvolucionMedica')" class="border_secoundary py-2 text-left btn_radius btn-block">
                                        Nota de evolución
                                        <span class="float-right px-2">
                                            <i class="fa fa-edit fa-lg" />
                                        </span>
                                    </button>
                                </div>
                            </div>
                            <div v-show="showView.show.notas.pre_operatoria">
                                <div class="d-flex mb-4">
                                    <i class="fa fa-file fa-lg my-auto px-3 icon_color_main" />
                                    <button id="notaPreoperatoria" @click="switchComponent('NotaPreOperatoria')" class="border_secoundary py-2 text-left btn_radius btn-block">
                                        Nota pre-operatoria
                                        <span class="float-right px-2">
                                            <i class="fa fa-edit fa-lg" />
                                        </span>
                                    </button>
                                </div>
                            </div>
                            <div v-show="showView.show.notas.pre_anestesica">
                                <div class="d-flex mb-4">
                                    <i class="fa fa-file fa-lg my-auto px-3 icon_color_main" />
                                    <button id="notaPreanestesica" @click="switchComponent('NPAMain')" class="border_secoundary py-2 text-left btn_radius btn-block">
                                        Nota pre-anestésica
                                        <span class="float-right px-2">
                                            <i class="fa fa-edit fa-lg" />
                                        </span>
                                    </button>
                                </div>
                            </div>
                            <div v-show="showView.show.notas.post_operatoria">
                                <div class="d-flex mb-4">
                                    <i class="fa fa-file fa-lg my-auto px-3 icon_color_main" />
                                    <button id="notaPostoperatoria" @click="switchComponent('NotaPostOperatoria')" class="border_secoundary py-2 text-left btn_radius btn-block">
                                        Nota post-operatoria
                                        <span class="float-right px-2">
                                            <i class="fa fa-edit fa-lg" />
                                        </span>
                                    </button>
                                </div>
                            </div>
                            <div v-show="showView.show.notas.nota_indicaciones">
                                <div class="d-flex mb-4">
                                    <i class="fa fa-file fa-lg my-auto px-3 icon_color_main" />
                                    <button id="indicaciones" @click="switchComponent('Indicaciones')" class="border_secoundary py-2 text-left btn_radius btn-block">
                                        Nota de Indicaciones
                                        <span class="float-right px-2">
                                            <i class="fa fa-edit fa-lg" />
                                        </span>
                                    </button>
                                </div>
                            </div>
                            <div v-show="showView.show.notas.nota_egreso">
                                <div class="d-flex mb-4">
                                    <i class="fa fa-file fa-lg my-auto px-3 icon_color_main" />
                                    <button id="notaEgreso" @click="switchComponent('NotaDeEgreso')" class="border_secoundary py-2 text-left btn_radius btn-block">
                                        Nota de egreso
                                        <span class="float-right px-2">
                                            <i class="fa fa-edit fa-lg" />
                                        </span>
                                    </button>
                                </div>
                            </div>

                        </b-col>
                    </b-row>
                </b-col>

                <b-col cols="12" sm="12" md="12" lg="9">
                    <keep-alive>
                        <component v-bind:is="current" ref="component"  @nextHCTwo="nextHCTwo($event)" @toEgreso="toEgreso($event)" @toInfo="toInfo()"></component>
                    </keep-alive>


                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
import { Informacion, VerHojaDeAdmision, HojaDeIngreso, Indicaciones, NotaDeEgreso, NotaPostOperatoria, FrontalDeExpediente, NotaDeIngreso, NotaEvolucionMedica, NotaPreOperatoria, NotaDeConsulta } from '@/components/Paciente'
// import { HCFormUno, HCFormDos, HCFormTres } from '@/components/Paciente/Historia_clinica'
import HCMain from '../../../components/Paciente/Historia_clinica/HCMain.vue';
import NPAMain from '../../../components/Paciente/NotaPreAnestesica/NPAMain.vue';
// import { NotaPreAnestesicaUno, NotaPreAnestesicaDos } from '@/components/Paciente/NotaPreAnestesica'
import { Carta, Anestesiologia, Hemodinamia } from '@/components/Paciente/Consentimiento'

import router from '../../../router'
import Navbar from "@/components/Navbar.vue";

// settings
import { clinica, showNotas } from '../../../../settings';
import CryptoJS from 'crypto-js'
import PDFMerger from 'pdf-merger-js'
import { url } from '../../../../settings';
var merger

export default {
    name: 'Registro',
    
    components: { 
        HCMain,
        Navbar, 
        Informacion, 
        // HCFormUno, 
        // HCFormDos,
        NotaDeIngreso, 
        NotaEvolucionMedica, 
        NotaPreOperatoria,
        NPAMain,
        // NotaPreAnestesicaUno,
        // NotaPreAnestesicaDos,
        NotaPostOperatoria,
        NotaDeEgreso,
        NotaDeConsulta,
        // HCFormTres,
        Indicaciones,
        FrontalDeExpediente,
        Carta,
        Anestesiologia,
        HojaDeIngreso,
        VerHojaDeAdmision,
        Hemodinamia,
    },

    data: () => ({
        pdfDone:{
            pdf2:false,
            pdf3:false,
            pdfC1:false,
            pdfC2:false,
            pdfC3:false,
            pdf4:false,
            pdf5:false,
            pdf6:false,
            pdf7:false,
            pdf8:false,
            pdf9:false,
            pdf10:false,
            pdf11:false,            
        },
        busy:false,
        show: true,
        showConsentim: true,
        current: "Informacion",
        clinica:clinica,
        idPerfil: '',
        expediente_id:'',
        showView: {
            id: 1,
            show: {
                AvisoDePrivacidad: true,
                frontal_expediente:true,
                hoja_admision:true,
                nota_consulta:true,
                historia_clinica:true,
                consentimientos: {
                    carta:true,
                    anestesiologia:true,
                    hemodinamia:true
                },
                notas: {
                    hoja_ingreso:true,
                    nota_ingreso:true,
                    nota_evolucion:true,
                    pre_operatoria:true,
                    pre_anestesica:true,
                    post_operatoria:true,
                    nota_indicaciones:true,
                    nota_egreso:true,
                }
            }
        },
    }),

    methods: {
        Imprimir(){
            const pacienteEncrypt=localStorage.expedienteEncrypt
            const expediente =  CryptoJS.AES.decrypt(pacienteEncrypt, 'secret key heal4701tech').toString(CryptoJS.enc.Utf8);
            const paciente=JSON.parse(expediente)
            console.log(paciente);
            this.expediente_id=paciente.id
            this.busy=true
            merger = new PDFMerger()
            this.arrayUrl = Array(14)
            
            // this.clinica=='chlo'?this.print1():this.pdfDone.pdf1=true,
            this.print2()
            this.print3()
            this.printC1()
            this.printC2()
            this.printC3()
            this.print4()
            this.print5()
            this.print6()
            this.print7()
            this.print8()
            this.print9()
            this.print10()
            this.print11()
            this.doneGetsPdf(paciente.no_expediente)
        },

        doneGetsPdf(v){
            if (Object.values(this.pdfDone).every(v => v == true)) {
                this.mergePdfs(v) 
            }else{
                setTimeout(() => {
                    this.doneGetsPdf(v)
                }, 1000);
            }
        },

        async mergePdfs(v) {
            console.log(this.arrayUrl);
            for (const iterator of this.arrayUrl) {
                try {
                    await merger.add(iterator)
                } catch (error) {
                    console.log("");
                }
            }

            await merger.save(v);

            this.busy = false;
            
            this.busy=false
            this.pdfDone={
                pdf2:false,
                pdf3:false,
                pdfC1:false,
                pdfC2:false,
                pdfC3:false,
                pdf4:false,
                pdf5:false,
                pdf6:false,
                pdf7:false,
                pdf8:false,
                pdf9:false,
                pdf10:false,
                pdf11:false,            
            }
        },

        async print2() {
            let can2 = false
            let urlNe = `${url}/api/hoja_admision/admision/pdf/${this.$route.params.id}`;
            let esto = this
            return fetch(urlNe, {
                method: 'get',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Requested-With': 'XMLHttpRequest',
                    'Authorization': "Bearer "+ localStorage.getItem("token"),
                }
            }).then(function(resp){
                can2 = resp.ok
                return resp.blob()
            }).then(function(blob) {
                if (can2) {
                    var url = URL.createObjectURL(blob);
                    // esto.arrayUrl.push(url)
                    esto.arrayUrl[1]=url
                }
                //esto.print3()
                esto.pdfDone.pdf2=true
            })
        },
        async print3() {
            let can = false
            let urlNe = `${url}/api/pacientes/pdf/${this.$route.params.id}`;
            let esto = this
            return fetch(urlNe, {
                method: 'get',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Requested-With': 'XMLHttpRequest',
                    'Authorization': "Bearer "+ localStorage.getItem("token"),
                }
            }).then(function(resp){
                can = resp.ok
                return resp.blob()
            }).then(function(blob) {
                if (can) {
                    var url = URL.createObjectURL(blob);
                    // esto.arrayUrl.push(url)
                    esto.arrayUrl[2]=url
                }
                //esto.print4()
                esto.pdfDone.pdf3=true
            })
        },
        async printC1() {
            let can1 = false
            let urlHi = `${url}/api/carta_consentimiento/${this.expediente_id}/true`;
            let esto = this
            return fetch(urlHi, {
                method: 'get',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Requested-With': 'XMLHttpRequest',
                    'Authorization': "Bearer "+ localStorage.getItem("token"),
                }
            }).then(function(resp){
                can1 = resp.ok
                return resp.blob()
            }).then(function(blob) {
                if (can1) {
                    var url = URL.createObjectURL(blob);
                    esto.arrayUrl[3]=url
                }
                esto.pdfDone.pdfC1=true
            })
        },
        async printC2() {
            let can1 = false
            let urlHi = `${url}/api/hoja_consentimiento/${this.expediente_id}/true`;
            let esto = this
            return fetch(urlHi, {
                method: 'get',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Requested-With': 'XMLHttpRequest',
                    'Authorization': "Bearer "+ localStorage.getItem("token"),
                }
            }).then(function(resp){
                can1 = resp.ok
                return resp.blob()
            }).then(function(blob) {
                if (can1) {
                    var url = URL.createObjectURL(blob);
                    esto.arrayUrl[4]=url
                }
                esto.pdfDone.pdfC2=true
            })
        },
        async printC3() {
            let can1 = false
            let urlHi = `${url}/api/consentimientos/hemodinamia/pdf/${this.expediente_id}`;
            let esto = this
            return fetch(urlHi, {
                method: 'get',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Requested-With': 'XMLHttpRequest',
                    'Authorization': "Bearer "+ localStorage.getItem("token"),
                }
            }).then(function(resp){
                can1 = resp.ok
                return resp.blob()
            }).then(function(blob) {
                if (can1) {
                    var url = URL.createObjectURL(blob);
                    esto.arrayUrl[5]=url
                }
                esto.pdfDone.pdfC3=true
            })
        },
        async print4() {
            let can = false
            let urlNe = `${url}/api/hoja_ingreso/pdf/${this.expediente_id}`;
            let esto = this
            return fetch(urlNe, {
                method: 'get',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Requested-With': 'XMLHttpRequest',
                    'Authorization': "Bearer "+ localStorage.getItem("token"),
                }
            }).then(function(resp){
                can = resp.ok
                return resp.blob()
            }).then(function(blob) {
                if (can) {
                    var url = URL.createObjectURL(blob);
                    // esto.arrayUrl.push(url)
                    esto.arrayUrl[6]=url
                }
                //esto.print5()
                esto.pdfDone.pdf4=true
            })
        },
        async print5() {
            let can = false
            let urlNe = `${url}/api/nota_ingreso/pdf/${this.expediente_id}`;
            let esto = this
            return fetch(urlNe, {
                method: 'get',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Requested-With': 'XMLHttpRequest',
                    'Authorization': "Bearer "+ localStorage.getItem("token"),
                }
            }).then(function(resp){
                console.log(resp);
                can = resp.ok
                return resp.blob()
            }).then(function(blob) {
                if (can) {
                    var url = URL.createObjectURL(blob);
                    esto.arrayUrl[7]=url
                }
                esto.pdfDone.pdf5=true
            })
        },
        async print6() {
            let can = false
            let urlNe = `${url}/api/nota_evolucion/descargar/${this.expediente_id}`;
            let esto = this
            return fetch(urlNe, {
                method: 'get',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Requested-With': 'XMLHttpRequest',
                    'Authorization': "Bearer "+ localStorage.getItem("token"),
                }
            }).then(function(resp){
                can = resp.ok
                return resp.blob()
            }).then(function(blob) {
                if (can) {
                    var url = URL.createObjectURL(blob);
                    // esto.arrayUrl.push(url)
                    esto.arrayUrl[8]=url
                }
                esto.pdfDone.pdf6=true
                //esto.print7()
            })
        },
        async print7() {
            let can = false
            let urlNe = `${url}/api/notas/pre-operatoria/descargar/${this.expediente_id}`;
            let esto = this
            return fetch(urlNe, {
                method: 'get',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Requested-With': 'XMLHttpRequest',
                    'Authorization': "Bearer "+ localStorage.getItem("token"),
                }
            }).then(function(resp){
                can = resp.ok
                return resp.blob()
            }).then(function(blob) {
                if (can) {
                    var url = URL.createObjectURL(blob);
                    // esto.arrayUrl.push(url)
                    esto.arrayUrl[9]=url
                }
                esto.pdfDone.pdf7=true
                //esto.print8()
            })
        },
        async print8() {
            let can = false
            let urlNe = `${url}/api/notas/pre-anestesica/descargar/${this.expediente_id}`;
            let esto = this
            return fetch(urlNe, {
                method: 'get',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Requested-With': 'XMLHttpRequest',
                    'Authorization': "Bearer "+ localStorage.getItem("token"),
                }
            }).then(function(resp){
                can = resp.ok
                return resp.blob()
            }).then(function(blob) {
                if (can) {
                    var url = URL.createObjectURL(blob);
                    esto.arrayUrl[10]=url
                }
                esto.pdfDone.pdf8=true
            })
            //esto.print9()
        },
        async print9() {
            let can = false
            let urlNe = `${url}/api/notas/post-operatoria/descargar/${this.expediente_id}`;
            let esto = this
            return fetch(urlNe, {
                method: 'get',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Requested-With': 'XMLHttpRequest',
                    'Authorization': "Bearer "+ localStorage.getItem("token"),
                }
            }).then(function(resp){
                can = resp.ok
                return resp.blob()
            }).then(function(blob) {
                if (can) {
                    var url = URL.createObjectURL(blob);
                    // esto.arrayUrl.push(url)
                    esto.arrayUrl[11]=url                
                }
                esto.pdfDone.pdf9=true
                //esto.print10()
            })
        },
        async print10() {
            let can = false
            let urlNe = `${url}/api/indicaciones_medicas/descargar/${this.expediente_id}`;
            let esto = this
            return fetch(urlNe, {
                method: 'get',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Requested-With': 'XMLHttpRequest',
                    'Authorization': "Bearer "+ localStorage.getItem("token"),
                }
            }).then(function(resp){
                can = resp.ok
                return resp.blob()
            }).then(function(blob) {
                if (can) {
                    var url = URL.createObjectURL(blob);
                    // esto.arrayUrl.push(url)
                    esto.arrayUrl[12]=url
                }
                esto.pdfDone.pdf10=true
                //esto.print11()
            })
        },
        async print11() {
            let can = false
            let urlNe = `${url}/api/notas/egreso/descargar/${this.expediente_id}`;
            let esto = this
            return fetch(urlNe, {
                method: 'get',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Requested-With': 'XMLHttpRequest',
                    'Authorization': "Bearer "+ localStorage.getItem("token"),
                }
            }).then(function(resp){
                can = resp.ok
                return resp.blob()
            }).then(function(blob) {
                if (can) {
                    var url = URL.createObjectURL(blob);
                    // esto.arrayUrl.push(url)
                    esto.arrayUrl[13]=url
                }
                esto.pdfDone.pdf11=true
                // esto.mergePdfs()
            })
        },
        async ImprimirAviso(){
            var este = this;
            this.loading = true

            let urlim = `${url}/api/aviso-privacidad`;
            return fetch(urlim, {
                method: 'get',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Requested-With': 'XMLHttpRequest',
                    'Authorization': "Bearer "+ localStorage.getItem("token"),
                }
            }).then(function(resp){
                return resp.blob("paciente.pdf")
            }).then(function(blob) {
                este.downloadPDF(blob)
            })
        },
        async downloadPDF(blob){
            try {
                var merger = new PDFMerger()
                await merger.add(URL.createObjectURL(blob))
                await merger.save("Aviso de privacidad");
                this.loading = false
            } catch (error) {
                console.log(error);
                this.loading = false
            }
        },

        
        switchComponent(value) {


            if (value==='notas' || value==='consentim') {
                console.log("notas");
            }else{
                localStorage.setItem("view",value)
                this.current = value;
            }
            

            const infoPaciente = document.getElementById('infoPaciente');
            const histClinica = document.getElementById('histClinica');
            const notasMedicas = document.getElementById('notasMedicas'); 
            const notaIngreso = document.getElementById('notaIngreso');
            const notaConsulta = document.getElementById('notaConsulta');
            const notaEvolucion = document.getElementById('notaEvolucion');
            const notaPreoperatoria = document.getElementById('notaPreoperatoria');
            const notaPreanestesica = document.getElementById('notaPreanestesica');
            const notaPostoperatoria = document.getElementById('notaPostoperatoria');
            const indicaciones = document.getElementById('indicaciones');
            const notaEgreso = document.getElementById('notaEgreso');
            const consentimiento = document.getElementById('consentimiento');
            const carta = document.getElementById('carta');
            const consentimAnest = document.getElementById('consentimAnest');
            const hojaIngreso = document.getElementById('hojaIngreso');
            const hemodinamia = document.getElementById('hemodinamia');

            var admision=null;
            var frontalExpediente = null;
            if ((clinica==='cqc' || clinica==='sandbox')) {
                frontalExpediente = document.getElementById('frontalExpediente');
                admision = document.getElementById('admision');
            }
            switch (value) {
                case 'Informacion':
                    this.show = true
                    this.showConsentim = true
                    if (this.showView.show.historia_clinica) {
                        histClinica.classList.remove('btn_menu_pressed');
                    }
                    if (Object.values(this.showView.show.notas).some(v=>v)) {
                        notasMedicas.classList.remove('btn_menu_pressed');
                    }
                    infoPaciente.classList.add('btn_menu_pressed');
                    if (this.clinica=="cqc" || this.clinica=="sandbox") {
                        admision.classList.remove('btn_menu_pressed');
                        frontalExpediente.classList.remove('btn_menu_pressed');
                    }
                    consentimiento.classList.remove('btn_menu_pressed');
                    notaConsulta.classList.remove('btn_menu_pressed');
                    break;

                case 'FrontalDeExpediente':
                    this.show = true
                    this.showConsentim = true
                    frontalExpediente.classList.add('btn_menu_pressed');
                    if ((clinica==='cqc' || clinica==='sandbox')) {
                        admision.classList.remove('btn_menu_pressed');
                    }
                    if (this.showView.show.historia_clinica) {
                        histClinica.classList.remove('btn_menu_pressed');
                    }
                    if (Object.values(this.showView.show.notas).some(v=>v)) {
                        notasMedicas.classList.remove('btn_menu_pressed');
                    }
                    infoPaciente.classList.remove('btn_menu_pressed');
                    consentimiento.classList.remove('btn_menu_pressed');
                    notaConsulta.classList.remove('btn_menu_pressed');
                    break;

                case 'VerHojaDeAdmision':
                    this.show = true
                    this.showConsentim = true
                    admision.classList.add('btn_menu_pressed');
                    frontalExpediente.classList.remove('btn_menu_pressed');
                    infoPaciente.classList.remove('btn_menu_pressed');
                    consentimiento.classList.remove('btn_menu_pressed');
                    notaConsulta.classList.remove('btn_menu_pressed');
                    if (this.showView.show.historia_clinica) {
                        histClinica.classList.remove('btn_menu_pressed');
                    }
                    if (Object.values(this.showView.show.notas).some(v=>v)) {
                        notasMedicas.classList.remove('btn_menu_pressed');
                    }
                    break;
                
                case 'NotaDeConsulta':
                    console.log("Entre");
                    this.show = true
                    this.showConsentim = true
                    notaConsulta.classList.add('btn_menu_pressed');
                    histClinica.classList.remove('btn_menu_pressed');
                    if ((clinica==='cqc' || clinica==='sandbox')) {
                        admision.classList.remove('btn_menu_pressed');
                        frontalExpediente.classList.remove('btn_menu_pressed');
                    }
                    consentimiento.classList.remove('btn_menu_pressed');
                    infoPaciente.classList.remove('btn_menu_pressed');
                    notasMedicas.classList.remove('btn_menu_pressed');
                    break;


                case 'HCMain':
                    this.show = true
                    this.showConsentim = true
                    histClinica.classList.add('btn_menu_pressed');
                    notaConsulta.classList.remove('btn_menu_pressed');
                    if ((clinica==='cqc' || clinica==='sandbox')) {
                        admision.classList.remove('btn_menu_pressed');
                        frontalExpediente.classList.remove('btn_menu_pressed');
                    }
                    consentimiento.classList.remove('btn_menu_pressed');
                    infoPaciente.classList.remove('btn_menu_pressed');
                    notasMedicas.classList.remove('btn_menu_pressed');
                    break;
                
                case 'consentim':
                    this.showConsentim =! this.showConsentim
                    consentimiento.classList.add('btn_menu_pressed');
                    infoPaciente.classList.remove('btn_menu_pressed');
                    notaConsulta.classList.remove('btn_menu_pressed');
                    try {
                        if (this.show == false && hojaIngreso.classList.contains('btn_submenu_pressed') || notaIngreso.classList.contains('btn_submenu_pressed') || notaEvolucion.classList.contains('btn_submenu_pressed')) {
                            hojaIngreso.classList.remove('btn_submenu_pressed')
                            notaIngreso.classList.remove('btn_submenu_pressed')
                            notaEvolucion.classList.remove('btn_submenu_pressed')
                        }
                    } catch (error) {
                        console.log("");
                    }

                    if (clinica==='cqc' || clinica==='sandbox') {
                        admision.classList.remove('btn_menu_pressed');
                        frontalExpediente.classList.remove('btn_menu_pressed');
                    }
                    if (this.showView.show.historia_clinica) {
                        histClinica.classList.remove('btn_menu_pressed');
                    }
                    if (Object.values(this.showView.show.notas).some(v=>v)) {
                        notasMedicas.classList.remove('btn_menu_pressed');
                    }
                    this.show = true
                    break;

                case 'Carta':
                    carta.classList.add('btn_submenu_pressed');
                    consentimAnest.classList.remove('btn_submenu_pressed');
                    hemodinamia.classList.remove('btn_submenu_pressed');
                    break;

                case 'Anestesiologia':
                    carta.classList.remove('btn_submenu_pressed');
                    consentimAnest.classList.add('btn_submenu_pressed');
                    hemodinamia.classList.remove('btn_submenu_pressed');
                    break;

                case 'Hemodinamia':
                    carta.classList.remove('btn_submenu_pressed');
                    consentimAnest.classList.remove('btn_submenu_pressed');
                    hemodinamia.classList.add('btn_submenu_pressed');
                    break;

                case 'notas':
                    this.show =! this.show
                    notasMedicas.classList.add('btn_menu_pressed');
                    infoPaciente.classList.remove('btn_menu_pressed');
                    histClinica.classList.remove('btn_menu_pressed');
                    consentimiento.classList.remove('btn_menu_pressed');
                    notaConsulta.classList.remove('btn_menu_pressed');
                    if (clinica==='cqc' || clinica==='sandbox') {
                        admision.classList.remove('btn_menu_pressed');
                        frontalExpediente.classList.remove('btn_menu_pressed');
                    }
                    try {
                        setTimeout(() => {
                            if (!this.show) {
                                var scrolling = this.$el.querySelector("#notasMedicas");
                                scrolling.scrollIntoView({inline: "end",behavior: "smooth"})
                            }
                        }, 400);
                    } catch (error) {
                        console.log("");
                    }
                    try {
                        if (this.showConsentim == false && carta.classList.contains('btn_submenu_pressed') || consentimiento.classList.contains('btn_submenu_pressed') || hemodinamia.classList.contains('btn_submenu_pressed')) {
                            carta.classList.remove('btn_submenu_pressed')
                            consentimAnest.classList.remove('btn_submenu_pressed')
                            hemodinamia.classList.remove('btn_submenu_pressed')
                        }
                    } catch (error) {
                        console.log('');
                    }
                    this.showConsentim = true
                    break;

                case 'HojaDeIngreso':
                    hojaIngreso.classList.add('btn_submenu_pressed');
                    notaIngreso.classList.remove('btn_submenu_pressed');
                    // notaConsulta.classList.remove('btn_submenu_pressed');
                    notaEvolucion.classList.remove('btn_submenu_pressed');
                    notaPreoperatoria.classList.remove('btn_submenu_pressed');
                    notaPreanestesica.classList.remove('btn_submenu_pressed');
                    notaPostoperatoria.classList.remove('btn_submenu_pressed');
                    notaEgreso.classList.remove('btn_submenu_pressed');
                    indicaciones.classList.remove('btn_submenu_pressed');
                    break;

                case 'NotaDeIngreso':
                    notaIngreso.classList.add('btn_submenu_pressed');
                    // notaConsulta.classList.remove('btn_submenu_pressed');
                    hojaIngreso.classList.remove('btn_submenu_pressed');
                    notaEvolucion.classList.remove('btn_submenu_pressed');
                    notaPreoperatoria.classList.remove('btn_submenu_pressed');
                    notaPreanestesica.classList.remove('btn_submenu_pressed');
                    notaPostoperatoria.classList.remove('btn_submenu_pressed');
                    notaEgreso.classList.remove('btn_submenu_pressed');
                    indicaciones.classList.remove('btn_submenu_pressed');
                    break;

                // case 'NotaDeConsulta':
                //     // notaConsulta.classList.add('btn_submenu_pressed');
                //     notaIngreso.classList.remove('btn_submenu_pressed');
                //     hojaIngreso.classList.remove('btn_submenu_pressed');
                //     notaEvolucion.classList.remove('btn_submenu_pressed');
                //     notaPreoperatoria.classList.remove('btn_submenu_pressed');
                //     notaPreanestesica.classList.remove('btn_submenu_pressed');
                //     notaPostoperatoria.classList.remove('btn_submenu_pressed');
                //     notaEgreso.classList.remove('btn_submenu_pressed');
                //     indicaciones.classList.remove('btn_submenu_pressed');
                //     break;

                case 'NotaEvolucionMedica':
                    notaIngreso.classList.remove('btn_submenu_pressed');
                    // notaConsulta.classList.remove('btn_submenu_pressed');
                    hojaIngreso.classList.remove('btn_submenu_pressed');
                    notaEvolucion.classList.add('btn_submenu_pressed');
                    notaPreoperatoria.classList.remove('btn_submenu_pressed');
                    notaPreanestesica.classList.remove('btn_submenu_pressed');
                    notaPostoperatoria.classList.remove('btn_submenu_pressed');
                    notaEgreso.classList.remove('btn_submenu_pressed');
                    indicaciones.classList.remove('btn_submenu_pressed');
                    break;
                
                case 'NotaPreOperatoria':
                    notaIngreso.classList.remove('btn_submenu_pressed');
                    // notaConsulta.classList.remove('btn_submenu_pressed');
                    hojaIngreso.classList.remove('btn_submenu_pressed');
                    notaEvolucion.classList.remove('btn_submenu_pressed');
                    notaPreoperatoria.classList.add('btn_submenu_pressed');
                    notaPreanestesica.classList.remove('btn_submenu_pressed');
                    notaPostoperatoria.classList.remove('btn_submenu_pressed');
                    notaEgreso.classList.remove('btn_submenu_pressed');
                    indicaciones.classList.remove('btn_submenu_pressed');
                    // frontalExpediente.classList.remove('btn_submenu_pressed');
                    break;
                
                case 'NPAMain':
                    notaIngreso.classList.remove('btn_submenu_pressed');
                    // notaConsulta.classList.remove('btn_submenu_pressed');
                    hojaIngreso.classList.remove('btn_submenu_pressed');
                    notaEvolucion.classList.remove('btn_submenu_pressed');
                    notaPreoperatoria.classList.remove('btn_submenu_pressed');
                    notaPreanestesica.classList.add('btn_submenu_pressed');
                    notaPostoperatoria.classList.remove('btn_submenu_pressed');
                    notaEgreso.classList.remove('btn_submenu_pressed');
                    indicaciones.classList.remove('btn_submenu_pressed');
                    // frontalExpediente.classList.remove('btn_submenu_pressed');
                    break;

                case 'NotaPostOperatoria':
                    notaIngreso.classList.remove('btn_submenu_pressed');
                    // notaConsulta.classList.remove('btn_submenu_pressed');
                    hojaIngreso.classList.remove('btn_submenu_pressed');
                    notaEvolucion.classList.remove('btn_submenu_pressed');
                    notaPreoperatoria.classList.remove('btn_submenu_pressed');
                    notaPreanestesica.classList.remove('btn_submenu_pressed');
                    notaPostoperatoria.classList.add('btn_submenu_pressed');
                    notaEgreso.classList.remove('btn_submenu_pressed');
                    indicaciones.classList.remove('btn_submenu_pressed');
                    // frontalExpediente.classList.remove('btn_submenu_pressed');
                    break;

                case 'Indicaciones':
                    indicaciones.classList.add('btn_submenu_pressed');
                    // notaConsulta.classList.remove('btn_submenu_pressed');
                    hojaIngreso.classList.remove('btn_submenu_pressed');
                    notaIngreso.classList.remove('btn_submenu_pressed');
                    notaEvolucion.classList.remove('btn_submenu_pressed');
                    notaPreoperatoria.classList.remove('btn_submenu_pressed');
                    notaPreanestesica.classList.remove('btn_submenu_pressed');
                    notaPostoperatoria.classList.remove('btn_submenu_pressed');
                    notaEgreso.classList.remove('btn_submenu_pressed');
                    // frontalExpediente.classList.remove('btn_submenu_pressed');
                    break;

                case 'NotaDeEgreso':
                    notaIngreso.classList.remove('btn_submenu_pressed');
                    // notaConsulta.classList.remove('btn_submenu_pressed');
                    hojaIngreso.classList.remove('btn_submenu_pressed');
                    notaEvolucion.classList.remove('btn_submenu_pressed');
                    notaPreoperatoria.classList.remove('btn_submenu_pressed');
                    notaPreanestesica.classList.remove('btn_submenu_pressed');
                    notaPostoperatoria.classList.remove('btn_submenu_pressed');
                    indicaciones.classList.remove('btn_submenu_pressed')
                    notaEgreso.classList.add('btn_submenu_pressed');
                    // frontalExpediente.classList.remove('btn_submenu_pressed');
                    break;

                default:
                break;
            }
        },

        regresar() {
            router.push({ name:'pacientes', 
                params: {
                    id:1
                } 
            })
        },

        toEgreso(){
            if (this.show) {
                this.switchComponent('notas')
            }
            setTimeout(() => {
                this.switchComponent('NotaDeEgreso')
            }, 50);
        },
        toInfo(){
            this.switchComponent('Informacion')
        }
    },

    mounted() {
        /* Id del perfil */
        var ide = localStorage.getItem("perfil");
        this.idPerfil =  CryptoJS.AES.decrypt(ide, 'secret key heal4701tech').toString(CryptoJS.enc.Utf8);
        this.showView=showNotas.tipo_usuario.find(id => id.id==this.idPerfil)
        console.log(this.idPerfil);

    },
    destroyed() {  
        this.$destroy();
    }

    
}
</script>

<style scoped>
    .btn_size_vw {
        width: 40vw;
    }

</style>