<template>
    <b-container>
        <b-row class="justify-content-between">
            <b-col cols="12" sm="12" md="12" lg="12">
                <h2 class="title_forms txt_primary text-uppercase pl-3">Carta consentimiento</h2>
                <h4 class="txt_primary pl-3">Procedimientos hemodinamicos</h4>
            </b-col>
        </b-row>
        
        <b-container class="mt-3">
            <!-- LISTADO DE EXPEDIENTES -->
            <p class="txt_secondary text-uppercase mt-1"><strong>Lista de expedientes</strong></p>
            <b-table-simple class="table-sm border_table">
                <b-tbody>  
                    <b-tr>
                        <b-th class="th_style size_th">Expediente</b-th>
                        <b-td class="border_table">
                            <b-form-select :disabled="!updating" v-model="state.idExpediente" @change="changeExp(state.idExpediente)" class="border-0" size="sm">
                                <template>
                                    <b-form-select-option v-for="(all) of allExp" :key="all.id" :value="all.id">{{all.no_expediente}}</b-form-select-option>
                                </template>
                            </b-form-select>
                        </b-td>
                    </b-tr>
                </b-tbody>
            </b-table-simple>

            <div class="sticky-top" v-if="(isEgresado && !loading)">
                <b-alert show dismissible variant="warning">
                    El paciente ha egresado desea ver la <b style="padding-top: 4px;">&rArr;</b> <strong class="alert-nota-egreso" @click="verNotaDeEgreso()">nota de egreso</strong>
                </b-alert>
            </div>

            <div v-else-if="(dtaExist && !loading)" class="sticky-top">
                <b-alert show dismissible variant="info">
                    <div class="d-flex">
                        <i class="fa fa-floppy-o fa-lg my-auto pr-2" />
                        Consentimiento de procedimientos hemodinamicos guardado correctamente
                    </div>
                </b-alert>
            </div>

            <b-row class="mt-3" no-gutters>
                <b-col cols="12" sm="12" md="2" lg="2">
                    <span class="size-span">C. Director de:</span>
                </b-col>
                <b-col cols="12" sm="12" md="10" lg="10">
                    <b-form-input v-model="dtaHemodinamica.nom_director" :disabled="(disableInput || state.oldExp || isEgresado)" :state="nom_director" v-on:keypress="soloLetras($event)" type="text" name="director" autocomplete="off" class="text-center border-input" size="sm" placeholder="Nombre del director" />
                </b-col>
            </b-row>

            <fieldset v-if="!dtaExist || actualizar" class="border my-3">
                <legend class="legent-title"><p class="txt_gray"><strong>Llenar datos con la información de:</strong></p></legend>
                <b-row align-h="center" class="pb-2 container-select">
                    <b-col cols="6" sm="6" md="3" lg="3">
                        <div class="d-flex">
                            <span class="pl-3 pr-2 txt_gray">
                                Paciente
                            </span>
                            <b-form-radio :disabled="(disableInput || state.oldExp || isEgresado)" v-model="firma.paciente" value="paciente" @change="sel()" class="rbtn_color" size="md" />
                        </div>
                    </b-col>
                    <b-col cols="6" sm="6" md="3" lg="3">
                        <div class="d-flex">
                            <span class="px-2 txt_gray">
                                Responsable
                            </span>
                            <b-form-radio :disabled="(disableInput || state.oldExp || isEgresado)" v-model="firma.paciente" value="responsable" @change="sel()" class="rbtn_color" size="md" />
                        </div>
                    </b-col>
                    <b-col cols="6" sm="6" md="3" lg="3">
                        <div class="d-flex">
                            <span class="px-2 txt_gray">
                                Otro
                            </span>
                            <b-form-radio :disabled="(disableInput || state.oldExp || isEgresado)" v-model="firma.paciente" value="otro" @change="sel()" class="rbtn_color" size="md" />
                        </div>
                    </b-col>
                </b-row>
            </fieldset>

            <b-row no-gutters class="my-2">
                <b-col cols="12">
                    <span class="size-span">Presente:</span>
                </b-col>
            </b-row>

            <b-row class="mt-0" no-gutters>
                <b-col cols="12" sm="12" md="1" lg="1">
                    <span class="size-span">Yo</span>
                </b-col>
                <b-col cols="12" sm="12" md="11" lg="11">
                    <b-form-input :disabled="( disableInputName || disableInput ) || state.oldExp" v-model="dtaHemodinamica.responsable" :state="responsable" v-on:keypress="soloLetras($event)" @change="verifyName()" @keyup="clearSpace()" list="my-list-id" type="text" name="responsable" autocomplete="off" class="text-center border-input" size="sm" placeholder="Nombre del responsable" />
                </b-col>
            </b-row>

            <!-- Responsive sm-md-lg -->
            <b-row class="mt-2 d-none d-lg-block d-md-block" no-gutters>
                <b-col cols="12" sm="12" md="12" lg="12">
                    <div class="d-flex">
                        <span class="size-span">En mi carácter de (pariente y/o familiar responsable del paciente de nombre)</span>
                        <b-form-input disabled v-model="dtaHemodinamica.paciente" :state="nom_paciente" v-on:keypress="soloLetras($event)" type="text" name="paciente" autocomplete="off" class="text-center input-size ml-1 border-input" size="sm" placeholder="Nombre del paciente" />
                    </div>
                </b-col>
            </b-row>
            <b-row class="mt-2 d-block d-sm-block d-md-none">
                <b-col cols="12" sm="12">
                    <span class="size-span">En mi carácter de (pariente y/o familiar responsable del paciente de nombre)</span>
                </b-col>
                <b-col cols="12" sm="12">
                    <b-form-input disabled v-model="dtaHemodinamica.paciente" :state="nom_paciente" v-on:keypress="soloLetras($event)" type="text" name="paciente" autocomplete="off" class="text-center input-size ml-1 border-input" size="sm" placeholder="Nombre del paciente" />
                </b-col>
            </b-row>
            <!-- end -->

            <b-row class="mt-1">
                <b-col cols="12" sm="12" md="8" lg="8">
                    <b-row no-gutters>
                        <b-col cols="12" sm="12" md="4" lg="4">
                            <span class="size-span mr-2">identificándome con</span>
                        </b-col>
                        <b-col cols="12" sm="12" md="8" lg="8">
                            <div class="d-flex">
                                <b-form-input v-model="dtaHemodinamica.identificacion" :disabled="(disableInput || state.oldExp || isEgresado)" :state="identificacion" type="text" name="identificacion" autocomplete="off" class="text-center border-input" size="sm" placeholder="Identificación" />
                                <span class="size-span ml-1">,</span>
                            </div>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col cols="12" sm="12" md="4" lg="4">
                    <b-row no-gutters>
                        <b-col cols="12" sm="12" md="3" lg="3">
                            <span class="size-span">número</span>
                        </b-col>
                        <b-col cols="12" sm="12" md="9" lg="9">
                            <b-form-input v-model="dtaHemodinamica.no_identificacion" :disabled="(disableInput || state.oldExp || isEgresado)" :state="no_identificacion" v-on:keypress="soloNumeros($event)" maxlength="10" type="text" name="numero" autocomplete="off" class="text-center border-input" size="sm" placeholder="Número" />
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>

            <b-row class="mt-1">
                <b-col cols="12" sm="12" md="8" lg="8">
                    <b-row no-gutters>
                        <b-col cols="12" sm="12" md="4" lg="4">
                            <span class="size-span mr-2">con domicilio en calle</span>
                        </b-col>
                        <b-col cols="12" sm="12" md="8" lg="8">
                            <div class="d-flex">
                                <b-form-input v-model="dtaHemodinamica.domicilio" :disabled="(disableInput || state.oldExp || isEgresado)" :state="domicilio" type="text" name="domicilio" autocomplete="off" class="text-center border-input" size="sm" placeholder="Calle" />
                                <span class="size-span ml-1">,</span>
                            </div>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col cols="12" sm="12" md="4" lg="4">
                    <b-row no-gutters>
                        <b-col cols="12" sm="12" md="3" lg="3">
                            <span class="size-span">número</span>
                        </b-col>
                        <b-col cols="12" sm="12" md="9" lg="9">
                            <b-form-input v-model="dtaHemodinamica.num_domicilio" :disabled="(disableInput || state.oldExp || isEgresado)" :state="num_domicilio" v-on:keypress="soloNumeros($event)" maxlength="8" type="text" name="numero" autocomplete="off" class="text-center border-input" size="sm" placeholder="Número" />
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>
            
            <b-row class="mt-1">
                <b-col cols="12" sm="12" md="6" lg="6">
                    <b-row no-gutters>
                        <b-col cols="12" sm="12" md="2" lg="2">
                            <span class="size-span mr-2">colonia</span>
                        </b-col>
                        <b-col cols="12" sm="12" md="10" lg="10">
                            <b-form-input v-model="dtaHemodinamica.colonia" :disabled="(disableInput || state.oldExp || isEgresado)" :state="colonia" type="text" name="colonia" autocomplete="off" class="text-center border-input" size="sm" placeholder="Colonia" />
                        </b-col>
                    </b-row>
                </b-col>
                <b-col cols="12" sm="12" md="6" lg="6">
                    <b-row no-gutters>
                        <b-col cols="12" sm="12" md="2" lg="2">
                            <span class="size-span mr-2">estado</span>
                        </b-col>
                        <b-col cols="12" sm="12" md="10" lg="10">
                            <div class="d-flex">
                                <b-form-select :disabled="(disableInput || state.oldExp || isEgresado)" :state="estado" v-model="dtaHemodinamica.estado" :options="dtaEstados" name="selectEstado" value-field="value" text-field="text" class="border-input" size="sm" />
                                <span class="size-span ml-1">,</span>
                            </div>
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>

            <b-row class="mt-1">
                <b-col cols="12" sm="12" md="5" lg="5">
                    <b-row no-gutters>
                        <b-col cols="12" sm="12" md="2" lg="2">
                            <span class="size-span mr-2">país</span>
                        </b-col>
                        <b-col cols="12" sm="12" md="10" lg="10">
                            <div class="d-flex">
                                <b-form-input v-model="dtaHemodinamica.pais" :disabled="(disableInput || state.oldExp || isEgresado)" :state="pais" v-on:keypress="soloLetras($event)" type="text" name="colonia" autocomplete="off" class="text-center border-input" size="sm" placeholder="País" />
                                <span class="size-span ml-1">;</span>
                            </div>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col cols="12" sm="12" md="7" lg="7">
                    <b-row no-gutters>
                        <b-col cols="12" sm="12" md="5" lg="5">
                            <span class="size-span mr-2">y con número de teléfono</span>
                        </b-col>
                        <b-col cols="12" sm="12" md="7" lg="7">
                            <div class="d-flex">
                                <b-form-input v-model="dtaHemodinamica.num_telefono" :disabled="(disableInput || state.oldExp || isEgresado)" :state="num_telefono" v-on:keypress="soloNumeros($event)" maxlength="10" type="text" name="num_telefono" autocomplete="off" class="text-center border-input" size="sm" placeholder="Número" />
                                <span class="size-span ml-1">,</span>
                            </div>
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>

            <b-row class="mt-2">
                <b-col cols="12" sm="12" md="12" lg="12">
                    <p class="size-span contenedor">En este acto, expreso que conozco el contenido y alcance legal de éste documento y manifiesto bajo protesta de decir 
                    verdad que, me han informado a satisfacción, respecto del procedimiento que afecta a 
                    <span class="size-span answer" v-if="visible_answer"><strong>(mi persona, mi representado y/o mi familiar)</strong></span>
                    <span class="size-span answer" v-if="show_all_answer"><strong v-if="answer_paciente">(mi persona)</strong><strong v-if="answer_resp">(mi representado y/o mi familiar)</strong></span> , por tal motivo acepto 
                    <span class="size-span answer" v-if="visible_answer"><strong>(se me/se le)</strong></span>
                    <span class="size-span answer" v-if="show_all_answer"><strong v-if="answer_paciente">(se me)</strong><strong v-if="answer_resp">(se le)</strong></span> autorice al personal de ésta unidad para que 
                    <span class="size-span answer" v-if="visible_answer"><strong>(me/le)</strong></span>
                    <span class="size-span answer" v-if="show_all_answer"><strong v-if="answer_paciente">(me)</strong><strong v-if="answer_resp">(le)</strong></span> practiquen con fines de diagnóstico o terapéuticos, los procedimientos médico quirúrgicos, incluso de vacunación necesarios de acuerdo a 
                    <span class="size-span answer" v-if="visible_answer"><strong>(mis/sus)</strong></span>
                    <span class="size-span answer" v-if="show_all_answer"><strong v-if="answer_paciente">(mis)</strong><strong v-if="answer_resp">(sus)</strong></span> condiciones de salud.
                    </p>
                </b-col>
            </b-row>

            <b-row>
                <b-col cols="12" sm="12" md="12" lg="12">
                    <p class="size-span contenedor">Así mismo autorizo que se practiquen los exámenes de laboratorio y de gabinete que sean necesarios para el diagnóstico
                    y/o tratamiento de la enfermedad o padecimiento.
                    </p>
                </b-col>
            </b-row>

            <b-row>
                <b-col cols="12" sm="12" md="12" lg="12">
                    <p class="size-span contenedor">De manera especial manifiesto que he sido particularmente informado a satisfacción respecto de los beneficios esperados y de los riesgos correlativos al procedimiento a efectuarse sobre 
                    <span class="size-span answer" v-if="visible_answer"><strong>(mi/su)</strong></span>
                    <span class="size-span answer" v-if="show_all_answer"><strong v-if="answer_paciente">(mi)</strong><strong v-if="answer_resp">(su)</strong></span> persona, por lo que autorizo al personal se servicios de la salud de ésta Unidad Médica, para que <span class="size-span answer" v-if="visible_answer"><strong>(me/le)</strong></span> <span class="size-span answer" v-if="show_all_answer"><strong v-if="answer_paciente">(me)</strong><strong v-if="answer_resp">(le)</strong></span> realicen 
                    <span class="size-span"><b-form-input v-model="dtaHemodinamica.procedimiento" :disabled="(disableInput || state.oldExp || isEgresado)" :state="procedimiento" type="text" name="procedimiento" autocomplete="off" class="text-center border-input mt-1" size="sm" placeholder="Tipo de procedimiento" /></span> y, el plan anestésico propuesto requerido
                    conociendo el riesgo inherente al mismo, así como la aplicación de los procedimientos que se requieran por alguna situación no
                    sospechada de contingencias y urgencias derivadas del acto autorizado, atendiendo al principio de libertad prescriptiva, en el
                    momento de la valoración médica inicial, dada la imposibilidad de predecir complicaciones que se pudieran presentar e incluso
                    eventos externos a la Unidad Médica como fallas por suministros eléctricos o eventos espontáneos por fuerza de la naturaleza,
                    quedando exentos de toda responsabilidad civil y/o penal por no ser atribuibles a la Unidad Médica, ni al personal propio y/externo
                    tratante. 
                    </p>
                </b-col>
            </b-row>

            <b-row>
                <b-col cols="12" sm="12" md="12" lg="12">
                    <p v-if="cli=='cqc'" class="size-span contenedor">
                        En Aguascalientes, Ags., a<span class="size-span answer"><strong>{{ fecha.dia }}</strong></span> del mes de <span class="size-span answer"><strong>{{ fecha.mes }}</strong></span> del año <span class="size-span answer"><strong>{{ fecha.year }}</strong></span>
                    </p>
                    <p v-else-if="cli=='chlo'" class="size-span contenedor">
                        En Jalpa, Zac., a<span class="size-span answer"><strong>{{ fecha.dia }}</strong></span> del mes de <span class="size-span answer"><strong>{{ fecha.mes }}</strong></span> del año <span class="size-span answer"><strong>{{ fecha.year }}</strong></span>
                    </p>
                    <p v-else-if="cli=='sandbox'" class="size-span contenedor">
                        <span class="size-span answer"><strong>{{ fecha.dia }}</strong></span> del mes de <span class="size-span answer"><strong>{{ fecha.mes }}</strong></span> del año <span class="size-span answer"><strong>{{ fecha.year }}</strong></span>
                    </p>
                </b-col>
            </b-row>

            <b-row align-h="center">
                <b-col cols="12" sm="12" md="5" lg="5">
                    <b-form-input v-model="dtaHemodinamica.responsable" disabled readonly type="text" name="responsable-tutor" autocomplete="off" class="text-center border-name-paciente-tutor input-disabled" size="sm" />
                </b-col>
            </b-row>
             <b-row align-h="center">
                <b-col class="text-center" cols="12" sm="12" md="4" lg="4">
                    <span class="size-span">Nombre del paciente o tutor</span>
                </b-col>
            </b-row>


            <b-container>
                <b-row class="my-3">
                    <b-col class="pr-1" cols="12" sm="12" md="6" lg="6">
                        <b-table-simple class="table-sm">
                            <b-thead>
                                <b-tr>
                                    <b-th colspan="2" class="th_style">
                                        <b-row no-gutters>
                                            <b-col cols="12" sm="12" md="12" lg="12">
                                                <span class="size-span">Por el hospital</span>
                                            </b-col>
                                            <!-- <b-col cols="12" sm="12" md="9" lg="9">
                                                <b-form-input v-model="dtaHemodinamica.nombre_hospital" :state="nombre_hospital" type="text" name="hospital" autocomplete="off" class="text-center border-input-none" size="sm" placeholder="Hospital" />
                                            </b-col> -->
                                        </b-row>
                                    </b-th>
                                </b-tr>
                            </b-thead>
                            <b-tbody>
                                <b-tr>
                                    <b-th class="th_style"><span class="size-span">Nombre</span></b-th>
                                    <b-td class="border_table">
                                        <b-form-textarea v-model="dtaHemodinamica.nombre_h" :disabled="(disableInput || state.oldExp || isEgresado)" :state="nombre_h" type="text" name="nombre_h" autocomplete="off" class="text-center border-input-none" no-resize debounce="500" rows="2" max-rows="2" size="sm" placeholder="Nombre" />
                                    </b-td>
                                </b-tr>
                                <b-tr>
                                    <b-th class="th_style"><span class="size-span">Domicilio</span></b-th>
                                    <b-td class="border_table">
                                        <b-form-textarea v-model="dtaHemodinamica.domicilio_h" :disabled="(disableInput || state.oldExp || isEgresado)" :state="domicilio_h" type="text" name="domicilio_h" autocomplete="off" class="text-center border-input-none" no-resize debounce="500" rows="2" max-rows="2" size="sm" placeholder="Domicilio" />
                                    </b-td>
                                </b-tr>

                                <b-tr>
                                    <b-th class="th_style"><span class="size-span">Identificación</span></b-th>
                                    <b-td class="border_table">
                                        <b-form-input v-model="dtaHemodinamica.identificacion_h" :disabled="(disableInput || state.oldExp || isEgresado)" :state="identificacion_h" type="text" name="identificacion_h" autocomplete="off" class="text-center border-input-none" size="sm" placeholder="Identificación" />
                                    </b-td>
                                </b-tr>

                                <b-tr>
                                    <b-th class="th_style"><span class="size-span">Nota adicional</span></b-th>
                                    <b-td class="border_table">
                                        <b-form-textarea v-model="dtaHemodinamica.nota_adicional_h" :disabled="(disableInput || state.oldExp || isEgresado)" type="text" name="nota_h" autocomplete="off" class="text-center border-input-none" size="sm" no-resize debounce="500" rows="3" max-rows="3" placeholder="Nota adicional" />
                                    </b-td>
                                </b-tr>
                            </b-tbody>
                        </b-table-simple>
                    </b-col>
                    <b-col class="pl-1" cols="12" sm="12" md="6" lg="6">
                        <b-table-simple class="table-sm">
                            <b-thead>
                                <b-tr>
                                    <b-th colspan="2" class="th_style">
                                        <b-row no-gutters>
                                            <b-col cols="12" sm="12" md="12" lg="12">
                                                <span class="size-span">Por el paciente</span>
                                            </b-col>
                                            <!-- <b-col cols="12" sm="12" md="9" lg="9">
                                                <b-form-input v-model="dtaHemodinamica.nombre_paciente" :state="nombre_paciente" type="text" name="n_paciente" autocomplete="off" class="text-center border-input-none ml-1" size="sm" placeholder="Paciente" />
                                            </b-col> -->
                                        </b-row>
                                    </b-th>
                                </b-tr>
                            </b-thead>
                            <b-tbody>
                                <b-tr>
                                    <b-th class="th_style"><span class="size-span">Nombre</span></b-th>
                                    <b-td class="border_table">
                                        <b-form-textarea v-model="dtaHemodinamica.nombre_p" :disabled="(disableInput || state.oldExp || isEgresado)" :state="nombre_p" type="text" name="name_paciente" autocomplete="off" class="text-center border-input-none" no-resize debounce="500" rows="2" max-rows="2" size="sm" placeholder="Nombre" />
                                    </b-td>
                                </b-tr>
                                <b-tr>
                                    <b-th class="th_style"><span class="size-span">Domicilio</span></b-th>
                                    <b-td class="border_table">
                                        <b-form-textarea v-model="dtaHemodinamica.domicilio_p" :disabled="(disableInput || state.oldExp || isEgresado)" :state="domicilio_p" type="text" name="domicilio_p" autocomplete="off" class="text-center border-input-none" no-resize debounce="500" rows="2" max-rows="2" size="sm" placeholder="Domicilio" />
                                    </b-td>
                                </b-tr>

                                <b-tr>
                                    <b-th class="th_style"><span class="size-span">Identificación</span></b-th>
                                    <b-td class="border_table">
                                        <b-form-input v-model="dtaHemodinamica.identificacion" :disabled="(disableInput || state.oldExp || isEgresado)" :state="identificacion" type="text" name="identificacion" autocomplete="off" class="text-center border-input-none" size="sm" placeholder="Identificación" />
                                    </b-td>
                                </b-tr>

                                <b-tr>
                                    <b-th class="th_style"><span class="size-span">Nota adicional</span></b-th>
                                    <b-td class="border_table">
                                        <b-form-textarea v-model="dtaHemodinamica.nota_adicional_p" :disabled="(disableInput || state.oldExp || isEgresado)" type="text" name="nota_p" autocomplete="off" class="text-center border-input-none" no-resize debounce="500" rows="3" max-rows="3" size="sm" placeholder="Nota adicional" />
                                    </b-td>
                                </b-tr>
                            </b-tbody>
                        </b-table-simple>
                    </b-col>
                </b-row>
            </b-container>

            <b-row>
                <b-col cols="12" sm="12" md="12" lg="12">
                    <p class="size-span contenedor">Nota: Para cuando el paciente se encuentre en estado de incapacidad transitoria o permanente para otorgar la autorización y, en
                        ausencia de su familiar, tutor o representante legal, los médicos autorizados del Hospital o maternidad, previa valoración del caso y
                        con el acuerdo de por lo menos dos de ellos, llevarán a cabo el procedimiento terapéutico que el caso requiera, dejando constancia
                        por escrito con el expediente clínico.
                    </p>
                </b-col>
            </b-row>

            <!-- <b-row class="justify-content-lg-center mb-3">
                <b-col cols="12" sm="12" md="3" lg="3">
                    <span class="size-span">Nombre de los médicos</span>
                </b-col>
            </b-row>
            <b-row class="justify-content-md-center text-center mb-4">
                <b-col cols="12" sm="12" md="6" lg="6">
                    <b-row no-gutters>
                        <b-col cols="12" sm="12" md="2" lg="2">
                            <span class="size-span">Dr.</span>
                        </b-col>
                        <b-col cols="12" sm="12" md="10" lg="10">
                            <b-form-input v-model="dtaHemodinamica.medicoUno" :disabled="(disableInput || state.oldExp || isEgresado)" :state="medico_uno" type="text" name="name_paciente" autocomplete="off" class="text-center border-input" size="sm" placeholder="Nombre" />
                        </b-col>
                    </b-row>
                </b-col>
                <b-col cols="12" sm="12" md="6" lg="6">
                    <b-row no-gutters>
                        <b-col cols="12" sm="12" md="2" lg="2">
                            <span class="size-span">Dr.</span>
                        </b-col>
                        <b-col cols="12" sm="12" md="10" lg="10">
                            <b-form-input v-model="dtaHemodinamica.medicoDos" :disabled="(disableInput || state.oldExp || isEgresado)" :state="medico_dos" type="text" name="name_paciente" autocomplete="off" class="text-center border-input" size="sm" placeholder="Nombre" />
                        </b-col>
                    </b-row>
                </b-col>
            </b-row> -->
            
            <div class="sticky-container" v-if="(idPacienteSeleccionado!='' && !openError && !openSuccess && !loading)">
                <ul class="sticky">
                    <!-- <li v-if="!dtaExist" @click="preGuardar()">
                        <img :src="require('../../../assets/icons/guardar.png')" width="50" height="32">
                        <p>
                            <a>Preguardar</a>
                        </p>
                    </li> -->
                    <li v-if="(dtaExist && updating && !state.oldExp && !isEgresado)" @click="editar()">
                        <img :src="require('../../../assets/icons/editar.png')" width="57" height="32">
                        <p>
                            <a>Editar</a>
                        </p>
                    </li>
                    <li v-if="dtaExist && updating" @click="imprimir()">
                        <img :src="require('../../../assets/icons/imprimir.png')" width="50" height="32">
                        <p>
                            <a>Imprimir</a>
                        </p>
                    </li>
                </ul>
            </div>

            <hr>
            <b-row v-if="error && !state.oldExp" class="my-3">
                <b-col cols="12">
                    <div class="contenedor-de-errores pt-3 pb-1">
                        <ul class="lista-errores px-2">
                            <li v-for="(err,index) of errores" :key="index"><i class="fa fa-warning px-1" v-if="!err.expediente_id" />| {{err[0]}} <br>
                            </li>
                        </ul>
                    </div>
                </b-col>
            </b-row>

            <div v-if="(btnGuardar && !dtaExist && !state.oldExp && !isEgresado)" class="d-flex justify-content-end my-4">
                <b-col cols="12" sm="12" md="5" lg="5">
                    <b-overlay
                    :show="busy"
                    rounded
                    opacity="0.6"
                    spinner-small
                    spinner-variant="primary"
                    >
                        <button class="btn_formluario border-0 btn-block py-2 txt_white" :disabled="busy" @click="createHemodinamica()">GUARDAR</button>
                    </b-overlay>
                </b-col>
            </div>
            
            <div v-if="(dtaExist && actualizar && !state.oldExp && !isEgresado)" class="d-flex justify-content-end mt-4 mb-3">
                <b-col cols="12" sm="12" md="5" lg="5">
                    <b-overlay
                    :show="busyUpdate"
                    rounded
                    opacity="0.6"
                    spinner-small
                    spinner-variant="primary"
                    >
                        <button class="btn_formluario border-0 btn-block py-2 txt_white" @click="updateData()" :disabled="busyUpdate">ACTUALIZAR</button>
                    </b-overlay>
                </b-col>
            </div>

            <div v-if="dtaExist && cancelar && !state.oldExp" class="d-flex justify-content-end mb-4">
                <b-col cols="12" sm="12" md="5" lg="5">
                    <button class="btn_formluario border-0 btn-block py-2 txt_white" @click="cancelEdit()">CANCELAR</button>
                </b-col>
            </div>
        </b-container>

        <Error :abrir="openError" @cerrarModalError="cerrarModalError()"/> 
        <Success :abrir="openSuccess" @cerrarModal="cerrarModal()"/>
        <Loading :show="loading"/>

    </b-container>
</template>

<script>
import PDFMerger from 'pdf-merger-js';
import CryptoJS from 'crypto-js';
import { clinica, url } from '../../../../settings'
import { getCatalogo } from '../../../helpers/catalogos'
import Success from '@/components/Modals/Success.vue'
import Error from '@/components/Modals/Error.vue'
import Loading from '@/components/Loader/Loading.vue'
import router from "../../../router";
import Vue from 'vue'

export default {
    name: 'Hemodinamia',
    components: { Success, Error, Loading },

    data: () => ({
        cli: clinica,
        isEgresado:false,
        
        state:{
            idExpediente:"",
            idPaciente: "",
            oldExp:false,
        },
        allExp:[],

        error: false,
        errores: [],
        busy: false,

        openError: false,
        openSuccess: false,
        loading: false,
        dtaExist: false,
        btnGuardar: false,

        updating: true,
        disableInput: false,
        actualizar: false,
        cancelar: false,
        busyUpdate: false,

        idPacienteSeleccionado: "",
        datosExpediente:{paciente:{nombre:""}},
        dtaEstados: [],
        datosPaciente: {
            password:"",
            edad:"",
            id: null,
            nombre: "",
            fecha_nacimiento: "",
            estado_civil: {
                id: null,
                nombre: ""
            },
            cp: "",
            domicilio: "",
            colonia: "",
            ciudad: "",
            estado: {
                id:null,
                nombre: "",
            },
            municipio:{
                id:null,
                nombre: "",
            },
            telefono: "",
            email: null,
            genero:{
                id:null,
                nombre: "",
            },
            lastExpediente: {
                expediente_id: null,
                no_habitacion: null
            },
        },

        datosResponsable:{},

        dtaHemodinamica: {
            id: '',
            paciente_id: '',
            nom_director: '',
            no_expediente: '',
            responsable: '',
            paciente: '',
            identificacion: '',
            no_identificacion: '',
            domicilio: '',
            num_domicilio: '',
            colonia: '',
            estado: null,
            pais: 'México',
            num_telefono: '',
            procedimiento: '',

            /* Datos tabla hospital */
            nombre_h: '',
            domicilio_h: '',
            identificacion_h: '',
            nota_adicional_h: '',
        
            /* Datos tabla paciente */
            nombre_p: '',
            domicilio_p: '',
            identificacion_p: '',
            nota_adicional_p: '',

            medicoUno: '',
            medicoDos: ''
        },

        dataHemodinamica: {
            expediente_id: '',
            director: '',
            nombre_completo: '',
            nombre_paciente: '',
            identificacion: '',
            no_identificacion: '',
            domicilio: '',
            no_domicilio: '',
            colonia: '',
            estado_id: '',
            pais: '',
            telefono: '',
            w1: '',
            w2: '',
            w3: '',
            w4: '',
            w5: '',
            w6: '',
            w7: '',
            hospital_responsable: '',
            hospital_responsable_domicilio: '',
            hospital_identificacion: '',
            hospital_nota_adicional: '',
            paciente_responsable: '',
            paciente_responsable_domicilio: '',
            paciente_identificacion: '',
            paciente_nota_adicional: ''
        },
        
        idHemodinamica: '',

        firma:{
            paciente:false,
            responsable:false,
            otro: false
        },

        fecha: {
            dia: '',
            mes: '',
            year: ''
        },
        visible_answer: true,
        show_all_answer: false,
        answer_paciente: false,
        answer_resp: false,
        other: false,
        disableInputName: false
    }),

    computed: {
        nom_director() {
            return this.dtaHemodinamica.nom_director != ''
        },
        nom_paciente() {
            return this.dtaHemodinamica.paciente != ''
        },
        responsable() {
            return this.dtaHemodinamica.responsable != '' && this.dtaHemodinamica.responsable.length >= 10
        },
        identificacion() {
            return this.dtaHemodinamica.identificacion != '' && this.dtaHemodinamica.identificacion.length >= 5
        },
        no_identificacion() {
            return this.dtaHemodinamica.no_identificacion != '' && this.dtaHemodinamica.no_identificacion.length >= 5
        },
        domicilio() {
            return this.dtaHemodinamica.domicilio != '' && this.dtaHemodinamica.domicilio.length >= 5
        },
        num_domicilio() {
            return this.dtaHemodinamica.num_domicilio != ''
        },
        colonia() {
            return this.dtaHemodinamica.colonia != ''
        },
        estado() {
            return this.dtaHemodinamica.estado != null
        },
        pais() {
            return this.dtaHemodinamica.pais != ''
        },
        num_telefono() {
            let flag = false;
            if (this.dtaHemodinamica.num_telefono) {
                if ((this.dtaHemodinamica.num_telefono.length>0 && this.dtaHemodinamica.num_telefono.length<10)) {
                    flag = false;
                }else if (this.dtaHemodinamica.num_telefono.length == 10) {
                    flag=true;
                }else{
                    flag=null
                }
            }
            return flag;
        },
        procedimiento(){
            return this.dtaHemodinamica.procedimiento != ''
        },

        /* Tabla datos hospital */
        nombre_hospital() {
            return this.dtaHemodinamica.nombre_hospital != '' && this.dtaHemodinamica.nombre_hospital.length>9
        },
        nombre_h() {
            return this.dtaHemodinamica.nombre_h != '' && this.dtaHemodinamica.nombre_h.length>=10
        },
        domicilio_h() {
            return this.dtaHemodinamica.domicilio_h != '' && this.dtaHemodinamica.domicilio_h.length >= 10
        },
        identificacion_h() {
            return this.dtaHemodinamica.identificacion_h != '' && this.dtaHemodinamica.identificacion_h.length >= 5
        },

        /* Tabla datos paciente */
        nombre_paciente() {
            return this.dtaHemodinamica.nombre_paciente != ''
        },
        nombre_p() {
            return this.dtaHemodinamica.nombre_p != '' && this.dtaHemodinamica.nombre_p.length>=10
        },
        domicilio_p() {
            return this.dtaHemodinamica.domicilio_p != '' && this.dtaHemodinamica.domicilio_p.length >= 10
        },
        identificacion_p() {
            return this.dtaHemodinamica.identificacion_p != '' && this.dtaHemodinamica.identificacion_p.length >= 5
        },

        medico_uno() {
            return this.dtaHemodinamica.medicoUno != ''
        },
        medico_dos() {
            return this.dtaHemodinamica.medicoDos != ''
        }
    },

    methods: {
        verNotaDeEgreso() {
          this.$emit('toEgreso')  
        },
        async imprimir() {
            // var can = false;
            var este = this;
            this.loading = true
            console.log(this.dtaHemodinamica);
            let urlim = `${url}/api/consentimientos/hemodinamia/pdf/${this.state.idExpediente}`;
            return fetch(urlim, {
                method: 'get',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Requested-With': 'XMLHttpRequest',
                    'Authorization': "Bearer "+ localStorage.getItem("token"),
                }
            }).then(function(resp){
                // can = resp.ok
                console.log(resp);
                return resp.blob("paciente.pdf")
            }).then(function(blob) {
                // if (can) {
                //     este.loading = false
                //     blob.name="paciente.pdf"
                //     var w = 900
                //     var h = 600
                //     const dualScreenLeft = window.screenLeft !==  undefined ? window.screenLeft : window.screenX;
                //     const dualScreenTop = window.screenTop !==  undefined   ? window.screenTop  : window.screenY;

                //     const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
                //     const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;

                //     const systemZoom = width / window.screen.availWidth;
                //     const left = (width - w) / 2 / systemZoom + dualScreenLeft
                //     const top = (height - h) / 2 / systemZoom + dualScreenTop

                //     var url = URL.createObjectURL(blob);
                //     var newWindow = window.open(url, "_blank",
                //         `
                //         scrollbars=yes,
                //         width=${w / systemZoom}, 
                //         height=${h / systemZoom}, 
                //         top=${top}, 
                //         left=${left}
                //         `
                //     )
                //     setTimeout(function(){ 
                //         newWindow.document.title = 'Número de expediente';
                //     }, 100);
                // } else {
                //     este.loading = false
                // }
                este.downloadPDF(blob)

                })
            },
            async downloadPDF(blob){
                try {
                    var merger = new PDFMerger()
                    await merger.add(URL.createObjectURL(blob))
                    await merger.save(this.datosPaciente.noExpediente+" Procedimientos hemodinamicos");
                    this.loading = false
                } catch (error) {
                    this.loading = false
                }
            },

        changeExp(id){
            // this.clearData();

            let exps = this.allExp
            Object.assign(this.$data, this.$options.data());
            this.loading=true
            let currentExp = exps.find(exp => exp.id == id)
            console.log(currentExp);

            if (currentExp) {
                this.sinExp=false
                var expediente = CryptoJS.AES.encrypt(JSON.stringify(currentExp), 'secret key heal4701tech')
                localStorage.setItem('expedienteEncrypt', expediente);
                this.setData(true)
            }
        },

        async allExpedientes() {
            try {
                const res = await fetch(`${url}/api/datos_medicos/`+this.idPacienteSeleccionado, {
                    method: 'get',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Requested-With': 'XMLHttpRequest',
                        'Authorization': "Bearer "+ localStorage.getItem("token"),
                    }
                });

                const data = await res.json();
                this.allExp=data.expedientes
                console.log(this.allExp);

            } catch (error) {
                console.log("el error es"+error);
            }
        },

        clearData(){

        },

        editar() {
            this.actualizar = true
            this.cancelar = true
            this.updating = false
            this.disableInput = false
            this.btnGuardar = false
        },
        
        cancelEdit() {
            this.getHemodinamica()
            this.disableInput = true
            this.actualizar = false
            this.updating = true
            this.cancelar = false
            this.error = false
            this.errores = []
        },

        async updateData() {
            this.asignacion()
            this.error=false
            this.errores=[]
            this.busyUpdate = true
            try {
                const res = await fetch(`${url}/api/consentimientos/hemodinamia/${this.idHemodinamica}`, {
                    method: 'put',
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        'Content-Type': 'application/json',
                        'X-Requested-With': 'XMLHttpRequest',
                        'Authorization': "Bearer "+ localStorage.getItem("token"),
                    },
                    body: JSON.stringify(this.dataHemodinamica)
                });
                const data = await res.json()
                Vue.prototype.$error500 = res.status===500
                var warn = false
                var err=[]
                var promise = Promise.resolve(data)
                await promise.then(function(val) {
                    if (val.class=="success") {
                        warn=true
                    }else{
                        err=val.errors   
                    }
                });
                if (warn) {
                    this.getHemodinamica()
                    this.openSuccess=true
                    this.updating = true
                    this.disableInput = true
                    this.busyUpdate=false
                    this.actualizar = false
                    this.cancelar = false
                }else{
                    this.openError=true
                    this.errores=err
                    this.error=true
                    this.busyUpdate = false
                }
            } catch (error) {
                this.busyUpdate = false
                console.log(error)
            }
        },

        asignacion() {
            this.dataHemodinamica.expediente_id = this.dtaHemodinamica.id
            this.dataHemodinamica.director = this.dtaHemodinamica.nom_director
            this.dataHemodinamica.nombre_completo = this.dtaHemodinamica.responsable
            this.dataHemodinamica.nombre_paciente = this.dtaHemodinamica.paciente
            this.dataHemodinamica.identificacion = this.dtaHemodinamica.identificacion
            this.dataHemodinamica.no_identificacion = this.dtaHemodinamica.no_identificacion
            this.dataHemodinamica.domicilio = this.dtaHemodinamica.domicilio
            this.dataHemodinamica.no_domicilio = this.dtaHemodinamica.num_domicilio
            this.dataHemodinamica.colonia = this.dtaHemodinamica.colonia
            this.dataHemodinamica.estado_id = this.dtaHemodinamica.estado
            this.dataHemodinamica.pais = this.dtaHemodinamica.pais
            this.dataHemodinamica.telefono = this.dtaHemodinamica.num_telefono

            const inputResp = this.dtaHemodinamica.responsable.trim()
            this.dtaHemodinamica.responsable = inputResp

            if (this.firma.paciente == 'paciente' || this.dtaHemodinamica.responsable.toLowerCase() === this.dtaHemodinamica.paciente.toLowerCase()) {
                this.dataHemodinamica.w1 = 'mi persona'
                this.dataHemodinamica.w2 = 'se me'
                this.dataHemodinamica.w3 = 'me'
                this.dataHemodinamica.w4 = 'mis'
                this.dataHemodinamica.w5 = 'mi'
                this.dataHemodinamica.w6 = 'me'
                this.dataHemodinamica.w7 = this.dtaHemodinamica.procedimiento
            } else if (this.firma.paciente == 'responsable' || this.firma.paciente == 'otro' || this.dtaHemodinamica.responsable.toLowerCase() != this.dtaHemodinamica.paciente.toLowerCase()) {
                this.dataHemodinamica.w1 = 'mi representado y/o mi familiar'
                this.dataHemodinamica.w2 = 'se le'
                this.dataHemodinamica.w3 = 'le'
                this.dataHemodinamica.w4 = 'sus'
                this.dataHemodinamica.w5 = 'su'
                this.dataHemodinamica.w6 = 'le'
                this.dataHemodinamica.w7 = this.dtaHemodinamica.procedimiento
            }
            /* Datos tabla hospital */
            this.dataHemodinamica.hospital_identificacion = this.dtaHemodinamica.identificacion_h
            this.dataHemodinamica.hospital_responsable = this.dtaHemodinamica.nombre_h
            this.dataHemodinamica.hospital_responsable_domicilio = this.dtaHemodinamica.domicilio_h
            if (this.dtaHemodinamica.nota_adicional_h == '') {
                this.dtaHemodinamica.nota_adicional_h = 'Sin nota adicional'
            }
            this.dataHemodinamica.hospital_nota_adicional = this.dtaHemodinamica.nota_adicional_h
            /* Datos tabla paciente */
            this.dataHemodinamica.paciente_responsable = this.dtaHemodinamica.nombre_p
            this.dataHemodinamica.paciente_responsable_domicilio = this.dtaHemodinamica.domicilio_p
            this.dataHemodinamica.paciente_identificacion = this.dtaHemodinamica.identificacion
            if (this.dtaHemodinamica.nota_adicional_p == '') {
                this.dtaHemodinamica.nota_adicional_p = 'Sin nota adicional'
            }
            this.dataHemodinamica.paciente_nota_adicional = this.dtaHemodinamica.nota_adicional_p
        },

        showData(data) {
            this.dtaHemodinamica.nom_director = data.director 
            this.dtaHemodinamica.no_expediente = data.no_expediente
            this.dtaHemodinamica.responsable = data.nombre_completo
            this.dtaHemodinamica.paciente = data.nombre_paciente
            this.dtaHemodinamica.identificacion = data.identificacion
            this.dtaHemodinamica.no_identificacion = data.no_identificacion
            this.dtaHemodinamica.domicilio = data.domicilio
            this.dtaHemodinamica.num_domicilio = data.no_domicilio
            this.dtaHemodinamica.colonia = data.colonia
            this.dtaHemodinamica.estado = data.estado_id.id
            this.dtaHemodinamica.pais = data.pais
            this.dtaHemodinamica.num_telefono = data.telefono

            if (data.w1 == 'mi persona') {
                this.answer_resp = false
                this.visible_answer = false
                this.show_all_answer = true
                this.answer_paciente = true
            } else {
                this.answer_paciente = false
                this.visible_answer = false
                this.show_all_answer = true
                this.answer_resp = true
            }
            this.dtaHemodinamica.procedimiento = data.w7
            /* Datos tabla hospital */
            this.dtaHemodinamica.nombre_h = data.hospital_responsable
            this.dtaHemodinamica.domicilio_h = data.hospital_responsable_domicilio
            this.dtaHemodinamica.identificacion_h = data.hospital_identificacion
            if (data.hospital_nota_adicional == '' || data.hospital_nota_adicional == null) {
                this.dtaHemodinamica.hospital_nota_adicional = 'Sin nota adicional'
            }
            this.dtaHemodinamica.nota_adicional_h = data.hospital_nota_adicional
            /* Datos tabla paciente */
            this.dtaHemodinamica.nombre_p = data.paciente_responsable
            this.dtaHemodinamica.domicilio_p = data.paciente_responsable_domicilio
            this.dtaHemodinamica.identificacion_p = data.paciente_identificacion
            if (data.paciente_nota_adicional == '' || data.paciente_nota_adicional == null) {
                data.paciente_nota_adicional = 'Sin nota adicional'
            }
            this.dtaHemodinamica.nota_adicional_p = data.paciente_nota_adicional
        },

        async createHemodinamica() {
            this.asignacion()
            this.error=false
            this.errores=[]
            this.busy = true
            try {
                const res = await fetch(`${url}/api/consentimientos/hemodinamia`, {
                    method: 'post',
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        "Content-Type": "application/json",
                        "X-Requested-With": "XMLHttpRequest",
                        "Authorization": "Bearer "+ localStorage.getItem("token"),
                    },
                    body: JSON.stringify(this.dataHemodinamica),
                });
                const data = res.json()
                Vue.prototype.$error500 = res.status===500
                var warn = false
                var err =[]
                var promise = Promise.resolve(data)
                await promise.then(function(val) {
                    console.log(val)
                    if (val.class=="success") {
                        warn = true
                    }else{
                        err=val.errors
                    }
                });
                if (warn) {
                    this.busy=false
                    this.openSuccess=true
                    this.getHemodinamica()
                }else{
                    this.error=true
                    this.errores=err
                    if (this.errores.w7) {
                        this.errores.w7[0] = 'El campo tipo de procedimiento es obligatorio'
                    }
                    this.openError=true
                    this.openError=true
                    this.busy=false
                }
            } catch (error) {
                console.log(error)
            }
        },

        async getHemodinamica() {
            console.log({id: this.dtaHemodinamica.id});
            try {
                const res = await fetch(`${url}/api/consentimientos/hemodinamia/${this.dtaHemodinamica.id}`, {
                    method: 'get',
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        'Content-Type': 'application/json',
                        'X-Requested-With': 'XMLHttpRequest',
                        'Authorization': "Bearer "+ localStorage.getItem("token"),
                    }
                });
                const data = await res.json()
                console.log({DATA: data});
                if (data.consentimientos.length == 0) {
                    this.disableInput = false
                    this.btnGuardar = true
                } else {
                    this.showData(data.consentimientos[0])
                    this.idHemodinamica = data.consentimientos[0].id
                    this.dtaExist = true
                    this.disableInput = true
                    this.btnGuardar = false
                } 
                window.scrollTo({ top: 0, behavior: 'smooth' })
                this.loading=false
            } catch (error) {
                window.scrollTo({ top: 0, behavior: 'smooth' })
                this.loading=false
                console.log(error);
            }
        },

        sel(){
            if (this.firma.paciente==false) {
                this.dtaHemodinamica.responsable = ''
                this.dtaHemodinamica.colonia = ''
                this.dtaHemodinamica.estado = ''
                this.dtaHemodinamica.num_telefono = ''
                this.dtaHemodinamica.nombre_p = ''
                this.dtaHemodinamica.domicilio_p = ''
                this.visible_answer = true
                this.show_all_answer = false
                this.answer_paciente = false
                this.answer_resp = false
            }else if(this.firma.paciente=='paciente'){
                this.disableInputName = true
                this.dtaHemodinamica.responsable=this.datosPaciente.nombre
                this.dtaHemodinamica.colonia=this.datosPaciente.colonia
                this.dtaHemodinamica.estado= this.datosPaciente.estado.id
                this.dtaHemodinamica.num_telefono=this.datosPaciente.telefono
                this.dtaHemodinamica.nombre_p=this.datosPaciente.nombre
                this.dtaHemodinamica.domicilio_p=this.datosPaciente.domicilio+", "+ this.datosPaciente.colonia+", "+this.datosPaciente.municipio.nombre+", "+this.datosPaciente.estado.nombre
                this.calleNumero(this.datosPaciente.domicilio)
                this.visible_answer = false
                this.answer_resp = false
                this.show_all_answer = true
                this.answer_paciente = true
                this.other = true
            }else if(this.firma.paciente=='responsable'){
                this.disableInputName = true
                this.dtaHemodinamica.responsable=this.datosResponsable.nombre
                this.dtaHemodinamica.colonia=this.datosResponsable.colonia
                this.dtaHemodinamica.estado= this.datosResponsable.estado.id
                this.dtaHemodinamica.num_telefono=this.datosResponsable.telefono
                this.dtaHemodinamica.nombre_p=this.datosPaciente.nombre
                this.dtaHemodinamica.domicilio_p=this.datosPaciente.domicilio+", "+ this.datosPaciente.colonia+", "+this.datosPaciente.municipio.nombre+", "+this.datosPaciente.estado.nombre
                this.calleNumero(this.datosResponsable.domicilio)
                this.answer_paciente = false
                this.visible_answer = false
                this.show_all_answer = true
                this.answer_resp = true
                this.other = true
            } else if(this.firma.paciente == 'otro') {
                this.disableInputName = false
                this.dtaHemodinamica.responsable = ''
                this.dtaHemodinamica.colonia = ''
                this.dtaHemodinamica.domicilio = ''
                this.dtaHemodinamica.num_domicilio = ''
                this.dtaHemodinamica.estado = null
                this.dtaHemodinamica.num_telefono = ''
                this.dtaHemodinamica.identificacion = ''
                this.dtaHemodinamica.no_identificacion = ''
                this.dtaHemodinamica.nota_adicional_p = ''
                this.dtaHemodinamica.nombre_p=this.datosPaciente.nombre
                this.dtaHemodinamica.domicilio_p=this.datosPaciente.domicilio+", "+ this.datosPaciente.colonia+", "+this.datosPaciente.municipio.nombre+", "+this.datosPaciente.estado.nombre
                this.visible_answer = true
                this.show_all_answer = false
                this.answer_paciente = false
                this.answer_resp = false
            }
        },
        verifyName() {
            const input = this.dtaHemodinamica.responsable.trim()
            this.dtaHemodinamica.responsable = input

            this.visible_answer = true
            this.show_all_answer = false
            this.answer_paciente = false
            this.answer_resp = false
            
            if(this.dtaHemodinamica.responsable.toLowerCase() === this.dtaHemodinamica.paciente.toLowerCase()) {
                this.visible_answer = false
                this.answer_resp = false
                this.show_all_answer = true
                this.answer_paciente = true
            } else if (this.dtaHemodinamica.responsable == '') {
                this.visible_answer = true
                this.show_all_answer = false
                this.answer_paciente = false
                this.answer_resp = false
            } else {
                this.answer_paciente = false
                this.visible_answer = false
                this.show_all_answer = true
                this.answer_resp = true
            }
        },

        clearSpace() {
            let letras = this.dtaHemodinamica.responsable.replace(/\s+/g, ' ')
            this.dtaHemodinamica.responsable = letras
        },

        setData(){
            const pacienteEncrypt=localStorage.expedienteEncrypt
            const expediente =  CryptoJS.AES.decrypt(pacienteEncrypt, 'secret key heal4701tech').toString(CryptoJS.enc.Utf8);
            const paciente=JSON.parse(expediente)

            this.state.idExpediente=paciente.id
            this.state.idPaciente=paciente.paciente.id

            if (paciente.paciente.lastExpediente.expediente_id!=paciente.id) {
                console.log("esold");
                this.state.oldExp=true
            }else{
                this.state.oldExp=false
            }


            this.datosExpediente=paciente
            console.log(paciente);
            this.datosResponsable=paciente.responsable_paciente
            if (paciente.paciente.telefono==null) {
                paciente.paciente.telefono = ''
            }
            this.idPacienteSeleccionado=paciente.paciente.id
            this.dtaHemodinamica.id = paciente.id
            this.dtaHemodinamica.paciente_id = paciente.paciente.id
            this.dtaHemodinamica.no_expediente = paciente.paciente.no_expediente
            this.dtaHemodinamica.paciente=paciente.paciente.nombre
            this.datosPaciente=paciente.paciente
            this.datosPaciente.estado_civil_id=""
            this.datosPaciente.responsable = paciente.responsable_paciente.nombre
            this.datosPaciente.noExpediente=paciente.paciente.no_expediente
            this.datosExpediente=paciente
            this.datosPaciente.medico=paciente.medico.nombre
            this.datosPaciente.diagnostico=paciente.diagnostico
            this.btnGuardar = false;
            this.btnFirmar = true;

            var year = new Date(this.datosPaciente.fecha_nacimiento);
            var today = new Date();
            var yyyy = today.getFullYear();
            this.datosPaciente.edad= yyyy-year.getFullYear();
            this.allExpedientes()

            this.getFecha()
            this.getCatalogos()
            this.getHemodinamica()
        },

        getFecha(){
            var today = new Date();
            var dd = ("0" + (today.getDate())).slice(-2);
            var mm = new Intl.DateTimeFormat('es-ES', {month: 'long'}).format(new Date())
            var yyyy = today.getFullYear();
           
            this.fecha.dia = dd;
            this.fecha.mes = mm;
            this.fecha.year = yyyy;
        },

        soloNumeros(evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault();
            } else {
                return true;
            }
        },

        soloLetras(evt) {
            let char = String.fromCharCode(evt.keyCode);
            if(/^[A-Za-z-zA-ZÀ-ÿ,\u00f1\u00d1 ]+$/.test(char)) {
                return true;
            } else {
                evt.preventDefault();
            }
        },

        calleNumero(data_domicilio){
            let data = data_domicilio.split(' ')
            const data2 = data.filter( d => !(d == 'numero') && !(d=='número') && !(d=='no') && !(d=='no.') && !(d=='núm') && !(d=='nro') && !(d=='num') && !(d=='s/n') && !(d=='S/N'))

            const domicilio = data2.filter( dom => !parseInt(dom) )
            this.dtaHemodinamica.domicilio = domicilio.join(" ")
            
            const numero = data2.filter(num => num == parseInt(num))
            if (numero.length == 0) {
                this.dtaHemodinamica.num_domicilio = 'S/N'    
            } else {
                this.dtaHemodinamica.num_domicilio = numero[0]
            }
        },

        //  ESTADOS ***************************************************************************************************************************************
        getCatalogos() {
            new Promise((resolve)=>{
                resolve(getCatalogo('/api/catalogos/estados'))
            }).then((data)=>{
                try {
                    this.dtaEstados=data.estados.map(item => {
                        return {
                            value: item.id,
                            text: item.nombre
                        };
                    })
                    this.dtaEstados.unshift({value:null, text:"seleccione una opción"})
                } catch (error) {
                    console.log(error);
                }
            });
        },
        cerrarModal(){
            this.openSuccess=false
        },
        cerrarModalError(){
            this.openError=false
        }
    },

    mounted() {
    },

    activated(){
        Object.assign(this.$data, this.$options.data());
        window.scrollTo({ top: 0, behavior: 'smooth' })
        const egreso=localStorage.egreso
        if (egreso) {
            const egresoDE =  JSON.parse(CryptoJS.AES.decrypt(egreso, 'secret key heal4701tech').toString(CryptoJS.enc.Utf8));
            console.log(egresoDE);
            this.isEgresado=egresoDE.egreso
        }else{
            router.push("/main")
        }

        this.loading=true
        this.setData()
    },

    destroyed() {  
        this.$destroy()
    },
}
</script>

<style scoped>
    @media only screen and (min-width: 1200px) {
        .size_th {
            width: 20vw;
        }

        .input-size {
            width: 20.1vw;
        }
    }

    @media only screen and (max-width: 1200px) {
        .size_th {
            width: 20vw;
        }

        .input-size {
            width: 20.1vw;
        }
    }

    @media only screen and (max-width: 992px) {
        .size_th {
            width: 30vw;
        }

        .input-size {
            width: 100%;
        }        
    }

    @media only screen and (max-width: 768px) {
        .size_th {
            width: 20vw;
        }

        .input-size {
            width: 100%;
        } 
    }

    @media only screen and (max-width: 576px) {
        .size_th {
            width: auto;
        }

        .input-size {
            width: 100%;
        } 
    }

    .contenedor {
        line-height: 25px;
        overflow: initial;
        text-overflow: ellipsis;
        text-align: justify;
    }

    .answer {
        border: 1px solid black;
        padding: 1px 3px 2px 3px;
        background-color: rgb(238, 239, 239);
    }

    .border-input {
        outline:none !important;
        outline-width: 0 !important;
        box-shadow: none !important;
        -moz-box-shadow: none !important;
        -webkit-box-shadow: none !important;

        border: 1px solid rgb(218, 218, 218) !important;
    }

    .border-input-none {
        outline:none !important;
        outline-width: 0 !important;
        box-shadow: none !important;
        -moz-box-shadow: none !important;
        -webkit-box-shadow: none !important;
        border: none !important;
    }

    .border-name-paciente-tutor {
        border-top: none;
        border-left: none;
        border-right: none;
        border-bottom: 1px solid rgb(218, 218, 218) !important;
    }

    legend {
        width: auto;
        margin-left: auto;
        margin-right: auto;
        font-size: 1rem;
    }

    .container-select {
        margin-top: -16px;
    }
</style>