<template>
    <b-container>
        <b-row class="justify-content-between">
            <b-col cols="12" sm="12" md="12" lg="12">
                <h2 class="title_forms txt_primary text-uppercase pl-3">HOJA DE CONSENTIMIENTO INFORMADO DE ANESTESIOLOGÍA</h2>      
            </b-col>
        </b-row>
        
        <b-container>
            <!-- LISTADO DE EXPEDIENTES -->
            <p class="txt_secondary text-uppercase mt-1"><strong>Lista de expedientes</strong></p>
            <b-table-simple class="table-sm border_table">
                <b-tbody>  
                    <b-tr>
                        <b-th class="th_style size_th">Expedientes</b-th>
                        <b-td class="border_table">
                            <b-form-select :disabled="!updating" v-model="state.idExpediente" @change="changeExp(state.idExpediente)" class="border-0" size="sm">
                                <template>
                                    <b-form-select-option v-for="(all) of allExp" :key="all.id" :value="all.id">{{all.no_expediente}}</b-form-select-option>
                                </template>
                            </b-form-select>
                        </b-td>
                    </b-tr>
                </b-tbody>
            </b-table-simple>

            <div class="sticky-top" v-if="(isEgresado && !loading)">
                <b-alert show dismissible variant="warning">
                    El paciente ha egresado desea ver la <b style="padding-top: 4px;">&rArr;</b> <strong class="alert-nota-egreso" @click="verNotaDeEgreso()">nota de egreso</strong>
                </b-alert>
            </div>

            <div v-else-if="(dtaExist && !loading)" class="sticky-top">
                <b-alert show dismissible variant="info">
                    <div class="d-flex">
                        <i class="fa fa-floppy-o fa-lg my-auto pr-2" />
                        Consentimiento informado de antestesiología guardado correctamente
                    </div>
                </b-alert>
            </div>
            <Datos :changeData="changeDta"/>
            <p class="txt_secondary text-uppercase pt-3"><strong>Información general</strong></p>
            <b-table-simple class="table-sm">
                <b-tbody>
                    <b-tr>
                        <b-th class="th_style size_th">Calidad de</b-th>
                        <b-td colspan="2" class="border_table">
                            <b-form-input :disabled="(disableInput || state.oldExp || isEgresado)" v-model="dtaAnestesiologia.calidad_de" :state="valCalidadDe" type="text" name="calidadDe" autocomplete="off" class="border-0" size="sm" placeholder="calidad de" />
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style size_th">Anestesiólogo</b-th>
                        <b-td class="border_table">
                            <b-form-select :disabled="(disableInput || state.oldExp || isEgresado)" v-model="dtaAnestesiologia.anestesiologo_id" :options="dtaMedicos" :state="valAnestesiologo" autocomplete="off" class="border-0" size="sm" />
                        </b-td>
                        <b-td :hidden="state.oldExp" class="border_table justify-content-center text-center my-auto">
                            <button :disabled="disableInput" class="border-0 bg_secondary txt_white rounded-circle pd-add-medic" title="Añadir médico" @click="modalAddMedic()">
                                <i class="fa fa-plus fa-md" />
                            </button>
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style size_th">Tipo de anestesia</b-th>
                        <b-td colspan="2" class="border_table">
                            <b-form-input :disabled="(disableInput || state.oldExp || isEgresado)" v-model="dtaAnestesiologia.tipo_anestecia" :state="valTipAnestecia" type="text" name="tipAnestesia" autocomplete="off" class="border-0" size="sm" placeholder="ingrese el tipo anestesia" />
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style size_th">Procedimiento quirúrgico</b-th>
                        <b-td colspan="2" class="border_table">
                            <b-form-input :disabled="(disableInput || state.oldExp || isEgresado)" v-model="dtaAnestesiologia.procedimiento_quirurgico" :state="valProcQuirurgico" type="text" name="procedQuirurgico" autocomplete="off" class="border-0" size="sm" placeholder="ingresa nombre del procedimiento médico" />
                        </b-td>
                    </b-tr>
                </b-tbody>
            </b-table-simple>

            <p class="txt_secondary text-uppercase pt-3"><strong>Datos adicionales</strong></p>
            <b-table-simple class="table-sm">
                <b-tbody>
                    <b-tr>
                        <b-th class="th_style size_th">Código</b-th>
                        <b-td class="border_table">
                            <b-form-input v-on:keypress="soloNumeros($event)" :disabled="(disableInput || state.oldExp || isEgresado)" v-model="dtaAnestesiologia.codigo" :state="valCodigo" type="text" name="codigo" autocomplete="off" class="border-0" size="sm" placeholder="Ingresa número de código" />
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style size_th">Número de revisión</b-th>
                        <b-td class="border_table">
                            <b-form-input v-on:keypress="soloNumeros($event)" :disabled="(disableInput || state.oldExp || isEgresado)" v-model="dtaAnestesiologia.no_revision" :state="valNoRevision" type="text" name="numRevision" autocomplete="off" class="border-0" size="sm" placeholder="Ingresa el número de revisiones" />
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style size_th">Fecha de última revisión</b-th>
                        <b-td class="border_table">
                            <b-form-input :disabled="(disableInput || state.oldExp || isEgresado)" v-model="dtaAnestesiologia.fecha_ultima_revision" :state="valFechaUltimaRev" autocomplete="off" maxlength="10" class="border-0" type="date" size="sm"></b-form-input>
                        </b-td>
                    </b-tr>
                </b-tbody>
            </b-table-simple>

            <b-table-simple class="table-sm">
                <b-tbody>
                    <b-tr>
                        <b-th class="th_style size_th">Fecha inicial</b-th>
                        <b-td class="border_table">
                            <b-form-input :disabled="(disableInput || state.oldExp || isEgresado)" v-model="dtaAnestesiologia.fecha_inicial" :state="valFechaInicial" autocomplete="off" maxlength="10" class="border-0" type="date" size="sm"></b-form-input>
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style size_th">Fecha final</b-th>
                        <b-td class="border_table">
                            <b-form-input :disabled="(disableInput || state.oldExp || isEgresado)" v-model="dtaAnestesiologia.fecha_final" :state="valFechaFinal" autocomplete="off" maxlength="10" class="border-0" type="date" size="sm"></b-form-input>
                        </b-td>
                    </b-tr>
                </b-tbody>
            </b-table-simple>

            <b-row v-if="error && !state.oldExp" class="my-3">
                <b-col cols="12">
                    <div class="contenedor-de-errores pt-3 pb-1">
                        <ul class="lista-errores px-2">
                            <li v-for="(err,index) of errores" :key="index"><i class="fa fa-warning px-1" v-if="!err.expediente_id" />| {{err[0]}} <br>
                            </li>
                        </ul>
                    </div>
                </b-col>
            </b-row>

            <div v-if="(btnGuardar && !dtaExist && !state.oldExp && !isEgresado)" class="d-flex justify-content-end my-4">
                <b-col cols="12" sm="12" md="5" lg="5">
                    <b-overlay
                    :show="busy"
                    rounded
                    opacity="0.6"
                    spinner-small
                    spinner-variant="primary"
                    >
                        <button class="btn_formluario border-0 btn-block py-2 txt_white" :disabled="busy" @click="cartaConseimiento()">GUARDAR</button>
                    </b-overlay>
                </b-col>
            </div>
            <div v-if="(dtaExist && actualizar && !state.oldExp && !isEgresado)" class="d-flex justify-content-end mt-4 mb-3">
                <b-col cols="12" sm="12" md="5" lg="5">
                    <b-overlay
                    :show="busyUpdate"
                    rounded
                    opacity="0.6"
                    spinner-small
                    spinner-variant="primary"
                    >
                        <button class="btn_formluario border-0 btn-block py-2 txt_white" @click="updateData()" :disabled="busyUpdate">ACTUALIZAR</button>
                    </b-overlay>
                </b-col>
            </div>
            <div v-if="dtaExist && cancelar && !state.oldExp" class="d-flex justify-content-end mb-4">
                <b-col cols="12" sm="12" md="5" lg="5">
                    <button class="btn_formluario border-0 btn-block py-2 txt_white" @click="cancelEdit()">CANCELAR</button>
                </b-col>
            </div>
        </b-container>

        <div class="sticky-container" v-if="(idPacienteSeleccionado!='' && !openError && !openPreSave && !openSuccess && !regNewExp && !regNewExp && !loading)">
            <ul class="sticky">
                <li v-if="(!dtaExist && !state.oldExp && !isEgresado)" @click="preGuardar()">
                    <img :src="require('../../../assets/icons/guardar.png')" width="50" height="32">
                    <p>
                        <a>Preguardar</a>
                    </p>
                </li>
                <li v-if="(dtaExist && updating && !state.oldExp && !isEgresado)" @click="editar()">
                    <img :src="require('../../../assets/icons/editar.png')" width="57" height="32">
                    <p>
                        <a>Editar</a>
                    </p>
                </li>
                <li v-if="dtaExist && updating" @click="imprimir()">
                    <img :src="require('../../../assets/icons/imprimir.png')" width="50" height="32">
                    <p>
                        <a>Imprimir</a>
                    </p>
                </li>
            </ul>
        </div>

        <Error :abrir="openError" @cerrarModalError="cerrarModalError()"/> 
        <DatosPreguardados :abrirPreSave="openPreSave" @showDtaPreSave="showDtaPreSave()" @cerrarModalPreSave="cerrarModalPreSave()"/>
        <Success :abrir="openSuccess" @cerrarModal="cerrarModal()"/>
        <RegistroMedico :openModalAddMedic="openAddMedic" @closeModalAddMedic="closeModalAddMedic()" @getMedicPerEsp="getMedicPerEsp()"/>
        <NuevoExpediente :openRegExp="regNewExp"/>
        <Loading :show="loading"/>

    </b-container>
</template>

<script>
import Vue from 'vue'
import PDFMerger from "pdf-merger-js";
import { url } from "../../../../settings";
import RegistroMedico from '@/components/Medico/Registro.vue'
const urlp = `${url}/api/pacientes`
import CryptoJS from 'crypto-js';
import Success from "@/components/Modals/Success.vue";
import Error from "@/components/Modals/Error.vue";
import DatosPreguardados from "@/components/Modals/DatosPreguardados.vue"
import NuevoExpediente from '@/components/Modals/NuevoExpediente.vue';
import Loading from '@/components/Loader/Loading.vue'
import router from "../../../router";
import Datos from '@/components/Paciente/Visualizar/Datos.vue'

export default {
    name: 'ConsentimAnestesiologia',
    components: { Success, Error, DatosPreguardados, RegistroMedico, NuevoExpediente, Loading, Datos },

    data: () => ({
        changeDta:null,
        isEgresado:false,
        state:{
            idExpediente:"",
            idPaciente: "",
            oldExp:false,
        },
        allExp:[],

        regNewExp: false,
        openAddMedic: false,
        
        editing: false,
        updating: true,
        disableInput: false,
        actualizar: false,
        cancelar: false,
        busyUpdate: false,
        dtaExist: false,
        existNotaEgreso: false,
        btnGuardar: false,
        btnFirmar: true,

        loading: true,

        datosPreGuardados:[],
        showDta: false,
        dtaMedicos: [{value:"",text:'seleccionar'},{text:'...'}], 
        error:false,
        errores:[],
        openError: false,
        openPreSave: false,
        openSuccess: false,
        openModalAddMedic: false,

        info: false,
        year:0,
        busy: false,
        idPacienteSeleccionado: "",
        datosExpediente:{paciente:{nombre:""}},
        pacientes:[{}],
        dtaAnestesiologia: {
            id: '',
            expediente_id: null,
            nombre: "",
            fecha_nacimiento: "",
            edad: 0,
            genero: "",
            cp: "",
            domicilio: "",
            colonia: "",
            ciudad: "",
            estado: "",
            municipio: "",
            telefono: "",
            responsable: {
                nombre: "",
                parentezco: "",
                edad: 0,
                cp: "",
                domicilio: "",
                colonia: "",
                ciudad: "",
                estado: "",
                municipio: ""
            },
            calidad_de: "",
            anestesiologo_id: "",
            anestesiologo: "",
            tipo_anestecia: "",
            procedimiento_quirurgico: "",
            codigo: "",
            no_revision: "",
            negacion: true,
            revocacion: true,
            fecha_ultima_revision: "",
            fecha_inicial: "",
            fecha_final: "",
        },

        dtaResponsable: {
            nombre: '',
            parentesco: '',
            edad: '',
            cp: '',
            colonia: '',
            ciudad: '',
            estado_id: ''
        },

        datosPaciente:{
            edad:"",
            nombre: "",
            fecha_nacimiento: "",
            estado_civil_id: "",
            cp: "",
            domicilio: "",
            colonia: "",
            ciudad: "",
            telefono: "",
            email: "",
            estado_id: null,
            municipio_id: null,
            noExpediente:""
        }
    }),

    computed: {
        validation() {
            return this.idPacienteSeleccionado != ""
        },

        valCalidadDe() {
            return this.dtaAnestesiologia.calidad_de != '' && this.dtaAnestesiologia.calidad_de.length >= 4 && this.dtaAnestesiologia.calidad_de.length <= 250
        },

        valAnestesiologo() {
            return !(this.dtaAnestesiologia.anestesiologo_id == null || this.dtaAnestesiologia.anestesiologo_id == "")
        },

        valTipAnestecia() {
            return this.dtaAnestesiologia.tipo_anestecia != '' && this.dtaAnestesiologia.tipo_anestecia.length >= 5 && this.dtaAnestesiologia.tipo_anestecia.length <= 250
        },

        valProcQuirurgico() {
            return this.dtaAnestesiologia.procedimiento_quirurgico != '' && this.dtaAnestesiologia.procedimiento_quirurgico.length >= 5 && this.dtaAnestesiologia.procedimiento_quirurgico.length <= 250
        },

        valCodigo() {
            return this.dtaAnestesiologia.codigo != '' && this.dtaAnestesiologia.codigo.length >= 5 && this.dtaAnestesiologia.codigo.length <= 250
        },

        valNoRevision() {
            return this.dtaAnestesiologia.no_revision != ''
        },

        valFechaUltimaRev() {
            return this.dtaAnestesiologia.fecha_ultima_revision != ''
        },

        valFechaInicial() {
            return this.dtaAnestesiologia.fecha_inicial != ''
        },

        valFechaFinal() {
            return this.dtaAnestesiologia.fecha_final != ''
        }
    },

    methods: {
        verNotaDeEgreso() {
          this.$emit('toEgreso')  
        },
        changeExp(id){

            this.editing= false
            this.updating= true
            this.disableInput= false
            this.actualizar= false
            this.cancelar= false
            this.busyUpdate= false
            this.dtaExist= false
            this.existNotaEgreso= false
            this.btnGuardar= false
            this.btnFirmar= true

            this.loading=true

            let exps = this.allExp
            let currentExp = exps.find(exp => exp.id == id)

            console.log(currentExp);

            if (currentExp) {
                this.sinExp=false
                this.changeDta=currentExp.id
                var expediente = CryptoJS.AES.encrypt(JSON.stringify(currentExp), 'secret key heal4701tech')
                localStorage.setItem('expedienteEncrypt', expediente);
                this.setData(true)
            }
        },

        async allExpedientes() {
            try {
                const res = await fetch(`${url}/api/datos_medicos/`+this.idPacienteSeleccionado, {
                    method: 'get',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Requested-With': 'XMLHttpRequest',
                        'Authorization': "Bearer "+ localStorage.getItem("token"),
                    }
                });

                const data = await res.json();
                this.allExp=data.expedientes
                console.log(this.allExp);

            } catch (error) {
                console.log("el error es"+error);
            }
        },

        cancelEdit() {
            this.getCarta()
            this.disableInput = true
            this.actualizar = false
            this.updating = true
            this.cancelar = false
            this.error = false
            this.errores = []
        },

        editar() {
            this.actualizar = true
            this.cancelar = true
            this.updating = false
            this.disableInput = false
            this.btnGuardar = false
        },

        async updateData() {
            this.error=false
            this.errores=[]
            this.busyUpdate = true

            try {
                const res = await fetch(`${url}/api/hoja_consentimiento/${this.dtaAnestesiologia.id}`, {
                    method: 'put',
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        'Content-Type': 'application/json',
                        'X-Requested-With': 'XMLHttpRequest',
                        'Authorization': "Bearer "+ localStorage.getItem("token"),
                    },
                    body: JSON.stringify(this.dtaAnestesiologia)
                });
                const data = await res.json()
                Vue.prototype.$error500 = res.status===500
                var warn = false
                var err = []
                var promise = Promise.resolve(data)
                await promise.then(function(val) {
                    console.log(val);
                    if (val.class=="success") {
                        warn=true
                    }else{
                        err=val.errors   
                    }
                });
                if (warn) {
                    this.getCarta()
                    this.openSuccess=true
                    this.updating = true
                    this.disableInput = true
                    this.busyUpdate=false
                    this.actualizar = false
                    this.cancelar = false
                }else{
                    this.openError=true
                    this.errores=err
                    this.error=true
                    this.busyUpdate = false
                }
            } catch (error) {
                this.busyUpdate = false
                console.log(error)
            }
        },

        async imprimir(){
            var este = this;
            this.loading = true
            let urlim = `${url}/api/hoja_consentimiento/${this.dtaAnestesiologia.expediente_id}/true`;
            return fetch(urlim, {
                method: 'get',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Requested-With': 'XMLHttpRequest',
                    'Authorization': "Bearer "+ localStorage.getItem("token"),
                }
            }).then(function(resp){
                return resp.blob("paciente.pdf")
            }).then(function(blob) {
                este.downloadPDF(blob)

            })
        },
        async downloadPDF(blob){
            try {
                var merger = new PDFMerger()
                await merger.add(URL.createObjectURL(blob))
                await merger.save(this.datosPaciente.noExpediente+" HOJA DE CONSENTIMIENTO INFORMADO DE ANESTESIOLOGÍA");
                this.loading = false
            } catch (error) {
                this.loading = false
            }
        },

        makeToast(variant = null) {
            this.$bvToast.toast('Los datos han sido preguardados con exito', {
            title: "Datos preguardados",
            variant: variant,
            solid: true
            })
        },

        toastPrint(variant = null) {
            this.$bvToast.toast('Seleccione un paciente', {
            title: "¡Error!",
            variant: variant,
            solid: true
            })
        },

        modalAddMedic() {
            this.openAddMedic = true;
        },

        soloNumeros(evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault();
            } else {
                return true;
            }
        },

        soloLetras(evt) {
            let char = String.fromCharCode(evt.keyCode);
            if(/^[A-Za-z-zA-ZÀ-ÿ,\u00f1\u00d1 ]+$/.test(char)) {
                return true;
            } else {
                evt.preventDefault();
            }
        },

        async obtenerMedicos() {
            try {
                const res = await fetch(`${url}/api/catalogos/medicos_internos_externos`, {
                    method: 'get',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Requested-With': 'XMLHttpRequest',
                        'Authorization': "Bearer "+ localStorage.getItem("token"),
                    }
                });       
                const data = await res.json();
                console.log({DATAs: data});
                this.dtaMedicos=data.medicos.map(item => {
                    return {
                        value: item.medico_interno_id,
                        text: item.nombre
                    };
                })
                this.dtaMedicos.unshift({value:"", text:"seleccionar"})
            } catch (error) {
                console.log(error);
            }
        },

        async obtenerEspecialidades() {
            try {
                const res = await fetch(`${url}/api/especialidades`, {
                    method: 'get',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Requested-With': 'XMLHttpRequest',
                        'Authorization': "Bearer "+ localStorage.getItem("token"),
                    }
                });
                const data = await res.json();
                this.dtaEspecialidades = data.especialidades.map(item => {
                    return {
                        value: item.id,
                        text: item.nombre
                    }
                })
                this.dtaEspecialidades.unshift({ value: null, text: 'seleccionar' })

                this.dtaEspecialidadesModal = data.especialidades.map(item => {
                    return {
                        value: item.id,
                        text: item.nombre
                    }
                })
                this.dtaEspecialidadesModal.unshift({ value: null, text: 'seleccionar' })

            } catch (error) {
                console.log(error);
            }
        },

        preGuardar() {
            if (this.idPacienteSeleccionado == '') {
                this.toastPrint('danger');
            } else {
                var existe=false;
                try {
                    if (localStorage.preGuardadoCartaA) {
                        console.log("si hay datos");
                        this.datosPreGuardados=JSON.parse(localStorage.preGuardadoCartaA)
                        console.log(this.datosPreGuardados);
                    }
                } catch (error) {
                    console.log(error);
                }
                 var i =0;
                try {
                    for (let val of this.datosPreGuardados) {
                        console.log("valor: "+val.expediente_id);
                        if (val&&this.dtaAnestesiologia.expediente_id===val.expediente_id) {
                            console.log("ya esta we");
                            this.datosPreGuardados[i]=this.dtaAnestesiologia
                            existe=true
                        }
                        i++
                    }
    
                } catch (error) {
                    console.log("");
                }
                if (!existe) {
                    this.datosPreGuardados.push(this.dtaAnestesiologia);
                }
                const dta = JSON.stringify(this.datosPreGuardados)
                localStorage.setItem('preGuardadoCartaA', dta);
                this.makeToast('success');
                console.log(this.datosPreGuardados);
            }
        },
        verificarPreGuardado(){
            try {
                if (localStorage.preGuardadoCartaA) {
                    this.datosPreGuardados=JSON.parse(localStorage.preGuardadoCartaA)
                }
            } catch (error) {
                console.log(error);
            }
            for (let val of this.datosPreGuardados) {
                try {
                if (this.dtaAnestesiologia.expediente_id===val.expediente_id) {
                    if (this.showDta === true) {
                        this.dtaAnestesiologia=val
                    } else {
                        this.openPreSave = true
                    }
                }          
                } catch (error) {
                    console.log("");   
                }
            }
        },

        async cartaConseimiento() {
            this.busy = true
            this.dtaAnestesiologia.negacion=true
            this.dtaAnestesiologia.revocacion=true
            console.log("bien hecho si jala");
            this.error=false;
            this.errores=[];
            try {
                const res = await fetch(`${url}/api/hoja_consentimiento`, {
                    method: 'post',
                    headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-Type": "application/json",
                    "X-Requested-With": "XMLHttpRequest",
                    'Authorization': "Bearer "+ localStorage.getItem("token"),
                    },
                    body: JSON.stringify(this.dtaAnestesiologia),
                });
                const data = res.json();
                Vue.prototype.$error500 = res.status===500
                var warn = false;
                var err =[];
                var promise = Promise.resolve(data);
                await promise.then(function(val) {
                    console.log(val);
                    if (val.class=="success") {
                        warn = true
                    }else{
                        err=val.errors
                    }
                });
                if (warn) {
                    this.busy=false
                    this.openSuccess=true
                    this.getCarta(this.dtaAnestesiologia.expediente_id)
                }else{
                    console.log("entre al else");
                    this.error=true
                    this.errores=err
                    this.openError=true
                    this.openError=true
                    this.busy=false
                }
            } catch (error) {
                console.log(error);
            }
       },

        async getCarta(){
            try {
                const res = await fetch(`${url}/api/hoja_consentimiento/${this.dtaAnestesiologia.expediente_id}`, {
                    method: 'get',
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        'Content-Type': 'application/json',
                        'X-Requested-With': 'XMLHttpRequest',
                        'Authorization': "Bearer "+ localStorage.getItem("token"),
                    }
                });
                const data = await res.json();
                console.log(data);
                if (data.status == false) {
                    this.disableInput = false
                    this.btnGuardar = true
                } else {
                    console.log("entre");
                    this.dtaExist = true
                    this.disableInput = true
                    this.btnGuardar = false
                }
                window.scrollTo({ top: 0, behavior: 'smooth' })
                this.loading=false
                this.dtaAnestesiologia=data.hoja_consentimiento
                console.log(data);
                if (!data.status) {
                    this.verificarPreGuardado()
                }
                
            } catch (error) {
                window.scrollTo({ top: 0, behavior: 'smooth' })
                this.loading=false
                console.log("el error es"+error);
            }
        },

        async obtenerPacientes() {
            try {
                const res = await fetch(`${urlp}`, {
                    method: 'get',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Requested-With': 'XMLHttpRequest',
                        'Authorization': "Bearer "+ localStorage.getItem("token"),
                    }
                });
                const data = await res.json();
                this.pacientes=data.pacientes
                this.pacientes.unshift({id:"", nombre:"Seleccionar paciente"})
            } catch (error) {
                console.log(error);
            }
        },

         changeAge:  function(val) {
            let dob = new Date();
            let today = new Date();

            let x = val.split("-");
            dob.setDate(x[2]);
            dob.setMonth(x[1] - 1);
            dob.setFullYear(x[0]);
           

            this.year = (function() {
                if(dob.getMonth() == today.getMonth()) {
                    if(dob.getDate() > today.getDate()) {
                        return (today.getFullYear() - 1) - dob.getFullYear();
                    }
                    else {
                        return today.getFullYear() - dob.getFullYear();
                    }
                }
                else {
                    if(dob.getMonth() > today.getMonth()) {
                        return (today.getFullYear() - 1) - dob.getFullYear();
                    }
                    else {
                        return today.getFullYear() - dob.getFullYear();
                    }
                }
            }());
		},

        setData(){
            this.obtenerPacientes()
            const pacienteEncrypt=localStorage.expedienteEncrypt
            const expediente =  CryptoJS.AES.decrypt(pacienteEncrypt, 'secret key heal4701tech').toString(CryptoJS.enc.Utf8);
            const paciente=JSON.parse(expediente)

            this.state.idExpediente=paciente.id
            this.state.idPaciente=paciente.paciente.id

            if (paciente.paciente.lastExpediente.expediente_id!=paciente.id) {
                this.state.oldExp=true
            }else{
                this.state.oldExp=false
            }

            console.log(paciente);
            if (paciente.paciente.telefono==null) {
                paciente.paciente.telefono="-----"
            }
            if (paciente.paciente.email==null) {
                paciente.paciente.email="-----"
            }
            this.idPacienteSeleccionado=paciente.paciente.id
            this.dtaAnestesiologia.expediente_id=paciente.id;
            this.datosPaciente=paciente.paciente
            this.datosPaciente.estado_civil_id=""
            this.datosPaciente.noExpediente=paciente.paciente.no_expediente
            this.datosExpediente=paciente
            this.btnGuardar = false;
            this.btnFirmar = true;

            var year = new Date(this.datosPaciente.fecha_nacimiento)
            var today = new Date();
            var yyyy = today.getFullYear();
            this.datosPaciente.edad= yyyy-year.getFullYear();
            setTimeout(() => {
                this.changeAge(paciente.responsable_paciente.fecha_nacimiento)
            }, 50);

            this.getCarta()
            this.allExpedientes()
            
            this.btnGuardar = false;
            this.btnFirmar = true;
        },
        cerrarModal(){
            this.openSuccess=false
        },
        cerrarModalError(){
            this.openError=false
        },
        closeModalAddMedic(){
            this.openAddMedic = false
        },
        getMedicPerEsp(){
            this.obtenerMedicos()
            this.openAddMedic = false
            this.openSuccess = true
        },
        showDtaPreSave(){
            this.showDta = true
            this.verificarPreGuardado()
            this.openPreSave = false
        },
        cerrarModalPreSave(){
            this.openPreSave = false
        }
    },

    mounted() {

    },
    destroyed() {  
        this.$destroy();
    },
    activated(){
        Object.assign(this.$data, this.$options.data());
        window.scrollTo({ top: 0, behavior: 'smooth' })
        const egreso=localStorage.egreso
        if (egreso) {
            const egresoDE =  JSON.parse(CryptoJS.AES.decrypt(egreso, 'secret key heal4701tech').toString(CryptoJS.enc.Utf8));
            console.log(egresoDE);
            this.isEgresado=egresoDE.egreso
        }else{
            router.push("/main")
        }

        this.obtenerEspecialidades()
        this.obtenerMedicos()
        this.obtenerEspecialidades()
        this.setData()
        this.loading=true
        // setTimeout(() => {
        //     window.scrollTo({ top: 0, behavior: 'smooth' })
        //     this.loading=false
        // }, 1500);
    }
    
}
</script>

<style scoped>
    @media only screen and (min-width: 1200px) {
        .size_th {
            width: 20vw;
        }
    }

    @media only screen and (max-width: 1200px) {
        .size_th {
            width: 20vw;
        }
    }

    @media only screen and (max-width: 992px) {
        .size_th {
            width: 30vw;
        }
    }

    @media only screen and (max-width: 768px) {
        .size_th {
            width: 20vw;
        }
    }

    @media only screen and (max-width: 576px) {
        .size_th {
            width: auto;
        }
    }
</style>