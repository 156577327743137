<template>
    <b-container>
       <b-row class="justify-content-between">
            <b-col cols="12" sm="12" md="8" lg="8">
                <h2 class="txt_primary text-uppercase pl-3">Historia clínica</h2>   
            </b-col>
            <b-col class="d-none d-lg-block" cols="4" sm="4" md="4" lg="4">
                <div class="float-right my-auto">
                    <Foto/>
                </div>
            </b-col>
        </b-row>
        
        <b-container>
            <b-row>
                <b-col class="p-0 padding_stepper" cols="12" sm="12" md="12" lg="3">
                    <div class="container">
                        <section class="step-indicator">
                            <div class="step step1 active">
                                <button id="step1" class="step-icon" />
                                <!-- <button id="step1" @click="formHC('HCFormUno')" class="step-icon" /> -->
                            </div>
                            <div class="indicator-line"></div>
                            <div class="step step2" v-if="datosAntecedentes.genero_id!=2">
                                <button id="step2" @click="formHC('HCFormDos')" class="step-icon" />
                            </div>
                            <div class="indicator-line"></div>
                            <div class="step step3">
                                <button id="step3" @click="formHC('HCFormTres')" class="step-icon" />
                            </div>
                        </section>
                    </div>
                </b-col> 
            </b-row>

            <p class="txt_secondary text-uppercase mt-4"><strong>Lista de expedientes</strong></p>
            <b-table-simple class="table-sm border_table">
                <b-tbody>  
                    <b-tr>
                        <b-th class="th_style size_th">Expediente</b-th>
                        <b-td class="border_table">
                            <b-form-select :disabled="!updating" v-model="idExpediente" @change="changeExp(idExpediente)" class="border-0" size="sm">
                                <template>
                                    <b-form-select-option v-for="(all) of allExp" :key="all.id" :value="all.id">{{all.no_expediente}}</b-form-select-option>
                                </template>
                            </b-form-select>
                        </b-td>
                    </b-tr>
                </b-tbody>
            </b-table-simple>

            <div class="sticky-top" v-if="(isEgresado && !loading) && timerMessages">
                <b-alert show dismissible variant="warning">
                    El paciente ha egresado desea ver la <b style="padding-top: 4px;">&rArr;</b> <strong class="alert-nota-egreso" @click="verNotaDeEgreso()">nota de egreso</strong>
                </b-alert>
            </div>

            <div v-else-if="(allNotas.historia_clinica.Antecedentes && allNotas.historia_clinica.no_patologicos && !loading) && timerMessages" class="sticky-top">
                <b-alert show dismissible variant="info">
                    <div class="d-flex" v-if="(datosAntecedentes.firma.is_firmado==1)">
                        <i class="fa fa-floppy-o fa-lg my-auto pr-2" />
                        Nota firmada y guardada correctamente
                    </div>
                    <div class="d-flex" v-else-if="(datosAntecedentes.firma.is_firmado==0)">
                        <i class="fa fa-floppy-o fa-lg my-auto pr-2" />
                        Guardado correctamente
                    </div>
                </b-alert>
            </div>

            <Datos :changeData="changeDta"/>

            <p class="txt_secondary text-uppercase pt-3"><strong>Datos generales</strong></p>

            <b-table-simple class="table-sm mt-3">
                <b-tbody>
                    <b-tr>
                        <b-th class="th_style size_th">Fecha de elaboración</b-th>
                        <b-td class="border_table">
                            <b-form-input disabled name="fechaElaboracion" autocomplete="off" v-on:keypress="soloNumeros($event)" :state="fecha" v-model="datosAntecedentes.fecha" type="date" class="border-0" size="sm" placeholder="Ingresa nombre del paciente" />
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style">Informante</b-th>
                        <b-td class="border_table">
                            <b-form-input :disabled="(state.oldExp || isEgresado || (estado!= 'f1' && estado!='editando' ))" type="text" name="informante" autocomplete="off" v-on:keypress="soloLetras($event)" :state="informante" v-model="datosAntecedentes.informante" class="border-0" size="sm" placeholder="Ingresa quién es el informante" />
                        </b-td>
                    </b-tr>
                </b-tbody>
            </b-table-simple>

            <p class="txt_secondary text-uppercase pt-3"><strong>Antecedentes</strong></p>
            <p class="txt_secondary">Hereditarios y familiares</p>
            <b-container>
                <b-row class="mx-auto">
                    <b-col cols="12" sm="12" md="6" lg="6">
                        <b-form-checkbox :disabled="(state.oldExp || isEgresado || (estado!= 'f1' && estado!='editando' ))" v-model="datosAntecedentes.diabetes_mellitus" size="lg" class="small"><small class="txt_gray">Diabetes mellitus</small></b-form-checkbox>
                        <b-form-checkbox :disabled="(state.oldExp || isEgresado || (estado!= 'f1' && estado!='editando' ))" v-model="datosAntecedentes.hipertencion_arterial" size="lg"><small class="txt_gray">Hipertensión arterial</small></b-form-checkbox>
                        <b-form-checkbox :disabled="(state.oldExp || isEgresado || (estado!= 'f1' && estado!='editando' ))" v-model="datosAntecedentes.tuberculosis_pulmonar" size="lg"><small class="txt_gray">Tuberculosis pulmonar</small></b-form-checkbox>
                        <b-form-checkbox :disabled="(state.oldExp || isEgresado || (estado!= 'f1' && estado!='editando' ))" v-model="datosAntecedentes.obesidad" size="lg"><small class="txt_gray">Obesidad</small></b-form-checkbox>
                        <b-form-checkbox :disabled="(state.oldExp || isEgresado || (estado!= 'f1' && estado!='editando' ))" v-model="datosAntecedentes.neoplasias" size="lg"><small class="txt_gray">Neoplasias</small></b-form-checkbox>
                        <b-form-checkbox :disabled="(state.oldExp || isEgresado || (estado!= 'f1' && estado!='editando' ))" v-model="datosAntecedentes.cardiopatias" size="lg"><small class="txt_gray">Cardiopatías</small></b-form-checkbox>
                        <b-form-checkbox :disabled="(state.oldExp || isEgresado || (estado!= 'f1' && estado!='editando' ))" v-model="datosAntecedentes.familiares_tabaquismo" size="lg"><small class="txt_gray">Tabaquismo</small></b-form-checkbox>
                    </b-col>
                    <b-col cols="12" sm="12" md="6" lg="6">
                        <b-form-checkbox :disabled="(state.oldExp || isEgresado || (estado!= 'f1' && estado!='editando' ))" v-model="datosAntecedentes.familiares_alcoholismo" size="lg"><small class="txt_gray">Alcoholismo</small></b-form-checkbox>
                        <b-form-checkbox :disabled="(state.oldExp || isEgresado || (estado!= 'f1' && estado!='editando' ))" v-model="datosAntecedentes.familiares_dependencia_drogas_medicamentos" size="lg"><small class="txt_gray">Dependencia a drogas o medicamento</small></b-form-checkbox>
                        <b-form-checkbox :disabled="(state.oldExp || isEgresado || (estado!= 'f1' && estado!='editando' ))" v-model="datosAntecedentes.familiares_alergias" size="lg"><small class="txt_gray">Alergias</small></b-form-checkbox>
                        <b-form-checkbox :disabled="(state.oldExp || isEgresado || (estado!= 'f1' && estado!='editando' ))" v-model="datosAntecedentes.malformaciones_congenitas" size="lg"><small class="txt_gray">Malformaciones congénitas</small></b-form-checkbox>
                        <b-form-checkbox :disabled="(state.oldExp || isEgresado || (estado!= 'f1' && estado!='editando' ))" v-model="datosAntecedentes.disfunciones_familiares" size="lg"><small class="txt_gray">Disfunciones familiares</small></b-form-checkbox>
                        <b-form-checkbox :disabled="(state.oldExp || isEgresado || (estado!= 'f1' && estado!='editando' ))" v-model="datosAntecedentes.familiares_otros" size="lg"><small class="txt_gray">Otro</small></b-form-checkbox>
                        <div class="d-flex" v-if="datosAntecedentes.familiares_otros">
                            <span class="pl-4 pr-2 pt-2 txt_gray">
                                ¿Cuál?
                            </span>
                            <b-form-input :disabled="(state.oldExp || isEgresado || (estado!= 'f1' && estado!='editando' ))" name="cualHF" autocomplete="off" :state="familiares_cual" v-model="datosAntecedentes.familiares_cual" class="float-right border_bottom_other" size="sm"></b-form-input>
                        </div>
                    </b-col>
                </b-row>
            </b-container>

            <p class="txt_secondary mt-3">Personales patológicos</p>
            <b-container>
                <b-row class="mx-auto">
                    <b-col cols="12" sm="12" md="6" lg="6">
                        <b-form-checkbox :disabled="(state.oldExp || isEgresado || (estado!= 'f1' && estado!='editando' ))" v-model="datosAntecedentes.quirurgicos" size="lg" class="small"><small class="txt_gray">Quirúrgicos</small></b-form-checkbox>
                        <b-form-checkbox :disabled="(state.oldExp || isEgresado || (estado!= 'f1' && estado!='editando' ))" v-model="datosAntecedentes.medicos" size="lg" class="small"><small class="txt_gray">Médicos</small></b-form-checkbox>
                        <b-form-checkbox :disabled="(state.oldExp || isEgresado || (estado!= 'f1' && estado!='editando' ))" v-model="datosAntecedentes.transfucionales" size="lg" class="small"><small class="txt_gray">Transfuncionales</small></b-form-checkbox>
                        <b-form-checkbox :disabled="(state.oldExp || isEgresado || (estado!= 'f1' && estado!='editando' ))" v-model="datosAntecedentes.personales_alergias" size="lg" class="small"><small class="txt_gray">Alérgicos</small></b-form-checkbox>
                    </b-col>
                    <b-col cols="12" sm="12" md="6" lg="6">
                        <b-form-checkbox :disabled="(state.oldExp || isEgresado || (estado!= 'f1' && estado!='editando' ))" v-model="datosAntecedentes.personales_tabaquismo" size="lg"><small class="txt_gray">Tabaquismo</small></b-form-checkbox>
                        <b-form-checkbox :disabled="(state.oldExp || isEgresado || (estado!= 'f1' && estado!='editando' ))" v-model="datosAntecedentes.personales_alcoholismo" size="lg"><small class="txt_gray">Alcoholismo</small></b-form-checkbox>
                        <b-form-checkbox :disabled="(state.oldExp || isEgresado || (estado!= 'f1' && estado!='editando' ))" v-model="datosAntecedentes.personales_dependencia_drogas_medicamentos" size="lg"><small class="txt_gray">Dependencia a drogas o medicamentos</small></b-form-checkbox>
                        <b-form-checkbox :disabled="(state.oldExp || isEgresado || (estado!= 'f1' && estado!='editando' ))" v-model="datosAntecedentes.personales_otros" size="lg"><small class="txt_gray">Otro</small></b-form-checkbox>
                        <div class="d-flex" v-if="datosAntecedentes.personales_otros">
                            <span class="pl-4 pr-2 pt-2 txt_gray">
                                ¿Cuál?
                            </span>
                            <b-form-input :disabled="(state.oldExp || isEgresado || (estado!= 'f1' && estado!='editando' ))" type="text" name="cualPP" autocomplete="off" :state="personales_cual" v-model="datosAntecedentes.personales_cual" class="float-right border_bottom_other" size="sm"></b-form-input>
                        </div>
                    </b-col>
                </b-row>
            </b-container>

            <p class="txt_secondary mt-3">Personales no patológicos</p>
            <b-table-simple class="table-sm">
                <b-tbody>  
                    <b-tr>
                        <b-th class="th_style">Fecha de nacimiento</b-th>
                        <b-td class="border_table">
                            <b-form-input disabled name="fechaNacimiento" autocomplete="off" :state="fecha_nacimiento" v-model="datosPatologicos.fecha_nacimiento" type="date" class="border-0" size="sm" placeholder="DD/MM/AAAA" />
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style">Lugar de nacimiento</b-th>
                        <b-td class="border_table">
                            <b-form-input :disabled="(state.oldExp || isEgresado || (estado!= 'f1' && estado!='editando' ))" type="text" name="lugarNacimiento" autocomplete="off" :state="ciudad" v-model="datosPatologicos.ciudad" class="border-0" size="sm" placeholder="Ingresa lugar de nacimiento" />
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style">Religión</b-th>
                        <b-td class="border_table">
                            <b-form-input disabled type="text" name="religion" autocomplete="off" :state="religion" v-model="datosPatologicos.religion" class="border-0" size="sm" placeholder="Ingresa religión" />
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style">Escolaridad</b-th>
                        <b-td class="border_table">
                            <b-form-select :disabled="(state.oldExp || isEgresado || (estado!= 'f1' && estado!='editando' ))" :state="escolaridad_id" v-model="datosPatologicos.escolaridad_id" value-field="id" text-field="nombre" :options="escolaridades" class="border-0" size="sm" placeholder="seleccionar" />
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style">Ocupación</b-th>
                        <b-td class="border_table">
                            <b-form-input :disabled="(state.oldExp || isEgresado || (estado!= 'f1' && estado!='editando' ))" type="text" name="ocupacion" autocomplete="off" :state="ocupacion" v-model="datosPatologicos.ocupacion" class="border-0" size="sm" placeholder="Ingresa actividad específica" />
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style">Jornada laboral</b-th>
                        <b-td class="border_table">
                            <b-form-input :disabled="(state.oldExp || isEgresado || (estado!= 'f1' && estado!='editando' ))" type="text" name="jornadaLaboral" autocomplete="off" v-model="datosPatologicos.tiempo_ocupacion" class="border-0" size="sm" :placeholder="estado!='f1'?'sin registro':'Ingresa tiempo'" />
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style">Actividad de la empresa</b-th>
                        <b-td class="border_table">
                            <b-form-input :disabled="(state.oldExp || isEgresado || (estado!= 'f1' && estado!='editando' ))" type="text" name="actEmpresa" autocomplete="off" v-model="datosPatologicos.actividad_empresa" class="border-0" size="sm" :placeholder="estado!='f1'?'sin registro':'Ingresa la actividad de la empresa'" />
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style">Calidad de alimentación</b-th>
                        <b-td class="border_table">
                            <b-row>
                                <b-col cols="12" sm="12" md="4" lg="4">
                                    <div class="d-flex">
                                        <span class="px-2 txt_gray rbtn_text">
                                            Buena
                                        </span>
                                        <b-form-radio :disabled="(state.oldExp || isEgresado || (estado!= 'f1' && estado!='editando' ))" v-model="datosPatologicos.calidad_alimentacion_id" value="1" class="rbtn_color" size="md" />
                                    </div>
                                </b-col>
                                <b-col cols="12" sm="12" md="4" lg="4">
                                    <div class="d-flex">
                                        <span class="px-2 txt_gray rbtn_text">
                                            Mala
                                        </span>
                                        <b-form-radio :disabled="(state.oldExp || isEgresado || (estado!= 'f1' && estado!='editando' ))" v-model="datosPatologicos.calidad_alimentacion_id" value="2" class="rbtn_color" size="md" />
                                    </div>
                                </b-col>
                                <b-col cols="12" sm="12" md="4" lg="4">
                                    <div class="d-flex">
                                        <span class="px-2 txt_gray rbtn_text">
                                            Regular
                                        </span>
                                        <b-form-radio :disabled="(state.oldExp || isEgresado || (estado!= 'f1' && estado!='editando' ))" v-model="datosPatologicos.calidad_alimentacion_id" value="3" class="rbtn_color" size="md" />
                                    </div>
                                </b-col>
                            </b-row>
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style">Calidad de habitación</b-th>
                        <b-td class="border_table">
                            <b-row>
                                <b-col cols="12" sm="12" md="4" lg="4">
                                    <div class="d-flex">
                                        <span class="px-2 txt_gray rbtn_text">
                                            Buena
                                        </span>
                                        <b-form-radio :disabled="(state.oldExp || isEgresado || (estado!= 'f1' && estado!='editando' ))" v-model="datosPatologicos.calidad_habitacion_id" value="1" class="rbtn_color" size="md" />
                                    </div>
                                </b-col>
                                <b-col cols="12" sm="12" md="4" lg="4">
                                    <div class="d-flex">
                                        <span class="px-2 txt_gray rbtn_text">
                                            Mala
                                        </span>
                                        <b-form-radio :disabled="(state.oldExp || isEgresado || (estado!= 'f1' && estado!='editando' ))" v-model="datosPatologicos.calidad_habitacion_id" value="2" class="rbtn_color" size="md" />
                                    </div>
                                </b-col>
                                <b-col cols="12" sm="12" md="4" lg="4">
                                    <div class="d-flex">
                                        <span class="px-2 txt_gray rbtn_text">
                                            Regular
                                        </span>
                                        <b-form-radio :disabled="(state.oldExp || isEgresado || (estado!= 'f1' && estado!='editando' ))" v-model="datosPatologicos.calidad_habitacion_id" value="3" class="rbtn_color" size="md" />
                                    </div>
                                </b-col>
                            </b-row>
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style">Higiene personal</b-th>
                        <b-td class="border_table">
                            <b-row>
                                <b-col cols="12" sm="12" md="4" lg="4">
                                    <div class="d-flex">
                                        <span class="px-2 txt_gray rbtn_text">
                                            Buena
                                        </span>
                                        <b-form-radio :disabled="(state.oldExp || isEgresado || (estado!= 'f1' && estado!='editando' ))" v-model="datosPatologicos.calidad_higiene_id" value="1" class="rbtn_color" size="md" />
                                    </div>
                                </b-col>
                                <b-col cols="12" sm="12" md="4" lg="4">
                                    <div class="d-flex">
                                        <span class="px-2 txt_gray rbtn_text">
                                            Mala
                                        </span>
                                        <b-form-radio :disabled="(state.oldExp || isEgresado || (estado!= 'f1' && estado!='editando' ))" v-model="datosPatologicos.calidad_higiene_id" value="2" class="rbtn_color" size="md" />
                                    </div>
                                </b-col>
                                <b-col cols="12" sm="12" md="4" lg="4">
                                    <div class="d-flex">
                                        <span class="px-2 txt_gray rbtn_text">
                                            Regular
                                        </span>
                                        <b-form-radio :disabled="(state.oldExp || isEgresado || (estado!= 'f1' && estado!='editando' ))" v-model="datosPatologicos.calidad_higiene_id" value="3" class="rbtn_color" size="md" />
                                    </div>
                                </b-col>
                            </b-row>
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style">Sedentarismo</b-th>
                        <b-td class="border_table">
                            <b-row>
                                <b-col cols="8">
                                    <b-row class="pl-2">
                                        <b-col cols="12" sm="12" md="6" lg="6">
                                            <div class="d-flex pl-4">
                                                <span class="px-2 txt_gray rbtn_text">
                                                    Sí
                                                </span>
                                                <b-form-radio :disabled="(state.oldExp || isEgresado || (estado!= 'f1' && estado!='editando' ))" v-model="datosPatologicos.sedentarismo" v-bind:value=true class="rbtn_color" size="md" />
                                            </div>
                                        </b-col>
                                        <b-col cols="12" sm="12" md="6" lg="6">
                                            <div class="d-flex pl-2">
                                                <span class="px-2 txt_gray rbtn_text">
                                                    No
                                                </span>
                                                <b-form-radio :disabled="(state.oldExp || isEgresado || (estado!= 'f1' && estado!='editando' ))" v-model="datosPatologicos.sedentarismo" v-bind:value=false class="rbtn_color" size="md" />
                                            </div>
                                        </b-col>
                                    </b-row>
                                </b-col>
                            </b-row>
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style">Tipo de relaciones sexuales</b-th>
                        <b-td class="border_table">
                            <b-form-input :disabled="(state.oldExp || isEgresado || (estado!= 'f1' && estado!='editando' ))" type="text" name="relacionSex" autocomplete="off" v-on:keypress="soloLetras($event)" v-model="datosPatologicos.tipo_relaciones_sexuales" class="border-0" size="sm" :placeholder="estado!='f1'?'sin registro':'Ingresa tipo de relaciones sexuales'" />
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style">Parejas sexuales (Ultimos 12 meses)</b-th>
                        <b-td class="border_table">
                            <b-form-input :disabled="(state.oldExp || isEgresado || (estado!= 'f1' && estado!='editando' ))" type="text" name="parejasSex" autocomplete="off" v-on:keypress="soloNumeros($event)" v-model="datosPatologicos.no_parejas_sexuales" class="border-0" size="sm" :placeholder="estado!='f1'?'sin registro':'Ingresa número de parejas sexuales'" />
                        </b-td>
                    </b-tr>
                </b-tbody>
            </b-table-simple>

            <div v-if="datosAntecedentes.firma.is_firmado==1" class="mb-4">
                <span class="txt_secondary">Nota firmada</span><br>
                <span class="txt_secondary size-span"><i class="fa fa-user mr-1"></i>Médico: {{datosAntecedentes.firma.firmado_por.nombre}}</span> <br>
                <span class="txt_secondary size-span"><i class="fa fa-calendar mr-1"></i>Fecha: {{datosAntecedentes.firma.fecha_firmado}}</span>
            </div>

            <b-row v-if="error && !state.oldExp">
                <b-col cols="12">
                    <div class="contenedor-de-errores pt-3 pb-1">
                        <ul class="lista-errores px-2">
                            <li v-for="(value,key) in errores" :key="key.value"><i class="fa fa-warning px-1" />| {{value[0]}} <br></li>
                            <li v-for="(value,key) in errores2" :key="key.value"><i class="fa fa-warning px-1" />| {{value[0]}} <br></li>
                        </ul>
                    </div>
                </b-col>
            </b-row>

            <div v-if="estado=='f1'&&!state.oldExp" class="d-flex justify-content-end my-4">
                <b-col cols="12" sm="12" md="5" lg="5">
                    <b-overlay
                    :show="busy"
                    rounded
                    opacity="0.6"
                    spinner-small
                    spinner-variant="primary"
                    >
                        <button class="btn_formluario border-0 btn-block py-2 txt_white" :disabled="busy" @click="saveNota()">GUARDAR</button>
                    </b-overlay>
                </b-col>
            </div>

            <div v-if="actualizar && !state.oldExp"  class="d-flex justify-content-end mb-4 pt-3">
                <b-col cols="12" sm="12" md="5" lg="5">
                    <b-overlay
                    :show="busyUpdate"
                    rounded
                    opacity="0.6"
                    spinner-small
                    spinner-variant="primary"
                    >
                        <button class="btn_formluario border-0 btn-block py-2 txt_white" @click="updateData()" :disabled="busyUpdate">ACTUALIZAR</button>
                    </b-overlay>
                </b-col>
            </div>

            <div v-if="actualizar && !state.oldExp" class="d-flex justify-content-end mb-4">
                <b-col cols="12" sm="12" md="5" lg="5">
                    <button  @click="cancelEdit()" class="btn_formluario border-0 btn-block py-2 txt_white">CANCELAR</button>
                </b-col>
            </div>

            <div class="d-flex justify-content-end mb-4" v-if="updating && estado=='done'">
                <b-col class="mt-3" cols="12" sm="12" md="6" lg="6">
                    <button @click="switchComponent('HCFormDos')" class="btn_formluario border-0 btn-block py-2 txt_white">SIGUIENTE</button>
                </b-col>
            </div>
        </b-container>

        <div class="sticky-container" v-if="(idPacienteSeleccionado!='' && !openPreSave && !regNewExp && !pswUpdate && !openError && !openSuccesUno && !openSuccesDos && !loading)">
            <ul class="sticky">
                <!-- <li @click="preGuardar()" v-if="estado=='f1'" >
                    <img :src="require('../../../assets/icons/guardar.png')" width="50" height="32">
                    <p>
                        <a>Preguardar</a>
                    </p>
                </li> -->
                <li @click="imprimir()" v-if="allNotas.historia_clinica.Antecedentes&&allNotas.historia_clinica.exploracion_fisica&&allNotas.historia_clinica.interrogatorio&&allNotas.historia_clinica.no_patologicos" >
                    <img :src="require('../../../assets/icons/imprimir.png')" width="50" height="32">
                    <p>
                        <a>Imprimir</a>
                    </p>
                </li>
                <li v-if="(allNotas.historia_clinica.Antecedentes && allNotas.historia_clinica.no_patologicos && updating && !state.oldExp && datosAntecedentes.firma.is_firmado==0 && !isEgresado)" @click="editar()">
                    <img :src="require('../../../assets/icons/editar.png')" width="57" height="32">
                    <p>
                        <a>Editar</a>
                    </p>
                </li>
            </ul>
        </div>


        <DatosPreguardados :abrirPreSave="openPreSave" />
        <NuevoExpediente :openRegExp="regNewExp"/>
        <FirmaPswUpdate :abrir="pswUpdate" />
        <Error :abrir="openError" @cerrarModalError="cerrarModalError()"/>
        <Success :abrir="openSuccesUno&&openSuccesDos" @cerrarModal="cerrarModal()"/> 
        <Loading :show="loading"/>

    </b-container>
</template>

<script> 
import Vue from 'vue'
import PDFMerger from "pdf-merger-js";
import router from "../../../router";
import Foto from "@/components/Paciente/Foto.vue"
import { url } from "../../../../settings";
const urlp = `${url}/api/pacientes`
import CryptoJS from 'crypto-js'
const urlec = `${url}/api/catalogos/estado_civil`
import DatosPreguardados from "@/components/Modals/DatosPreguardados.vue"
import NuevoExpediente from '@/components/Modals/NuevoExpediente.vue';
import FirmaPswUpdate from '@/components/Modals/FirmaPswUpdate.vue'
import Success from "@/components/Modals/Success.vue";
import Error from "@/components/Modals/Error.vue";
import Loading from '@/components/Loader/Loading.vue'
import Datos from '@/components/Paciente/Visualizar/Datos.vue'
export default {
    name: 'FormUno',
    components: { Datos, Foto, DatosPreguardados, NuevoExpediente, FirmaPswUpdate, Success, Error, Loading},

    computed: {
        genero_id(){
            return this.datosAntecedentes.genero_id !=null
        },

        validation() {
            return this.idPacienteSeleccionado != ""
        },

        fecha(){
            return this.datosAntecedentes.fecha !=""
        },

        informante(){
            return this.datosAntecedentes.informante !=""
        },

        familiares_cual(){
            return this.datosAntecedentes.familiares_cual !=""
        },

        personales_cual(){
            return this.datosAntecedentes.personales_cual !=""
        },

        fecha_nacimiento(){
            return this.datosPatologicos.fecha_nacimiento != ""
        },

        ciudad(){
            return this.datosPatologicos.ciudad != ""
        },

        calidad_alimentacion_id(){
            return this.datosPatologicos.calidad_alimentacion_id != null
        },

        calidad_habitacion_id(){
            return this.datosPatologicos.calidad_habitacion_id != null
        },

        calidad_higiene_id(){
            return this.datosPatologicos.calidad_higiene_id != null
        },

        sedentarismo(){
            return this.datosPatologicos.sedentarismo != null
        },

        tipo_relaciones_sexuales(){
            return this.datosPatologicos.tipo_relaciones_sexuales != ""
        },

        no_parejas_sexuales(){
            return this.datosPatologicos.no_parejas_sexuales != null
        },

        escolaridad_id(){
            return this.datosPatologicos.escolaridad_id != null
        },

        ocupacion(){
            return this.datosPatologicos.ocupacion != ""
        },

        tiempo_ocupacion(){
            return this.datosPatologicos.tiempo_ocupacion != ""
        },

        actividad_empresa(){
            return this.datosPatologicos.actividad_empresa != ""
        },

        religion(){
            return this.datosPatologicos.religion != ""
        },
    },

    data:() => ({
        timerMessages:false,
        busy:false,
        estado:"f1",//f1: fomulario, done: formularios completos, editando: para editar datos
        changeDta:null,
        isEgresado:false,
        allExp:[],
        idExpediente: "",

        state:{
            idExpediente:"",
            idPaciente: "",
            oldExp:false
        },

        url:"",

        btnFirmaUpdate: false,
        updating: true,
        actualizar: false,
        busyUpdate: false,
        pswUpdate: false,

        loading:true,

        openSuccesUno:false,
        openSuccesDos:false,
        openError:false,


        regNewExp: false,
        cargado:false,
        openPreSave: false,
        datosPreGuardados:[],
        showDta:false,
        showDtaDisable:false,

        ecivil:[],
        info:false,
        disabled:true,
        ih:true,
        error:false,
        errores:"",
        errores2:"",

        canPrint: false,

        genero: [{value:null, text:'seleccionar'},{text: '...'}],

        idPacienteSeleccionado:"",
        pacientes:[{id:"",nombre:"..."}],
        nombres:[],
        
        datosAntecedentes: {
            id:null,
            password: '',
            expediente_id: null,
            fecha: "",
            informante: "",
            genero_id: null,
            diabetes_mellitus: false,
            hipertencion_arterial: false,
            tuberculosis_pulmonar: false,
            obesidad: false,
            neoplasias: false,
            cardiopatias: false,
            familiares_tabaquismo: false,
            familiares_alcoholismo: false,
            familiares_dependencia_drogas_medicamentos: false,
            familiares_alergias: false,
            malformaciones_congenitas: false,
            disfunciones_familiares: false,
            familiares_otros: false,
            familiares_cual: "",
            medicos: false,
            quirurgicos: false,
            transfucionales: false,
            personales_alergias: false,
            personales_tabaquismo: false,
            personales_alcoholismo: false,
            personales_dependencia_drogas_medicamentos: false,
            personales_otros: false,
            personales_cual: "",
            firma:{
                fecha_firmado:null,
                firmado_por:{
                    id:null,
                    nombre:""
                },
                is_firmado:0
            },
        },

        datosPatologicos: {
            id:null,
            password: '',
            fecha_nacimiento:"",
            expediente_id: "",
            ciudad: "",
            calidad_alimentacion_id: 1,
            calidad_habitacion_id: 1,
            calidad_higiene_id: 1,
            sedentarismo: false,
            tipo_relaciones_sexuales: "",
            no_parejas_sexuales: null,
            escolaridad_id: null,
            ocupacion: "",
            tiempo_ocupacion: "",
            actividad_empresa: "",
            religion:"",
        },
        datosPaciente:{
            password:"",
            edad:"",
            id: null,
            nombre: "",
            fecha_nacimiento: "",
            estado_civil: {
                id: null,
                nombre: ""
            },
            cp: "",
            domicilio: "",
            colonia: "",
            ciudad: "",
            estado: {
                id:null,
                nombre: "",
            },
            municipio:{
                id:null,
                nombre: "",
            },
            telefono: "",
            email: null,
            genero:{
                id:null,
                nombre: "",
            },
            lastExpediente: {
                expediente_id: null,
                no_habitacion: null
            },
        },
        gineco_obstetrico:null,
        control_prenatal:null,
        escolaridades: [],
        encabezado:{
            idioma:"",
            religion:"",
        },
        datosExpediente:{},

        allNotas:{
            egreso:false,
            evolucion: false,
            historia_clinica: false,
            ingreso: false,
            post_operatoria: false,
            pre_anestesica: false,
            pre_operatoria:false
        },
        done:false,
    }),
    methods: {
        makeToast(tittle, msj, variant = null) {
            this.$bvToast.toast(msj, {
            title: tittle,
            variant: variant,
            autoHideDelay: 2000,
            solid: true
            })
        },

        verNotaDeEgreso() {
          this.$emit('toEgreso')
        },

        //  pa update update update update update update update update 
        cancelEdit() {
            this.actualizar = false
            this.updating = true
            this.error = false
            this.errores = []
            this.setData()
            this.btnFirmaUpdate=false
        },

        editar() {
            this.estado='editando'
            // this.pswUpdate = true
            this.pswUpdate = false
            // this.datosAntecedentes.password=psw
            // this.datosPatologicos.password=psw
            this.actualizar = true
            this.btnFirmaUpdate = false
            this.updating = false
        },

        firmaUpdate() {
            this.pswUpdate = true
        },

        async updateData() {
            this.error=false
            this.errores=[]
            this.errores2=[]
            this.busyUpdate = true
            this.openSuccesUno=false
            this.openSuccesDos=false
            this.updateDatosAntecedentes(this.datosAntecedentes.id)
            // this.updateDatosPatologicos(this.datosPatologicos.id)

        },

        async updateDatosAntecedentes(id){
            try {
                const res = await fetch(`${url}/api/historia_clinica/antecedentes/update/${id}`, {
                    method: 'put',
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        'Content-Type': 'application/json',
                        'X-Requested-With': 'XMLHttpRequest',
                        'Authorization': "Bearer "+ localStorage.getItem("token"),
                    },
                    body: JSON.stringify(this.datosAntecedentes)
                });
                const data = await res.json()
                Vue.prototype.$error500 = res.status===500
                var warn = false
                var err=[]
                var promise = Promise.resolve(data)
                await promise.then(function(val) {
                    if (val.class=="success") {
                        warn=true
                    }else{
                        err=val.errors   
                    }
                });
                if (!warn) {
                    this.openError=true
                    this.error=true
                    this.errores=err
                    this.busyUpdate = false
                }else{
                    this.openSuccesUno=true
                }
                this.allNotas.historia_clinica.no_patologicos?this.updateDatosPatologicos(this.datosPatologicos.id):this.saveDatosPatologicos()
            } catch (error) {
                this.busyUpdate = false
                this.openError = true
            }
        },

        async updateDatosPatologicos(id){
            try {
                const res = await fetch(`${url}/api/historia_clinica/no_patologicos/update/${id}`, {
                    method: 'put',
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        'Content-Type': 'application/json',
                        'X-Requested-With': 'XMLHttpRequest',
                        'Authorization': "Bearer "+ localStorage.getItem("token"),
                    },
                    body: JSON.stringify(this.datosPatologicos)
                });
                const data = await res.json()
                Vue.prototype.$error500 = res.status===500
                var warn = false
                var err=[]
                var promise = Promise.resolve(data)
                await promise.then(function(val) {
                    if (val.class=="success") {
                        warn=true
                    }else{
                        err=val.errors   
                    }
                });
                if (warn) {
                    if (!this.error) {
                        this.setData()
                    }
                    if (this.estado=='editando') {
                        this.updating = true
                        this.busyUpdate=false
                        this.actualizar = false
                    }
                    this.openSuccesDos=true
                }else{
                    this.openError=true
                    this.error=true
                    this.errores2=err
                    this.busyUpdate = false
                }
                this.busy=false
            } catch (error) {
                this.busyUpdate = false
                this.openError = true
            }
        },

        // update update update update update update update update update 
        // /////////////

        toastPrint(variant = null) {
            this.$bvToast.toast('Seleccione un paciente', {
            title: "¡Error!",
            variant: variant,
            solid: true
            })
        },
        
        async imprimir() {
            // var can = false;
            var este = this;
            this.loading = true
            let urlim = `${url}/api/pacientes/pdf/${this.idPacienteSeleccionado}`;
            return fetch(urlim, {
                method: 'get',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Requested-With': 'XMLHttpRequest',
                    'Authorization': "Bearer "+ localStorage.getItem("token"),
                }
            }).then(function(resp){
                return resp.blob("paciente.pdf")
            }).then(function(blob) {
                este.downloadPDF(blob)

            })
        },
        async downloadPDF(blob){
            try {
                var merger = new PDFMerger()
                await merger.add(URL.createObjectURL(blob))
                await merger.save(this.datosExpediente.no_expediente+" Historia clínica");
                this.loading = false
            } catch (error) {
                this.loading = false
            }
        },

        preGuardar() {
            this.datosAntecedentes.password = ""
            this.datosPatologicos.password = ""
            var existe=false;
            try {
                if (localStorage.preGuardadoHCF1) {
                    this.datosPreGuardados=JSON.parse(localStorage.preGuardadoHCF1)
                }
            } catch (error) {
                console.log(error);
            }
             var i =0;
            for (let val of this.datosPreGuardados) {
                if (this.datosAntecedentes.expediente_id===val.expediente_id) {
                    this.datosPreGuardados[i]={"datosAntecedentes":this.datosAntecedentes,"datosPatologicos":this.datosPatologicos}
                    existe=true
                }
                i++
            }
            if (!existe) {
                this.datosPreGuardados.push({"datosAntecedentes":this.datosAntecedentes,"datosPatologicos":this.datosPatologicos});
            }   

            const dta = JSON.stringify(this.datosPreGuardados)
            localStorage.setItem('preGuardadoHCF1', dta);
            this.makeToast('Preguardado','Datos guardados','success');
        },

        verificarPreGuardado(){
            try {
                if (localStorage.preGuardadoHCF1) {
                    this.datosPreGuardados=JSON.parse(localStorage.preGuardadoHCF1)
                }
            } catch (error) {
                console.log(error);
            }
            for (let val of this.datosPreGuardados) {
                if (this.datosAntecedentes.expediente_id===val.datosAntecedentes.expediente_id) {
                    if ((this.showDta === true)) {
                        this.datosAntecedentes=val.datosAntecedentes
                        this.datosPatologicos=val.datosPatologicos
                    } else{
                        if (localStorage.view==="HCFormUno") {
                            this.openPreSave = true
                        }
                    }
                }
            }
        },

        async obtenerEstadoCivil() {
            try {
                const res = await fetch(`${urlec}`, {
                    method: 'get',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Requested-With': 'XMLHttpRequest',
                        'Authorization': "Bearer "+ localStorage.getItem("token"),
                    }
                });
                if (!res.ok) {
                    const message = `An error has occured: ${res.status} - ${res.statusText}`;
                    throw new Error(message);
                }
                const data = await res.json();


                this.ecivil = data.estado_civil.map(item => {
                    return {
                        value: item.id,
                        text: item.nombre
                    };
                });
                this.ecivil.unshift( { value: null, text: 'seleccione una opcion' },)


            } catch (error) {
                console.log("el error es"+error);
            }
        },
        setData(){
            const pacienteEncrypt=localStorage.expedienteEncrypt
            const expediente =  CryptoJS.AES.decrypt(pacienteEncrypt, 'secret key heal4701tech').toString(CryptoJS.enc.Utf8);
            const paciente=JSON.parse(expediente)
            this.idExpediente=paciente.id
            this.state.idExpediente=paciente.id
            this.state.idPaciente=this.idPacienteSeleccionado

            if (paciente.paciente.lastExpediente.expediente_id!=this.state.idExpediente) {
                    this.state.oldExp=true
            }else{
                    this.state.oldExp=false
            }

            if (paciente.paciente.telefono==null) {
                paciente.paciente.telefono="-----"
            }
            if (paciente.paciente.email==null) {
                paciente.paciente.email="-----"
            }
            this.idPacienteSeleccionado=paciente.paciente.id
            this.datosAntecedentes.expediente_id = paciente.id
            this.datosPatologicos.expediente_id= paciente.id
            this.datosPaciente=paciente.paciente
            this.datosAntecedentes.genero_id=this.datosPaciente.genero.id
            this.datosExpediente=paciente

            this.datosPatologicos.fecha_nacimiento=paciente.paciente.fecha_nacimiento
            this.datosPatologicos.religion=paciente.religion
            this.datosPatologicos.ocupacion=paciente.ocupacion
            this.datosPatologicos.escolaridad_id=paciente.escolaridad.id

            this.encabezado.idioma=paciente.idioma
            this.encabezado.religion=paciente.religion
            var year = new Date(this.datosPaciente.fecha_nacimiento)
            var today = new Date();
            var yyyy = today.getFullYear();
            this.datosPaciente.edad= yyyy-year.getFullYear();
            this.getNotas()
            this.allExpedientes()
        },
        soloNumeros(evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault();
            } else {
                return true;
            }
        },

        soloLetras(evt) {
            let char = String.fromCharCode(evt.keyCode);
            if(/^[A-Za-z-zA-ZÀ-ÿ,\u00f1\u00d1 ]+$/.test(char)) {
                return true;
            } else {
                evt.preventDefault();
            }
        },

        async obtenerPacientes() {
            try {
                const res = await fetch(`${urlp}`, {
                    method: 'get',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Requested-With': 'XMLHttpRequest',
                        'Authorization': "Bearer "+ localStorage.getItem("token"),
                    }
                });
                if (!res.ok) {
                    const message = `An error has occured: ${res.status} - ${res.statusText}`;
                    throw new Error(message);
                }
                const data = await res.json();
                this.pacientes=data.pacientes
                this.pacientes.unshift({id:"",nombre:"Seleccionar paciente"})
            } catch (error) {
                console.log("el error es"+error);
            }
        },

        async getEscolaridades() {
            try {
                const res = await fetch(`${url}/api/catalogos/escolaridades`, {
                    method: 'get',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Requested-With': 'XMLHttpRequest',
                        'Authorization': "Bearer "+ localStorage.getItem("token"),
                    }
                });
                if (!res.ok) {
                    const message = `An error has occured: ${res.status} - ${res.statusText}`;
                    throw new Error(message);
                }
                const data = await res.json();
                this.escolaridades=data.escolaridades
                this.escolaridades.unshift({id:null,nombre:"seleccionar"})
            } catch (error) {
                console.log("el error es"+error);
            }
        },

        async getGeneros() {
            try {
                const res = await fetch(`${url}/api/catalogos/genero`, {
                    method: 'get',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Requested-With': 'XMLHttpRequest',
                        'Authorization': "Bearer "+ localStorage.getItem("token"),
                    }
                });
                if (!res.ok) {
                    const message = `An error has occured: ${res.status} - ${res.statusText}`;
                    throw new Error(message);
                }
                const data = await res.json();
                this.genero=data.genero.map(item => {
                    return {
                        value: item.id,
                        text: item.nombre
                    };
                })
                this.genero.unshift({value:null, text:"seleccionar"})
            } catch (error) {
                console.log(error);
            }
        },
        
        formHC(value) {
            if (this.updating) {
                localStorage.setItem("view",value)
                this.$emit('changeHC', value);
            }else{
                localStorage.setItem("view",value)
                this.$emit('changeHC', value);
                this.cancelEdit()
            }
        },

        switchComponent(value) {
            if (this.datosAntecedentes.genero_id==2) {
                localStorage.setItem("view","HCFormTres")
                this.$emit('changeHC',"HCFormTres")
            }else{
                localStorage.setItem("view",value)
                this.$emit('nextHCTwo', value)
            }

        },

        saveNota(){
            this.error=false
            this.errores=[]
            this.errores2=[]
            this.busy=true
            this.openSuccesUno=false
            this.openSuccesDos=false
            this.allNotas.historia_clinica.Antecedentes?this.updateDatosAntecedentes(this.datosAntecedentes.id):this.saveDatosAntecedentes()
        },

       async saveDatosAntecedentes() {
            this.datosAntecedentes.password=this.password
            const pacienteEncrypt=localStorage.expedienteEncrypt
            const expediente =  CryptoJS.AES.decrypt(pacienteEncrypt, 'secret key heal4701tech').toString(CryptoJS.enc.Utf8);
            const paciente=JSON.parse(expediente)
            this.datosAntecedentes.expediente_id=paciente.id
            try {
                const res = await fetch(`${url}/api/historia_clinica/antecedentes/store`, {
                    method: 'post',
                    headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-Type": "application/json",
                    "X-Requested-With": "XMLHttpRequest",
                    'Authorization': "Bearer "+ localStorage.getItem("token"),
                    },
                    body: JSON.stringify(this.datosAntecedentes),
                });
                const data = res.json();
                Vue.prototype.$error500 = res.status===500
                var warn = false;
                var err =[];
                var promise = Promise.resolve(data);
                var id;
                await promise.then(function(val) {
                    if (val.class=="success") {
                        warn = true
                        id=val.antecedentes.id
                    }else{
                        err=val.errors
                    }
                });
                if (!warn) {
                    this.errores=err
                    this.error=true
                    this.openError=true
                }else{
                    this.datosAntecedentes.id=id
                    this.openSuccesUno=true
                    this.allNotas.historia_clinica.Antecedentes=true
                }
                this.allNotas.historia_clinica.no_patologicos?this.updateDatosPatologicos(this.datosPatologicos.id):this.saveDatosPatologicos()
            } catch (error) {
                console.log(error);
            }
       },

       async saveDatosPatologicos() {
            this.datosPatologicos.password=this.password
            const pacienteEncrypt=localStorage.expedienteEncrypt
            const expediente =  CryptoJS.AES.decrypt(pacienteEncrypt, 'secret key heal4701tech').toString(CryptoJS.enc.Utf8);
            const paciente=JSON.parse(expediente)
            this.datosPatologicos.expediente_id=paciente.id

            try {
                const res = await fetch(`${url}/api/historia_clinica/no_patologicos/store`, {
                    method: 'post',
                    headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-Type": "application/json",
                    "X-Requested-With": "XMLHttpRequest",
                    'Authorization': "Bearer "+ localStorage.getItem("token"),
                    },
                    body: JSON.stringify(this.datosPatologicos),
                });
                const data = res.json();
                Vue.prototype.$error500 = res.status===500
                var warn = false;
                var err=[];
                var id;
                var promise = Promise.resolve(data);
                await promise.then(function(val) {
                    if (val.class=="success") {
                        warn = true
                        id=val.no_patologicos.id
                    }else{
                        err=val.errors
                    }
                });
                if (warn) {
                    this.openSuccesDos=true
                    this.datosPatologicos.id=id
                    if (!this.error) {
                        this.setData()
                    }
                }else{
                    this.errores2=err
                    this.error=true
                    this.openError=true
                }
                this.busy=false
            } catch (error) {
                console.log(error);
            }
       },

        async getNotas() {
            
            try {
               const res = await fetch(`${url}/api/notas/${this.datosAntecedentes.expediente_id}`, {
                    method: 'get',
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        'Content-Type': 'application/json',
                        'X-Requested-With': 'XMLHttpRequest',
                        'Authorization': "Bearer "+ localStorage.getItem("token"),
                    }
                });
                const data = await res.json();
                this.allNotas=data.notas
                this.estado = this.allNotas.historia_clinica.Antecedentes && this.allNotas.historia_clinica.no_patologicos? 'done' : 'f1'
                this.obtenerDatosAntecedentes(this.datosAntecedentes.expediente_id)
                this.obtenerNoPatologicos(this.datosPatologicos.expediente_id)
                setTimeout(() => {
                    if (this.estado=='f1') {
                        this.verificarPreGuardado()
                    }
                }, 500);
                this.done=true
           } catch (error) {
               console.log(error);
           }
        },
        /* Obtener los datos de formulario uno */
        async obtenerDatosAntecedentes(value) {
            try {
               const res = await fetch(`${url}/api/historia_clinica/antecedentes/${value}`, {
                    method: 'get',
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        'Content-Type': 'application/json',
                        'X-Requested-With': 'XMLHttpRequest',
                        'Authorization': "Bearer "+ localStorage.getItem("token"),
                    }
                });
                const data = await res.json();
                if (data.nota_antecedentes) {
                    this.datosAntecedentes = data.nota_antecedentes
                    this.datosAntecedentes.genero_id=this.datosPaciente.genero.id
                    this.datosAntecedentes.diabetes_mellitus = data.nota_antecedentes.diabetes_mellitus==1? true : false
                    this.datosAntecedentes.hipertencion_arterial = data.nota_antecedentes.hipertencion_arterial==1? true : false
                    this.datosAntecedentes.tuberculosis_pulmonar = data.nota_antecedentes.tuberculosis_pulmonar==1? true : false
                    this.datosAntecedentes.obesidad = data.nota_antecedentes.obesidad==1? true : false
                    this.datosAntecedentes.neoplasias = data.nota_antecedentes.neoplasias==1? true : false
                    this.datosAntecedentes.cardiopatias = data.nota_antecedentes.cardiopatias==1? true : false
                    this.datosAntecedentes.familiares_tabaquismo = data.nota_antecedentes.familiares_tabaquismo==1? true : false
                    this.datosAntecedentes.familiares_alcoholismo = data.nota_antecedentes.familiares_alcoholismo==1? true : false
                    this.datosAntecedentes.familiares_dependencia_drogas_medicamentos = data.nota_antecedentes.familiares_dependencia_drogas_medicamentos==1? true : false
                    this.datosAntecedentes.familiares_alergias = data.nota_antecedentes.familiares_alergias==1? true : false
                    this.datosAntecedentes.malformaciones_congenitas = data.nota_antecedentes.malformaciones_congenitas==1? true : false
                    this.datosAntecedentes.disfunciones_familiares = data.nota_antecedentes.disfunciones_familiares==1? true : false
                    this.datosAntecedentes.medicos = data.nota_antecedentes.medicos==1? true : false
                    this.datosAntecedentes.quirurgicos = data.nota_antecedentes.quirurgicos==1? true : false
                    this.datosAntecedentes.transfucionales = data.nota_antecedentes.transfucionales==1? true : false
                    this.datosAntecedentes.personales_alergias = data.nota_antecedentes.personales_alergias==1? true : false
                    this.datosAntecedentes.personales_tabaquismo = data.nota_antecedentes.personales_tabaquismo==1? true : false
                    this.datosAntecedentes.personales_alcoholismo = data.nota_antecedentes.personales_alcoholismo==1? true : false
                    this.datosAntecedentes.personales_dependencia_drogas_medicamentos = data.nota_antecedentes.personales_dependencia_drogas_medicamentos==1? true : false
                    this.datosAntecedentes.familiares_otros = data.nota_antecedentes.familiares_otros==1? true : false
                    this.datosAntecedentes.personales_otros = data.nota_antecedentes.personales_otros==1? true : false
                    this.datosAntecedentes.familiares_cual = data.nota_antecedentes.familiares_cual==null? "" : data.nota_antecedentes.familiares_cual
                    this.datosAntecedentes.personales_cual = data.nota_antecedentes.personales_cual==null? "" : data.nota_antecedentes.personales_cual
                }
                this.loading=false
           } catch (error) {
                this.loading=false
                console.log(error);
           }
        },
        async obtenerNoPatologicos(value) {
            try {
                const res = await fetch(`${url}/api/historia_clinica/no_patologicos/${value}`, {
                    method: 'get',
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        'Content-Type': 'application/json',
                        'X-Requested-With': 'XMLHttpRequest',
                        'Authorization': "Bearer "+ localStorage.getItem("token"),
                    }
                });
                const data = await res.json();
                if (data.nota_no_patologicos) {
                    this.datosPatologicos = data.nota_no_patologicos
                    this.datosPatologicos.escolaridad_id = data.nota_no_patologicos.escolaridad_id.id
                    this.datosPatologicos.calidad_alimentacion_id = data.nota_no_patologicos.calidad_alimentacion_id.id
                    this.datosPatologicos.calidad_habitacion_id = data.nota_no_patologicos.calidad_habitacion_id.id
                    this.datosPatologicos.calidad_higiene_id = data.nota_no_patologicos.calidad_higiene_id.id

                    if (data.nota_no_patologicos.sedentarismo == 1) {
                        this.datosPatologicos.sedentarismo = true
                    } else {
                        this.datosPatologicos.sedentarismo = false
                    }
                }
                const pacienteEncrypt=localStorage.expedienteEncrypt
                const expediente =  CryptoJS.AES.decrypt(pacienteEncrypt, 'secret key heal4701tech').toString(CryptoJS.enc.Utf8);
                const paciente=JSON.parse(expediente)
                this.datosPatologicos.fecha_nacimiento=paciente.paciente.fecha_nacimiento
                this.datosPatologicos.religion=paciente.religion

                this.loading=false
                
            } catch (error) {
                this.loading=false
                console.log(error);
            }
        },
        
        clearData(){
            this.error=false
            this.errores={}
            this.errores2={}
            this.showDta=false
            this.datosPatologicos.password= ''
            this.datosPatologicos.ciudad= ""
            this.datosPatologicos.calidad_alimentacion_id= 1
            this.datosPatologicos.calidad_habitacion_id= 1
            this.datosPatologicos.calidad_higiene_id= 1
            this.datosPatologicos.sedentarismo= false
            this.datosPatologicos.tipo_relaciones_sexuales= ""
            this.datosPatologicos.no_parejas_sexuales= null
            this.datosPatologicos.escolaridad_id= null
            this.datosPatologicos.ocupacion= ""
            this.datosPatologicos.tiempo_ocupacion= ""
            this.datosPatologicos.actividad_empresa= ""
            this.datosPatologicos.fecha_nacimiento=""
            this.datosPatologicos.religion=""

            this.datosAntecedentes.password= ''
            this.datosAntecedentes.informante= ""
            this.datosAntecedentes.genero_id= null
            this.datosAntecedentes.diabetes_mellitus= false
            this.datosAntecedentes.hipertencion_arterial= false
            this.datosAntecedentes.tuberculosis_pulmonar= false
            this.datosAntecedentes.obesidad= false
            this.datosAntecedentes.neoplasias= false
            this.datosAntecedentes.cardiopatias= false
            this.datosAntecedentes.familiares_tabaquismo= false
            this.datosAntecedentes.familiares_alcoholismo= false
            this.datosAntecedentes.familiares_dependencia_drogas_medicamentos= false
            this.datosAntecedentes.familiares_alergias= false
            this.datosAntecedentes.malformaciones_congenitas= false
            this.datosAntecedentes.disfunciones_familiares= false
            this.datosAntecedentes.familiares_otros= false
            this.datosAntecedentes.familiares_cual= ""
            this.datosAntecedentes.medicos= false
            this.datosAntecedentes.quirurgicos= false
            this.datosAntecedentes.transfucionales= false
            this.datosAntecedentes.personales_alergias= false
            this.datosAntecedentes.personales_tabaquismo= false
            this.datosAntecedentes.personales_alcoholismo= false
            this.datosAntecedentes.personales_dependencia_drogas_medicamentos= false
            this.datosAntecedentes.personales_otros= false
            this.datosAntecedentes.personales_cual= ""
        },

        getfecha(){
            var today = new Date();
        var dd = ("0" + (today.getDate())).slice(-2);
        var mm = ("0" + (today.getMonth() +1)).slice(-2);
        var yyyy = today.getFullYear();
        this.datosAntecedentes.fecha = yyyy + '-' + mm + '-' + dd ;
        },

        changeExp(id){
            this.loading=true

            this.clearData()

            let exps = this.allExp
            let currentExp = exps.find(exp => exp.id == id)

            if (currentExp) {
                var expediente = CryptoJS.AES.encrypt(JSON.stringify(currentExp), 'secret key heal4701tech')
                localStorage.setItem('expedienteEncrypt', expediente);
                this.changeDta=currentExp.id
                this.getEscolaridades();
                this.getGeneros();
                this.obtenerEstadoCivil()
                this.obtenerPacientes();[]
                this.getfecha()
                this.setData()
            }
        },

        async allExpedientes() {
            try {
                const res = await fetch(`${url}/api/datos_medicos/`+this.idPacienteSeleccionado, {
                    method: 'get',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Requested-With': 'XMLHttpRequest',
                        'Authorization': "Bearer "+ localStorage.getItem("token"),
                    }
                });

                const data = await res.json();
                this.allExp=data.expedientes

            } catch (error) {
                console.log("el error es"+error);
            }
        },
        cerrarModal(){
            this.openSuccesUno=false
            this.openSuccesDos=false
        },
        cerrarModalError(){
            this.openSuccesUno=false
            this.openSuccesDos=false
            this.openError=false
            if (this.actualizar) {
                this.estado='editando'
                this.updating = false
            }
        }

    },

    mounted(){

        if (!(JSON.parse(CryptoJS.AES.decrypt(localStorage.expedienteEncrypt, 'secret key heal4701tech').toString(CryptoJS.enc.Utf8)).id==this.idExpediente)) {
            // ||(this.state.idPaciente!=JSON.parse(CryptoJS.AES.decrypt(localStorage.expedienteEncrypt, 'secret key heal4701tech').toString(CryptoJS.enc.Utf8)).paciente.id)
            Object.assign(this.$data, this.$options.data());
            this.changeDta=JSON.parse(CryptoJS.AES.decrypt(localStorage.expedienteEncrypt, 'secret key heal4701tech').toString(CryptoJS.enc.Utf8)).id
            const egreso=localStorage.egreso
            if (egreso) {
                const egresoDE =  JSON.parse(CryptoJS.AES.decrypt(egreso, 'secret key heal4701tech').toString(CryptoJS.enc.Utf8));
                this.isEgresado=egresoDE.egreso
            }else{
                router.push("/main")
            }

            this.done=false
        }
        this.timerMessages=true
        setTimeout(() => {
            this.timerMessages=false
        }, 4000);

        if (!this.done) {
            this.clearData()
            this.loading=true
            this.getEscolaridades();
            this.getGeneros();
            this.obtenerEstadoCivil()
            this.obtenerPacientes();
            this.getfecha()
            this.setData()  
        }

    },
}
</script>

<style scoped>
    @media only screen and (max-width: 992px) {
        .padding_stepper {
            margin-top: 29px;
        }
    }

    @media only screen and (min-width: 1200px) {
        .size_th {
            width: 20vw;
        }
    }

    @media only screen and (max-width: 1200px) {
        .size_th {
            width: 20vw;
        }
    }

    @media only screen and (max-width: 992px) {
        .size_th {
            width: 30vw;
        }
    }

    @media only screen and (max-width: 768px) {
        .size_th {
            width: 20vw;
        }
    }

    @media only screen and (max-width: 576px) {
        .size_th {
            width: auto;
        }
    }
</style>