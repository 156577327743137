<template>
    <b-container>
        <b-row>
            <b-col cols="12" sm="12" md="12" lg="12">
                <h2 class="txt_primary text-uppercase pl-3">Historia clínica</h2>
            </b-col>
        </b-row>

        <b-container class="margen">
            <b-row>
                <b-col class="p-0" cols="12" sm="12" md="12" lg="3">
                    <div class="container">
                        <section class="step-indicator">
                            <div class="step step1">
                                <button id="step1" @click="formHC('HCFormUno')" class="step-icon" />
                            </div>
                            <div class="indicator-line"></div>
                            <div class="step step2" v-if="hidde == false">
                                <button id="step2" @click="formHC('HCFormDos')" class="step-icon" />
                            </div>
                            <div class="indicator-line"></div>
                            <div class="step step3 active">
                                <button id="step3" class="step-icon" />
                                <!-- <button id="step3" @click="formHC('HCFormTres')" class="step-icon" /> -->
                            </div>
                        </section>
                    </div>
                </b-col>
            </b-row>

            <div class="sticky-top mt-3" v-if="(isEgresado && !loading)">
                <b-alert show dismissible variant="warning">
                    El paciente ha egresado desea ver la <b style="padding-top: 4px;">&rArr;</b> <strong
                        class="alert-nota-egreso" @click="verNotaDeEgreso()">nota de egreso</strong>
                </b-alert>
            </div>

            <div v-else-if="(estado == 'done')" class="sticky-top mt-3">
                <b-alert show dismissible variant="info">
                    <div class="d-flex" v-if="(interrogatorio.firma.is_firmado == 1)">
                        <i class="fa fa-floppy-o fa-lg my-auto pr-2" />
                        Nota firmada y guardada correctamente
                    </div>
                    <div class="d-flex" v-else-if="(interrogatorio.firma.is_firmado == 0)">
                        <i class="fa fa-floppy-o fa-lg my-auto pr-2" />
                        Guardado correctamente, se requiere firmar la nota
                    </div>
                </b-alert>
            </div>

            <p class="txt_secondary text-uppercase pt-3"><strong>Interrogatorio</strong></p>

            <p class="txt_secondary">Padecimiento actual</p>
            <b-form-textarea :disabled="((estado != 'f1' && estado != 'editando') || state.oldExp || isEgresado)"
                name="padecimientoAct" autocomplete="off" v-model="interrogatorio.padecimiento_actual"
                :state="VPadecimientoActual" class="txtArea_border" size="sm" no-resize rows="2" max-rows="2" />

            <p class="txt_secondary mt-2">Aparatos y sistemas</p>
            <b-form-textarea :disabled="((estado != 'f1' && estado != 'editando') || state.oldExp || isEgresado)"
                name="AparatosSis" autocomplete="off" v-model="interrogatorio.aparatos_sistemas" class="txtArea_border"
                size="sm" no-resize rows="2" max-rows="2"
                :placeholder="(estado != 'f1' && estado != 'editando') ? 'Sin registro' : ''" />

            <p class="txt_secondary mt-2">Síntomas generales</p>
            <b-form-textarea :disabled="((estado != 'f1' && estado != 'editando') || state.oldExp || isEgresado)"
                name="sistemasGenerales" autocomplete="off" v-model="interrogatorio.sintomas_generales"
                :state="VSintomasGenerales" class="txtArea_border" size="sm" no-resize rows="2" max-rows="2" />

            <p class="txt_secondary mt-2">Exámenes de laboratorio y gabinete</p>
            <b-form-textarea :disabled="((estado != 'f1' && estado != 'editando') || state.oldExp || isEgresado)"
                name="Examenes" autocomplete="off" v-model="interrogatorio.examenes_lab_gabninete" :state="VExamenesLab"
                class="txtArea_border" size="sm" no-resize rows="2" max-rows="2" />

            <p class="txt_secondary mt-2">Resultado de estudios auxiliares de diagnóstico previos</p>
            <b-form-textarea :disabled="((estado != 'f1' && estado != 'editando') || state.oldExp || isEgresado)"
                name="estudios" autocomplete="off" v-model="interrogatorio.estudios_auxiliares" :state="VEstudiosAux"
                class="txtArea_border" size="sm" no-resize rows="2" max-rows="2" />

            <p class="txt_secondary mt-2">Diagnósticos previos</p>
            <b-form-textarea :disabled="((estado != 'f1' && estado != 'editando') || state.oldExp || isEgresado)"
                name="diagnosticos" autocomplete="off" v-model="interrogatorio.diagnosticos_previos" :state="VDiagPrevios"
                class="txtArea_border" size="sm" no-resize rows="2" max-rows="2" />

            <p class="txt_secondary mt-2">Terapéutica previa y resultados obtenidos</p>
            <b-form-textarea :disabled="((estado != 'f1' && estado != 'editando') || state.oldExp || isEgresado)"
                name="terapeutica" autocomplete="off" v-model="interrogatorio.terapeutica_previa_resultados"
                :state="VTeraPreviaResultados" class="txtArea_border" size="sm" no-resize rows="2" max-rows="2" />

            <p class="txt_secondary mt-2">Diagnósticos definitivos</p>
            <b-form-textarea :disabled="((estado != 'f1' && estado != 'editando') || state.oldExp || isEgresado)"
                name="diagDefinitivos" autocomplete="off" v-model="interrogatorio.diagnosticos_definitivos"
                :state="VDiagDefinitivos" class="txtArea_border" size="sm" no-resize rows="2" max-rows="2" />

            <p class="txt_secondary mt-2">Tratamiento</p>
            <b-form-textarea :disabled="((estado != 'f1' && estado != 'editando') || state.oldExp || isEgresado)"
                name="tratamiento" autocomplete="off" v-model="interrogatorio.tratamiento" :state="VTratamiento"
                class="txtArea_border" size="sm" no-resize rows="2" max-rows="2" />

            <p class="txt_secondary mt-3">Exploración física</p>
            <b-row>
                <b-col cols="12" sm="12" md="5" lg="5">
                    <b-table-simple class="table-sm">
                        <b-tbody>
                            <b-tr>
                                <b-th class="size_th th_style">Peso</b-th>
                                <b-td class="border_table">
                                    <div class="d-flex">
                                        <b-form-input
                                            :disabled="((estado != 'f1' && estado != 'editando') || state.oldExp || isEgresado)"
                                            type="text" name="peso" autocomplete="off" maxlength="6"
                                            v-on:keypress="soloNumeros($event)" v-model="exploracion.peso" :state="VPeso"
                                            class="border-0" size="sm" />
                                        <span class="float-right px-2">
                                            <span class="txt_gray size-span">kg</span>
                                        </span>
                                    </div>
                                </b-td>
                            </b-tr>
                            <b-tr>
                                <b-th class="th_style">Talla</b-th>
                                <b-td class="border_table">
                                    <div class="d-flex">
                                        <b-form-input
                                            :disabled="((estado != 'f1' && estado != 'editando') || state.oldExp || isEgresado)"
                                            type="text" name="talla" autocomplete="off" maxlength="6"
                                            v-on:keypress="soloNumeros($event)" v-model="exploracion.talla" :state="VTalla"
                                            class="border-0" size="sm" />
                                        <span class="float-right px-2">
                                            <span class="txt_gray size-span">cm</span>
                                        </span>
                                    </div>
                                </b-td>
                            </b-tr>
                            <b-tr>
                                <b-th class="th_style">Temperatura</b-th>
                                <b-td class="border_table">
                                    <div class="d-flex">
                                        <b-form-input
                                            :disabled="((estado != 'f1' && estado != 'editando') || state.oldExp || isEgresado)"
                                            type="text" name="temperatura" autocomplete="off" maxlength="5"
                                            v-on:keypress="soloNumeros($event)" v-model="exploracion.temperatura"
                                            :state="VTemperatura" class="border-0" size="sm" />
                                        <span class="float-right px-2">
                                            <span class="txt_gray size-span">°C</span>
                                        </span>
                                    </div>
                                </b-td>
                            </b-tr>
                            <b-tr>
                                <b-th class="th_style">SPO2</b-th>
                                <b-td class="border_table">
                                    <div class="d-flex">
                                        <b-form-input
                                            :disabled="((estado != 'f1' && estado != 'editando') || state.oldExp || isEgresado)"
                                            type="text" name="SPO2" autocomplete="off" maxlength="6"
                                            v-on:keypress="soloNumeros($event)" v-model="exploracion.saturacion_oxigeno"
                                            :state="VSaturacionOxigeno" class="border-0" size="sm" />
                                        <span class="float-right px-2">
                                            <span class="txt_gray size-span">%</span>
                                        </span>
                                    </div>
                                </b-td>
                            </b-tr>
                        </b-tbody>
                    </b-table-simple>
                </b-col>
                <b-col cols="12" sm="12" md="7" lg="7">
                    <b-table-simple class="table-sm">
                        <b-tbody>
                            <b-tr>
                                <b-th class="th_style">Frecuencia cardiaca</b-th>
                                <b-td class="border_table">
                                    <div class="d-flex">
                                        <b-form-input
                                            :disabled="((estado != 'f1' && estado != 'editando') || state.oldExp || isEgresado)"
                                            type="text" name="FC" autocomplete="off" maxlength="3"
                                            v-on:keypress="soloNumeros($event)" v-model="exploracion.frecuencia_cardiaca"
                                            :state="VFrecCardiaca" class="border-0" size="sm" />
                                        <span class="float-right px-2">
                                            <span class="txt_gray size-span">/min</span>
                                        </span>
                                    </div>
                                </b-td>
                            </b-tr>
                            <b-tr>
                                <b-th class="th_style">Frecuencia respiratoria</b-th>
                                <b-td class="border_table">
                                    <div class="d-flex">
                                        <b-form-input
                                            :disabled="((estado != 'f1' && estado != 'editando') || state.oldExp || isEgresado)"
                                            type="text" name="FR" autocomplete="off" maxlength="3"
                                            v-on:keypress="soloNumeros($event)"
                                            v-model="exploracion.frecuencia_respiratoria" :state="VFrecRespiratoria"
                                            class="border-0" size="sm" />
                                        <span class="float-right px-2">
                                            <span class="txt_gray size-span">/min</span>
                                        </span>
                                    </div>
                                </b-td>
                            </b-tr>
                            <b-tr>
                                <b-th class="th_style size_th_precion">Presión arterial</b-th>
                                <b-td class="border_table">
                                    <div class="d-flex">
                                        <div>
                                            <b-form-input
                                                :disabled="((estado != 'f1' && estado != 'editando') || state.oldExp || isEgresado)"
                                                type="text" name="pa1" autocomplete="off" maxlength="3"
                                                v-on:keypress="soloNumeros($event)" v-model="presion1"
                                                class="text-center border-0" size="sm" placeholder="#" />
                                        </div>
                                        <div class="txt_gray px-1">/</div>
                                        <div>
                                            <b-form-input
                                                :disabled="((estado != 'f1' && estado != 'editando') || state.oldExp || isEgresado)"
                                                type="text" name="pa2" autocomplete="off" maxlength="3"
                                                v-on:keypress="soloNumeros($event)" v-model="presion2"
                                                :state="VPresionArterialDos" @keyup="tensionArterial()"
                                                class="text-center border-0" size="sm" placeholder="#" />
                                        </div>
                                    </div>
                                </b-td>
                            </b-tr>
                        </b-tbody>
                    </b-table-simple>
                </b-col>
            </b-row>

            <p class="txt_secondary mt-2">Inspección general</p>
            <b-form-textarea :disabled="((estado != 'f1' && estado != 'editando') || state.oldExp || isEgresado)"
                name="inspeccionGral" autocomplete="off" v-model="exploracion.inspeccion_general" :state="VInspeccionGral"
                class="txtArea_border" size="sm" no-resize rows="2" max-rows="2" />

            <p class="txt_secondary mt-2">Cabeza</p>
            <b-form-textarea :disabled="((estado != 'f1' && estado != 'editando') || state.oldExp || isEgresado)"
                name="cabeza" autocomplete="off" v-model="exploracion.cabeza" :state="VCabeza" class="txtArea_border"
                size="sm" no-resize rows="2" max-rows="2" />

            <p class="txt_secondary mt-2">Cuello</p>
            <b-form-textarea :disabled="((estado != 'f1' && estado != 'editando') || state.oldExp || isEgresado)"
                name="cuello" autocomplete="off" v-model="exploracion.cuello" :state="VCuello" class="txtArea_border"
                size="sm" no-resize rows="2" max-rows="2" />

            <p class="txt_secondary mt-2">Tórax</p>
            <b-form-textarea :disabled="((estado != 'f1' && estado != 'editando') || state.oldExp || isEgresado)" name="torax"
                autocomplete="off" v-model="exploracion.torax" :state="VTorax" class="txtArea_border" size="sm" no-resize
                rows="2" max-rows="2" />

            <p class="txt_secondary mt-2">Abdomen</p>
            <b-form-textarea :disabled="((estado != 'f1' && estado != 'editando') || state.oldExp || isEgresado)"
                name="abdomen" autocomplete="off" v-model="exploracion.abdomen" :state="VAbdomen" class="txtArea_border"
                size="sm" no-resize rows="2" max-rows="2" />

            <p class="txt_secondary mt-2">Columna vertebral</p>
            <b-form-textarea :disabled="((estado != 'f1' && estado != 'editando') || state.oldExp || isEgresado)"
                name="columna" autocomplete="off" v-model="exploracion.columna_vertebral" :state="VColumnaVert"
                class="txtArea_border" size="sm" no-resize rows="2" max-rows="2" />

            <p class="txt_secondary mt-2">Genitales externos</p>
            <b-form-textarea :disabled="((estado != 'f1' && estado != 'editando') || state.oldExp || isEgresado)"
                name="genitalesExt" autocomplete="off" v-model="exploracion.genitales_externos" :state="VGenitalesExt"
                class="txtArea_border" size="sm" no-resize rows="2" max-rows="2" />

            <p class="txt_secondary mt-2">Tacto rectal</p>
            <b-form-textarea :disabled="((estado != 'f1' && estado != 'editando') || state.oldExp || isEgresado)"
                name="tactoRectal" autocomplete="off" v-model="exploracion.tacto_rectal" :state="VTactoRectal"
                class="txtArea_border" size="sm" no-resize rows="2" max-rows="2" />

            <p class="txt_secondary mt-2" v-if="!hidde">Tacto vaginal</p>
            <b-form-textarea :disabled="((estado != 'f1' && estado != 'editando') || state.oldExp || isEgresado)"
                name="tactoVaginal" autocomplete="off" v-if="!hidde" v-model="exploracion.tacto_vaginal"
                :state="VTactoVaginal" class="txtArea_border" size="sm" no-resize rows="2" max-rows="2" />

            <p class="txt_secondary mt-2">Extremidades</p>
            <b-form-textarea :disabled="((estado != 'f1' && estado != 'editando') || state.oldExp || isEgresado)"
                name="extremidades" autocomplete="off" v-model="exploracion.extremidades" :state="VExtremidades"
                class="txtArea_border mb-3" size="sm" no-resize rows="2" max-rows="2" />

            <div v-if="exploracion.firma.is_firmado == 1" class="mb-4">
                <span class="txt_secondary">Nota firmada</span><br>
                <span class="txt_secondary size-span"><i class="fa fa-user mr-1"></i>Médico:
                    {{ exploracion.firma.firmado_por.nombre }}</span> <br>
                <span class="txt_secondary size-span"><i class="fa fa-calendar mr-1"></i>Fecha:
                    {{ exploracion.firma.fecha_firmado }}</span>
            </div>

            <b-row v-if="error && !state.oldExp" class="my-3">
                <b-col cols="12">
                    <div class="contenedor-de-errores pt-3 pb-1">
                        <ul class="lista-errores px-2">
                            <li v-for="(value, key) in errores" :key="key.value"><i class="fa fa-warning px-1" />|
                                {{ value[0] }} <br></li>
                            <li v-for="(value, key) in errores2" :key="key.value"><i class="fa fa-warning px-1" />|
                                {{ value[0] }} <br></li>
                        </ul>
                    </div>
                </b-col>
            </b-row>
            <div v-if="estado == 'editando'" class="d-flex justify-content-end mb-4 mt-3">
                <b-col cols="12" sm="12" md="5" lg="5">
                    <b-overlay :show="busyUpdate" rounded opacity="0.6" spinner-small spinner-variant="primary">
                        <button class="btn_formluario border-0 btn-block py-2 txt_white" @click="updateData()"
                            :disabled="busyUpdate">ACTUALIZAR</button>
                    </b-overlay>
                </b-col>
            </div>
            <div v-if="estado == 'editando'" class="d-flex justify-content-end mb-4 mt-3">
                <b-col cols="12" sm="12" md="5" lg="5">
                    <button @click="cancelEdit()" class="btn_formluario border-0 btn-block py-2 txt_white">CANCELAR</button>
                </b-col>
            </div>
            <div v-if="!isEgresado && !state.oldExp && estado == 'f1'" class="d-flex justify-content-end mb-4 mt-4">
                <!-- && !((hidde&&allNotas.historia_clinica.Antecedentes&&allNotas.historia_clinica.exploracion_fisica&&allNotas.historia_clinica.interrogatorio&&allNotas.historia_clinica.no_patologicos)||(!hidde&&allNotas.historia_clinica.Antecedentes&&allNotas.historia_clinica.exploracion_fisica&&allNotas.historia_clinica.interrogatorio&&allNotas.historia_clinica.no_patologicos&&allNotas.historia_clinica.control_parental&&allNotas.historia_clinica.gineco_obstetricos)) -->
                <!-- <b-col cols="12" sm="12" md="6" lg="6">
                    <button @click="volver()" class="btn_formluario border-0 btn-block py-2 txt_white">ANTERIOR</button>
                </b-col> -->
                <b-col cols="12" sm="12" md="6" lg="6">
                    <b-overlay :show="busy" rounded opacity="0.6" spinner-small spinner-variant="primary">
                        <button class="btn_formluario border-0 btn-block py-2 txt_white" :disabled="busy"
                            @click="save()">GUARDAR</button>
                    </b-overlay>
                </b-col>
            </div>
            <div v-if="(idResponsable === usuario.id && estado == 'done' && exploracion.firma.is_firmado == 0 && !isEgresado && !state.oldExp)"
                class="d-flex justify-content-end mb-4 mt-4">
                <b-col cols="12" sm="12" md="6" lg="6">
                    <button @click="volver()" class="btn_formluario border-0 btn-block py-2 txt_white">ANTERIOR</button>
                </b-col>
                <b-col cols="12" sm="12" md="6" lg="6">
                    <b-overlay :show="busy" rounded opacity="0.6" spinner-small spinner-variant="primary">
                        <button @click="firmar()" class="btn_formluario border-0 btn-block py-2 txt_white">FIRMAR Y
                            GUARDAR</button>
                    </b-overlay>
                </b-col>
            </div>

            <div v-if="(exploracion.firma.is_firmado == 1 || (idResponsable != usuario.id && estado == 'done' || isEgresado) || state.oldExp)"
                class="d-flex justify-content-start mb-4 mt-4">
                <b-col cols="12" sm="12" md="6" lg="6">
                    <button @click="volver()" class="btn_formluario border-0 btn-block py-2 txt_white">ANTERIOR</button>
                </b-col>
            </div>

            <FirmaPassword :abrir="openFirmaPsw" @firma_psw="firma_psw($event)" @cerrarModalFirmarPswd="cerrarModalFirmarPswd()"/>
            <Success :abrir="openSuccess || openSuccesUno && openSuccesDos" @cerrarModal="cerrarModal()" />
            <Error :abrir="openError" @cerrarModalError="cerrarModalError()" />
            <AltertPresionArterial :abrir="openAlertPresion"
                @cerrarModalAlertPresionArterial="cerrarModalAlertPresionArterial()" />
            <DatosPreguardados :abrirPreSave="openPreSave" />
            <Loading :show="loading" />
        </b-container>



        <div class="sticky-container"
            v-if="((interrogatorio.expediente_id != null || exploracion.expediente_id != null) && !openFirmaPsw && !openSuccess && !openSuccesUno && !openSuccesDos && !openError && !openAlertPresion && !openPreSave && !loading)">
            <ul class="sticky">
                <!-- <li @click="preGuardar()" v-if="(!(allNotas.historia_clinica.Antecedentes && allNotas.historia_clinica.exploracion_fisica&&allNotas.historia_clinica.interrogatorio&&allNotas.historia_clinica.no_patologicos) && !isEgresado)">
                    <img :src="require('../../../assets/icons/guardar.png')" width="50" height="32">
                    <p>
                        <a>Preguardar</a>
                    </p>
                </li> -->
                <li @click="imprimir()"
                    v-if="(allNotas.historia_clinica.Antecedentes && allNotas.historia_clinica.exploracion_fisica && allNotas.historia_clinica.interrogatorio && allNotas.historia_clinica.no_patologicos)">
                    <img :src="require('../../../assets/icons/imprimir.png')" width="50" height="32">
                    <p>
                        <a>Imprimir</a>
                    </p>
                </li>
                <li v-if="(estado == 'done' && !state.oldExp && exploracion.firma.is_firmado == 0 && !isEgresado)"
                    @click="editar()">
                    <img :src="require('../../../assets/icons/editar.png')" width="57" height="32">
                    <p>
                        <a>Editar</a>
                    </p>
                </li>
            </ul>
        </div>
    </b-container>
</template>

<script>
import router from "../../../router";
import FirmaPassword from "@/components/Modals/FirmaPassword.vue"
import AltertPresionArterial from '@/components/Modals/AltertPresionArterial.vue';
import { url } from "../../../../settings";
import Success from "@/components/Modals/Success.vue";
import Error from "@/components/Modals/Error.vue";
import CryptoJS from 'crypto-js'
import DatosPreguardados from "@/components/Modals/DatosPreguardados.vue"
import Loading from '@/components/Loader/Loading.vue'
import Vue from 'vue'
import PDFMerger from "pdf-merger-js";

export default {
    name: 'FormTres',

    components: { Success, Error, FirmaPassword, AltertPresionArterial, DatosPreguardados, Loading },

    computed: {
        VPadecimientoActual() {
            return this.interrogatorio.padecimiento_actual != ""
        },

        VAparatosSistemas() {
            return this.interrogatorio.aparatos_sistemas != ""
        },

        VSintomasGenerales() {
            return this.interrogatorio.sintomas_generales != ""
        },

        VExamenesLab() {
            return this.interrogatorio.examenes_lab_gabninete != ""
        },

        VEstudiosAux() {
            return this.interrogatorio.estudios_auxiliares != ""
        },

        VDiagPrevios() {
            return this.interrogatorio.diagnosticos_previos != ""
        },

        VTeraPreviaResultados() {
            return this.interrogatorio.terapeutica_previa_resultados != ""
        },

        VDiagDefinitivos() {
            return this.interrogatorio.diagnosticos_definitivos != ""
        },

        VTratamiento() {
            return this.interrogatorio.tratamiento != ""
        },

        /* Exploración física */

        VPeso() {
            return this.exploracion.peso != ''
        },

        VTalla() {
            return this.exploracion.talla != ''
        },

        VTemperatura() {
            return this.exploracion.temperatura != ''
        },

        VSaturacionOxigeno() {
            return this.exploracion.saturacion_oxigeno != ''
        },

        VFrecCardiaca() {
            return this.exploracion.frecuencia_cardiaca != ''
        },

        VFrecRespiratoria() {
            return this.exploracion.frecuencia_respiratoria != ''
        },

        VPresionArterialDos() {
            return this.presion2 != "" && this.presion1 != ""
        },

        VInspeccionGral() {
            return this.exploracion.inspeccion_general != ""
        },

        VCabeza() {
            return this.exploracion.cabeza != ""
        },

        VCuello() {
            return this.exploracion.cuello != ""
        },

        VTorax() {
            return this.exploracion.torax != ""
        },

        VAbdomen() {
            return this.exploracion.abdomen != ""
        },

        VColumnaVert() {
            return this.exploracion.columna_vertebral != ""
        },

        VGenitalesExt() {
            return this.exploracion.genitales_externos != ""
        },

        VTactoRectal() {
            return this.exploracion.tacto_rectal != ""
        },

        VTactoVaginal() {
            return this.exploracion.tacto_vaginal != ""
        },

        VExtremidades() {
            return this.exploracion.extremidades != ""
        },

        VPlanManejo() {
            return this.exploracion.plan_manejo_tratamiento != ""
        },

        VPronosticos() {
            return this.exploracion.pronostico_id != null
        }
    },

    data: () => ({
        pie: {
            state: "",
            fecha: "",
            hora: ""
        },

        busy: false,
        estado: "f1",//f1: formulario uno, done: formularios completos, editando: para editar datos

        isEgresado: false,
        dtaFirma: {
            expediente_id: null,
            password: ""
        },
        usuario: {
            id: 0,
            nombre: "",
            email: "",
            celular: "",
            perfil: {
                id: 1,
                nombre: ""
            },
            cedula: "",
            especialidad: {
                especialidad: {
                    id: null,
                    nombre: ""
                },
                cedula: ""
            }
        },
        idResponsable: null,

        state: {
            idExpediente: "",
            idPaciente: "",
            oldExp: false
        },

        // for update
        busyUpdate: false,

        loading: true,

        openSuccesUno: false,
        openSuccesDos: false,


        allNotas: {
            egreso: false,
            evolucion: false,
            historia_clinica: false,
            ingreso: false,
            post_operatoria: false,
            pre_anestesica: false,
            pre_operatoria: false
        },
        idPacienteSeleccionado: '',
        cargado: false,
        openPreSave: false,
        showDta: false,
        showDtaDisable: false,
        datosPreGuardados: [],
        openFirmaPsw: false,
        btnGuardar: false,
        btnFirmar: true,
        password: '',

        hayError: false,
        hayErrorf2: false,
        hayErrorf1: false,
        error: false,
        errores: "",
        errores2: "",
        openSuccess: false,
        openError: false,
        presion2: "",
        presion1: "",

        interrogatorio: {
            password: '',
            expediente_id: null,
            padecimiento_actual: "",
            aparatos_sistemas: "",
            sintomas_generales: "",
            examenes_lab_gabninete: "",
            estudios_auxiliares: "",
            diagnosticos_previos: "",
            terapeutica_previa_resultados: "",
            diagnosticos_definitivos: "",
            tratamiento: "",
            resultados: "",
            firma: {
                fecha_firmado: null,
                firmado_por: null,
                is_firmado: 0
            },
        },
        no_expediente:"",
        exploracion: {
            password: '',
            expediente_id: "",
            peso: "",
            talla: "",
            frecuencia_cardiaca: "",
            frecuencia_respiratoria: "",
            presion_arterial: "",
            temperatura: "",
            saturacion_oxigeno: "",
            inspeccion_general: "",
            cabeza: "",
            torax: "",
            abdomen: "",
            columna_vertebral: "",
            genitales_externos: "",
            tacto_rectal: "",
            tacto_vaginal: "",
            extremidades: "",
            cuello: "",
            plan_manejo_tratamiento: "",
            pronostico_id: null,
            firma: {
                fecha_firmado: null,
                firmado_por: {
                    id: null,
                    nombre: ""
                },
                is_firmado: 0
            },
        },

        pronosticos: [],
        hidde: false,
        genero: null,
        openAlertPresion: false,
        done: false,
        datosAntecedentes: {
            id: null,
            password: '',
            expediente_id: null,
            fecha: "",
            informante: "",
            genero_id: null,
            diabetes_mellitus: false,
            hipertencion_arterial: false,
            tuberculosis_pulmonar: false,
            obesidad: false,
            neoplasias: false,
            cardiopatias: false,
            familiares_tabaquismo: false,
            familiares_alcoholismo: false,
            familiares_dependencia_drogas_medicamentos: false,
            familiares_alergias: false,
            malformaciones_congenitas: false,
            disfunciones_familiares: false,
            familiares_otros: false,
            familiares_cual: "",
            medicos: false,
            quirurgicos: false,
            transfucionales: false,
            personales_alergias: false,
            personales_tabaquismo: false,
            personales_alcoholismo: false,
            personales_dependencia_drogas_medicamentos: false,
            personales_otros: false,
            personales_cual: ""
        },

        datosPatologicos: {
            id: null,
            password: '',
            fecha_nacimiento: "",
            expediente_id: "",
            ciudad: "",
            calidad_alimentacion_id: 1,
            calidad_habitacion_id: 1,
            calidad_higiene_id: 1,
            sedentarismo: false,
            tipo_relaciones_sexuales: "",
            no_parejas_sexuales: null,
            escolaridad_id: null,
            ocupacion: "",
            tiempo_ocupacion: "",
            actividad_empresa: "",
            religion: "",
        },
    }),


    methods: {
        verNotaDeEgreso() {
            this.$emit('toEgreso')
        },

        //  pa update update update update update update update update 
        cancelEdit() {
            this.error = false
            this.errores = []
            this.setData()
        },

        editar() {
            this.estado = 'editando'
        },

        async updateData() {
            this.error = false
            this.errores = []
            this.errores2 = []
            this.busyUpdate = true
            this.updateInterrogatorio(this.interrogatorio.id)

        },

        async updateInterrogatorio(id) {
            try {
                const res = await fetch(`${url}/api/historia_clinica/interrogatorio/update/${id}`, {
                    method: 'put',
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        'Content-Type': 'application/json',
                        'X-Requested-With': 'XMLHttpRequest',
                        'Authorization': "Bearer " + localStorage.getItem("token"),
                    },
                    body: JSON.stringify(this.interrogatorio)
                });
                const data = await res.json()
                Vue.prototype.$error500 = res.status === 500
                var warn = false
                var err = []
                var promise = Promise.resolve(data)
                await promise.then(function (val) {
                    if (val.class == "success") {
                        warn = true
                    } else {
                        err = val.errors
                    }
                });
                if (warn) {
                    this.openSuccesUno = true
                } else {
                    this.openError = true
                    this.error = true
                    this.errores = err
                    this.busyUpdate = false
                }
                this.allNotas.historia_clinica.exploracion_fisica ? this.updateExploracion_fisica(this.exploracion.id) : this.saveExploracion_fisca()
            } catch (error) {
                this.busyUpdate = false
                this.openError = true
                console.log(error)
            }
        },

        async updateExploracion_fisica(id) {
            if (this.exploracion.tacto_vaginal == "") {
                this.tacto_vaginal = "no aplica"
            }

            this.exploracion.presion_arterial = this.presion1 + "/" + this.presion2
            try {
                const res = await fetch(`${url}/api/historia_clinica/exploracion_fisica/update/${id}`, {
                    method: 'put',
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        'Content-Type': 'application/json',
                        'X-Requested-With': 'XMLHttpRequest',
                        'Authorization': "Bearer " + localStorage.getItem("token"),
                    },
                    body: JSON.stringify(this.exploracion)
                });
                const data = await res.json()
                Vue.prototype.$error500 = res.status === 500
                var warn = false
                var err = []
                var promise = Promise.resolve(data)
                await promise.then(function (val) {
                    console.log(val);
                    if (val.class == "success") {
                        warn = true
                    } else {
                        err = val.errors
                    }
                });
                if (warn) {
                    this.openSuccesDos = true
                    this.busyUpdate = false
                    this.setData()
                } else {
                    this.openError = true
                    this.error = true
                    this.errores2 = err
                    this.busyUpdate = false
                }
            } catch (error) {
                this.busyUpdate = false
                this.openError = true
            }
        },

        // update update update update update update update update update 
        // /////////////

        toastPrint(variant = null) {
            this.$bvToast.toast('Seleccione un paciente', {
                title: "¡Error!",
                variant: variant,
                solid: true
            })
        },

        async imprimir() {
            // var can = false;
            var este = this;
            this.loading = true
            let urlim = `${url}/api/pacientes/pdf/${this.idPacienteSeleccionado}`;
            return fetch(urlim, {
                method: 'get',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Requested-With': 'XMLHttpRequest',
                    'Authorization': "Bearer "+ localStorage.getItem("token"),
                }
            }).then(function(resp){
                return resp.blob("paciente.pdf")
            }).then(function(blob) {
                este.downloadPDF(blob)

            })
        },
        async downloadPDF(blob){
            try {
                var merger = new PDFMerger()
                await merger.add(URL.createObjectURL(blob))
                await merger.save(this.no_expediente+" Historia clínica");
                this.loading = false
            } catch (error) {
                this.loading = false
            }
        },
        tensionArterial() {
            if (this.presion2.length >= 2 && this.presion1.length >= 2) {
                setTimeout(() => {
                    if (this.presion1 < 100 || this.presion1 > 140 || this.presion2 < 60 || this.presion2 > 90) {
                        this.openAlertPresion = true
                    }
                }, 500);
            }
        },

        firmar() {
            this.openFirmaPsw = true;
        },

        formHC(value) {
            if (this.estado == 'editando') {
                localStorage.setItem("view", value)
                this.$emit('changeHC', value);
            } else {
                localStorage.setItem("view", value)
                this.$emit('changeHC', value);
                this.cancelEdit()
            }

        },

        soloNumeros(evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault();
            } else {
                return true;
            }
        },

        save() {
            // if exploracion_fisica - interrogatorio
            this.busy = true
            this.openSuccesUno = false
            this.openSuccesDos = false
            this.allNotas.historia_clinica.interrogatorio ? this.updateInterrogatorio(this.interrogatorio.id) : this.saveInterrogatorio()
        },

        async saveInterrogatorio() {
            this.error = false
            this.errores = []
            this.interrogatorio.password = this.password
            try {
                const res = await fetch(`${url}/api/historia_clinica/interrogatorio/store`, {
                    method: 'post',
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        "Content-Type": "application/json",
                        "X-Requested-With": "XMLHttpRequest",
                        'Authorization': "Bearer " + localStorage.getItem("token"),
                    },
                    body: JSON.stringify(this.interrogatorio),
                });
                const data = res.json();
                Vue.prototype.$error500 = res.status === 500
                var warn = false;
                var err = []
                var promise = Promise.resolve(data);
                var id;
                await promise.then(function (val) {
                    if (val.class == "success") {
                        warn = true
                        id = val.interrogatorio.id
                    } else {
                        err = val.errors
                    }
                });
                if (warn) {
                    this.interrogatorio.id = id
                    this.openSuccesUno = true
                    this.allNotas.historia_clinica.interrogatorio = true
                } else {
                    this.errores = err
                    this.error = true
                    this.openError = true
                }
                this.allNotas.historia_clinica.exploracion_fisica ? this.updateExploracion_fisica(this.exploracion.id) : this.saveExploracion_fisca()
            } catch (error) {
                console.log(error);
            }
        },

        async saveExploracion_fisca() {
            this.error = false
            this.errores2 = []
            this.exploracion.password = this.password
            if (this.exploracion.tacto_vaginal == "") {
                this.tacto_vaginal = "no aplica"
            }

            this.exploracion.presion_arterial = this.presion1 + "/" + this.presion2
            try {
                const res = await fetch(`${url}/api/historia_clinica/exploracion_fisica/store`, {
                    method: 'post',
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        "Content-Type": "application/json",
                        "X-Requested-With": "XMLHttpRequest",
                        'Authorization': "Bearer " + localStorage.getItem("token"),
                    },
                    body: JSON.stringify(this.exploracion),
                });
                const data = res.json();
                Vue.prototype.$error500 = res.status === 500
                var warn = false;
                var err = []
                var promise = Promise.resolve(data);
                await promise.then(function (val) {
                    if (val.class == "success") {
                        warn = true
                    } else {
                        err = val.errors
                    }
                });
                if (warn) {
                    this.openSuccesDos = true
                    this.setData()
                } else {
                    this.error = true
                    this.errores2 = err
                    this.openError = true;
                }
                this.busy = false
            } catch (error) {
                console.log(error);
                this.busy = false
            }
        },

        async pronostico() {
            try {
                const res = await fetch(`${url}/api/catalogos/pronostico`, {
                    method: 'get',
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        'Content-Type': 'application/json',
                        'X-Requested-With': 'XMLHttpRequest',
                        'Authorization': "Bearer " + localStorage.getItem("token"),
                    }
                });
                const data = await res.json();
                this.pronosticos = data.pronostico.map(item => {
                    return {
                        value: item.id,
                        text: item.nombre
                    };
                });
                this.pronosticos.unshift({ value: null, text: 'seleccionar' },)
            } catch (error) {
                console.log("el error es" + error);
            }
        },

        /* Obtener datos de formulario 3 */
        async obtenerInterrogatorio(value) {

            try {
                const res = await fetch(`${url}/api/historia_clinica/interrogatorio/${value}`, {
                    method: 'get',
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        'Content-Type': 'application/json',
                        'X-Requested-With': 'XMLHttpRequest',
                        'Authorization': "Bearer " + localStorage.getItem("token"),
                    }
                });
                const data = await res.json();
                if (data.nota_interogatorio) {
                    this.interrogatorio = data.nota_interogatorio
                }
                this.loading = false
            } catch (error) {
                console.log(error);
            }
        },
        clearData() {
            this.error = false
            this.errores = {}
            this.errores2 = {}
            this.interrogatorio.password = ''
            this.interrogatorio.expediente_id = null
            this.interrogatorio.padecimiento_actual = ""
            this.interrogatorio.aparatos_sistemas = ""
            this.interrogatorio.sintomas_generales = ""
            this.interrogatorio.examenes_lab_gabninete = ""
            this.interrogatorio.estudios_auxiliares = ""
            this.interrogatorio.diagnosticos_previos = ""
            this.interrogatorio.terapeutica_previa_resultados = ""
            this.interrogatorio.diagnosticos_definitivos = ""
            this.interrogatorio.tratamiento = ""
            this.interrogatorio.resultados = ""

            this.exploracion.password = ''
            this.exploracion.expediente_id = null
            this.exploracion.peso = ""
            this.exploracion.talla = ""
            this.exploracion.frecuencia_cardiaca = ""
            this.exploracion.frecuencia_respiratoria = ""
            this.exploracion.presion_arterial = ""
            this.exploracion.temperatura = ""
            this.exploracion.saturacion_oxigeno = ""
            this.exploracion.inspeccion_general = ""
            this.exploracion.cabeza = ""
            this.exploracion.torax = ""
            this.exploracion.abdomen = ""
            this.exploracion.columna_vertebral = ""
            this.exploracion.genitales_externos = ""
            this.exploracion.tacto_rectal = ""
            this.exploracion.tacto_vaginal = ""
            this.exploracion.extremidades = ""
            this.exploracion.cuello = ""
            this.presion1 = ""
            this.presion2 = ""
        },
        async obtenerExploracionFisica(value) {
            try {
                const res = await fetch(`${url}/api/historia_clinica/exploracion_fisica/${value}`, {
                    method: 'get',
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        'Content-Type': 'application/json',
                        'X-Requested-With': 'XMLHttpRequest',
                        'Authorization': "Bearer " + localStorage.getItem("token"),
                    }
                });
                if (!res.ok) {
                    const message = `An error has occured: ${res.status} - ${res.statusText}`;
                    throw new Error(message);
                }
                const data = await res.json();
                var ta = ""
                var ta1 = ""
                if (data.nota_exploracion_fisica) {
                    window.scrollTo({ top: 0, behavior: 'smooth' })
                    this.exploracion = data.nota_exploracion_fisica
                    ta = this.exploracion.presion_arterial
                    ta1 = ta.indexOf("/")
                    this.presion1 = ta.substring(0, ta1)
                    this.presion2 = ta.substring(ta1 + 1, ta.length)
                    this.pie.state = false
                }
                this.loading = false

            } catch (error) {
                window.scrollTo({ top: 0, behavior: 'smooth' })
                this.loading = false
                console.log(error);
            }
        },
        async obtenerControlPrenatal(value) {

            try {
                const res = await fetch(`${url}/api/historia_clinica/control_prenatal/${value}`, {
                    method: 'get',
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        'Content-Type': 'application/json',
                        'X-Requested-With': 'XMLHttpRequest',
                        'Authorization': "Bearer " + localStorage.getItem("token"),
                    }
                });
                if (!res.ok) {
                    const message = `An error has occured: ${res.status} - ${res.statusText}`;
                    throw new Error(message);
                }
                const data = await res.json();

                var ta = ""
                var ta1 = ""

                if (data.nota_control_prenatal) {
                    ta = data.nota_control_prenatal.tension_arterial
                    ta1 = ta.indexOf("/")
                    this.presion1 = ta.substring(0, ta1)
                    this.presion2 = ta.substring(ta1 + 1, ta.length)

                    this.exploracion.peso = data.nota_control_prenatal.peso
                    this.exploracion.talla = data.nota_control_prenatal.talla
                    this.exploracion.frecuencia_cardiaca = data.nota_control_prenatal.pulso
                    this.exploracion.frecuencia_respiratoria = data.nota_control_prenatal.respiracion
                    this.exploracion.presion_arterial = data.nota_control_prenatal.presion_arterial
                    this.exploracion.temperatura = data.nota_control_prenatal.temperatura

                }
                this.loading = false
            } catch (error) {
                this.loading = false
                console.log(error);
            }
        },
        verificarPreGuardado() {
            try {
                if (localStorage.preGuardadoHCF3) {
                    console.log("si hay datos");
                    this.datosPreGuardados = JSON.parse(localStorage.preGuardadoHCF3)
                    console.log(this.datosPreGuardados);
                }
            } catch (error) {
                console.log(error);
            }
            for (let val of this.datosPreGuardados) {
                if (val.exploracion || val.interrogatorio) {
                    if (this.exploracion.expediente_id === val.exploracion.expediente_id) {
                        if ((this.showDta === true)) {
                            this.interrogatorio = val.interrogatorio
                            this.exploracion = val.exploracion
                            var ta = this.exploracion.presion_arterial
                            var ta1 = ta.indexOf("/")
                            this.presion1 = ta.substring(0, ta1)
                            this.presion2 = ta.substring(ta1 + 1, ta.length)
                            this.showDtaDone = true
                        } else {
                            if (localStorage.view === "HCFormTres") {
                                this.openPreSave = true
                            }
                        }
                    }
                }
            }
        },
        async getNotas() {

            try {
                const res = await fetch(`${url}/api/notas/${this.interrogatorio.expediente_id}`, {
                    method: 'get',
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        'Content-Type': 'application/json',
                        'X-Requested-With': 'XMLHttpRequest',
                        'Authorization': "Bearer " + localStorage.getItem("token"),
                    }
                });
                if (!res.ok) {
                    const message = `An error has occured: ${res.status} - ${res.statusText}`;
                    throw new Error(message);
                }
                const data = await res.json();
                this.allNotas = data.notas
                this.estado = this.allNotas.historia_clinica.exploracion_fisica && this.allNotas.historia_clinica.interrogatorio ? 'done' : 'f1'
                this.obtenerInterrogatorio(this.interrogatorio.expediente_id)
                this.obtenerExploracionFisica(this.exploracion.expediente_id)
                if (this.allNotas.historia_clinica.control_prenatal) {
                    this.obtenerControlPrenatal(this.interrogatorio.expediente_id)
                } else if (this.allNotas.ingreso) {
                    this.getIngreso()
                }
                this.done = true
            } catch (error) {
                console.log(error);
            }
        },
        async getIngreso() {
            try {
                const res = await fetch(`${url}/api/nota_ingreso/` + this.interrogatorio.expediente_id, {
                    method: 'get',
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        'Content-Type': 'application/json',
                        'X-Requested-With': 'XMLHttpRequest',
                        'Authorization': "Bearer " + localStorage.getItem("token"),
                    }
                });
                const data = await res.json();

                var ta = data.nota_ingreso.tension_arterial
                var ta1 = ta.indexOf("/")
                this.tension1 = ta.substring(0, ta1)
                this.tension2 = ta.substring(ta1 + 1, ta.length)
                this.exploracion.frecuencia_cardiaca = data.nota_ingreso.frecuencia_cardiaca
                this.exploracion.frecuencia_respiratoria = data.nota_ingreso.frecuencia_respiratoria
                this.exploracion.peso = data.nota_ingreso.peso
                this.exploracion.talla = data.nota_ingreso.talla
                this.exploracion.temperatura = data.nota_ingreso.temperatura
                this.exploracion.saturacion_oxigeno = data.nota_ingreso.saturacion_oxigeno
                this.pie.fecha = data.nota_ingreso.fecha_ingreso
                this.pie.hora = data.nota_ingreso.hora_ingreso

            } catch (error) {
                console.log(error);
            }
        },

        volver() {

            if (this.hidde) {
                this.formHC('HCFormUno')
            } else {
                this.formHC('HCFormDos')
            }

        },

        setData() {
            this.pronostico()
            const pacienteEncrypt = localStorage.expedienteEncrypt
            const expediente = CryptoJS.AES.decrypt(pacienteEncrypt, 'secret key heal4701tech').toString(CryptoJS.enc.Utf8);
            const paciente = JSON.parse(expediente)
            this.state.idExpediente = paciente.id
            this.state.idPaciente = this.idPacienteSeleccionado
            this.no_expediente=paciente.no_expediente
            this.idResponsable = paciente.medico.id

            if (paciente.paciente.lastExpediente.expediente_id != this.state.idExpediente) {
                this.state.oldExp = true
            } else {
                this.state.oldExp = false
            }


            this.interrogatorio.expediente_id = paciente.id
            this.exploracion.expediente_id = paciente.id
            this.idPacienteSeleccionado = paciente.paciente.id
            if (paciente.paciente.genero.id == 2) {
                this.hidde = true
                this.exploracion.tacto_vaginal = "no Aplica"
            } else {
                this.hidde = false
            }
            this.getNotas()
        },

        async getUserData() {
            try {
                const res = await fetch(`${url}/api/usuarios/logged`, {
                    method: 'get',
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        'Content-Type': 'application/json',
                        'X-Requested-With': 'XMLHttpRequest',
                        'Authorization': "Bearer " + localStorage.getItem("token"),
                    }
                });
                const data = await res.json();
                try {
                    this.usuario = data.usuario
                } catch (error) {
                    console.log(error);
                }
            } catch (error) {
                console.log("");
            }
        },

        async firmarAllNotas() {
            try {
                this.dtaFirma.expediente_id = this.exploracion.expediente_id
                const res = await fetch(`${url}/api/datos_medicos/firmarAllHistoriaClinica`, {
                    method: 'post',
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        "Content-Type": "application/json",
                        "X-Requested-With": "XMLHttpRequest",
                        'Authorization': "Bearer " + localStorage.getItem("token"),
                    },
                    body: JSON.stringify(this.dtaFirma),
                });
                const data = res.json();
                Vue.prototype.$error500 = res.status === 500
                var warn = false;
                var err = []
                var promise = Promise.resolve(data);
                await promise.then(function (val) {
                    if (val.class == "success") {
                        warn = true
                    } else {
                        err = val.errors
                    }
                });
                if (warn) {
                    this.error = false
                    this.openSuccess = true
                    this.setData()
                } else {
                    this.openError = true
                    this.errores = err
                    this.error = true
                }
                this.busy = false
            } catch (error) {
                console.log("error");
                console.log(error);
            }
        },
        cerrarModal() {
            this.openSuccesUno = false
            this.openSuccesDos = false
            this.openSuccess = false
        },
        cerrarModalError() {
            this.openError = false
        },
        cerrarModalAlertPresionArterial() {
            this.openAlertPresion = false
        },
        firma_psw(psw) {
            this.busy = true
            this.dtaFirma.password = psw
            this.firmarAllNotas()
            this.openFirmaPsw = false
        },
        cerrarModalFirmarPswd(){
            this.openFirmaPsw = false
        }
    },

    mounted() {
        if (!this.done) {
            const egreso = localStorage.egreso
            if (egreso) {
                const egresoDE = JSON.parse(CryptoJS.AES.decrypt(egreso, 'secret key heal4701tech').toString(CryptoJS.enc.Utf8));
                this.isEgresado = egresoDE.egreso
            } else {
                router.push("/main")
            }
            this.getUserData()
            this.setData()
            this.loading = true
        }
    },
}
</script>

<style scoped>@media only screen and (min-width: 1100px) {
    .size_th_precion {
        width: 15vw;
    }
}

@media only screen and (max-width: 992px) {
    .size_th_precion {
        width: 20vw;
    }
}

@media only screen and (max-width: 768px) {
    .size_th_precion {
        width: 15vw;
    }
}

@media only screen and (max-width: 760px) {
    .size_th_precion {
        width: 30vw;
    }
}

/*  */

@media only screen and (min-width: 760px) {
    .size_th {
        width: 8vw;
    }
}

@media only screen and (max-width: 760px) {
    .size_th {
        width: 30vw;
    }
}

.margen {
    margin-top: 29px;
}</style>