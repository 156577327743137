<template>
    <b-container>
        <b-row class="justify-content-between">
            <b-col cols="11">
                <h2 class="font-size-vw txt_primary text-uppercase pl-3">Hoja de valoración pre-anestésica</h2>   
            </b-col>
            <b-col class="d-none d-lg-block" cols="1">
                <div class="float-right my-auto">
                    <Foto/>
                </div>
            </b-col>
        </b-row>
        <b-container>
            <b-row>
                <b-col class="p-0 padding_stepper" cols="12" sm="12" md="12" lg="3">
                    <div class="container">
                        <section class="step-indicator">
                            <div class="step step1 active">
                                <!-- <button id="step1" @click="formNotaPreA('NotaPreAnestesicaUno')" class="step-icon" /> -->
                                <button id="step1" class="step-icon" />
                            </div>
                            <div class="indicator-line"></div>
                            <div class="step step2">
                                <button id="step2" @click="formNotaPreA('NotaPreAnestesicaDos')" class="step-icon" />
                            </div>
                        </section>
                    </div>
                </b-col> 
            </b-row>
            <!-- LISTADO DE EXPEDIENTES -->
            <p class="txt_secondary text-uppercase mt-1"><strong>Lista de expedientes</strong></p>
            <b-table-simple class="table-sm border_table">
                <b-tbody>  
                    <b-tr>
                        <b-th class="th_style size_th">Expedientes</b-th>
                        <b-td class="border_table">
                            <b-form-select :disabled="!updating" v-model="idExpediente" @change="changeExp(idExpediente)" class="border-0" size="sm">
                                <template>
                                    <b-form-select-option v-for="(all) of allExp" :key="all.id" :value="all.id">{{all.no_expediente}}</b-form-select-option>
                                </template>
                            </b-form-select>
                        </b-td>
                    </b-tr>
                </b-tbody>
            </b-table-simple>

            <div class="sticky-top" v-if="(isEgresado && !loading)">
                <b-alert show dismissible variant="warning">
                    El paciente ha egresado desea ver la <b style="padding-top: 4px;">&rArr;</b> <strong class="alert-nota-egreso" @click="verNotaDeEgreso()">nota de egreso</strong>
                </b-alert>
            </div>

            <div v-else-if="(dtaExist && !loading)" class="sticky-top">
                <b-alert show dismissible variant="info">
                    <div class="d-flex" v-if="(datos.firma.is_firmado==1)">
                        <i class="fa fa-floppy-o fa-lg my-auto pr-2" />
                        Nota firmada y guardada correctamente
                    </div>
                    <div class="d-flex" v-if="(datos.firma.is_firmado==0)">
                        <i class="fa fa-floppy-o fa-lg my-auto pr-2" />
                        Guardado correctamente, se requiere firmar la nota
                    </div>
                </b-alert>
            </div>

            <Datos :changeData="changeDta"/>


            <p class="txt_secondary text-uppercase pt-3"><strong>Datos generales</strong></p>

            <b-row>
                <b-col cols="12" sm="12" md="6" lg="6">
                    <b-table-simple class="table-sm">
                        <b-tbody>  
                            <b-tr>
                                <b-th class="size_th_table th_style">Fecha</b-th>
                                <b-td class="border_table">
                                    <b-form-input disabled name="fecha" autocomplete="off" type="date" :state="fecha" v-model="datos.fecha" @change="change(datos.fecha_nacimiento)" class="border-0" size="sm" placeholder="DD/MM/AAAA" />
                                </b-td>
                            </b-tr>
                            <b-tr>
                                <b-th class="th_style">Hora</b-th>
                                <b-td class="border_table">
                                    <div class="d-flex">
                                        <b-form-input disabled name="hora" autocomplete="off" type="time" min="00:00:01" max="24:59:59" :state="hour" v-model="datos.hora" class="border-0" size="sm" />
                                        <span class="float-right px-1 txt_gray">
                                            <span class="txt_gray size-span">hora</span>
                                        </span>
                                    </div>
                                </b-td>
                            </b-tr>
                        </b-tbody>
                    </b-table-simple>
                </b-col>
               
            </b-row>

            <b-row>
                <b-col cols="12">
                    <p class="txt_secondary">Diagnóstico</p>
                    <b-form-textarea name="diagnostico" autocomplete="off" v-model="dtaPreOperatoria.diagnostico" disabled class="txtArea_border" size="sm" no-resize  rows="3" max-rows="3" placeholder="Sin registro" />
                </b-col>
                <b-col cols="12">
                    <p class="txt_secondary mt-3">Plan quirúrgico</p>
                    <b-form-textarea name="planQuirurgico" autocomplete="off" v-model="dtaPreOperatoria.plan_quirurgico" disabled class="txtArea_border" size="sm" no-resize  rows="3" max-rows="3" placeholder="Sin registro"/>
                </b-col>
            </b-row>

            <div>
                <b-table-simple class="table-sm mt-4">
                    <b-tbody>  
                        <b-tr>
                            <b-th class="th_style size_th_habitos">Tipo de intervención quirúrgica</b-th>
                            <b-td class="border_table">
                               <b-form-input v-model="dtaPreOperatoria.tipo_intervencion_id" disabled class="border-0" size="sm" placeholder="Sin registro"/>
                            </b-td>
                        </b-tr>
                    </b-tbody>
                </b-table-simple>
            </div>

            <p class="txt_secondary text-uppercase pt-3"><strong>Hábitos</strong></p>
            <b-table-simple class="table-sm">
                <b-tbody>  
                    <b-tr>
                        <b-th class="size_th_habitos th_style">Fuma</b-th>
                        <b-td class="border-0">
                            <label class="switch">
                                <input :disabled="(disableInput || state.oldExp || isEgresado)" v-model="datos.fuma" type="checkbox">
                                <span class="slider round"></span>
                            </label>
                        </b-td>
                    </b-tr>
                    <b-tr v-if="datos.fuma">
                        <b-th class="th_option_color">¿Cuántos cigarrillos al día?</b-th>
                        <b-td class="border-0">
                            <div class="switch color_contador">
                                <div class="d-flex flex-row justify-content-center px-auto">
                                    <button :disabled="(disableInput || state.oldExp || isEgresado)" id="desactivar" @click="disminuir('cigarros')" class="py-auto px-1 txt_white border-0 btn_contador_menos">
                                        <strong>-</strong>
                                    </button>
                               
                                    <div class="py-auto px-1">
                                        <small class="txt_white"><strong>{{datos.cigarros}}</strong></small>
                                    </div>

                                    <button :disabled="(disableInput || state.oldExp || isEgresado)" @click="aumentar('cigarros')" class="py-auto px-1 txt_white border-0 btn_contador_mas">
                                        <strong>+</strong>
                                    </button>
                                </div>
                            </div>
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style">Consume bebidas alcohólicas</b-th>
                        <b-td class="border-0">
                            <label class="switch">
                                <input :disabled="(disableInput || state.oldExp || isEgresado)" v-model="datos.bebe" type="checkbox">
                                <span class="slider round"></span>
                            </label>
                        </b-td>
                    </b-tr>
                    <b-tr v-if="datos.bebe">
                        <b-th class="th_option_color">¿Cuántos copas al día?</b-th>
                        <b-td class="border-0">
                            <div class="switch color_contador">
                                <div class="d-flex flex-row justify-content-center px-auto">
                                    <button :disabled="(disableInput || state.oldExp || isEgresado)" id="desactivarbebe" @click="disminuir('copas')" class="py-auto px-1 txt_white border-0 btn_contador_menos">
                                        <strong>-</strong>
                                    </button>
                               
                                    <div class="py-auto px-1">
                                        <small class="txt_white"><strong>{{datos.bebidas_alcoholicas}}</strong></small>
                                    </div>

                                    <button :disabled="(disableInput || state.oldExp || isEgresado)" @click="aumentar('copas')" class="py-auto px-1 txt_white border-0 btn_contador_mas">
                                        <strong>+</strong>
                                    </button>
                                </div>
                            </div>
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style">Emplea drogas</b-th>
                        <b-td class="border-0">
                            <label class="switch">
                                <input :disabled="(disableInput || state.oldExp || isEgresado)" v-model="datos.emplea_drogas" type="checkbox">
                                <span class="slider round"></span>
                            </label>
                        </b-td>
                    </b-tr>
                    <b-tr v-if="datos.emplea_drogas">
                        <b-th class="th_style">
                            ¿De qué tipo?
                            <b-form-input :disabled="(disableInput || state.oldExp || isEgresado)" type="text" name="tipoDrogas" autocomplete="off" :state="drogas" v-model="datos.drogas" class="float-right border_bottom mb-3" size="sm"></b-form-input>
                        </b-th>
                    </b-tr>
                </b-tbody>
            </b-table-simple>

            <b-table-simple class="table-sm">
                <b-tbody>  
                    <b-tr>
                        <b-th class="size_th_habitos th_style">Actividad física</b-th>
                        <b-td class="border_table">
                            <b-form-select :disabled="(disableInput || state.oldExp || isEgresado)" :state="actividad_fisica_id" v-model="datos.actividad_fisica_id" :options="actividadF" class="border-0" size="sm">
                                <template #first>
                                    <b-form-select-option :value="null" disabled>seleccione una opción</b-form-select-option>
                                </template>
                            </b-form-select>
                        </b-td>
                    </b-tr>
                </b-tbody>
            </b-table-simple>

            <p class="txt_secondary text-uppercase pt-3"><strong>Cirugías</strong></p>
            <b-table-simple class="table-sm">
                <b-tbody>  
                    <b-tr>
                        <b-th class="size_th_cirujia th_style">Ha tenido complicaciones en anestesias previas</b-th>
                        <b-td class="border-0">
                            <label class="switch">
                                <input :disabled="(disableInput || state.oldExp || isEgresado)" v-model="datos.complicaciones_anestesias_previas" type="checkbox">
                                <span class="slider round"></span>
                            </label>
                        </b-td>
                    </b-tr>
                    <b-tr v-if="datos.complicaciones_anestesias_previas">
                        <b-th class="th_option_color py-2">
                            <div class="d-flex">
                                <span class="px-2 pt-2 txt_secondary">
                                    ¿Cuál?
                                </span>
                                <b-form-input :disabled="(disableInput || state.oldExp || isEgresado)" type="text" name="cualComplicacionPrevias" autocomplete="off" :state="cuales_complicaciones_anestesias" v-model="datos.cuales_complicaciones_anestesias" class="float-right border_bottom" size="sm"></b-form-input>
                            </div>
                        </b-th>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style">Algún familiar directo ha tenido problemas con anestesia</b-th>
                        <b-td class="border-0">
                            <label class="switch">
                                <input :disabled="(disableInput || state.oldExp || isEgresado)" v-model="datos.complicaciones_anestesias_previas_familia" type="checkbox">
                                <span class="slider round"></span>
                            </label>
                        </b-td>
                    </b-tr>
                    <b-tr v-if="datos.complicaciones_anestesias_previas_familia">
                        <b-th class="th_option_color py-2">
                            <div class="d-flex">
                                <span class="px-2 pt-2 txt_secondary">
                                    ¿Cuál?
                                </span>
                                <b-form-input :disabled="(disableInput || state.oldExp || isEgresado)" type="text" name="cualComplicacionAnestecia" autocomplete="off" :state="cuales_complicaciones_anestesias_familia" v-model="datos.cuales_complicaciones_anestesias_familia" class="float-right border_bottom" size="sm"></b-form-input>
                            </div>
                        </b-th>
                    </b-tr>
                </b-tbody>
            </b-table-simple>

            <p class="txt_secondary text-uppercase mt-4"><strong>Padecimientos actuales</strong></p>
            <b-row>
                <b-col cols="12" sm="12" md="4" lg="4">
                    <b-form-checkbox :disabled="(disableInput || state.oldExp || isEgresado)" v-model="datos.resfriado" size="lg" class="small"><small class="txt_gray">Resfriado</small></b-form-checkbox>
                    <b-form-checkbox :disabled="(disableInput || state.oldExp || isEgresado)" v-model="datos.garganta_inflamada" size="lg" class="small"><small class="txt_gray">Garganta inflamada</small></b-form-checkbox>
                    <b-form-checkbox :disabled="(disableInput || state.oldExp || isEgresado)" v-model="datos.bronquitis" size="lg" class="small"><small class="txt_gray">Bronquitis</small></b-form-checkbox>
                </b-col>
                <b-col cols="12" sm="12" md="4" lg="4">
                    <b-form-checkbox :disabled="(disableInput || state.oldExp || isEgresado)" v-model="datos.laringuitis" size="lg" class="small"><small class="txt_gray">Laringuitis</small></b-form-checkbox>
                    <b-form-checkbox :disabled="(disableInput || state.oldExp || isEgresado)" v-model="datos.fiebre" size="lg" class="small"><small class="txt_gray">Fiebre</small></b-form-checkbox>
                    <b-form-checkbox :disabled="(disableInput || state.oldExp || isEgresado)" v-model="datos.protesis_dentales" size="lg" class="small"><small class="txt_gray">Prótesis dentales</small></b-form-checkbox>
                </b-col>
                <b-col cols="12" sm="12" md="4" lg="4">
                    <b-form-checkbox :disabled="(disableInput || state.oldExp || isEgresado)" v-model="datos.dientes_flojos" size="lg" class="small"><small class="txt_gray">Dientes flojos</small></b-form-checkbox>
                    <b-form-checkbox :disabled="(disableInput || state.oldExp || isEgresado)" v-model="datos.dientes_rotos" size="lg" class="small"><small class="txt_gray">Dientes rotos</small></b-form-checkbox>
                </b-col>
            </b-row>

            <p class="txt_secondary text-uppercase mt-4"><strong>Ha padecido</strong></p>
            <b-row>
                <b-col cols="12" sm="12" md="6" lg="y">
                    <b-form-checkbox :disabled="(disableInput || state.oldExp || isEgresado)" v-model="datos.paralisis" size="lg" class="small"><small class="txt_gray">Parálisis</small></b-form-checkbox>
                    <b-form-checkbox :disabled="(disableInput || state.oldExp || isEgresado)" v-model="datos.presion_alta" size="lg" class="small"><small class="txt_gray">Presión alta</small></b-form-checkbox>
                    <b-form-checkbox :disabled="(disableInput || state.oldExp || isEgresado)" v-model="datos.artritis" size="lg" class="small"><small class="txt_gray">Artritis</small></b-form-checkbox>
                    <b-form-checkbox :disabled="(disableInput || state.oldExp || isEgresado)" v-model="datos.diabetes" size="lg" class="small"><small class="txt_gray">Diabetes</small></b-form-checkbox>
                    <b-form-checkbox :disabled="(disableInput || state.oldExp || isEgresado)" v-model="datos.cancer" size="lg" class="small"><small class="txt_gray">Cáncer</small></b-form-checkbox>
                    <b-form-checkbox :disabled="(disableInput || state.oldExp || isEgresado)" v-model="datos.mareos_movimiento" size="lg" class="small"><small class="txt_gray">Mareos por movimiento</small></b-form-checkbox>
                    <b-form-checkbox :disabled="(disableInput || state.oldExp || isEgresado)" v-model="datos.problemas_higado" size="lg" class="small"><small class="txt_gray">Problemas de hígado</small></b-form-checkbox>
                    <b-form-checkbox :disabled="(disableInput || state.oldExp || isEgresado)" v-model="datos.ataques_espilepsia" size="lg" class="small"><small class="txt_gray">Ataques de epilepsia</small></b-form-checkbox>
                    <b-form-checkbox :disabled="(disableInput || state.oldExp || isEgresado)" v-model="datos.ataque_falla_cardiaca" size="lg" class="small"><small class="txt_gray">Ataque o falla cardiaca</small></b-form-checkbox>
                    <b-form-checkbox :disabled="(disableInput || state.oldExp || isEgresado)" v-model="datos.asma_enfisema_neumonia" size="lg" class="small"><small class="txt_gray">Asma, enfisema, neumonía</small></b-form-checkbox>
                    <b-form-checkbox :disabled="(disableInput || state.oldExp || isEgresado)" v-model="datos.tos_espectoracion_cronica" size="lg" class="small"><small class="txt_gray">Tos-espectoración crónica</small></b-form-checkbox>
                </b-col>
                <b-col cols="12" sm="12" md="6" lg="y">
                    <b-form-checkbox :disabled="(disableInput || state.oldExp || isEgresado)" v-model="datos.nausea_vomito" size="lg" class="small"><small class="txt_gray">Náusea/vómito postoperatorios</small></b-form-checkbox>
                    <b-form-checkbox :disabled="(disableInput || state.oldExp || isEgresado)" v-model="datos.vision_borrosa_perdida" size="lg" class="small"><small class="txt_gray">Visión borrosa/pérdida temporal de la vista</small></b-form-checkbox>
                    <b-form-checkbox :disabled="(disableInput || state.oldExp || isEgresado)" v-model="datos.problemas_rinion_vejiga" size="lg" class="small"><small class="txt_gray">Problemas de riñón y/o vegija</small></b-form-checkbox>
                    <b-form-checkbox :disabled="(disableInput || state.oldExp || isEgresado)" v-model="datos.sangrado_excesivo" size="lg" class="small"><small class="txt_gray">Sangrado excesivo (incluso en cortadas pequeñas)</small></b-form-checkbox>
                    <b-form-checkbox :disabled="(disableInput || state.oldExp || isEgresado)" v-model="datos.sangre_orina_excremento" size="lg" class="small"><small class="txt_gray">Sangre en orina o excremento</small></b-form-checkbox>
                    <b-form-checkbox :disabled="(disableInput || state.oldExp || isEgresado)" v-model="datos.dolor_espalda_cuello" size="lg" class="small"><small class="txt_gray">Dolor de espalda o cuello</small></b-form-checkbox>
                    <b-form-checkbox :disabled="(disableInput || state.oldExp || isEgresado)" v-model="datos.dolor_pecho_ejercicio" size="lg" class="small"><small class="txt_gray">Dolor de pecho al ejercicio</small></b-form-checkbox>
                    <b-form-checkbox :disabled="(disableInput || state.oldExp || isEgresado)" v-model="datos.dolor_piernas_ejercicio" size="lg" class="small"><small class="txt_gray">Dolor de piernas por ejercicio al caminar</small></b-form-checkbox>
                    <b-form-checkbox :disabled="(disableInput || state.oldExp || isEgresado)" v-model="datos.hinchazon_piernas_tobillos" size="lg" class="small"><small class="txt_gray">Hinchazón en piernas y/o tobillos</small></b-form-checkbox>
                    <b-form-checkbox :disabled="(disableInput || state.oldExp || isEgresado)" v-model="datos.medicamentos_alternativos" size="lg" class="small"><small class="txt_gray">Toma de medicamentos alternativos</small></b-form-checkbox>
                </b-col>
            </b-row>
            <b-row v-if="error && !state.oldExp && (errores.actividad_fisica_id || errores.cuales_complicaciones_anestesias || errores.cuales_complicaciones_anestesias_familia)" class="my-3">
                <b-col cols="12">
                    <div class="contenedor-de-errores pt-1 pb-1">
                        <ul class="lista-errores px-2">
                            <li v-for="(value,key) in errores" :key="key.value">
                                <div v-if="key=='actividad_fisica_id'">
                                    <i class="fa fa-warning px-1"/>| {{value[0]}}
                                </div>
                                <div v-if="key=='cuales_complicaciones_anestesias'">
                                    <i class="fa fa-warning px-1"/>| {{value[0]}}
                                </div>
                                <div v-if="key=='cuales_complicaciones_anestesias_familia'">
                                    <i class="fa fa-warning px-1"/>| {{value[0]}}
                                </div>
                            </li>
                        </ul>
                    </div>
                </b-col>
            </b-row>
            <div class="d-flex justify-content-end my-4">
                <b-col cols="12" sm="12" md="5" lg="5">
                    <button @click="formNotaPreA('NotaPreAnestesicaDos');" class="btn_formluario border-0 btn-block py-2 txt_white">SIGUIENTE</button>
                </b-col>
            </div>
        </b-container>
        <div class="sticky-container"
            v-if="(datos.expediente_id != '' && !loading &&  !openPreSave)">
            <ul class="sticky">
                <li v-if="!dtaExist && !state.oldExp" @click="preGuardar()">
                    <img :src="require('../../../assets/icons/guardar.png')" width="50" height="32">
                    <p>
                        <a>Preguardar</a>
                    </p>
                </li>
                <li v-if="dtaExist && updating && !state.oldExp && datos.firma.is_firmado == 0" @click="$emit('editar')">
                    <img :src="require('../../../assets/icons/editar.png')" width="57" height="32">
                    <p>
                        <a>Editar</a>
                    </p>
                </li>
                <li v-if="(dtaExist && updating)" @click="$emit('imprimir')">
                    <img :src="require('../../../assets/icons/imprimir.png')" width="50" height="32">
                    <p>
                        <a>Imprimir</a>
                    </p>
                </li>

            </ul>
        </div>

        <DatosPreguardados :abrirPreSave="openPreSave" @showDtaPreSave="showDtaPreSave()" @cerrarModalPreSave="cerrarModalPreSave()"/>
        <NuevoExpediente :openRegExp="regNewExp"/>
        <Loading :show="loading"/>

    </b-container>
</template>

<script>
import router from '../../../router';
import { url } from "../../../../settings";
const urlp = `${url}/api/pacientes`
import Foto from "@/components/Paciente/Foto.vue"
const urlec = `${url}/api/catalogos/estado_civil`
import CryptoJS from 'crypto-js'
import DatosPreguardados from "@/components/Modals/DatosPreguardados.vue"
import NuevoExpediente from '@/components/Modals/NuevoExpediente.vue';
import Loading from '@/components/Loader/Loading.vue'
import Datos from '@/components/Paciente/Visualizar/Datos.vue'


export default {
    name: "FormUno",
    components: { Datos, Foto, DatosPreguardados, NuevoExpediente, Loading},

    computed: {
        hour() {
            return this.datos.hora.length != 0
        },

        fecha() {
            return this.datos.fecha != 0
        },

        validation() {
            return this.idPacienteSeleccionado != ""
        },

        no_habitacion() {
            return this.datos.no_habitacion != ""
        },

        genero_id() {
            return this.datos.genero_id != null
        },

        cigarros() {
            return this.datos.cigarros != null
        },

        bebidas_alcoholicas() {
            return this.datos.bebidas_alcoholicas != null
        },

        drogas() {
            return this.datos.drogas != ""
        },

        actividad_fisica_id() {
            return this.datos.actividad_fisica_id != null
        },

        cuales_complicaciones_anestesias() {
            return this.datos.cuales_complicaciones_anestesias != null
        },

        cuales_complicaciones_anestesias_familia() {
            return this.datos.cuales_complicaciones_anestesias_familia != null
        },

        escala_glasgow() {
            return this.datos.escala_glasgow != ""
        },

        padecimientos_observaciones() {
            return this.datos.padecimientos_observaciones != ""
        },
    },

    data:() => ({
        changeDta:null,
        isEgresado:false,
        allExp:[],
        idExpediente: "",

        state:{
            idExpediente:"",
            idPaciente: "",
            oldExp:false
        },

        //update
        btnFirmaUpdate: false,
        updating: true,
        disableInput: false,
        actualizar: false,
        cancelar: false,
        busyUpdate: false,
        dtaExist: false,
        pswUpdate: false,


        loading:false,
        regNewExp: false,
        cargado:false,
        openPreSave: false,
        datosPreGuardados:[],
        showDta:false,

        info:false,
        error:false,
        errores:"",
        cuarto:"",
        numero: 1,
        actividadF:[{value: null, text: "seleccionar"},{text: '...'}],
        ecivil: [{value:null,text:'seleccione una opcion'},{text:'...'}],
        datos:{
            edad:"",
            no_habitacion: null,
            password: '',
            expediente_id: null,
            fecha: "",
            hora: "",
            genero_id: null,
            fuma: false,
            cigarros: 1,
            bebe: false,
            bebidas_alcoholicas: 1,
            emplea_drogas: false,
            drogas: "",
            actividad_fisica_id: null,
            complicaciones_anestesias_previas: false,
            cuales_complicaciones_anestesias: null,
            complicaciones_anestesias_previas_familia: false,
            cuales_complicaciones_anestesias_familia: null,
            escala_glasgow: "",
            resfriado: false,
            fiebre: false,
            garganta_inflamada: false,
            protesis_dentales: false,
            bronquitis: false,
            dientes_flojos: false,
            laringuitis: false,
            dientes_rotos: false,
            paralisis: false,
            nausea_vomito: false,
            presion_alta: false,
            vision_borrosa_perdida: false,
            artritis: false,
            problemas_rinion_vejiga: false,
            diabetes: false,
            sangrado_excesivo: false,
            asma_enfisema_neumonia: false,
            sangre_orina_excremento: false,
            mareos_movimiento: false,
            problemas_higado: false,
            dolor_espalda_cuello: false,
            ataques_espilepsia: false,
            dolor_pecho_ejercicio: false,
            ataque_falla_cardiaca: false,
            dolor_piernas_ejercicio: false,
            cancer: false,
            hinchazon_piernas_tobillos: false,
            medicamentos_alternativos: false,
            tos_espectoracion_cronica: false,
            padecimientos_observaciones: ""
        },
        datosPaciente: {
            password:"",
            edad:"",
            id: null,
            nombre: "",
            fecha_nacimiento: "",
            estado_civil: {
                id: null,
                nombre: ""
            },
            cp: "",
            domicilio: "",
            colonia: "",
            ciudad: "",
            estado: {
                id:null,
                nombre: "",
            },
            municipio:{
                id:null,
                nombre: "",
            },
            telefono: "",
            email: null,
            genero:{
                id:null,
                nombre: "",
            },
            lastExpediente: {
                expediente_id: null,
                no_habitacion: null
            },
        },
        pacientes: [{}],
        nombres: [],
        idPacienteSeleccionado: "",
        
        genero: [{text:"..."}],

        /* Datos nota pre-operatoria */
        dtaPreOperatoria: {
            expediente_id: '',
            diagnostico: '',
            plan_quirurgico: '',
            tipo_intervencion_id: ''
        },

        tipo_intervencion_quirurgica: [
            {
                value: 1,
                text: "Urgencia"
            },
            {
                value: 2,
                text: "Programada"
            }
        ],
        datosExpediente:{},
        update:false,
    }),

    methods: {
        verNotaDeEgreso() {
          this.$emit('toEgreso')  
        },

        changeExp(id){
            this.loading=true

            this.clearData()

            let exps = this.allExp
            let currentExp = exps.find(exp => exp.id == id)

            if (currentExp) {
                var expediente = CryptoJS.AES.encrypt(JSON.stringify(currentExp), 'secret key heal4701tech')
                localStorage.setItem('expedienteEncrypt', expediente);
                this.changeDta=currentExp.id
                this.setData()
                this.$emit("expChangedPA")
            }
        },

        async allExpedientes() {
            try {
                const res = await fetch(`${url}/api/datos_medicos/`+this.idPacienteSeleccionado, {
                    method: 'get',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Requested-With': 'XMLHttpRequest',
                        'Authorization': "Bearer "+ localStorage.getItem("token"),
                    }
                });

                const data = await res.json();
                this.allExp=data.expedientes
                console.log(this.allExp);

            } catch (error) {
                console.log("el error es"+error);
            }
        },

        // to update
        editar(){
            this.update=true
            this.disableInput=false
            this.updating=false
        },

        cancelEdit(){
            this.disableInput = true
            this.updating = true
            this.update=false
            this.error = false
            this.errores = []
            this.setData()
        },


        preGuardar() {
            console.log("preguardado");
            this.$emit("preGuardarNPreA");
        },

        verificarPreGuardado(){
            console.log("verifico");
            try {
                if (localStorage.preGuardadoNPreA) {
                    this.datosPreGuardados=JSON.parse(localStorage.preGuardadoNPreA);
                }
            } catch (error) {
                console.log(error);
            }
            for (let val of this.datosPreGuardados) {
                if (this.datos.expediente_id===val.datosF1.expediente_id) {
                    if (this.showDta === true) {
                        this.datos=val.datosF1;
                    } else {
                        this.openPreSave = true;
                    }
                }
            }
        },

        // async getActividadesFisicas(){
        //     const headers = {
        //         method: 'get',
        //         headers: {
        //             "Access-Control-Allow-Origin": "*",
        //             "Content-Type": "application/json",
        //             "X-Requested-With": "XMLHttpRequest",
        //             'Authorization': "Bearer "+ localStorage.getItem("token"),
        //         },
        //     };

        //     await fetch(`${url}/api/catalogos/actividad_fisica`, headers)
        //     .then(response => response.json())
        //     .then(data => {
        //         this.actividadF = data.actividad_fisica.map(af => {
        //             af['value'] = af.id;
        //             af['text'] = af.nombre;
        //             return af;
        //         });
        //     })
        //     .catch(errors => {
        //         console.log(errors)
        //     })
        // },

        formNotaPreA(value) {
            this.$emit('changeStepperPreAnestesica', value);
            setTimeout(() => {
                this.$emit('datosPaTwo', {datos:this.datos,update:this.update})
            }, 200);
        },

        disminuir(caso) {
            switch (caso) {
                case 'cigarros':
                    if (this.datos.cigarros<=1) {
                        document.getElementById('desactivar').disabled = false; 
                    }else{
                        this.datos.cigarros--
                    }
                    break;
                case 'copas':
                    if (this.datos.bebidas_alcoholicas<=1) {
                        document.getElementById('desactivarbebe').disabled = false; 
                    }else{
                        this.datos.bebidas_alcoholicas--
                    }
                    break;
                default:
                    break;
            }
        },

        aumentar(caso) {
            switch (caso) {
                case 'cigarros':
                    this.datos.cigarros++;
                    break;
                case 'copas':
                    this.datos.bebidas_alcoholicas++;
                    break;
                default:
                    break;
            }
        },

        async obtenerActividadesFisicas() {
            try {
                const res = await fetch(`${url}/api/catalogos/actividad_fisica`, {
                    method: 'get',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Requested-With': 'XMLHttpRequest',
                        'Authorization': "Bearer "+ localStorage.getItem("token"),
                    }
                });
                if (!res.ok) {
                    const message = `An error has occured: ${res.status} - ${res.statusText}`;
                    throw new Error(message);
                }
                const data = await res.json();
                this.actividadF = data.actividad_fisica.map(item => {
                    return {
                        value: item.id,
                        text: item.nombre
                    }
                })
            } catch (error) {
                console.log(error);
            }
        },

        change: function(val) {
            let dob = new Date();
            let today = new Date();

            let x = val.split("-");
            dob.setDate(x[2]);
            dob.setMonth(x[1] - 1);
            dob.setFullYear(x[0]);

            this.year = (function() {
                if(dob.getMonth() == today.getMonth()) {
                    if(dob.getDate() > today.getDate()) {
                        return (today.getFullYear() - 1) - dob.getFullYear();
                    }
                    else {
                        return today.getFullYear() - dob.getFullYear();
                    }
                }
                else {
                    if(dob.getMonth() > today.getMonth()) {
                        return (today.getFullYear() - 1) - dob.getFullYear();
                    }
                    else {
                        return today.getFullYear() - dob.getFullYear();
                    }
                }
            }());
            if (this.datos.fecha_nacimiento!="") {
                this.datos.edad = this.year
                
            }else{
                this.datos.edad=""
            }
		},

        async obtenerPacientes() {
            try {
                const res = await fetch(`${urlp}`, {
                    method: 'get',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Requested-With': 'XMLHttpRequest',
                        'Authorization': "Bearer "+ localStorage.getItem("token"),
                    }
                });
                if (!res.ok) {
                    const message = `An error has occured: ${res.status} - ${res.statusText}`;
                    throw new Error(message);
                }
                const data = await res.json();
                this.pacientes=data.pacientes
                this.pacientes.unshift({id:"",nombre:"Seleccionar paciente"})
            } catch (error) {
                console.log("el error es"+error);
            }
        },

        /* Obtener datos de nota pre-operatoria  */
        async getDtaPreOperatoria(id) {
            console.log("entre preperp´de");
            try {
                const res = await fetch(`${url}/api/notas/pre-operatoria/${id}`, {
                    method: 'get',
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        'Content-Type': 'application/json',
                        'X-Requested-With': 'XMLHttpRequest',
                        'Authorization': "Bearer "+ localStorage.getItem("token"),
                    }
                });
                if (!res.ok) {
                    const message = `An error has occured: ${res.status} - ${res.statusText}`;
                    throw new Error(message);
                }
                const data = await res.json();
                if (data.preoperatoria) {
                    this.dtaPreOperatoria = data.preoperatoria
                    if (data.preoperatoria.tipo_intervencion.id === 1) {
                        this.dtaPreOperatoria.tipo_intervencion_id = "Urgencia"
                    } else {
                        this.dtaPreOperatoria.tipo_intervencion_id = "Programada"
                    }
                }
                console.log(data);
                /* this.dtaPreOperatoria.tipo_intervencion_id = data.preoperatoria.tipo_intervencion.id; */
            } catch (error) {
                console.log(error);
            }
        },

        /* Obtener datos Form uno */
        async obetenerDatosFormUno(value) {
            
            for(let val of this.pacientes){
                if(val.id==this.idPacienteSeleccionado){
                    var date = new Date(val.fecha_nacimiento)
                    var dateNow = new Date();
                    this.datos.edad=dateNow.getFullYear()-date.getFullYear();
                }
            }

            try {
                const res = await fetch(`${url}/api/notas/pre-anestesica/${value}`, {
                    method: 'get',
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        'Content-Type': 'application/json',
                        'X-Requested-With': 'XMLHttpRequest',
                        'Authorization': "Bearer "+ localStorage.getItem("token"),
                    }
                });
                if (!res.ok) {
                    const message = `An error has occured: ${res.status} - ${res.statusText}`;
                    throw new Error(message);
                }
                const data = await res.json();
                if (data.preanestesica) {
                    this.loading=false
                    window.scrollTo({ top: 0, behavior: 'smooth' })
                    this.dtaExist = true
                    this.disableInput = true
                    this.datos = data.preanestesica
                    this.datos.genero_id = this.datosExpediente.paciente.genero.id
                    this.datos.padecimientos_observaciones = data.preanestesica.observaciones
                    this.datos.edad = data.preanestesica.paciente.edad
                    this.datos.actividad_fisica_id= data.preanestesica.actividad_fisica.id
                     /* Padecimientos */
                    this.datos.resfriado = data.preanestesica.padecimientos.resfriado
                    this.datos.fiebre = data.preanestesica.padecimientos.fiebre
                    this.datos.garganta_inflamada = data.preanestesica.padecimientos.garganta_inflamada
                    this.datos.protesis_dentales = data.preanestesica.padecimientos.protesis_dentales
                    this.datos.bronquitis = data.preanestesica.padecimientos.bronquitis
                    this.datos.dientes_flojos = data.preanestesica.padecimientos.dientes_flojos
                    this.datos.laringuitis = data.preanestesica.padecimientos.laringuitis
                    this.datos.dientes_rotos = data.preanestesica.padecimientos.dientes_rotos
                    this.datos.paralisis = data.preanestesica.padecimientos.paralisis
                    this.datos.nausea_vomito = data.preanestesica.padecimientos.nausea_vomito
                    this.datos.presion_alta = data.preanestesica.padecimientos.presion_alta
                    this.datos.vision_borrosa_perdida = data.preanestesica.padecimientos.vision_borrosa_perdida
                    this.datos.artritis =data.preanestesica.padecimientos.artritis
                    this.datos.problemas_rinion_vejiga = data.preanestesica.padecimientos.problemas_rinion_vejiga
                    this.datos.diabetes = data.preanestesica.padecimientos.diabetes
                    this.datos.sangrado_excesivo = data.preanestesica.padecimientos.sangrado_excesivo
                    this.datos.asma_enfisema_neumonia = data.preanestesica.padecimientos.asma_enfisema_neumonia
                    this.datos.sangre_orina_excremento = data.preanestesica.padecimientos.sangre_orina_excremento
                    this.datos.mareos_movimiento = data.preanestesica.padecimientos.mareos_movimiento
                    this.datos.problemas_higado = data.preanestesica.padecimientos.problemas_higado
                    this.datos.dolor_espalda_cuello = data.preanestesica.padecimientos.dolor_espalda_cuello
                    this.datos.ataques_espilepsia = data.preanestesica.padecimientos.ataques_espilepsia
                    this.datos.dolor_pecho_ejercicio = data.preanestesica.padecimientos.dolor_pecho_ejercicio
                    this.datos.ataque_falla_cardiaca = data.preanestesica.padecimientos.ataque_falla_cardiaca
                    this.datos.dolor_piernas_ejercicio = data.preanestesica.padecimientos.dolor_piernas_ejercicio
                    this.datos.cancer = data.preanestesica.padecimientos.cancer
                    this.datos.hinchazon_piernas_tobillos = data.preanestesica.padecimientos.hinchazon_piernas_tobillos
                    this.datos.medicamentos_alternativos = data.preanestesica.padecimientos.medicamentos_alternativos
                    this.datos.tos_espectoracion_cronica = data.preanestesica.padecimientos.tos_espectoracion_cronica
                }else{
                    this.loading=false
                    window.scrollTo({ top: 0, behavior: 'smooth' })
                    this.disableInput=false
                    this.verificarPreGuardado()
                }
            } catch (error) {
                this.loading=false
                window.scrollTo({ top: 0, behavior: 'smooth' })
                console.log(error);
            }
        },

        async getGeneros() {
            try {
                const res = await fetch(`${url}/api/catalogos/genero`, {
                    method: 'get',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Requested-With': 'XMLHttpRequest',
                        'Authorization': "Bearer "+ localStorage.getItem("token"),
                    }
                });
                if (!res.ok) {
                    const message = `An error has occured: ${res.status} - ${res.statusText}`;
                    throw new Error(message);
                }
                const data = await res.json();
                this.genero=data.genero.map(item => {
                    return {
                        value: item.id,
                        text: item.nombre
                    };
                })
            } catch (error) {
                console.log(error);
            }
        },

        clearData(){
            this.showDta=false
            this.datos.edad=""
            this.datos.password= ''
            this.datos.fecha= ""
            this.datos.hora= ""
            this.datos.genero_id= null
            this.datos.fuma= false
            this.datos.cigarros= 1
            this.datos.bebe= false
            this.datos.bebidas_alcoholicas= 1
            this.datos.emplea_drogas= false
            this.datos.drogas= ""
            this.datos.actividad_fisica_id= null
            this.datos.complicaciones_anestesias_previas= false
            this.datos.cuales_complicaciones_anestesias= null
            this.datos.complicaciones_anestesias_previas_familia= false
            this.datos.cuales_complicaciones_anestesias_familia= null
            this.datos.escala_glasgow= ""
            this.datos.resfriado= false
            this.datos.fiebre= false
            this.datos.garganta_inflamada= false
            this.datos.protesis_dentales= false
            this.datos.bronquitis= false
            this.datos.dientes_flojos= false
            this.datos.laringuitis= false
            this.datos.dientes_rotos= false
            this.datos.paralisis= false
            this.datos.nausea_vomito= false
            this.datos.presion_alta= false
            this.datos.vision_borrosa_perdida= false
            this.datos.artritis= false
            this.datos.problemas_rinion_vejiga= false
            this.datos.diabetes= false
            this.datos.sangrado_excesivo= false
            this.datos.asma_enfisema_neumonia= false
            this.datos.sangre_orina_excremento= false
            this.datos.mareos_movimiento= false
            this.datos.problemas_higado= false
            this.datos.dolor_espalda_cuello= false
            this.datos.ataques_espilepsia= false
            this.datos.dolor_pecho_ejercicio= false
            this.datos.ataque_falla_cardiaca= false
            this.datos.dolor_piernas_ejercicio= false
            this.datos.cancer= false
            this.datos.hinchazon_piernas_tobillos= false
            this.datos.medicamentos_alternativos= false
            this.datos.tos_espectoracion_cronica= false
            this.datos.padecimientos_observaciones= ""
            this.dtaPreOperatoria= {
            expediente_id: '',
            diagnostico: '',
            plan_quirurgico: '',
            tipo_intervencion_id: ''
            }
            this.getTime();
        },

        getTime(){
            var date = new Date;
            var minutes = ("0"+date.getMinutes()).slice(-2);
            var hour = ("0"+date.getHours()).slice(-2);
            this.datos.hora=""+hour+":"+minutes+":00"
            var today = new Date();
            var dd = ("0" + (today.getDate())).slice(-2);
            var mm = ("0" + (today.getMonth() +1)).slice(-2);
            var yyyy = today.getFullYear();
            this.datos.fecha = yyyy + '-' + mm + '-' + dd ;
        },

        getEdad(){
            for(let val of this.pacientes){
                if(val.id==this.idPacienteSeleccionado){
                    var date = new Date(val.fecha_nacimiento)
                    var dateNow = new Date();
                    this.datos.edad=dateNow.getFullYear()-date.getFullYear();
                }
            }
        },
        setData(){
            // this.getActividadesFisicas();
            this.obtenerActividadesFisicas();
            this.getGeneros()
            this.obtenerPacientes();
            this.obtenerEstadoCivil();

            const pacienteEncrypt=localStorage.expedienteEncrypt
            const expediente =  CryptoJS.AES.decrypt(pacienteEncrypt, 'secret key heal4701tech').toString(CryptoJS.enc.Utf8);
            const paciente=JSON.parse(expediente)
            this.changeDta=paciente.id
            this.idExpediente=paciente.id
            this.state.idExpediente=paciente.id
            this.state.idPaciente=this.idPacienteSeleccionado

            if (paciente.paciente.lastExpediente.expediente_id!=this.state.idExpediente) {
                    this.state.oldExp=true
                    console.log("es old");
            }else{
                    console.log("entrr en el else");
                    this.state.oldExp=false
            }

            if (paciente.paciente.telefono==null) {
                paciente.paciente.telefono="-----"
            }
            if (paciente.paciente.email==null) {
                paciente.paciente.email="-----"
            }
            this.idPacienteSeleccionado=paciente.paciente.id
            this.datos.expediente_id=paciente.id;
            this.datosPaciente=paciente.paciente
            this.datosExpediente=paciente
            this.datosPaciente.noExpediente=paciente.paciente.no_expediente
            this.btnGuardar = false;
            this.btnFirmar = true;
            this.datos.genero_id=this.datosPaciente.genero.id

            var year = new Date(this.datosPaciente.fecha_nacimiento)
            var today = new Date();
            var yyyy = today.getFullYear();
            this.datosPaciente.edad= yyyy-year.getFullYear();

            this.cuarto=paciente.no_habitacion
            this.dtaPreOperatoria.expediente_id = paciente.id
            this.obetenerDatosFormUno(this.datos.expediente_id)
            this.getDtaPreOperatoria(this.dtaPreOperatoria.expediente_id)
            this.allExpedientes()
        },

         async obtenerEstadoCivil() {
            try {
                const res = await fetch(`${urlec}`, {
                    method: 'get',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Requested-With': 'XMLHttpRequest',
                        'Authorization': "Bearer "+ localStorage.getItem("token"),
                    }
                });
                if (!res.ok) {
                    const message = `An error has occured: ${res.status} - ${res.statusText}`;
                    throw new Error(message);
                }
                const data = await res.json();


                this.ecivil = data.estado_civil.map(item => {
                    return {
                        value: item.id,
                        text: item.nombre
                    };
                });
                this.ecivil.unshift( { value: null, text: 'seleccione una opcion' },)


            } catch (error) {
                console.log("el error es"+error);
            }
        },
        makeToast(variant = null) {
            this.$bvToast.toast('Los datos han sido preguardados con exito', {
            title: "Datos preguardados",
            variant: variant,
            solid: true
            })
        },
        showDtaPreSave(){
            this.showDta = true
            this.verificarPreGuardado()
            this.openPreSave = false
            this.$emit('showDtaPreSave')
        },
        cerrarModalPreSave(){
            this.openPreSave = false
        },
        preGuardarNPreA(){
            this.datos.password = ""
            var existe=false;
            try {
                if (localStorage.preGuardadoNPreA) {
                    console.log("si hay datos");
                    this.datosPreGuardados=JSON.parse(localStorage.preGuardadoNPreA)
                    console.log(this.datosPreGuardados);
                }
            } catch (error) {
                console.log(error);
            }
             var i =0;
            for (let val of this.datosPreGuardados) {
                console.log("valor: "+val.expediente_id);
                if (this.datos.expediente_id===val.datosF1.expediente_id) {
                    console.log("ya esta we");
                    this.datosPreGuardados[i]={"datosF1":this.datos}
                    existe=true
                }
                i++
            }
            if (!existe) {
                this.datosPreGuardados.push({"datosF1":this.datos});
            }   

            const dta = JSON.stringify(this.datosPreGuardados)
            localStorage.setItem('preGuardadoNPreA', dta);
            this.makeToast('success');
            console.log('oreguardar');
        },
        err(v){
            console.log(v);
            console.log('err');
            this.error=v.bol
            this.errores=v.errs
        },

        borrarDatosPreAnestesica(){
            this.error=false
            this.errores=[]
            this.obtenerPacientes();
            var date = new Date;
            var minutes = ("0"+date.getMinutes()).slice(-2);
            var hour = ("0"+date.getHours()).slice(-2);
            this.datos.hora=""+hour+":"+minutes+":00"
            var today = new Date();
            var dd = ("0" + (today.getDate())).slice(-2);
            var mm = ("0" + (today.getMonth() +1)).slice(-2);
            var yyyy = today.getFullYear();
            this.datos.fecha = yyyy + '-' + mm + '-' + dd ;
            this.loading=true
            this.clearData()
            this.setData()
            setTimeout(() => {
                this.$emit("expChangedPA")
            }, 400);
        }
    },

    mounted(){
        
        var date = new Date;
        var minutes = ("0"+date.getMinutes()).slice(-2);
        var hour = ("0"+date.getHours()).slice(-2);
        this.datos.hora=""+hour+":"+minutes+":00"
        var today = new Date();
        var dd = ("0" + (today.getDate())).slice(-2);
        var mm = ("0" + (today.getMonth() +1)).slice(-2);
        var yyyy = today.getFullYear();
        this.datos.fecha = yyyy + '-' + mm + '-' + dd ;

        if (!(JSON.parse(CryptoJS.AES.decrypt(localStorage.expedienteEncrypt, 'secret key heal4701tech').toString(CryptoJS.enc.Utf8)).id==this.idExpediente)) {

            Object.assign(this.$data, this.$options.data());

            const egreso=localStorage.egreso
            if (egreso) {
                const egresoDE =  JSON.parse(CryptoJS.AES.decrypt(egreso, 'secret key heal4701tech').toString(CryptoJS.enc.Utf8));
                console.log(egresoDE);
                this.isEgresado=egresoDE.egreso
            }else{
                router.push("/main")
            }

            this.loading=true
            this.clearData()
            this.setData()
        }
    },
    
}
</script>

<style scoped>
    /* Title */
    @media only screen and (min-width: 1100px) {
        .font-size-vw {
            font-size: 1.7vw
        }
    }

    @media only screen and (max-width: 1100px) {
        .font-size-vw {
            font-size: 2vw
        }
    } 
    
     @media only screen and (max-width: 910px) {
        .font-size-vw {
            font-size: 2.2vw
        }
    } 
    
    @media only screen and (max-width: 810px) {
        .font-size-vw {
            font-size: 2.7vw
        }
    } 

    @media only screen and (max-width: 710px) {
        .font-size-vw {
            font-size: 3vw
        }
    }  

    @media only screen and (max-width: 576px) {
        .font-size-vw {
            font-size: 3.8vw
        }

        .size_th_table {
            width: auto;
        }
    } 

    /* Padding stepper */
    @media only screen and (max-width: 992px) {
        .padding_stepper {
            margin-top: 29px;
        }
    }

    /* Size th */
    @media only screen and (min-width: 960px) {
        .size_th_table {
            width: 13vw;
        }

        .size_th_cirujia {
            width: 27vw;
        }

        .size_th_habitos {
            width: 21vw;
        }
    }

    @media only screen and (max-width: 960px) {
        .size_th_table {
            width: 15vw;
        }

        .size_th_habitos {
            width: 35vw;
        }

        .size_th_cirujia {
            width: 41vw;
        }
    }


    @media only screen and (max-width: 768px) {
        .size_th_habitos {
            width: auto;
        }
        
        .size_th_cirujia {
            width: auto;
        }
    } 

    /* Tabla (Expediente, fecha, hora, habitacion, genero y edad) */
    @media only screen and (min-width: 1200px) {
        .size_th {
            width: 25vw;
        }
    }

    @media only screen and (max-width: 1200px) {
        .size_th {
            width: 28vw;
        }
    }

    @media only screen and (max-width: 992px) {
        .size_th {
            width: 30vw;
        }
    }

    @media only screen and (max-width: 768px) {
        .size_th {
            width: 30vw;
        }
    }

    @media only screen and (max-width: 576px) {
        .size_th {
            width: auto;
        }
    }

</style>
