<template>
    <b-container>
        <b-row>
            <b-col cols="12" sm="12" md="8" lg="8">
                <h2 class="txt_primary text-uppercase pl-3">Historia clínica</h2>   
            </b-col>
        </b-row>       
        
        <b-container class="margen">
            <b-row>
                <b-col class="p-0" cols="12" sm="12" md="12" lg="3">
                    <div class="container">
                        <section class="step-indicator">
                            <div class="step step1">
                                <button id="step1" @click="formHC('HCFormUno')" class="step-icon" />
                            </div>
                            <div class="indicator-line"></div>
                            <div class="step step2 active">
                                <button id="step2" class="step-icon" />
                                <!-- <button id="step2" @click="formHC('HCFormDos')" class="step-icon" /> -->
                            </div>
                            <div class="indicator-line"></div>
                            <div class="step step3">
                                <button id="step3" @click="formHC('HCFormTres')" class="step-icon" />
                            </div>
                        </section>
                    </div>
                </b-col> 
            </b-row>

            <div class="sticky-top mt-3" v-if="(isEgresado && !loading)">
                <b-alert show dismissible variant="warning">
                    El paciente ha egresado desea ver la <b style="padding-top: 4px;">&rArr;</b> <strong class="alert-nota-egreso" @click="verNotaDeEgreso()">nota de egreso</strong>
                </b-alert>
            </div>

            <div v-else-if="(dtaExist && !loading)" class="sticky-top mt-3">
                <b-alert show dismissible variant="info">
                    <div class="d-flex" v-if="(notaFirmada || gineco_obstetrico.firma.is_firmado==1)">
                        <i class="fa fa-floppy-o fa-lg my-auto pr-2" />
                        Nota firmada y guardada correctamente
                    </div>
                    <div class="d-flex" v-else-if="(!notaFirmada || gineco_obstetrico.firma.is_firmado==0)">
                        <i class="fa fa-floppy-o fa-lg my-auto pr-2" />
                        Guardado correctamente, se requiere firmar la nota
                    </div>
                </b-alert>
            </div>

            <p class="txt_secondary text-uppercase mt-3"><strong>Gineco-obstétricos</strong></p>
            <b-table-simple class="table-sm">
                <b-tbody>  
                <b-tr>
                    <b-th class="size_th th_style">Persona gestante</b-th>
                    <b-td class="border-0">
                        <label class="switch">
                            <input :disabled="((estado!= 'f1' && estado!='editando' ) || state.oldExp || isEgresado || notaFirmada)" v-model="edadFertil" type="checkbox">
                            <span class="slider round"></span>
                        </label>
                    </b-td>
                </b-tr>
                </b-tbody>
            </b-table-simple>

            <b-table-simple class="table-sm" v-if="edadFertil">
                <b-tbody>
                    <b-tr>
                        <b-th class="size_th th_style">Menarca</b-th>
                        <b-td class="border_table">
                            <div class="d-flex">
                                <b-form-input :disabled="((estado!= 'f1' && estado!='editando' ) || state.oldExp || isEgresado)" type="text" name="menemarca" autocomplete="off" v-on:keypress="soloNumeros($event)" maxlength="2" v-model="gineco_obstetrico.menarca" :state="Vmenarca" class="border-0" size="sm" placeholder="Ingrese la edad" />
                                <span class="float-right px-2">
                                    <span class="txt_gray size-span">años</span>
                                </span>
                            </div>
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style">Ritmo menstrual</b-th>
                        <b-td class="border_table">
                            <div class="d-flex">
                                <b-form-input :disabled="((estado!= 'f1' && estado!='editando' ) || state.oldExp || isEgresado)" type="text" name="ritmoMenstrual" autocomplete="off" v-on:keypress="soloNumeros($event)" maxlength="2" v-model="gineco_obstetrico.ritmo_menstrual" :state="VritmoMens" class="border-0" size="sm" placeholder="Ingrese la días" />
                                <span class="float-right px-2">
                                    <span class="txt_gray size-span">días</span>
                                </span>
                            </div>
                        </b-td>
                    </b-tr>
                     <b-tr>
                        <b-th class="th_style">Ultima regla</b-th>
                        <b-td class="border_table">
                            <div class="d-flex">
                                <b-form-input :disabled="((estado!= 'f1' && estado!='editando' ) || state.oldExp || isEgresado)" name="ultimaRegla" autocomplete="off" v-model="gineco_obstetrico.ultima_regla" :state="VfechaUltimaRegla" type="date" class="border-0" size="sm" placeholder="Ingrese la días" />
                                <span class="float-right px-2">
                                    <span class="txt_gray size-span">días</span>
                                </span>
                            </div>
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style">Inicio de vida sexual</b-th>
                        <b-td class="border_table">
                            <div class="d-flex">
                                <b-form-input :disabled="((estado!= 'f1' && estado!='editando' ) || state.oldExp || isEgresado)" type="text" name="inicioVidaSex" autocomplete="off" v-on:keypress="soloNumeros($event)" maxlength="2" v-model="gineco_obstetrico.inicio_vida_sexual" class="border-0" size="sm" placeholder="Ingrese la edad" />
                                <span class="float-right px-2">
                                    <span class="txt_gray size-span">años</span>
                                </span>
                            </div>
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style"><strong>Método anticonceptivo</strong></b-th>
                        <b-td class="border_table">
                            <b-form-select :disabled="((estado!= 'f1' && estado!='editando' ) || state.oldExp || isEgresado)" v-model="gineco_obstetrico.metodo_anticonceptivo_id" :options="metodos" @change="habilitarTiempoUsoMetodo()" class="border-0" size="sm" />
                        </b-td>
                    </b-tr>
                    <b-tr v-if="tiempo_uso_metodo">
                        <b-th class="th_style">Tiempo usando el método</b-th>
                        <b-td class="border_table">
                            <b-form-input :disabled="((estado!= 'f1' && estado!='editando' ) || state.oldExp || isEgresado)" type="text" name="tiempoMetodo" autocomplete="off" v-model="gineco_obstetrico.tiempo_metodo_anticonceptivo" :state="VtiempoUsuandoMetodo" class="border-0" size="sm" placeholder="Ingresa el tiempo usando el método" />
                        </b-td>
                    </b-tr>
                </b-tbody>
            </b-table-simple>

            <b-table-simple class="table-sm" v-if="edadFertil">
                <b-tbody>  
                    <b-tr>
                        <b-th class="size_th th_style">Embarazos</b-th>
                        <b-td class="border-0">
                            <label class="switch">
                                <input :disabled="((estado!= 'f1' && estado!='editando' ) || state.oldExp || isEgresado)" v-model="gineco_obstetrico.embarazos" @click="clearEmbarazosData" type="checkbox">
                                <span class="slider round"></span>
                            </label>
                        </b-td>
                    </b-tr>
                    <b-tr v-if="gineco_obstetrico.embarazos">
                        <b-th class="th_option_color">¿Cuántos?</b-th>
                        <b-td class="border-0">
                            <div class="switch color_contador">
                                <div class="d-flex flex-row justify-content-center px-auto">
                                    <button :disabled="((estado!= 'f1' && estado!='editando' ) || state.oldExp || isEgresado)" id="desactivarEm" @click="disminuir('embarazo')" class="py-auto px-1 txt_white border-0 btn_contador_menos">
                                        <strong>-</strong>
                                    </button>
                               
                                    <div class="py-auto px-1">
                                        <small class="txt_white"><strong>{{gineco_obstetrico.cantidad_embarazos}}</strong></small>
                                    </div>

                                    <button :disabled="((estado!= 'f1' && estado!='editando' ) || state.oldExp || isEgresado)" @click="aumentar('embarazo')" class="py-auto px-1 txt_white border-0 btn_contador_mas">
                                        <strong>+</strong>
                                    </button>
                                </div>
                            </div>
                        </b-td>
                    </b-tr>
                    <b-tr v-if="gineco_obstetrico.embarazos">
                        <b-th class="size_th th_style">Embarazo actual</b-th>
                        <b-td class="border-0">
                            <label class="switch">
                                <input :disabled="((estado!= 'f1' && estado!='editando' ) || state.oldExp || isEgresado)" v-model="embarazo_actual" type="checkbox">
                                <span class="slider round"></span>
                            </label>
                        </b-td>
                    </b-tr>
                    <b-tr v-if="gineco_obstetrico.embarazos">
                        <b-th class="th_style">Partos</b-th>
                        <b-td class="border-0">
                            <label class="switch">
                                <input :disabled="((estado!= 'f1' && estado!='editando' ) || state.oldExp || isEgresado)" v-model="gineco_obstetrico.partos" type="checkbox">
                                <span class="slider round"></span>
                            </label>
                        </b-td>
                    </b-tr>
                    <b-tr v-if="gineco_obstetrico.partos && gineco_obstetrico.embarazos">
                        <b-th class="th_option_color">
                            ¿Cuántos? <br>
                            <b-row class="mt-2">
                                <b-col cols="12" sm="12" md="9" lg="8">
                                    <b-row>
                                        <b-col cols="12" sm="12" md="12" lg="12">
                                            Fecha de último parto
                                        </b-col>
                                        <b-col cols="12" sm="12" md="11" lg="11">
                                            <b-form-input :disabled="((estado!= 'f1' && estado!='editando' ) || state.oldExp || isEgresado)" name="fechaUltimoParto" autocomplete="off" v-model="gineco_obstetrico.fecha_ultimo_parto" :state="VfechaUltimoParto" type="date" class="border-0" size="sm" placeholder="DD/MM/AAAA" />
                                        </b-col>
                                    </b-row>
                                </b-col>
                            </b-row>
                        </b-th>
                        <b-td class="border-0">
                            <div class="switch color_contador">
                                <div class="d-flex flex-row justify-content-center px-auto">
                                    <button :disabled="((estado!= 'f1' && estado!='editando' ) || state.oldExp || isEgresado)" id="desactivarPa" @click="disminuir('partos')" class="py-auto px-1 txt_white border-0 btn_contador_menos">
                                        <strong>-</strong>
                                    </button>
                               
                                    <div class="py-auto px-1">
                                        <small class="txt_white"><strong>{{gineco_obstetrico.cantidad_partos}}</strong></small>
                                    </div>

                                    <button :disabled="((estado!= 'f1' && estado!='editando' ) || state.oldExp || isEgresado)" @click="aumentar('partos')" class="py-auto px-1 txt_white border-0 btn_contador_mas">
                                        <strong>+</strong>
                                    </button>
                                </div>
                            </div>
                        </b-td>
                    </b-tr>
                    <b-tr v-if="gineco_obstetrico.embarazos">
                        <b-th class="th_style">Abortos</b-th>
                        <b-td class="border-0">
                            <label class="switch">
                                <input :disabled="((estado!= 'f1' && estado!='editando' ) || state.oldExp || isEgresado)" v-model="gineco_obstetrico.abortos" type="checkbox">
                                <span class="slider round"></span>
                            </label>
                        </b-td>
                    </b-tr>
                    <b-tr v-if="gineco_obstetrico.abortos && gineco_obstetrico.embarazos">
                        <b-th class="th_option_color">¿Cuántos?</b-th>
                        <b-td class="border-0">
                            <div class="switch color_contador">
                                <div class="d-flex flex-row justify-content-center px-auto">
                                    <button :disabled="((estado!= 'f1' && estado!='editando' ) || state.oldExp || isEgresado)" id="desactivarAb" @click="disminuir('abortos')" class="py-auto px-1 txt_white border-0 btn_contador_menos">
                                        <strong>-</strong>
                                    </button>
                               
                                    <div class="py-auto px-1">
                                        <small class="txt_white"><strong>{{gineco_obstetrico.cantidad_abortos}}</strong></small>
                                    </div>

                                    <button :disabled="((estado!= 'f1' && estado!='editando' ) || state.oldExp || isEgresado)" @click="aumentar('abortos')" class="py-auto px-1 txt_white border-0 btn_contador_mas">
                                        <strong>+</strong>
                                    </button>
                                </div>
                            </div>
                        </b-td>
                    </b-tr>
                    <b-tr v-if="gineco_obstetrico.embarazos">
                        <b-th class="th_style">Cesáreas</b-th>
                        <b-td class="border-0">
                            <label class="switch">
                                <input :disabled="((estado!= 'f1' && estado!='editando' ) || state.oldExp || isEgresado)" v-model="gineco_obstetrico.cesareas" type="checkbox">
                                <span class="slider round"></span>
                            </label>
                        </b-td>
                    </b-tr>
                    <b-tr v-if="gineco_obstetrico.cesareas && gineco_obstetrico.embarazos">
                        <b-th class="th_option_color">¿Cuántos?</b-th>
                        <b-td class="border-0">
                            <div class="switch color_contador">
                                <div class="d-flex flex-row justify-content-center px-auto">
                                    <button :disabled="((estado!= 'f1' && estado!='editando' ) || state.oldExp || isEgresado)" id="desactivarCe" @click="disminuir('cesarea')" class="py-auto px-1 txt_white border-0 btn_contador_menos">
                                        <strong>-</strong>
                                    </button>
                               
                                    <div class="py-auto px-1">
                                        <small class="txt_white"><strong>{{gineco_obstetrico.cantidad_cesareas}}</strong></small>
                                    </div>

                                    <button :disabled="((estado!= 'f1' && estado!='editando' ) || state.oldExp || isEgresado)" @click="aumentar('cesarea')" class="py-auto px-1 txt_white border-0 btn_contador_mas">
                                        <strong>+</strong>
                                    </button>
                                </div>
                            </div>
                        </b-td>
                    </b-tr>
                    <b-tr v-if="gineco_obstetrico.embarazos">
                        <b-th class="th_style">Hijos macrosómicos</b-th>
                        <b-td class="border-0">
                            <label class="switch">
                                <input :disabled="((estado!= 'f1' && estado!='editando' ) || state.oldExp || isEgresado)" v-model="gineco_obstetrico.hijos_macrosomicos" type="checkbox">
                                <span class="slider round"></span>
                            </label>
                        </b-td>
                    </b-tr>
                    <b-tr v-if="gineco_obstetrico.hijos_macrosomicos && gineco_obstetrico.embarazos">
                        <b-th class="th_option_color">¿Cuántos?</b-th>
                        <b-td class="border-0">
                            <div class="switch color_contador">
                                <div class="d-flex flex-row justify-content-center px-auto">
                                    <button :disabled="((estado!= 'f1' && estado!='editando' ) || state.oldExp || isEgresado)" id="desactivarMi" @click="disminuir('hijosMacro')" class="py-auto px-1 txt_white border-0 btn_contador_menos">
                                        <strong>-</strong>
                                    </button>
                               
                                    <div class="py-auto px-1">
                                        <small class="txt_white"><strong>{{gineco_obstetrico.cantidad_hijos_macrosomicos}}</strong></small>
                                    </div>

                                    <button :disabled="((estado!= 'f1' && estado!='editando' ) || state.oldExp || isEgresado)" @click="aumentar('hijosMacro')" class="py-auto px-1 txt_white border-0 btn_contador_mas">
                                        <strong>+</strong>
                                    </button>
                                </div>
                            </div>
                        </b-td>
                    </b-tr>
                    <b-tr v-if="gineco_obstetrico.embarazos">
                        <b-th class="th_style">Hijos con bajo peso al nacer</b-th>
                        <b-td class="border-0">
                            <label class="switch">
                                <input :disabled="((estado!= 'f1' && estado!='editando' ) || state.oldExp || isEgresado)" v-model="gineco_obstetrico.hijos_con_bajo_peso" type="checkbox">
                                <span class="slider round"></span>
                            </label>
                        </b-td>
                    </b-tr> 
                    <b-tr v-if="gineco_obstetrico.hijos_con_bajo_peso && gineco_obstetrico.embarazos">
                        <b-th class="th_option_color">¿Cuántos?</b-th>
                        <b-td class="border-0">
                            <div class="switch color_contador">
                                <div class="d-flex flex-row justify-content-center px-auto">
                                    <button :disabled="((estado!= 'f1' && estado!='editando' ) || state.oldExp || isEgresado)" id="desactivarBp" @click="disminuir('hijosbajopeso')" class="py-auto px-1 txt_white border-0 btn_contador_menos">
                                        <strong>-</strong>
                                    </button>
                               
                                    <div class="py-auto px-1">
                                        <small class="txt_white"><strong>{{gineco_obstetrico.cantidad_hijos_con_bajo_peso}}</strong></small>
                                    </div>

                                    <button :disabled="((estado!= 'f1' && estado!='editando' ) || state.oldExp || isEgresado)" @click="aumentar('hijosbajopeso')"  class="py-auto px-1 txt_white border-0 btn_contador_mas">
                                        <strong>+</strong>
                                    </button>
                                </div>
                            </div>
                        </b-td>
                    </b-tr>
                </b-tbody>
            </b-table-simple>

            <p  class="txt_secondary text-uppercase pt-3" v-if="edadFertil && embarazo_actual && gineco_obstetrico.embarazos"><strong>Control prenatal</strong></p>
            <div style="margin-top: -16px;" v-if="pie.state && edadFertil && embarazo_actual && gineco_obstetrico.embarazos">
                <small class="txt_gray">* Algunos datos provienen de la nota de ingreso, actualización: {{ pie.fecha }} {{ pie.hora }}</small>
            </div>
            <b-row  v-if="edadFertil && embarazo_actual && gineco_obstetrico.embarazos">
                <b-col cols="12" sm="12" md="6" lg="6">
                    <b-table-simple class="table-sm">
                        <b-tbody>  
                            <b-tr>
                                <b-th class="size_th_control_parental th_style">F.U.M.</b-th>
                                <b-td class="border_table">
                                    <b-form-input :disabled="((estado!= 'f1' && estado!='editando' ) || state.oldExp || isEgresado)" name="fum" autocomplete="off" type="date" v-model="control_prenatal.fum" :state="VFum" class="border-0" size="sm" placeholder="DD/MM/AAAA" />
                                </b-td>
                            </b-tr>
                            <b-tr>
                                <b-th class="th_style">F.P.P.</b-th>
                                <b-td class="border_table">
                                    <b-form-input :disabled="((estado!= 'f1' && estado!='editando' ) || state.oldExp || isEgresado)" name="fpp" autocomplete="off" type="date" v-model="control_prenatal.fpp" :state="VFpp" class="border-0" size="sm" placeholder="DD/MM/AAAA" />
                                </b-td>
                            </b-tr>
                            <b-tr>
                                <b-th class="th_style">F.C.F.</b-th>
                                <b-td class="border_table">
                                    <div class="d-flex">
                                        <b-form-input :disabled="((estado!= 'f1' && estado!='editando' ) || state.oldExp || isEgresado)" type="text" name="fcf" autocomplete="off" v-on:keypress="soloNumeros($event)" maxlength="3" v-model="control_prenatal.fcf" :state="VFcf" class="border-0" size="sm" />
                                        <span class="float-right px-2">
                                            <span class="txt_gray size-span">/min</span>
                                        </span>
                                    </div>
                                </b-td>
                            </b-tr>
                            <b-tr>
                                <b-th class="th_style">F.U.</b-th>
                                <b-td class="border_table">
                                    <div class="d-flex">
                                        <b-form-input :disabled="((estado!= 'f1' && estado!='editando' ) || state.oldExp || isEgresado)" type="text" name="fu" autocomplete="off" v-on:keypress="soloNumeros($event)" maxlength="3" v-model="control_prenatal.fu" :state="VFu" class="border-0" size="sm" />
                                        <span class="float-right px-2">
                                            <span class="txt_gray size-span">cm</span>
                                        </span>
                                    </div>
                                </b-td>
                            </b-tr>
                            <b-tr>
                                <b-th class="th_style">S.D.G.</b-th>
                                <b-td class="border_table">
                                    <b-form-input :disabled="((estado!= 'f1' && estado!='editando' ) || state.oldExp || isEgresado)" type="text" name="sdg" autocomplete="off" v-on:keypress="soloNumeros($event)" maxlength="4" v-model="control_prenatal.sdp" :state="VSdp" class="border-0" size="sm" />
                                </b-td>
                            </b-tr>
                            <b-tr>
                                <b-th class="th_style">T.A.</b-th>
                                <b-td class="border_table">
                                    <div class="d-flex">
                                        <div>
                                            <b-form-input :disabled="((estado!= 'f1' && estado!='editando' ) || state.oldExp || isEgresado)" type="text" name="ta1" autocomplete="off" v-on:keypress="soloNumeros($event)" maxlength="3" v-model="tension1" class="text-center border-0" size="sm" placeholder="#"/>
                                        </div>
                                        <div class="txt_gray px-1">/</div>
                                        <div>
                                            <b-form-input :disabled="((estado!= 'f1' && estado!='editando' ) || state.oldExp || isEgresado)" type="text" name="ta2" autocomplete="off" v-on:keypress="soloNumeros($event)" maxlength="3" v-model="tension2" :state="VTensionDos" @keyup="tensionArterial()" class="text-center border-0" size="sm" placeholder="#"/>
                                        </div>
                                    </div>
                                </b-td>
                            </b-tr>
                            <b-tr>
                                <b-th class="th_style">Cuadrante</b-th>
                                <b-td class="border_table">
                                    <b-form-input :disabled="((estado!= 'f1' && estado!='editando' ) || state.oldExp || isEgresado)" type="text" name="cuadrante" autocomplete="off" v-model="control_prenatal.en" class="border-0" size="sm" />
                                </b-td>
                            </b-tr>
                            <b-tr>
                                <b-th class="th_style">Pulso</b-th>
                                <b-td class="border_table">
                                    <div class="d-flex">
                                        <b-form-input :disabled="((estado!= 'f1' && estado!='editando' ) || state.oldExp || isEgresado)" type="text" name="pulso" autocomplete="off" v-on:keypress="soloNumeros($event)" maxlength="3" v-model="control_prenatal.pulso" :state="VPulso" class="border-0" size="sm" />
                                        <span class="float-right px-2">
                                            <span class="txt_gray size-span">/min</span>
                                        </span>
                                    </div>
                                </b-td>
                            </b-tr>
                            <b-tr>
                                <b-th class="th_style">Respiración</b-th>
                                <b-td class="border_table">
                                    <div class="d-flex">
                                        <b-form-input :disabled="((estado!= 'f1' && estado!='editando' ) || state.oldExp || isEgresado)" type="text" name="respiracion" autocomplete="off" v-on:keypress="soloNumeros($event)" maxlength="3" v-model="control_prenatal.respiracion" :state="Vrespiracion" class="border-0" size="sm" />
                                        <span class="float-right px-2">
                                            <span class="txt_gray size-span">/min</span>
                                        </span>
                                    </div>
                                </b-td>
                            </b-tr>
                            <b-tr>
                                <b-th class="th_style">Situación</b-th>
                                <b-td class="border_table">
                                    <b-form-input :disabled="((estado!= 'f1' && estado!='editando' ) || state.oldExp || isEgresado)" type="text" name="situacion" autocomplete="off" v-model="control_prenatal.situacion" :state="VSituacion" class="border-0" size="sm" />
                                </b-td>
                            </b-tr>
                        </b-tbody>
                    </b-table-simple>
                </b-col>
                <b-col cols="12" sm="12" md="6" lg="6">
                    <b-table-simple class="table-sm">
                        <b-tbody>  
                            <b-tr>
                                <b-th class="size_th_control_parental th_style">Presentación</b-th>
                                <b-td class="border_table">
                                    <b-form-input :disabled="((estado!= 'f1' && estado!='editando' ) || state.oldExp || isEgresado)" type="text" name="presentacion" autocomplete="off" v-model="control_prenatal.presentacion" :state="VPresentacion" class="border-0" size="sm" />
                                </b-td>
                            </b-tr>
                            <b-tr>
                                <b-th class="th_style">Peso</b-th>
                                <b-td class="border_table">
                                    <div class="d-flex">
                                        <b-form-input :disabled="((estado!= 'f1' && estado!='editando' ) || state.oldExp || isEgresado)" type="text" name="peso" autocomplete="off" v-on:keypress="soloNumeros($event)" maxlength="6" v-model="control_prenatal.peso" :state="VPeso" class="border-0" size="sm" />
                                        <span class="float-right px-2">
                                            <span class="txt_gray size-span">kg</span>
                                        </span>
                                    </div>
                                </b-td>
                            </b-tr>
                            <b-tr>
                                <b-th class="th_style">Talla</b-th>
                                <b-td class="border_table">
                                    <div class="d-flex">
                                        <b-form-input :disabled="((estado!= 'f1' && estado!='editando' ) || state.oldExp || isEgresado)" type="text" name="talla" autocomplete="off" v-on:keypress="soloNumeros($event)" maxlength="6" v-model="control_prenatal.talla" :state="VTalla" class="border-0" size="sm" />
                                        <span class="float-right px-2">
                                            <span class="txt_gray size-span">cm</span>
                                        </span>
                                    </div>
                                </b-td>
                            </b-tr>
                            <b-tr>
                                <b-th class="th_style">Temperatura</b-th>
                                <b-td class="border_table">
                                    <div class="d-flex">
                                        <b-form-input :disabled="((estado!= 'f1' && estado!='editando' ) || state.oldExp || isEgresado)" type="text" name="temperatura" autocomplete="off" v-on:keypress="soloNumeros($event)" maxlength="5" v-model="control_prenatal.temperatura" :state="VTemperatura" class="border-0" size="sm" />
                                        <span class="float-right px-2">
                                            <span class="txt_gray size-span">°C</span>
                                        </span>
                                    </div>
                                </b-td>
                            </b-tr>
                            <b-tr>
                                <b-th class="th_style">Dorso</b-th>
                                <b-td class="border_table">
                                    <b-form-select :disabled="((estado!= 'f1' && estado!='editando' ) || state.oldExp || isEgresado)" v-model="control_prenatal.dorso" :options="dorso" :state="VDorso" class="border-0" size="sm" placeholder="seleccionar" />
                                </b-td>
                            </b-tr>
                            <b-tr>
                                <b-th class="th_style">Edema</b-th>
                                <b-td class="border_table">
                                    <b-form-select :disabled="((estado!= 'f1' && estado!='editando' ) || state.oldExp || isEgresado)" v-model="control_prenatal.edema" :options="factorRh" :state="VEdema" class="border-0" size="sm" placeholder="seleccionar" />
                                </b-td>
                            </b-tr>
                            <b-tr>
                                <b-th class="th_style">Factor Rh</b-th>
                                <b-td class="border_table">
                                    <b-form-select :disabled="((estado!= 'f1' && estado!='editando' ) || state.oldExp || isEgresado)" v-model="control_prenatal.factor_rh" :options="factorRh" :state="VFactorRh" class="border-0" size="sm" placeholder="seleccionar" />
                                </b-td>
                            </b-tr>
                            <b-tr>
                                <b-th class="th_style">Grupo sanguíneo</b-th>
                                <b-td class="border_table">
                                    <b-form-select :disabled="((estado!= 'f1' && estado!='editando' ) || state.oldExp || isEgresado)" v-model="control_prenatal.grupo_sanguineo" :options="grupo_sanguineo" :state="VGSanguineo" class="border-0" size="sm" placeholder="seleccionar" />
                                </b-td>
                            </b-tr>
                        </b-tbody>
                    </b-table-simple>
                </b-col>
            </b-row>

            <div v-if="gineco_obstetrico.firma.is_firmado==1" class="mb-4">
                <span class="txt_secondary">Nota firmada</span><br>
                <span class="txt_secondary size-span"><i class="fa fa-user mr-1"></i>Médico: {{gineco_obstetrico.firma.firmado_por.nombre}}</span> <br>
                <span class="txt_secondary size-span"><i class="fa fa-calendar mr-1"></i>Fecha: {{gineco_obstetrico.firma.fecha_firmado}}</span>
            </div>

            <b-row v-if="error">
                <b-col cols="12">
                    <div class="contenedor-de-errores pt-3 pb-1">
                        <ul class="lista-errores px-2">
                            <li v-for="(value,key) in errores" :key="key.value"><i class="fa fa-warning px-1" />| {{value[0]}} <br></li>
                            <li v-for="(value,key) in errores2" :key="key.value"><i class="fa fa-warning px-1" />| {{value[0]}} <br></li>
                        </ul>
                    </div>
                </b-col>
            </b-row>

            <div v-if="actualizar && !state.oldExp"  class="d-flex justify-content-end mb-4 pt-3">
                <b-col cols="12" sm="12" md="5" lg="5">
                    <b-overlay
                    :show="busyUpdate"
                    rounded
                    opacity="0.6"
                    spinner-small
                    spinner-variant="primary"
                    >
                        <button class="btn_formluario border-0 btn-block py-2 txt_white" @click="updateData()" :disabled="busyUpdate">ACTUALIZAR</button>
                    </b-overlay>
                </b-col>
            </div>
            <div v-if="btnFirmaUpdate && !state.oldExp" class="d-flex justify-content-end mb-4">
                <b-col cols="12" sm="12" md="5" lg="5">
                    <button @click="firmaUpdate()" class="btn_formluario border-0 btn-block py-2 txt_white">FIRMAR</button>
                </b-col>
            </div>

            <div v-if="cancelar && !state.oldExp" class="d-flex justify-content-end mb-4">
                <b-col cols="12" sm="12" md="5" lg="5">
                    <button  @click="cancelEdit()" class="btn_formluario border-0 btn-block py-2 txt_white">CANCELAR</button>
                </b-col>
            </div>

            <div v-if="estado=='f1' && edadFertil" class="d-flex justify-content-end my-4">
                <b-col cols="12" sm="12" md="6" lg="6">
                    <b-overlay
                    :show="busy"
                    rounded
                    opacity="0.6"
                    spinner-small
                    spinner-variant="primary"
                    >
                        <button class="btn_formluario border-0 btn-block py-2 txt_white" :disabled="busy" @click="verificar()">GUARDAR</button>
                    </b-overlay>
                </b-col>
            </div>

            <div v-if="estado=='done' || !edadFertil" class="d-flex justify-content-end mt-3 mb-4">
                <b-col cols="12" sm="12" md="6" lg="6">
                    <button @click="switchComponent('HCFormUno')" class="btn_formluario border-0 btn-block py-2 txt_white">ANTERIOR</button>
                </b-col>
                <b-col cols="12" sm="12" md="6" lg="6" v-if="!edadFertil||estado=='done'">
                    <button @click="formHC('HCFormTres')" class="btn_formluario border-0 btn-block py-2 txt_white">SIGUIENTE</button>
                </b-col>
                <b-col cols="12" sm="12" md="6" lg="6" v-else-if="estado!='done'">
                </b-col>
            </div>
            <AltertPresionArterial :abrir="openAlertPresion" @cerrarModalAlertPresionArterial="cerrarModalAlertPresionArterial()"/>
            <DatosPreguardados :abrirPreSave="openPreSave" @showDtaPreSave="showDtaPreSave()"/>
        </b-container>
        <div class="sticky-container" v-if="((control_prenatal.expediente_id != '' || gineco_obstetrico.expediente_id != '') && !openError && !openSuccess && !pswUpdate && !loading && !openAlertPresion && !openPreSave)">
            <ul class="sticky">
                <!-- <li @click="preGuardar()" v-if="estado=='f1' && edadFertil">
                    <img :src="require('../../../assets/icons/guardar.png')" width="50" height="32">
                    <p>
                        <a>Preguardar</a>
                    </p>
                </li> -->
                <li @click="imprimir()" v-if="allNotas.historia_clinica.Antecedentes&&allNotas.historia_clinica.exploracion_fisica&&allNotas.historia_clinica.interrogatorio&&allNotas.historia_clinica.no_patologicos">
                    <img :src="require('../../../assets/icons/imprimir.png')" width="50" height="32">
                    <p>
                        <a>Imprimir</a>
                    </p>
                </li>
                <li v-if="!firmed && !isEgresado && !notaFirmada && !state.oldExp && allNotas.historia_clinica.gineco_obstetricos" @click="editar()">
                    <img :src="require('../../../assets/icons/editar.png')" width="57" height="32">
                    <p>
                        <a>Editar</a>
                    </p>
                </li>
            </ul>
        </div>

        <Error :abrir="openError" @cerrarModalError="cerrarModalError()"/>
        <Success :abrir="openSuccess" @cerrarModal="cerrarModal()"/> 
        <FirmaPswUpdate :abrir="pswUpdate" />
        <Loading :show="loading"/>

    </b-container>
</template>

<script>
import Vue from 'vue'
import PDFMerger from "pdf-merger-js";
import router from "../../../router";
import { url } from "../../../../settings";
import CryptoJS from 'crypto-js'
import AltertPresionArterial from '@/components/Modals/AltertPresionArterial.vue';
import DatosPreguardados from "@/components/Modals/DatosPreguardados.vue"
import Success from "@/components/Modals/Success.vue";
import Error from "@/components/Modals/Error.vue";
import FirmaPswUpdate from '@/components/Modals/FirmaPswUpdate.vue'
import Loading from '@/components/Loader/Loading.vue'

export default {
    name: 'FormDos',
    components: { AltertPresionArterial, DatosPreguardados, Success, Error, FirmaPswUpdate, Loading },

    computed: {
        Vmenarca() {
            return this.gineco_obstetrico.menarca != ""
        },

        VritmoMens() {
            return this.gineco_obstetrico.ritmo_menstrual != ""
        },

        VinicioSex() {
            return this.gineco_obstetrico.inicio_vida_sexual != ""
        },

        VfechaUltimaRegla() {
            return this.gineco_obstetrico.ultima_regla != ""
        },

        VmetodoAnticonceptivo() {
            return this.gineco_obstetrico.metodo_anticonceptivo_id != null
        },

        VfechaUltimoParto() {
            return this.gineco_obstetrico.fecha_ultimo_parto != ""
        },

        VtiempoUsuandoMetodo() {
            return this.gineco_obstetrico.tiempo_metodo_anticonceptivo != ""
        },

        /* Control prenatal */
        VFum() {
            return this.control_prenatal.fum != ""
        },

        VFpp() {
            return this.control_prenatal.fpp != ""
        },

        VFcf() {
            return this.control_prenatal.fcf != ""
        },

        VFu() {
            return this.control_prenatal.fu != ""
        },

        VSdp() {
            return this.control_prenatal.sdp != ""
        },

        VTensionDos() {
            return this.tension2 != "" && this.tension1 != "" 
        },
        
        VPulso() {
            return this.control_prenatal.pulso != ""
        },

        Vrespiracion() {
            return this.control_prenatal.respiracion != ""
        },

        VSituacion() {
            return this.control_prenatal.situacion != ""
        },

        VPresentacion() {
            return this.control_prenatal.presentacion != ""
        },

        VPeso() {
            return this.control_prenatal.peso != ""
        },

        VTalla() {
            return this.control_prenatal.talla != ""
        },

        VTemperatura() {
            return this.control_prenatal.temperatura != ""
        },

        VDorso() {
            return this.control_prenatal.dorso != null
        },

        VEdema() {
            return this.control_prenatal.edema != null
        },

        VFactorRh() {
            return this.control_prenatal.factor_rh != null
        },

        VGSanguineo() {
            return this.control_prenatal.grupo_sanguineo != null
        }
    },

    data:() => ({
        timerMessages:false,
        busy:false,
        estado:"f1",//f1: Gineco-obstétricos, done: formularios completos, editando: para editar datos
        no_expediente:'',
        isEgresado:false,
        firmed:false,
        notaFirmada:false,
        state:{
            idExpediente:"",
            idPaciente: "",
            oldExp:false
        },
        
        //  pa update
        btnFirmaUpdate: false,
        updating: true,
        actualizar: false,
        cancelar: false,
        busyUpdate: false,
        dtaExist: false,
        pswUpdate: false,
        openSuccess:false,
        openError:false,

        loading:true,

         allNotas:{
            egreso:false,
            evolucion: false,
            historia_clinica: false,
            ingreso: false,
            post_operatoria: false,
            pre_anestesica: false,
            pre_operatoria:false
        },
        idPacienteSeleccionado: '',
        cargado: false,
        openPreSave: false,
        datosPreGuardados:[],
        tiempo_uso_metodo: false,
        openAlertPresion: false,
        changed:"",
        error: false,
        embarazo_actual:false,
        errores:"",
        errores2:"",
        numero: 1,
        tension2:"",
        tension1:"",
        datosAntecedentes:null,
        datosPatologicos:null,
        edadFertil:false,
        password: '',
        gineco_obstetrico: {
            expediente_id: "",
            password: '',
            menarca: "",
            ritmo_menstrual: "",
            ultima_regla: "",
            inicio_vida_sexual: "",
            embarazos: false,
            cantidad_embarazos: 1,
            partos: false,
            cantidad_partos: 1,
            fecha_ultimo_parto: "",
            abortos: false,
            cantidad_abortos: 1,
            cesareas: false,
            cantidad_cesareas: 1,
            hijos_macrosomicos: false,
            cantidad_hijos_macrosomicos: 1,
            hijos_con_bajo_peso: false,
            cantidad_hijos_con_bajo_peso: 1,
            metodo_anticonceptivo_id: null,
            tiempo_metodo_anticonceptivo: "",
            firma:{
                fecha_firmado:null,
                firmado_por:{
                    id:null,
                    nombre:""
                },
                is_firmado:0
            },
        },
        metodos: [{value:null,text:'seleccione una opción'},{text:'...'}],

        control_prenatal: {
            password: '',
            expediente_id: '',
            fum: "",
            fpp: "",
            sdp: "",
            fu: "",
            fcf: "",
            en: "",
            tension_arterial: "",
            situacion: "",
            presentacion: "",
            dorso: null,
            pulso: "",
            respiracion: "",
            peso: "",
            talla: "",
            edema: null,
            temperatura: "",
            factor_rh: null,
            grupo_sanguineo: null,
        },
        pie:{
            state:"",
            fecha:"",
            hora:""
        },

        dorso:[
            {
                value:null,
                text:"seleccionar"
            },
            {
                value:"Izquierdo",
                text:"Izquierdo"
            },
            {
                value:"Derecho",
                text:"Derecho"
            },
        ],

        factorRh:[
            {
                value:null,
                text:'seleccionar'
            },
            {
                value:true,
                text:'Positivo'
            },
            {
                value:false,
                text:'Negativo'
            }
        ],

        grupo_sanguineo:[
            {
                value:null,
                text:"seleccionar"
            },
            {
                value:"A",
                text:"A"
            },
            // {
            //     value:"A-",
            //     text:"A-"
            // },
            {
                value:"B",
                text:"B"
            },
            // {
            //     value:"B-",
            //     text:"B-"
            // },
            {
                value:"AB",
                text:"AB"
            },
            // {
            //     value:"AB-",
            //     text:"AB-"
            // },
            {
                value:"O",
                text:"O"
            },
            // {
            //     value:"O-",
            //     text:"O-"
            // }
        ],
        hayDatos:{control_prenatal:false,gineco_obstetrico:false},
        showDta:false,
        showDtaDisable:false,
        done:false,
    }),

    methods: {
        verNotaDeEgreso() {
          this.$emit('toEgreso')  
        },

        verificar(){
            this.busy=true
            this.error=false
            this.errores={}
            this.errores2={}
            if (this.edadFertil) {
                if (this.gineco_obstetrico.menarca==""||this.gineco_obstetrico.menarca==null) {
                    this.error=true
                    this.errores.menarca=["El dato menarca es requerido."]
                }

                if (this.gineco_obstetrico.ritmo_menstrual==""||this.gineco_obstetrico.ritmo_menstrual==null) {
                    this.error=true
                    this.errores.ritmo_menstrual=["El dato ritmo menstrual es requerido."]
                }

                if (this.gineco_obstetrico.ultima_regla==""||this.gineco_obstetrico.ultima_regla==null) {
                    this.error=true
                    this.errores.ultima_regla=["El dato ultima regla es requerido."]
                }

                // if (this.gineco_obstetrico.inicio_vida_sexual==""||this.gineco_obstetrico.inicio_vida_sexual==null) {
                //     this.error=true
                //     this.errores.inicio_vida_sexual=["El dato inicio de vida sexual es requerido."]
                // }

                // if (this.gineco_obstetrico.inicio_vida_sexual==""||this.gineco_obstetrico.inicio_vida_sexual==null) {
                //     this.error=true
                //     this.errores.inicio_vida_sexual=["El dato inicio de vida sexual es requerido."]
                // }
                console.log(this.gineco_obstetrico);
                if (this.gineco_obstetrico.metodo_anticonceptivo_id==""||this.gineco_obstetrico.metodo_anticonceptivo_id==null) {
                    this.error=true
                    this.errores.metodo_anticonceptivo_id=["El dato método anticonceptivo es requerido."]
                }

                if (this.gineco_obstetrico.tiempo_metodo_anticonceptivo==""||this.gineco_obstetrico.tiempo_metodo_anticonceptivo==null) {
                    this.error=true
                    this.errores.tiempo_metodo_anticonceptivo=["El dato tiempo usando el método es requerido."]
                }
                
                if (this.gineco_obstetrico.embarazos && this.gineco_obstetrico.partos&&(this.gineco_obstetrico.fecha_ultimo_parto==""||this.gineco_obstetrico.fecha_ultimo_parto==null)) {
                    this.error=true
                    this.errores.fecha_ultimo_parto=["El dato fecha de último parto  es requerido."]
                }

                if (this.gineco_obstetrico.embarazos && this.gineco_obstetrico.partos&&(this.gineco_obstetrico.fecha_ultimo_parto==""||this.gineco_obstetrico.fecha_ultimo_parto==null)) {
                    this.error=true
                    this.errores.fecha_ultimo_parto=["El dato fecha de último parto  es requerido."]
                }

                if (this.embarazo_actual) {
                    if (this.control_prenatal.fpp=="" || this.control_prenatal.fpp==null) {
                        this.error=true
                        this.errores2.fpp=["El dato F.U.M. es requerido."]
                    }

                    if (this.control_prenatal.fum=="" || this.control_prenatal.fum==null) {
                        this.error=true
                        this.errores2.fum=["El dato F.P.P. es requerido."]
                    }

                    if (this.control_prenatal.fcf=="" || this.control_prenatal.fcf==null) {
                        this.error=true
                        this.errores2.fcf=["El dato F.C.F. es requerido."]
                    }

                    if (this.control_prenatal.fu=="" || this.control_prenatal.fu==null) {
                        this.error=true
                        this.errores2.fu=["El dato F.U. es requerido."]
                    }
                    
                    if (this.control_prenatal.sdp=="" || this.control_prenatal.sdp==null) {
                        this.error=true
                        this.errores2.sdp=["El dato S.D.G. es requerido."]
                    }

                    if ((this.tension1=="" || this.tension2=="") || (this.tension1==null || this.tension2==null)) {
                        this.error=true
                        this.errores2.tension_arterial=["El dato T.A. es requerido."]
                    }

                    if (this.control_prenatal.en=="" || this.control_prenatal.en==null) {
                        this.error=true
                        this.errores2.en=["El dato cuadrante es requerido."]
                    }

                    if (this.control_prenatal.pulso=="" || this.control_prenatal.pulso==null) {
                        this.error=true
                        this.errores2.pulso=["El dato pulso es requerido."]
                    }

                    if (this.control_prenatal.respiracion=="" || this.control_prenatal.respiracion==null) {
                        this.error=true
                        this.errores2.respiracion=["El dato respiracion es requerido."]
                    }

                    if (this.control_prenatal.situacion=="" || this.control_prenatal.situacion==null) {
                        this.error=true
                        this.errores2.situacion=["El dato situacion es requerido."]
                    }

                    if (this.control_prenatal.presentacion=="" || this.control_prenatal.presentacion==null) {
                        this.error=true
                        this.errores2.presentacion=["El dato presentacion es requerido."]
                    }

                    if (this.control_prenatal.peso==="" || this.control_prenatal.peso===null) {
                        this.error=true
                        this.errores2.peso=["El dato peso es requerido."]
                    }

                    if (this.control_prenatal.talla==="" || this.control_prenatal.talla===null) {
                        this.error=true
                        this.errores2.talla=["El dato talla es requerido."]
                    }

                    if (this.control_prenatal.temperatura==="" || this.control_prenatal.temperatura===null) {
                        this.error=true
                        this.errores2.temperatura=["El dato temperatura es requerido."]
                    }

                    if (this.control_prenatal.dorso==="" || this.control_prenatal.dorso===null) {
                        this.error=true
                        this.errores2.dorso=["El dato dorso es requerido."]
                    }

                    if (this.control_prenatal.edema==="" || this.control_prenatal.edema===null) {
                        this.error=true
                        this.errores2.edema=["El dato edema es requerido."]
                    }

                    console.log(this.control_prenatal);
                    if (this.control_prenatal.factor_rh==="" || this.control_prenatal.factor_rh===null) {
                        this.error=true
                        this.errores2.factor_rh=["El dato factor rh es requerido."]
                    }
                    
                    if (this.control_prenatal.grupo_sanguineo=="" || this.control_prenatal.grupo_sanguineo==null) {
                        this.error=true
                        this.errores2.grupo_sanguineo=["El dato grupo sanguineo rh es requerido."]
                    }
                }

                if (this.error) {
                    this.openError=true
                }else{
                    this.error=false
                    this.errores=[]
                    this.errores2=[]
                    this.busy=true
                    this.allNotas.historia_clinica.gineco_obstetricos?this.updateGineco_obstetrico(this.gineco_obstetrico.id):this.saveGineco_obstetrico()
                }

                
                
            }

        },

        //  pa update update update update update update update update 
        cancelEdit() {
            this.actualizar = false
            this.updating = true
            this.cancelar = false
            this.error = false
            this.errores = []
            this.estado='done'
            this.setData()
            this.btnFirmaUpdate=false
        },

        editar() {

            this.pswUpdate = false
            this.cancelar = true
            this.actualizar = true
            this.btnFirmaUpdate = false
            this.updating = false
            this.estado='editando'
        },

        firmaUpdate() {
            this.pswUpdate = true
        },

        async updateData() {
            this.error=false
            this.errores=[]
            this.busyUpdate = true
            this.verificar()
            

        },

        async updateGineco_obstetrico(id){
            this.error=false
            this.errores=[]
            try {
                const res = await fetch(`${url}/api/historia_clinica/gineco_obstetricos/update/`+id, {
                    method: 'put',
                    headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-Type": "application/json",
                    "X-Requested-With": "XMLHttpRequest",
                    'Authorization': "Bearer "+ localStorage.getItem("token"),
                    },
                    body: JSON.stringify(this.gineco_obstetrico),
                });
                const data = res.json();
                Vue.prototype.$error500 = res.status===500
                var warn = false;
                var promise = Promise.resolve(data);
                await promise.then(function(val) {
                    if (val.class=="success") {
                        warn = true
                    }
                });
                if (warn) {
                    console.log('gineco');
                    if (this.embarazo_actual) {
                        if (this.allNotas.historia_clinica.control_parental) {
                            this.updateControl_prenatal(id)
                        }else{

                            this.saveControl_prenatal()
                        }
                    }else{
                        this.openSuccess=true
                        this.busy=false
                        this.busyUpdate=false
                        this.cancelEdit()

                    }
                }else{
                    Vue.prototype.$error500 = true
                    this.openError=true
                }
                this.busyUpdate=false
            } catch (error) {
                this.busyUpdate=false
                console.log(error);
            }
        },

        async updateControl_prenatal(id){
            this.error=false
            this.errores2=[]
            this.control_prenatal.tension_arterial = this.tension1+"/"+this.tension2
            try {
                const res = await fetch(`${url}/api/historia_clinica/control_prenatal/update/`+id, {
                    method: 'put',
                    headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-Type": "application/json",
                    "X-Requested-With": "XMLHttpRequest",
                    'Authorization': "Bearer "+ localStorage.getItem("token"),
                    },
                    body: JSON.stringify(this.control_prenatal),
                });
                const data = res.json();
                Vue.prototype.$error500 = res.status===500
                var warn = false;
                var promise = Promise.resolve(data);
                await promise.then(function(val) {
                    console.log(val)
                    if (val.class=="success") {
                        warn = true
                    }
                });
                if (warn) {
                    this.openSuccess=true
                    if (this.estado=='editando') {
                        this.updating = true
                        this.busyUpdate=false
                        this.actualizar = false
                        this.cancelar = false
                        this.cancelEdit()
                    }
                }else{
                    this.error=false
                    Vue.prototype.$error500 = true
                    this.openError=true
                }
            } catch (error) {
                this.busyUpdate=false
                console.log(error);
            }
        },

        // update update update update update update update update update 
        // /////////////


        toastPrint(variant = null) {
            this.$bvToast.toast('Seleccione un paciente', {
            title: "¡Error!",
            variant: variant,
            solid: true
            })
        },
        
        async imprimir() {
            // var can = false;
            var este = this;
            this.loading = true
            let urlim = `${url}/api/pacientes/pdf/${this.idPacienteSeleccionado}`;
            return fetch(urlim, {
                method: 'get',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Requested-With': 'XMLHttpRequest',
                    'Authorization': "Bearer "+ localStorage.getItem("token"),
                }
            }).then(function(resp){
                return resp.blob("paciente.pdf")
            }).then(function(blob) {
                console.log('entre');
                este.downloadPDF(blob)

            })
        },
        async downloadPDF(blob){
            try {
                console.log('entre');
                var merger = new PDFMerger()
                await merger.add(URL.createObjectURL(blob))
                await merger.save(this.no_expediente+" Historia clínica");
                this.loading = false
            } catch (error) {
                console.log(error);
                this.loading = false
            }
        },

        preGuardar() {
            this.control_prenatal.password = ""
            this.gineco_obstetrico.password = ""
            var existe=false;
            if (this.edadFertil) {
                try {
                    if (localStorage.preGuardadoHCF2) {
                        console.log("si hay datos");
                        this.datosPreGuardados=JSON.parse(localStorage.preGuardadoHCF2)
                        console.log(this.datosPreGuardados);
                    }
                } catch (error) {
                    console.log(error);
                }
                 var i =0;
                for (let val of this.datosPreGuardados) {
                    console.log("valor: "+val.expediente_id);
                    if (this.gineco_obstetrico.expediente_id===val.expediente_id) {
                        console.log("ya esta we");
                        this.datosPreGuardados[i]={"control_prenatal":this.control_prenatal,"gineco_obstetrico":this.gineco_obstetrico}
                        existe=true
                    }
                    i++
                }
                if (!existe) {
                    this.datosPreGuardados.push({"control_prenatal":this.control_prenatal,"gineco_obstetrico":this.gineco_obstetrico});
                }   

                const dta = JSON.stringify(this.datosPreGuardados)
                localStorage.setItem('preGuardadoHCF2', dta);
                this.makeToast('Preguardado','Datos guardados','success');
                console.log(this.datosPreGuardados);
            }else{
                console.log("no me guarde we, bien hecho");
            }
        },
        makeToast(tittle, msj, variant = null) {
            this.$bvToast.toast(msj, {
            title: tittle,
            variant: variant,
            autoHideDelay: 2000,
            solid: true
            })
        },

        verificarPreGuardado(){
            try {
                if (localStorage.preGuardadoHCF2) {
                    this.datosPreGuardados=JSON.parse(localStorage.preGuardadoHCF2);
                    console.log(this.datosPreGuardados);
                }
            } catch (error) {
                console.log(error);
            }
            for (let val of this.datosPreGuardados) {
                console.log(val.control_prenatal.expediente_id);
                if (this.control_prenatal.expediente_id===val.control_prenatal.expediente_id) {
                    if ((this.showDta === true)) {
                        this.edadFertil=true
                        this.embarazo_actual=true
                        if (!this.allNotas.historia_clinica.gineco_obstetricos) {
                            this.gineco_obstetrico=val.gineco_obstetrico;
                        }
                        if (!this.allNotas.historia_clinica.control_parental) {
                            this.control_prenatal=val.control_prenatal;
                        }
                    } else{
                        if (localStorage.view==="HCFormDos") {
                            this.openPreSave = true
                        }
                    }
                }
            }
        },

        habilitarTiempoUsoMetodo() {
            if (this.gineco_obstetrico.metodo_anticonceptivo_id != null) {
                this.tiempo_uso_metodo = true
            } else {
                this.tiempo_uso_metodo = false
            }
        },

        tensionArterial() {
            if (this.tension2.length >= 2 && this.tension1.length >= 2) {
                setTimeout(() => {
                if (this.tension1 < 100 || this.tension1 > 140 || this.tension2 < 60 || this.tension2 > 90) {
                        this.openAlertPresion = true
                }
                }, 500);
            }
        },

        clearEmbarazosData(){
            if (!this.gineco_obstetrico.embarazos) {
                this.gineco_obstetrico.embarazos= false
                this.gineco_obstetrico.cantidad_embarazos= 1
                this.gineco_obstetrico.partos= false
                this.gineco_obstetrico.cantidad_partos= 1
                this.gineco_obstetrico.fecha_ultimo_parto= ""
                this.gineco_obstetrico.abortos= false
                this.gineco_obstetrico.cantidad_abortos= 1
                this.gineco_obstetrico.cesareas= false
                this.gineco_obstetrico.cantidad_cesareas= 1
                this.gineco_obstetrico.hijos_macrosomicos= false
                this.gineco_obstetrico.cantidad_hijos_macrosomicos= 1
                this.gineco_obstetrico.hijos_con_bajo_peso= false
                this.gineco_obstetrico.cantidad_hijos_con_bajo_peso= 1
                this.embarazo_actual=false;
            }
        },

        soloNumeros(evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault();
            } else {
                return true;
            }
        },

        soloLetras(evt) {
            let char = String.fromCharCode(evt.keyCode);
            if(/^[A-Za-z-zA-ZÀ-ÿ,\u00f1\u00d1 ]+$/.test(char)) {
                return true;
            } else {
                evt.preventDefault();
            }
        },

        formHC(value) {
            if (this.updating) {
                localStorage.setItem("view",value)
                this.$emit('changeHC', value);
            }else{
                localStorage.setItem("view",value)
                this.$emit('changeHC', value);
                this.cancelEdit()
            }
        },

        switchComponent(value) {
            localStorage.setItem("view",value)
            this.control_prenatal.tension_arterial=this.control_prenatal.tension_arterial+"/"+this.tension2
            this.$emit('changeHC', value)
        },

        disminuir(value) {
            switch (value) {
                case 'embarazo':
                    if (this.gineco_obstetrico.cantidad_embarazos<=1) {
                        document.getElementById('desactivarEm').disabled = false; 
                    }else{
                        this.gineco_obstetrico.cantidad_embarazos--
                    }
                    break;
                case 'partos':
                    if (this.gineco_obstetrico.cantidad_partos<=1) {
                        document.getElementById('desactivarPa').disabled = false; 
                    }else{
                        this.gineco_obstetrico.cantidad_partos--
                    }
                    break;
                case 'abortos':
                    if (this.gineco_obstetrico.cantidad_abortos<=1) {
                        document.getElementById('desactivarAb').disabled = false; 
                    }else{
                        this.gineco_obstetrico.cantidad_abortos--
                    }
                    break;
                case 'cesarea':
                    if (this.gineco_obstetrico.cantidad_cesareas<=1) {
                        document.getElementById('desactivarCe').disabled = false; 
                    }else{
                        this.gineco_obstetrico.cantidad_cesareas--
                    }
                    break;
                case 'hijosMacro':
                    if (this.gineco_obstetrico.cantidad_hijos_macrosomicos<=1) {
                        document.getElementById('desactivarMi').disabled = false; 
                    }else{
                        this.gineco_obstetrico.cantidad_hijos_macrosomicos--
                    }
                    break;
                case 'hijosbajopeso':
                    if (this.gineco_obstetrico.cantidad_hijos_con_bajo_peso<=1) {
                        document.getElementById('desactivarBp').disabled = false; 
                    }else{
                        this.gineco_obstetrico.cantidad_hijos_con_bajo_peso--
                    }
                    break;
            
                default:
                    break;
            }
        },

        aumentar(value) {
            switch (value) {
                case 'embarazo':
                    this.gineco_obstetrico.cantidad_embarazos++;
                    break;
                case 'partos':
                    this.gineco_obstetrico.cantidad_partos++;
                    break;
                case 'abortos':
                    this.gineco_obstetrico.cantidad_abortos++;
                    break;
                case 'cesarea':
                    this.gineco_obstetrico.cantidad_cesareas++;
                    break;
                case 'hijosbajopeso':
                    this.gineco_obstetrico.cantidad_hijos_con_bajo_peso++;
                    break;
                case 'hijosMacro':
                    this.gineco_obstetrico.cantidad_hijos_macrosomicos++;
                    break;
                default:
                    break;
            }
        },

        async saveGineco_obstetrico() {
            try {
                const res = await fetch(`${url}/api/historia_clinica/gineco_obstetricos/store`, {
                    method: 'post',
                    headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-Type": "application/json",
                    "X-Requested-With": "XMLHttpRequest",
                    'Authorization': "Bearer "+ localStorage.getItem("token"),
                    },
                    body: JSON.stringify(this.gineco_obstetrico),
                });
                const data = res.json();
                Vue.prototype.$error500 = res.status===500
                var warn = false;
                var promise = Promise.resolve(data);
                var err = []
                await promise.then(function(val) {
                    if (val.class=="success") {
                        warn = true
                    }else{
                        err=val.errors
                    }
                });
                if (warn) {
                    if (this.estado=='f1') {
                        if (this.embarazo_actual) {
                            this.allNotas.historia_clinica.control_parental?this.updateControl_prenatal(this.control_prenatal.id):this.saveControl_prenatal()
                        }else{
                            this.busy=false
                            this.openSuccess=true
                            this.setData()
                        }
                    }
                    // else{
                    //     if (this.embarazo_actual) {
                    //         this.allNotas.historia_clinica.control_parental?this.updateControl_prenatal(this.control_prenatal.id):this.saveControl_prenatal()
                    //     }else{
                    //         this.busy=false
                    //         this.openSuccess=true
                    //         this.updating = true
                    //         this.busyUpdate=false
                    //         this.actualizar = false
                    //         this.cancelar = false
                    //         this.cancelEdit()
                    //     }
                    // }
                }else{
                    this.openError=true
                    this.errores2=err
                    this.error=true  
                }
            } catch (error) {
                console.log(error);
            }
       },

       async saveControl_prenatal() {
            // this.control_prenatal.password=this.password
            this.error=false
            this.errores2=[]
            this.control_prenatal.tension_arterial = this.tension1+"/"+this.tension2
            try {
                const res = await fetch(`${url}/api/historia_clinica/control_prenatal/store`, {
                    method: 'post',
                    headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-Type": "application/json",
                    "X-Requested-With": "XMLHttpRequest",
                    'Authorization': "Bearer "+ localStorage.getItem("token"),
                    },
                    body: JSON.stringify(this.control_prenatal),
                });
                const data = res.json();
                Vue.prototype.$error500 = res.status===500
                var warn = false;
                var err = []
                var promise = Promise.resolve(data);
                await promise.then(function(val) {
                    console.log(val)
                    if (val.class=="success") {
                        warn = true
                    }else{
                        err=val.errors
                    }
                });
                if (warn) {
                    this.openSuccess=true
                    this.busy=false
                    this.setData()
                    if (this.actualizar) {
                        this.openSuccess=true
                        this.updating = true
                        this.busyUpdate=false
                        this.actualizar = false
                        this.cancelar = false
                        this.cancelEdit()
                    }else{
                        this.openSuccess=true
                        this.updating = true
                        this.busyUpdate=false
                        this.actualizar = false
                        this.cancelar = false
                        this.cancelEdit()
                    }
                }else{
                    if (this.actualizar) {
                        this.busyUpdate=false
                        this.openError=true
                        this.errores2=err
                        this.error=true   
                    }else{
                        this.openError=true
                        this.errores2=err
                        this.error=true                   
                    }
                }
            } catch (error) {
                console.log(error);
            }
        },

        /* Obtener datos form dos */
        async obtenerGinecObstetrico(value) {
            try {
                const res = await fetch(`${url}/api/historia_clinica/gineco_obstetricos/${value}`, {
                    method: 'get',
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        'Content-Type': 'application/json',
                        'X-Requested-With': 'XMLHttpRequest',
                        'Authorization': "Bearer "+ localStorage.getItem("token"),
                    }
                });
                 if (!res.ok) {
                    const message = `An error has occured: ${res.status} - ${res.statusText}`;
                    throw new Error(message);
                }
                const data = await res.json();
                console.log(data);
                if (data.nota_gineco_obstetricos) {
                    window.scrollTo({ top: 0, behavior: 'smooth' })
                    this.edadFertil=true
                    this.gineco_obstetrico = data.nota_gineco_obstetricos
                    if (this.gineco_obstetrico.metodo_anticonceptivo_id!=null) {
                        this.tiempo_uso_metodo=true
                    }
                }
                this.loading=false
            } catch (error) {
                window.scrollTo({ top: 0, behavior: 'smooth' })
                this.loading=false
                console.log(error);   
            }
        },

        clearData(){
            this.error=false
            this.errores={}
            this.errores2={}
            this.showDta=false
            this.edadFertil=false
            this.control_prenatal.password= ''
            this.control_prenatal.fum= ""
            this.control_prenatal.fpp= ""
            this.control_prenatal.sdp= ""
            this.control_prenatal.fu= ""
            this.control_prenatal.fcf= ""
            this.control_prenatal.en= ""
            this.control_prenatal.tension_arterial= ""
            this.control_prenatal.situacion= ""
            this.control_prenatal.presentacion= ""
            this.control_prenatal.dorso= null
            this.control_prenatal.pulso= ""
            this.control_prenatal.respiracion= ""
            this.control_prenatal.peso= ""
            this.control_prenatal.talla= ""
            this.control_prenatal.edema= null
            this.control_prenatal.temperatura= ""
            this.control_prenatal.factor_rh= null
            this.control_prenatal.grupo_sanguineo= null
            this.tension1=""
            this.tension2=""

            this.gineco_obstetrico.expediente_id= ""
            this.gineco_obstetrico.password= ''
            this.gineco_obstetrico.menarca= ""
            this.gineco_obstetrico.ritmo_menstrual= ""
            this.gineco_obstetrico.ultima_regla= ""
            this.gineco_obstetrico.inicio_vida_sexual= ""
            this.gineco_obstetrico.embarazos= false
            this.gineco_obstetrico.cantidad_embarazos= 1
            this.gineco_obstetrico.partos= false
            this.gineco_obstetrico.cantidad_partos= 1
            this.gineco_obstetrico.fecha_ultimo_parto= ""
            this.gineco_obstetrico.abortos= false
            this.gineco_obstetrico.cantidad_abortos= 1
            this.gineco_obstetrico.cesareas= false
            this.gineco_obstetrico.cantidad_cesareas= 1
            this.gineco_obstetrico.hijos_macrosomicos= false
            this.gineco_obstetrico.cantidad_hijos_macrosomicos= 1
            this.gineco_obstetrico.hijos_con_bajo_peso= false
            this.gineco_obstetrico.cantidad_hijos_con_bajo_peso= 1
            this.gineco_obstetrico.metodo_anticonceptivo_id= null
            this.gineco_obstetrico.tiempo_metodo_anticonceptivo= ""
        },

        async obtenerControlPrenatal(value) {
            
            try {
                const res = await fetch(`${url}/api/historia_clinica/control_prenatal/${value}`, {
                    method: 'get',
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        'Content-Type': 'application/json',
                        'X-Requested-With': 'XMLHttpRequest',
                        'Authorization': "Bearer "+ localStorage.getItem("token"),
                    }
                });
                 if (!res.ok) {
                    const message = `An error has occured: ${res.status} - ${res.statusText}`;
                    throw new Error(message);
                }
                const data = await res.json();

                var ta = ""
                var ta1 = ""

                if (data.nota_control_prenatal) {
                    this.embarazo_actual=true
                    this.control_prenatal = data.nota_control_prenatal
                    this.control_prenatal.edema = data.nota_control_prenatal.edema
                    this.control_prenatal.factor_rh = data.nota_control_prenatal.factor_rh
                    ta = this.control_prenatal.tension_arterial
                    ta1 = ta.indexOf("/")
                    this.tension1=ta.substring(0,ta1)
                    this.tension2 = ta.substring(ta1+1,ta.length)
                    if (data.nota_control_prenatal.edema == 1) {
                        this.control_prenatal.edema = true
                    } else {
                        this.control_prenatal.edema = false
                    }

                    if (data.nota_control_prenatal.factor_rh) {
                        this.control_prenatal.factor_rh = true
                    } else {
                        this.control_prenatal.factor_rh = false
                    }
                }
                this.loading=false
            } catch (error) {
                this.loading=false
                console.log(error);
            }
        },

        async obtenerMetodos() {
            try {
                const res = await fetch(`${url}/api/catalogos/metodos_planificacion`, {
                    method: 'get',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Requested-With': 'XMLHttpRequest',
                        'Authorization': "Bearer "+ localStorage.getItem("token"),
                    }
                });
                if (!res.ok) {
                    const message = `An error has occured: ${res.status} - ${res.statusText}`;
                    throw new Error(message);
                }
                const data = await res.json();
                this.metodos=data.metodos.map(item => {
                    return {
                        value: item.id,
                        text: item.nombre
                    };
                })
                this.metodos.unshift({value:null, text:"seleccione una opción"})
            } catch (error) {
                console.log(error);
            }
        },
        async getNotas() {
            try {
               const res = await fetch(`${url}/api/notas/${this.gineco_obstetrico.expediente_id}`, {
                    method: 'get',
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        'Content-Type': 'application/json',
                        'X-Requested-With': 'XMLHttpRequest',
                        'Authorization': "Bearer "+ localStorage.getItem("token"),
                    }
                });
                 if (!res.ok) {
                    const message = `An error has occured: ${res.status} - ${res.statusText}`;
                    throw new Error(message);
                }
                const data = await res.json();
                this.allNotas=data.notas

                !this.allNotas.historia_clinica.control_parental? this.allNotas.ingreso ? this.getIngreso():"":this.pie.state=false
                this.estado = this.allNotas.historia_clinica.gineco_obstetricos?'done':'f1'
                this.allNotas.historia_clinica.control_parental? this.pie.state=false:""
                this.obtenerGinecObstetrico(this.gineco_obstetrico.expediente_id)
                this.obtenerControlPrenatal(this.control_prenatal.expediente_id)
                this.done=true
                if (this.estado=='f1') {
                    this.verificarPreGuardado()
                }

           } catch (error) {
               console.log(error);
           }
        },

        async getIngreso() {
            try {
                const res = await fetch(`${url}/api/nota_ingreso/`+this.gineco_obstetrico.expediente_id, {
                    method: 'get',
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        'Content-Type': 'application/json',
                        'X-Requested-With': 'XMLHttpRequest',
                        'Authorization': "Bearer "+ localStorage.getItem("token"),
                    }
                });
                const data = await res.json();

                var ta = data.nota_ingreso.tension_arterial
                var ta1 = ta.indexOf("/")
                this.tension1=ta.substring(0,ta1)
                this.tension2 = ta.substring(ta1+1,ta.length)
                this.control_prenatal.pulso=data.nota_ingreso.frecuencia_cardiaca
                this.control_prenatal.respiracion=data.nota_ingreso.frecuencia_respiratoria
                this.control_prenatal.peso=data.nota_ingreso.peso
                this.control_prenatal.talla=data.nota_ingreso.talla
                this.control_prenatal.temperatura=data.nota_ingreso.temperatura
                this.pie.fecha=data.nota_ingreso.fecha_ingreso
                this.pie.hora=data.nota_ingreso.hora_ingreso
                
            } catch (error) {
                console.log(error);
            }
        },

        setData(){
            this.obtenerMetodos();
            const pacienteEncrypt=localStorage.expedienteEncrypt
            const expediente =  CryptoJS.AES.decrypt(pacienteEncrypt, 'secret key heal4701tech').toString(CryptoJS.enc.Utf8);
            const paciente=JSON.parse(expediente)
            this.no_expediente=paciente.no_expediente
            this.state.idExpediente=paciente.id
            this.state.idPaciente=this.idPacienteSeleccionado

            if (paciente.paciente.lastExpediente.expediente_id!=this.state.idExpediente) {
                    this.state.oldExp=true
            }else{
                    console.log("entrr en el else");
                    this.state.oldExp=false
            }

            this.gineco_obstetrico.expediente_id = paciente.id
            this.control_prenatal.expediente_id = paciente.id
            this.idPacienteSeleccionado = paciente.paciente.id
            this.obtenerDatosAntecedentes(this.state.idExpediente)
            this.getNotas()
            
        },
        async obtenerDatosAntecedentes(value) {
            
            try {
               const res = await fetch(`${url}/api/historia_clinica/antecedentes/${value}`, {
                    method: 'get',
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        'Content-Type': 'application/json',
                        'X-Requested-With': 'XMLHttpRequest',
                        'Authorization': "Bearer "+ localStorage.getItem("token"),
                    }
                });
                 if (!res.ok) {
                    const message = `An error has occured: ${res.status} - ${res.statusText}`;
                    throw new Error(message);
                }
                const data = await res.json();
                if (data.nota_antecedentes.firma.is_firmado==1) {
                    this.notaFirmada=true
                }else{
                    this.notaFirmada=false
                }  
           } catch (error) {
                window.scrollTo({ top: 0, behavior: 'smooth' })
                this.loading=false
                console.log(error);
           }
        },
        cerrarModal(){
            this.openSuccess=false
        },
        cerrarModalError(){
            this.openError=false
            this.busyUpdate = false
            this.busy=false
            if (!this.updating) {
                this.actualizar = true
                this.cancelar = true
                this.updating = false
            }
        },
        cerrarModalAlertPresionArterial(){
            this.openAlertPresion = false
        },
        showDtaPreSave(){
            console.log('presave');
            this.showDta = true
            this.verificarPreGuardado()
            this.openPreSave = false
        }
    },

    mounted(){

        if (!this.done) {
            const egreso=localStorage.egreso
            if (egreso) {
                const egresoDE =  JSON.parse(CryptoJS.AES.decrypt(egreso, 'secret key heal4701tech').toString(CryptoJS.enc.Utf8));
                console.log(egresoDE);
                this.isEgresado=egresoDE.egreso
            }else{
                router.push("/main")
            }
            this.setData()
            this.loading=true
        }
    },

    deactivated(){
    

    }
}
</script>

<style scoped>
    @media only screen and (min-width: 1200px) {
        .size_th {
            width: 21vw;
        }
    }

    @media only screen and (min-width: 900px) {
        .size_th_control_parental {
            width: 10vw;
        }
    }

    @media only screen and (max-width: 900px) {
        .size_th_control_parental {
            width: 20vw;
        }
    }
</style>