<template>
    <b-container>
        <b-row class="justify-content-between">
            <b-col cols="12" sm="12" md="12" lg="12">
                <h2 class="title_forms txt_primary text-uppercase pl-3">CARTA DE CONSENTIMIENTO INFORMADO</h2>
                <h4 class="txt_primary pl-3">Autorización para recibir procedimiento médico y/o quirúrgico</h4>     
            </b-col>
        </b-row>
        
        <b-container>
            <!-- LISTADO DE EXPEDIENTES -->
            <p class="txt_secondary text-uppercase mt-1"><strong>Lista de expedientes</strong></p>
            <b-table-simple class="table-sm border_table">
                <b-tbody>  
                    <b-tr>
                        <b-th class="th_style size_th">Expedientes</b-th>
                        <b-td class="border_table">
                            <b-form-select :disabled="!updating" v-model="state.idExpediente" @change="changeExp(state.idExpediente)" class="border-0" size="sm">
                                <template>
                                    <b-form-select-option v-for="(all) of allExp" :key="all.id" :value="all.id">{{all.no_expediente}}</b-form-select-option>
                                </template>
                            </b-form-select>
                        </b-td>
                    </b-tr>
                </b-tbody>
            </b-table-simple>

            <div class="sticky-top" v-if="(isEgresado && !loading)">
                <b-alert show dismissible variant="warning">
                    El paciente ha egresado desea ver la <b style="padding-top: 4px;">&rArr;</b> <strong class="alert-nota-egreso" @click="verNotaDeEgreso()">nota de egreso</strong>
                </b-alert>
            </div>

            <div v-else-if="(dtaExist && !loading)" class="sticky-top">
                <b-alert show dismissible variant="info">
                    <div class="d-flex">
                        <i class="fa fa-floppy-o fa-lg my-auto pr-2" />
                        Carta de consentimiento informado guardado correctamente
                    </div>
                </b-alert>
            </div>
            <Datos :changeData="changeDta"/>

            <!-- <p class="txt_secondary text-uppercase pt-3"><strong>Datos generales</strong></p>
            <b-table-simple class="table-sm">
                <b-tbody>  
                    <b-tr>
                        <b-th class="th_style size_th">Nombre</b-th>
                        <b-td class="border_table">
                            <b-form-input v-model="datosExpediente.paciente.nombre" class="border-0" size="sm" placeholder="Ingresa nombre del paciente"/>
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style">Fecha de nacimiento</b-th>
                        <b-td class="border_table">
                            <b-form-input disabled readonly v-model="dtaCarta.fecha_nacimiento" autocomplete="off" type="date" class="border-0 input-disabled" size="sm" placeholder="aaaa/mm/dd" />
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style">Edad</b-th>
                        <b-td class="border_table">
                            <b-row>
                                <b-col cols="12" sm="12" md="4" lg="3">
                                    <div class="d-flex">
                                        <span class="border-0 px-2"><span class="size-span">{{datosExpediente.paciente_edad_years}}</span></span>
                                        <span class="float-right px-1 py-auto">
                                            <span class="txt_gray size-span">años</span>
                                        </span>
                                    </div>
                                </b-col>
                                <b-col cols="12" sm="12" md="4" lg="3">
                                    <div class="d-flex">
                                        <span class="border-0 px-2"><span class="size-span">{{datosExpediente.paciente_edad_months}}</span></span>
                                        <span class="float-right px-1 py-auto">
                                            <span class="txt_gray size-span">meses</span>
                                        </span>
                                    </div>
                                </b-col>
                            </b-row>
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style">Expediente</b-th>
                        <b-td class="border_table">
                            <b-form-input v-model="datosPaciente.noExpediente" disabled readonly autocomplete="off" class="border-0 input-disabled" size="sm" placeholder="xxxx-xxxxx-x" />
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style">Domicilio</b-th>
                        <b-td class="border_table">
                            <b-form-input disabled readonly v-model="dtaCarta.domicilio" autocomplete="off" class="border-0 input-disabled" size="sm" placeholder="domicilio" />
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style">Código postal</b-th>
                        <b-td class="border_table">
                            <b-form-input disabled readonly v-model="dtaCarta.cp" autocomplete="off" class="border-0 input-disabled" size="sm" placeholder="código postal" />
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style">Colonia/Comunidad</b-th>
                        <b-td class="border_table">
                            <b-form-input disabled readonly v-model="dtaCarta.colonia" autocomplete="off" class="border-0 input-disabled" size="sm" placeholder="colonia/comunidad" />
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style">Ciudad</b-th>
                        <b-td class="border_table">
                            <b-form-input disabled readonly v-model="dtaCarta.ciudad" autocomplete="off" class="border-0 input-disabled" size="sm" placeholder="ciudad" />
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style">Responsable legal</b-th>
                        <b-td class="border_table">
                            <b-form-input disabled readonly v-model="dtaCarta.responsable" autocomplete="off" class="border-0 input-disabled" size="sm" placeholder="responsable del paciente" />
                        </b-td>
                    </b-tr>
                </b-tbody>
            </b-table-simple> -->
            

            <p class="txt_secondary text-uppercase pt-3"><strong>Información general</strong></p>
            <b-table-simple class="table-sm">
                <b-tbody>
                    <!-- <b-tr>
                        <b-th class="th_style size_th">Médico tratante</b-th>
                        <b-td class="border_table">
                            <b-form-input v-model="datosPaciente.medico" disabled readonly autocomplete="off" class="border-0 input-disabled" size="sm" placeholder="Médico tratante" />
                        </b-td>
                    </b-tr> -->
                    <b-tr>
                        <b-th class="th_style size_th">Diagnóstico</b-th>
                        <b-td class="border_table">
                            <b-form-input v-model="dtaCarta.diagnostico" disabled readonly autocomplete="off" class="border-0 input-disabled" size="sm" placeholder="Ingresa diagnóstico" />
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style size_th">Procedimiento médico</b-th>
                        <b-td class="border_table">
                            <b-form-input :disabled="(disableInput || state.oldExp || isEgresado)" v-model="dtaCarta.procedimiento_medico" :state="valProcedimientoMed" type="text" name="procedimiento" autocomplete="off" class="border-0" size="sm" placeholder="Ingresa nombre del procedimiento médico" />
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style size_th">Médico cirujano</b-th>
                        <b-td class="border_table">
                            <b-form-select :disabled="(disableInput || state.oldExp || isEgresado)" v-model="dtaCarta.cirujano_id" :state="valCirujano" :options="medicos" value-field="id" text-field="nombre" autocomplete="off" class="border-0" size="sm" />
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style size_th">Modalidades de atención</b-th>
                        <b-td class="border_table">
                            <b-form-input :disabled="(disableInput || state.oldExp || isEgresado)" v-model="dtaCarta.otras_modalidades" :state="valModalidades" autocomplete="off" class="border-0" size="sm" placeholder="Ingresa modalidades de atención" />
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style size_th">Procedimiento quirúrgico</b-th>
                        <b-td class="border_table">
                            <b-form-input :disabled="(disableInput || state.oldExp || isEgresado)" v-model="dtaCarta.procedimiento_quirurgico" :state="valProcedimientoQui" type="text" name="procedQuirurgico" autocomplete="off" class="border-0" size="sm" placeholder="Ingresa nombre del procedimiento médico" />
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style size_th">Beneficios</b-th>
                        <b-td class="border_table">
                            <b-form-input :disabled="(disableInput || state.oldExp || isEgresado)" v-model="dtaCarta.beneficios" :state="valBeneficios" type="text" name="beneficios" autocomplete="off" class="border-0" size="sm" placeholder="Ingresa beneficios de dicho procedimiento" />
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-th class="th_style size_th">Riesgos</b-th>
                        <b-td class="border_table">
                            <b-form-input :disabled="(disableInput || state.oldExp || isEgresado)" v-model="dtaCarta.riesgos" :state="valRiesgos" type="text" name="riesgos" autocomplete="off" class="border-0" size="sm" placeholder="Ingrese riesgos de dicho procedimiento" />
                        </b-td>
                    </b-tr>
                </b-tbody>
            </b-table-simple>

            <b-table-simple class="table-sm">
                <b-tbody>
                    <b-tr>
                        <b-th class="th_style size_th">Fecha</b-th>
                        <b-td class="border_table">
                            <b-form-input :disabled="(disableInput || state.oldExp || isEgresado)" v-model="dtaCarta.fecha" :state="valFecha" name="fechaCartaConsentim" type="date" class="border-0" size="sm" placeholder="DD/MM/AAAA" />
                        </b-td>
                    </b-tr>
                </b-tbody>
            </b-table-simple>

            <b-row v-if="error && !state.oldExp" class="my-3">
                <b-col cols="12">
                    <div class="contenedor-de-errores pt-3 pb-1">
                        <ul class="lista-errores px-2">
                            <li v-for="(err,index) of errores" :key="index"><i class="fa fa-warning px-1" v-if="!err.expediente_id" />| {{err[0]}} <br>
                            </li>
                        </ul>
                    </div>
                </b-col>
            </b-row>

            <div v-if="(btnGuardar && !dtaExist && !state.oldExp && !isEgresado)" class="d-flex justify-content-end my-4">
                <b-col cols="12" sm="12" md="5" lg="5">
                    <b-overlay
                    :show="busy"
                    rounded
                    opacity="0.6"
                    spinner-small
                    spinner-variant="primary"
                    >
                        <button class="btn_formluario border-0 btn-block py-2 txt_white" :disabled="busy" @click="cartaConseimiento()">GUARDAR</button>
                    </b-overlay>
                </b-col>
            </div> 
            <div v-if="(dtaExist && actualizar && !state.oldExp && !isEgresado)" class="d-flex justify-content-end mt-4 mb-3">
                <b-col cols="12" sm="12" md="5" lg="5">
                    <b-overlay
                    :show="busyUpdate"
                    rounded
                    opacity="0.6"
                    spinner-small
                    spinner-variant="primary"
                    >
                        <button class="btn_formluario border-0 btn-block py-2 txt_white" @click="updateData()" :disabled="busyUpdate">ACTUALIZAR</button>
                    </b-overlay>
                </b-col>
            </div>
            <div v-if="dtaExist && cancelar && !state.oldExp" class="d-flex justify-content-end mb-4">
                <b-col cols="12" sm="12" md="5" lg="5">
                    <button class="btn_formluario border-0 btn-block py-2 txt_white" @click="cancelEdit()">CANCELAR</button>
                </b-col>
            </div>
        </b-container>
        <div class="sticky-container" v-if="(idPacienteSeleccionado!='' && !openError && !openPreSave && !openSuccess && !regNewExp && !loading)">
            <ul class="sticky">
                <li v-if="(!dtaExist && !state.oldExp && !isEgresado)" @click="preGuardar()">
                    <img :src="require('../../../assets/icons/guardar.png')" width="50" height="32">
                    <p>
                        <a>Preguardar</a>
                    </p>
                </li>
                <li v-if="(dtaExist && updating && !state.oldExp && !isEgresado)" @click="editar()">
                    <img :src="require('../../../assets/icons/editar.png')" width="57" height="32">
                    <p>
                        <a>Editar</a>
                    </p>
                </li>
                <li v-if="dtaExist && updating" @click="imprimir()">
                    <img :src="require('../../../assets/icons/imprimir.png')" width="50" height="32">
                    <p>
                        <a>Imprimir</a>
                    </p>
                </li>
            </ul>
        </div>

        <Error :abrir="openError" @cerrarModalError="cerrarModalError()"/> 
        <DatosPreguardados :abrirPreSave="openPreSave" @showDtaPreSave="showDtaPreSave()" @cerrarModalPreSave="cerrarModalPreSave()"/>
        <Success :abrir="openSuccess" @cerrarModal="cerrarModal()"/>
        <NuevoExpediente :openRegExp="regNewExp"/>
        <Loading :show="loading"/>

    </b-container>
</template>

<script>
import Vue from "vue";
import PDFMerger from "pdf-merger-js";
import { url } from "../../../../settings";
const urlp = `${url}/api/pacientes`
import CryptoJS from 'crypto-js';
import Success from "@/components/Modals/Success.vue";
import Error from "@/components/Modals/Error.vue";
import DatosPreguardados from "@/components/Modals/DatosPreguardados.vue"
import NuevoExpediente from '@/components/Modals/NuevoExpediente.vue';
import { getCatalogo } from '../../../helpers/catalogos'
import Loading from '@/components/Loader/Loading.vue'
import router from "../../../router";
import Datos from '@/components/Paciente/Visualizar/Datos.vue'

export default {
    name: 'CartaConsentim',
    components: { Success, Error, DatosPreguardados, NuevoExpediente, Loading, Datos },

    data: () => ({
        changeDta:null,
        isEgresado:false,
        state:{
            idExpediente:"",
            idPaciente: "",
            oldExp:false,
        },
        allExp:[],


        regNewExp: false,
        editing: false,
        updating: true,
        disableInput: false,
        actualizar: false,
        cancelar: false,
        busyUpdate: false,
        dtaExist: false,

        loading:true,

        datosPreGuardados:[],
        showDta: false,
        openPreSave: false,

        error:"",
        errores:[],
        openError: false,
        openSuccess: false,
        cargado:false,
        btnGuardar: false,
        btnFirmar: true,
        busy: false,
        idPacienteSeleccionado: "",
        datosExpediente:{paciente:{nombre:""}},
        pacientes:[{}],

        dtaCarta: {
            id: 0,
            expediente_id: null,
            nombre: "",
            no_expediente: "",
            fecha_nacimiento: "",
            edad: 0,
            cp: "",
            domicilio: "",
            colonia: "",
            ciudad: "",
            estado: "",
            municipio: "",
            responsable: "",
            medico_tratante: "",
            diagnostico: "",
            procedimiento_medico: "",
            cirujano_id: null,
            medico_cirujano: null,
            otras_modalidades: "",
            procedimiento_quirurgico: "",
            beneficios: "",
            riesgos: "",
            fecha: "",
        },

        datosPaciente: {
            password:"",
            edad:"",
            id: null,
            nombre: "",
            fecha_nacimiento: "",
            estado_civil: {
                id: null,
                nombre: ""
            },
            cp: "",
            domicilio: "",
            colonia: "",
            ciudad: "",
            estado: {
                id:null,
                nombre: "",
            },
            municipio:{
                id:null,
                nombre: "",
            },
            telefono: "",
            email: null,
            genero:{
                id:null,
                nombre: "",
            },
            lastExpediente: {
                expediente_id: null,
                no_habitacion: null
            },
        },
        medicos:[],
    }),

    computed: {
        validation() {
            return this.idPacienteSeleccionado != ""
        },

        valProcedimientoMed() {
            return this.dtaCarta.procedimiento_medico != '' && this.dtaCarta.procedimiento_medico.length >= 5 && this.dtaCarta.procedimiento_medico.length <= 250
        },

        valCirujano() {
            return !(this.dtaCarta.cirujano_id === null)
        },

        valModalidades() {
            return this.dtaCarta.otras_modalidades != '' && this.dtaCarta.otras_modalidades.length >= 5 && this.dtaCarta.otras_modalidades.length <= 250
        },

        valProcedimientoQui() {
            return this.dtaCarta.procedimiento_quirurgico != '' && this.dtaCarta.procedimiento_quirurgico.length >= 5 && this.dtaCarta.procedimiento_quirurgico.length <= 250
        },

        valBeneficios() {
            return this.dtaCarta.beneficios != '' && this.dtaCarta.beneficios.length >= 5 && this.dtaCarta.beneficios.length <= 250
        },

        valRiesgos() {
            return this.dtaCarta.riesgos != '' && this.dtaCarta.riesgos.length >= 5 && this.dtaCarta.riesgos.length <= 250
        },

        valFecha() {
            return this.dtaCarta.fecha != '' 
        }
    },

    methods: {
        verNotaDeEgreso() {
          this.$emit('toEgreso')  
        },

        changeExp(id){
            this.loading=true

            let exps = this.allExp
            let currentExp = exps.find(exp => exp.id == id)
            console.log(currentExp);

            if (currentExp) {
                this.sinExp=false
                this.changeDta=currentExp.id
                var expediente = CryptoJS.AES.encrypt(JSON.stringify(currentExp), 'secret key heal4701tech')
                localStorage.setItem('expedienteEncrypt', expediente);
                this.setData(true)
            }
        },

        async allExpedientes() {
            try {
                const res = await fetch(`${url}/api/datos_medicos/`+this.idPacienteSeleccionado, {
                    method: 'get',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Requested-With': 'XMLHttpRequest',
                        'Authorization': "Bearer "+ localStorage.getItem("token"),
                    }
                });

                const data = await res.json();
                this.allExp=data.expedientes
                console.log(this.allExp);

            } catch (error) {
                console.log("el error es"+error);
            }
        },

        makeToast(variant = null) {
            this.$bvToast.toast('Los datos han sido preguardados con exito', {
            title: "Datos preguardados",
            variant: variant,
            solid: true
            })
        },

        toastPrint(variant = null) {
            this.$bvToast.toast('Seleccione un paciente', {
            title: "¡Error!",
            variant: variant,
            solid: true
            })
        },

        cancelEdit() {
            this.getCarta()
            this.disableInput = true
            this.actualizar = false
            this.updating = true
            this.cancelar = false
            this.error = false
            this.errores = []
        },

        editar() {
            this.actualizar = true
            this.cancelar = true
            this.updating = false
            this.disableInput = false
            this.btnGuardar = false
        },

        async updateData() {
            this.error=false
            this.errores=[]
            this.busyUpdate = true
            console.log(this.dtaCarta);
            try {
                const res = await fetch(`${url}/api/carta_consentimiento/${this.dtaCarta.id}`, {
                    method: 'put',
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        'Content-Type': 'application/json',
                        'X-Requested-With': 'XMLHttpRequest',
                        'Authorization': "Bearer "+ localStorage.getItem("token"),
                    },
                    body: JSON.stringify(this.dtaCarta)
                });
                const data = await res.json()
                Vue.prototype.$error500 = res.status===500
                var warn = false
                var err=[]
                var promise = Promise.resolve(data)
                await promise.then(function(val) {
                    if (val.class=="success") {
                        warn=true
                    }else{
                        err=val.errors   
                    }
                });
                if (warn) {
                    this.getCarta()
                    this.openSuccess=true
                    this.updating = true
                    this.disableInput = true
                    this.busyUpdate=false
                    this.actualizar = false
                    this.cancelar = false
                }else{
                    this.openError=true
                    this.errores=err
                    this.error=true
                    this.busyUpdate = false
                }
            } catch (error) {
                this.busyUpdate = false
                console.log(error)
            }
        },

        async imprimir(){
            // var can = false;
            var este = this;
            this.loading = true
            let urlim = `${url}/api/carta_consentimiento/${this.dtaCarta.expediente_id}/true`;
            return fetch(urlim, {
                method: 'get',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Requested-With': 'XMLHttpRequest',
                    'Authorization': "Bearer "+ localStorage.getItem("token"),
                }
            }).then(function(resp){
                // can = resp.ok
                return resp.blob("paciente.pdf")
            }).then(function(blob) {
                // if (can) {
                //     este.loading = false
                //     blob.name="paciente.pdf"
                //     var w = 900
                //     var h = 600
                //     const dualScreenLeft = window.screenLeft !==  undefined ? window.screenLeft : window.screenX;
                //     const dualScreenTop = window.screenTop !==  undefined   ? window.screenTop  : window.screenY;

                //     const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
                //     const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;

                //     const systemZoom = width / window.screen.availWidth;
                //     const left = (width - w) / 2 / systemZoom + dualScreenLeft
                //     const top = (height - h) / 2 / systemZoom + dualScreenTop

                //     var url = URL.createObjectURL(blob);
                //     var newWindow = window.open(url, "_blank",
                //         `
                //         scrollbars=yes,
                //         width=${w / systemZoom}, 
                //         height=${h / systemZoom}, 
                //         top=${top}, 
                //         left=${left}
                //         `
                //     )
                //     setTimeout(function(){ 
                //         newWindow.document.title = 'Número de expediente';
                //     }, 100);
                // } else {
                //     este.loading = false
                // }
                este.downloadPDF(blob)

            })
        },
        async downloadPDF(blob){
            try {
                var merger = new PDFMerger()
                await merger.add(URL.createObjectURL(blob))
                await merger.save(this.datosPaciente.noExpediente+ " CARTA DE CONSENTIMIENTO INFORMADO");
                this.loading = false
            } catch (error) {
                this.loading = false
            }
        },
        preGuardar() {
            var existe=false;
            try {
                if (localStorage.preGuardadoCarta) {
                    console.log("si hay datos");
                    this.datosPreGuardados=JSON.parse(localStorage.preGuardadoCarta)
                    console.log(this.datosPreGuardados);
                }
            } catch (error) {
                console.log(error);
            }
             var i =0;
            for (let val of this.datosPreGuardados) {
                console.log("valor: "+val.expediente_id);
                if (val&&this.dtaCarta.expediente_id===val.expediente_id) {
                    console.log("ya esta we");
                    this.datosPreGuardados[i]=this.dtaCarta
                    existe=true
                }
                i++
            }
            if (!existe) {
                this.datosPreGuardados.push(this.dtaCarta);
            }
            const dta = JSON.stringify(this.datosPreGuardados)
            localStorage.setItem('preGuardadoCarta', dta);
            this.makeToast('success');
            console.log(this.datosPreGuardados);
        },
        verificarPreGuardado(){
            /* this.openPreSave = true */
            try {
                if (localStorage.preGuardadoCarta) {
                    this.datosPreGuardados=JSON.parse(localStorage.preGuardadoCarta)
                }
            } catch (error) {
                console.log(error);
            }
            for (let val of this.datosPreGuardados) {
                if (this.dtaCarta.expediente_id===val.expediente_id) {
                    if (this.showDta === true) {
                        this.dtaCarta=val
                    } else {
                        this.openPreSave = true
                    }
                }
            }
        },

        async obtenerPacientes() {
            try {
                const res = await fetch(`${urlp}`, {
                    method: 'get',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Requested-With': 'XMLHttpRequest',
                        'Authorization': "Bearer "+ localStorage.getItem("token"),
                    }
                });
                const data = await res.json();
                this.pacientes=data.pacientes
                this.pacientes.unshift({id:"", nombre:"Seleccionar paciente"})
            } catch (error) {
                console.log(error);
            }
        },

        async getCarta(){
            this.dtaExist = false
            try {
                const res = await fetch(`${url}/api/carta_consentimiento/${this.dtaCarta.expediente_id}`, {
                    method: 'get',
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        'Content-Type': 'application/json',
                        'X-Requested-With': 'XMLHttpRequest',
                        'Authorization': "Bearer "+ localStorage.getItem("token"),
                    }
                });
                const data = await res.json();
                console.log(data);
                if (data.status == false) {
                    this.disableInput = false
                    this.btnGuardar = true
                } else {
                    this.dtaExist = true
                    this.disableInput = true
                    this.btnGuardar = false
                } 
                this.dtaCarta=data.consentimiento
                this.dtaCarta.medico_tratante=data.consentimiento.medico_tratante.nombre
                window.scrollTo({ top: 0, behavior: 'smooth' })
                this.loading=false
                if (this.dtaCarta.cirujano_id===0) {
                    this.dtaCarta.cirujano_id=null
                }
                if (data.status == false) {
                    try {
                        if (Vue.prototype.$procedimientoPaciente) {
                            console.log("entre en");
                            console.log(Vue.prototype.$procedimientoPaciente);
                            this.dtaCarta.procedimiento_medico = Vue.prototype.$procedimientoPaciente
                        }
                    } catch (error) {
                        console.log("error");
                        this.dtaCarta.procedimiento_medico=""
                    }
                }
                if (!data.status) {
                    this.getFecha()
                    this.verificarPreGuardado()
                }
                
            } catch (error) {
                window.scrollTo({ top: 0, behavior: 'smooth' })
                this.loading=false
                console.log("el error es"+error);
            }
        },
        

        setData(){
            const pacienteEncrypt=localStorage.expedienteEncrypt
            const expediente =  CryptoJS.AES.decrypt(pacienteEncrypt, 'secret key heal4701tech').toString(CryptoJS.enc.Utf8);
            const paciente=JSON.parse(expediente)

            this.state.idExpediente=paciente.id
            this.state.idPaciente=paciente.paciente.id

            if (paciente.paciente.lastExpediente.expediente_id!=paciente.id) {
                this.state.oldExp=true
            }else{
                this.state.oldExp=false
            }

            console.log(paciente);
            if (paciente.paciente.telefono==null) {
                paciente.paciente.telefono="-----"
            }
            if (paciente.paciente.email==null) {
                paciente.paciente.email="-----"
            }
            this.idPacienteSeleccionado=paciente.paciente.id
            this.dtaCarta.expediente_id=paciente.id;
            this.datosPaciente=paciente.paciente
            this.datosPaciente.estado_civil_id=""
            this.datosPaciente.responsable = paciente.responsable_paciente.nombre
            this.datosPaciente.noExpediente=paciente.paciente.no_expediente
            this.datosExpediente=paciente
            this.datosPaciente.medico=paciente.medico.nombre
            this.datosPaciente.diagnostico=paciente.diagnostico
            this.btnGuardar = false;
            this.btnFirmar = true;

            var year = new Date(this.datosPaciente.fecha_nacimiento);
            var today = new Date();
            var yyyy = today.getFullYear();
            this.datosPaciente.edad= yyyy-year.getFullYear();
            this.getCarta()
            this.allExpedientes()
        },

        getFecha(){
            console.log("toy entrando");
            var today = new Date();
            var dd = ("0" + (today.getDate())).slice(-2);
            var mm = ("0" + (today.getMonth() +1)).slice(-2);
            var yyyy = today.getFullYear();
            this.dtaCarta.fecha = yyyy + '-' + mm + '-' + dd ;

            console.log(this.dtaCarta.fecha);
        },

        async cartaConseimiento() {
            this.error=false;
            this.errores=[];
            this.busy = true
            try {
                const res = await fetch(`${url}/api/carta_consentimiento`, {
                    method: 'post',
                    headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-Type": "application/json",
                    "X-Requested-With": "XMLHttpRequest",
                    'Authorization': "Bearer "+ localStorage.getItem("token"),
                    },
                    body: JSON.stringify(this.dtaCarta),
                });
                const data = res.json();
                Vue.prototype.$error500 = res.status===500
                var warn = false;
                var err =[];
                var promise = Promise.resolve(data);
                await promise.then(function(val) {
                    console.log(val);
                    if (val.class=="success") {
                        warn = true
                    }else{
                        err=val.errors
                    }
                });
                if (warn) {
                    this.busy=false
                    this.openSuccess=true
                    this.getCarta(this.dtaCarta.expediente_id)
                }else{
                    console.log("entre al else");
                    this.error=true
                    this.errores=err
                    this.openError=true
                    this.openError=true
                    this.busy=false
                }
            } catch (error) {
                console.log(error);
            }
       },
       
       getCatalogos() {
            //  MÉDICOS **********************************************************************************************************************************
            new Promise((resolve)=>{
                resolve(getCatalogo('/api/catalogos/medicos'))
            }).then((data)=>{
                try {
                    this.medicos=data.medicos
                    this.medicos.unshift({id: null, nombre: 'seleccione una opción'})
                } catch (error) {
                    console.log(error);
                }
            })
       },
       cerrarModal(){
            this.openSuccess=false
        },
        cerrarModalError(){
            console.log('cerrarModalError');
            this.openError=false
        },
        showDtaPreSave(){
            this.showDta = true
            this.verificarPreGuardado()
            this.openPreSave = false
        },
        cerrarModalPreSave(){
            this.openPreSave = false
        }
    },

    mounted() {

    },
    destroyed() {  
        this.$destroy();
        // this.$el.parentNode.removeChild(this.$el);
    },
    activated(){
        Object.assign(this.$data, this.$options.data());

        const egreso=localStorage.egreso
        if (egreso) {
            const egresoDE =  JSON.parse(CryptoJS.AES.decrypt(egreso, 'secret key heal4701tech').toString(CryptoJS.enc.Utf8));
            console.log(egresoDE);
            this.isEgresado=egresoDE.egreso
        }else{
            router.push("/main")
        }

        this.getCatalogos()
        this.setData()
        this.loading=true
        // setTimeout(() => {
        //     window.scrollTo({ top: 0, behavior: 'smooth' })
        //     this.loading=false
        // }, 1500);
    },
    deactivated(){

    },
}
</script>

<style scoped>
    @media only screen and (min-width: 1200px) {
        .size_th {
            width: 20vw;
        }
    }

    @media only screen and (max-width: 1200px) {
        .size_th {
            width: 20vw;
        }
    }

    @media only screen and (max-width: 992px) {
        .size_th {
            width: 30vw;
        }
    }

    @media only screen and (max-width: 768px) {
        .size_th {
            width: 20vw;
        }
    }

    @media only screen and (max-width: 576px) {
        .size_th {
            width: auto;
        }
    }
</style>