<template>
    <b-container>
        <b-row v-if="current">
            <NotaPreAnestesicaUno 
            ref="uno"  
            v-show="current==='NotaPreAnestesicaUno'"
             @preGuardarNPreA="preGuardarNPreA()" 
             @toEgreso="$emit('toEgreso')" 
             @changeStepperPreAnestesica="changeStepperPreAnestesica($event)" 
             @expChangedPA="expChangedPA()" 
             @datosPaTwo="datosPaTwo($event)"
             @showDtaPreSave="showDtaPreSave()"
             @imprimir="imprimir()"
             @editar="editar()"
             />

            <NotaPreAnestesicaDos 
            ref="dos"  
            v-show="current==='NotaPreAnestesicaDos'" 
            @preGuardarNPreA="preGuardarNPreA()" 
            @toEgreso="$emit('toEgreso')" 
            @changeStepperPreAnestesica="changeStepperPreAnestesica($event)" 
            @borrarDatosPreAnestesica="borrarDatosPreAnestesica()" 
            @errores="errores($event)" 
            @updatePA="updatePA()"
            @cancelarUpdatePA="cancelarUpdatePA()"/>
        </b-row>
    </b-container>
</template>

<script>
import { NotaPreAnestesicaUno, NotaPreAnestesicaDos } from '@/components/Paciente/NotaPreAnestesica'
export default {
    name:'HCMain',
    components: { 
        NotaPreAnestesicaUno, 
        NotaPreAnestesicaDos
    },
    data:() => ({
        current:'NotaPreAnestesicaUno'
    }),
    methods:{
        changeStepperPreAnestesica(value){
            localStorage.setItem("view",value)
            this.current = value;
        },
        datosPaTwo(value){
            this.datosFormUno = value.datos
            this.update = value.update
            setTimeout(() => {
                this.$refs.dos.datosPaTwo(value.datos,value.update)
            }, 300);
        },
        preGuardarNPreA(){
            this.$refs.uno.preGuardarNPreA()
            this.$refs.dos.preGuardarNPreA()
        },
        errores(v){
            console.log(v);
            this.$refs.uno.err(v)
        },
        borrarDatosPreAnestesica(){
        this.$refs.uno.borrarDatosPreAnestesica()
         },
        expChangedPA(){
            this.$refs.dos.expChangedPA()
        },
        updatePA(){
            this.$refs.uno.editar()
        },
        cancelarUpdatePA(){
            this.$refs.uno.cancelEdit()
        },
        showDtaPreSave(){
            this.$refs.dos.showDtaPreSave()
        },
        imprimir(){
            this.$refs.dos.imprimir()
        },
        editar(){
            this.$refs.dos.editar()
        }
    },
    activated(){
        this.current='NotaPreAnestesicaUno'
    },
    deactivated(){
        console.log('deactive');
        this.current=null
        console.log(this.current);
    },

}
</script>

<style></style>